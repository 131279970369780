import React, { FC, useEffect } from 'react'
import { IRankingOperators } from './interfaces/IRankingOperators';
import RankingList from '../rakingList/RankingList';
import { moneyMaskNumber } from 'services/utils/Money';
import { IParamsUseRankingOperators } from '../../hooks/UseRankingOperators';

export interface IRankingOperatorsProps {
    getRankingOperators: (params?: IParamsUseRankingOperators) => Promise<IRankingOperators[] | undefined>
    rankingOperators: IRankingOperators[] | undefined
    isLoading: boolean | undefined
}

const RankingOperators: FC<IRankingOperatorsProps> = ({ getRankingOperators, isLoading, rankingOperators }) => { 

    useEffect(() => {
        getRankingOperators();
    }, [getRankingOperators])    
 
    return (
        <div>
            <RankingList
                // keyProp="id"
                list={rankingOperators}
                isLoading={isLoading}
                colunms={{
                    name: { title: "Nome" },
                    consumed: { title: "Consumido", formatValue: (value) => moneyMaskNumber(value) },
                    value: { title: "Cobrado", formatValue: (value) => moneyMaskNumber(value) },
                }}
            />
        </div>
    )
}
export default RankingOperators