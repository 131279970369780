import styles from "./SimpleRegister.module.scss";
import { useForm } from "react-hook-form";
import { CircularProgress, MenuItem, Select, TextField } from "@mui/material";
import { ISimpleRegisterSchema, simpleRegisterSchema } from "modules/saas/domain/schema/simpleRegisterSchema";
import { zodResolver } from '@hookform/resolvers/zod';
import { StepEnum, UseSimpleRegister } from "./UseSimpleRegister";
import Button from "components/ui/Button/Button";
import { useEffect, useState } from "react";
import UseDimension from "components/dimension/UseDimension";
import ReactCodeInput from "react-code-input";
import Timer from "../../components/timer/Timer";
import { useQuery } from "hooks/UseQuery";
import SaasPublicLayout from "../SaasPublicLayout";
import ShowPassword from "../../components/showPassword/ShowPassword";

const SimpleRegister = () => {
    const { dimensions } = UseDimension();
    const query = useQuery();
    const {
        segments,
        step,
        isLoading,
        setStep,
        handleRegister,
        handleValidate
    } = UseSimpleRegister();
    
    const [showPassword, setShowPassword] = useState(false);
    const [code, setCode] = useState("");

    const { register, handleSubmit, formState: { errors }, setValue, getValues } = useForm<ISimpleRegisterSchema>({
        resolver: zodResolver(simpleRegisterSchema)
    });

    useEffect(() => {
        const email = query.get("email");
        if (email) {
            setValue("email", email);
        }
    }, [query, setValue]);

    useEffect(() => {
        if (!getValues("segment")) {
            setValue("segment", undefined);
        }
    }, [getValues, setValue, step]);

    return (
        <SaasPublicLayout backgroundColorTop="#6B41C9" backgroundColorBottom="#865FDD" terms={step === StepEnum.form}>
            <div className={styles.content}>
                <img src="/assets/icon/restaurant-icon.svg" alt=""/>

                {step === StepEnum.form && (
                    <>
                        <h1>Vamos criar a sua conta</h1>
                        <form
                            onSubmit={handleSubmit(handleRegister)}
                        >
                            <div className={styles.column}>
                                <label htmlFor="establishmentName" className={styles.required}>Nome do seu negócio</label>
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    size="small"
                                    {...register("establishmentName")}
                                    helperText={errors.establishmentName?.message}
                                    error={!!errors.establishmentName}
                                    disabled={isLoading}
                                />
                            </div>

                            <div className={styles.column}>
                                <label htmlFor="segment">Qual o seu segmento?</label>
                                <Select
                                    style={{ width: "100%" }}
                                    size="small"
                                    placeholder="Passo 01"
                                    {...register("segment")}
                                    defaultValue={getValues("segment")}
                                    disabled={isLoading}
                                >
                                    {
                                        segments.map(item => (
                                            <MenuItem value={item.id} key={item.id}>
                                                {item.description}
                                            </MenuItem>
                                        ))
                                    }
                                </Select>
                            </div>

                            <div className={styles.row} style={{ flexDirection: dimensions.width < 601 ? "column" : "row" }}>
                                <div className={styles.column}>
                                    <label htmlFor="email" className={styles.required}>E-mail</label>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        size="small"
                                        {...register("email")}
                                        helperText={errors.email?.message}
                                        error={!!errors.email}
                                        disabled={isLoading}
                                    />
                                </div>
                                <div className={`${styles.column} ${styles.inputPass}`}>
                                    <label htmlFor="password" className={styles.required}>Senha</label>
                                    <TextField
                                        variant="outlined"
                                        type={showPassword ? "text" : "password"}
                                        fullWidth
                                        size="small"
                                        {...register("password")}
                                        helperText={errors.password?.message}
                                        error={!!errors.password}
                                        disabled={isLoading}
                                    />
                                    <ShowPassword showPassword={showPassword} setShowPassword={setShowPassword} />
                                </div>
                            </div>

                            <Button type="submit" color="secondary">
                                {isLoading ? <CircularProgress size={16} color="inherit" /> : "Iniciar teste grátis"}
                            </Button>
                        </form>
                    </>
                )}
                {step === StepEnum.validate && (
                    <>
                        <div className={styles.header}>
                            <h1>Agora, vamos confirmar seu email</h1>
                            <span>Leva só 1 minuto</span>
                        </div>

                        <div className={styles.validateContent}>
                            <p>Digite abaixo o código enviado para o seu e-mail:</p>
                            <div className={styles.row}>
                                <ReactCodeInput
                                    inputMode="numeric"
                                    name="code"
                                    type="number"
                                    fields={6}
                                    value={code}
                                    onChange={(value) => setCode(value)}
                                    inputStyle={{
                                        fontFamily: "'Roboto', sans-serif",
                                        fontSize: "18px",
                                        fontWeight: "bold",
                                        borderRadius: "8px",
                                        border: "1px solid #A2A2A2",
                                        margin: "4px",
                                        padding: "10px",
                                        width: dimensions.width > 600 ? "50px" : dimensions.width > 460 ? "40px" : "30px",
                                        height: dimensions.width > 600 ? "50px" : dimensions.width > 460 ? "40px" : "30px",
                                        boxSizing: "border-box",
                                        textAlign: "center"
                                    }}
                                />
                            </div>

                            <Timer initialTime={120} handleNewCode={() => handleRegister(getValues())} style={{ marginTop: "20px" }} />

                            <div className={styles.footerButtons}>
                                <Button variant="text" color="secondary" onClick={() => setStep(StepEnum.form)}>Voltar</Button>
                                <Button color="secondary" onClick={() => handleValidate(getValues("email"), code)} disabled={code.length < 6}>
                                    {isLoading ? <CircularProgress size={16} color="inherit" /> : "Começar"}
                                </Button>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </SaasPublicLayout>
    )
}

export default SimpleRegister;