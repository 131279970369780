import { useLocal } from "modules/local/presentation/context/LocalContext";
import { useCallback, useState } from "react";
import DashboardTicketApi from "services/api/dashboardTicket/DashboardTicketApi";
import { ISession } from "../interfaces/ISession";
import GetSessionUseCase from "modules/dashboardTicket/application/useCases/GetSessionUseCase";

const UseSession = () => {

  const [isLoading, setIsLoading] = useState<boolean>();
  const [session, setSession] = useState<ISession[]>()
  const { currentLocal } = useLocal();

  const getSession = useCallback(
    async (quantity?: number) => {
      try {
        const servicePermutation = DashboardTicketApi();
        setIsLoading(true);
        if (currentLocal) {
          const response = await GetSessionUseCase(servicePermutation,
            currentLocal.id,
            quantity ?? 40
          );

          setSession(response);

          return response
        }
      } finally {
        setIsLoading(false);
      }
    },
    [currentLocal]
  );


  return {
    session,
    isLoading,
    getSession,
    setSession,
  };
};

export default UseSession;
