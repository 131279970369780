import React from 'react';
import {
  LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer
} from 'recharts';
import styles from './TransactionChart.module.scss';
import { ITransactions } from '../../interfaces/IAllMovements';
import Utils from 'services/utils/Utils';

interface Props {
  transactions: ITransactions[] | undefined;
}

const TransactionChart: React.FC<Props> = ({ transactions }) => { 
  const data = transactions?.map(transaction => ({
    date: Utils.toDateAndTime(transaction.dateTransactions),
    credito: transaction.creditValue,
    debito: transaction.debitValue,   
    dinheiro: transaction.moneyValue,
    outros: transaction.outhersValue,
  }));

  return (
    <div id={styles.TransactionChart}>
      <ResponsiveContainer width="100%" height={500}>
        <LineChart
          data={data}
          margin={{
            top: 10, right: 30, left: 0, bottom: 0,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Line type="monotone" dataKey="credito" stroke="#DE9C00" />
          <Line type="monotone" dataKey="debito" stroke="#955CFF" />
          <Line type="monotone" dataKey="dinheiro" stroke="#FF00A6" />
          <Line type="monotone" dataKey="outros" stroke="#32008E" />
        </LineChart>
      </ResponsiveContainer>
      <p className={styles.obs}><b>Obs.:</b> O valor de venda cashless não entra no total, uma vez que já está presente na venda de recarga/ativação do cashless nas formas Crédito/Débito/Dinheiro</p>
    </div>
  );
};

export default TransactionChart;