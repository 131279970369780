import { IGetCashlessResponse } from "modules/generalDashboard/domain/dto/IGetCashlessResponse";
import { IGeneralDashboardApiService } from "modules/generalDashboard/domain/interfaces/IGeneralDashboardApiService";



const GetCashlessUseCase = async (service: IGeneralDashboardApiService, localId: string): Promise<IGetCashlessResponse> => {
    const response = await service.getCashless(localId);

    return response;
}

export default GetCashlessUseCase;