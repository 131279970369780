
import { ISaveCatalogProductRequest } from "modules/catalog/domain/dtos/IAddCatalogProductRequest"
import { ICatalogService } from "modules/catalog/domain/interface/ICatalogService"
import { IProductFormValue } from "modules/catalog/presentation/componentes/productForm/IProducFormValuet"

const EditProductUseCase = async (service: ICatalogService, localId: string, productId: string, values: IProductFormValue) => {

    const request: ISaveCatalogProductRequest = {
        ...values,
        localId: localId,
        catalogIds: values.catalogs?.map(catalog => catalog.id) ?? [],
        categoryId: values.category?.id,
        defaultPrinterId: values.defaultPrinter?.id,
        eventId: values.event?.id,
        enabled: values.enabled ?? true,
    }

    await service.editProduct(localId, productId, request);
}
export default EditProductUseCase