import { IDashboardTicketApiService } from "modules/dashboardTicket/domain/interfaces/IMyAccountApiService";
import { IGetMyAccountResponse } from "modules/dashboardTicket/domain/dto/IGetMyAccountResponse";
import { IMyAccount } from "modules/dashboardTicket/presentation/interfaces/IMyAccount";

const GetMyAccountUseCase = async (service: IDashboardTicketApiService, localId: string): Promise<IMyAccount> => {
    const response: IGetMyAccountResponse = await service.getMyAccount(localId);

    const parse: IMyAccount = {
        retainedBalance: response.SaldoRetido,
        balanceAvailable: response.SaldoDisponivel,
        balanceToBeReleased: response.SaldoALiberar 
    };

    return parse;
}

export default GetMyAccountUseCase;