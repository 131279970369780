import { IGetSalesDevicesResponse } from "modules/dashboardTicket/domain/dto/IGetSalesDevicesResponse";
import { IDashboardTicketApiService } from "modules/dashboardTicket/domain/interfaces/IMyAccountApiService";
import { IFilterValue } from "modules/dashboardTicket/presentation/interfaces/IFilter";
import { ISalesDevices } from "modules/dashboardTicket/presentation/interfaces/ISalesDevices";



const GetSalesDevicesUseCase = async (service: IDashboardTicketApiService, localId: string, filter?: IFilterValue): Promise<ISalesDevices[]> => {

    const response: IGetSalesDevicesResponse[] = await service.getSalesDevices(localId, filter);

    const parse: ISalesDevices[] = response.map(item => {
        return {
            name: item.nome,
            value: item.valorNaoCashless,
        };
    });

    return parse;

}

export default GetSalesDevicesUseCase;