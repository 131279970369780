import { IGetAllMovementsResponse } from "modules/generalDashboard/domain/dto/IGetAllMovementsResponse";
import { api } from "../Api";
import { IGetMyAccountResponse } from "modules/generalDashboard/domain/dto/IGetMyAccountResponse";
import { IGeneralDashboardApiService } from "modules/generalDashboard/domain/interfaces/IGeneralDashboardApiService";
import { IGetPosPaidResponse } from "modules/generalDashboard/domain/dto/IGetPosPaidResponse";
import { IGetCashlessResponse } from "modules/generalDashboard/domain/dto/IGetCashlessResponse";
import { IGetPreRechargeResponse } from "modules/generalDashboard/domain/dto/IGetPreRechargeResponse";
import { IGetAverageTicketResponse } from "modules/generalDashboard/domain/dto/IGetAverageTicketResponse";
import { IGetTurnoverTableResponse } from "modules/generalDashboard/domain/dto/IGetTurnoverTableResponse";
import qs from "qs";
import { IGetRankingCustomersResponse } from "modules/generalDashboard/domain/dto/IGetRankingCustomersResponse";
import { IGetSalesCategoriesResponse } from "modules/generalDashboard/domain/dto/IGetSalesCategoriesResponse";
import { IGetRankingOperatorsResponse } from "modules/generalDashboard/domain/dto/IGetRankingOperatorsResponse";
import { IGetRankingProductsResponse } from "modules/generalDashboard/domain/dto/IGetRankingProductsResponse";
import { IGetSalesDevicesResponse } from "modules/generalDashboard/domain/dto/IGetSalesDevicesResponse";
import { IFilterValue } from "modules/generalDashboard/presentation/interfaces/IFilter";

const GeneralDashboardApi = (): IGeneralDashboardApiService => {
  const getMyAccount = async (localId: string): Promise<IGetMyAccountResponse> => {
    const response = await api.get(`ContaDigital/BuscarSaldoAtual/${localId}`);
    return response.data;
  };

  const getAllMovements = async (localId: string): Promise<IGetAllMovementsResponse> => {
    const params = {
      localId: localId
    }
    const response = await api.get(`DashBoard/AllMovements`, {params});
    return response.data;
  };
  const getPosPaid = async (localId: string): Promise<IGetPosPaidResponse> => {
    const params = {
      localId: localId
    }
    const response = await api.get(`DashBoard/PosPaid`, {params});
    return response.data;
  };

  const getCashless = async (localId: string): Promise<IGetCashlessResponse> => {
    const params = {
      localId: localId
    }
    const response = await api.get(`DashBoard/Cashless`, {params});
    return response.data;
  };
  
  const getPreRecharge = async (localId: string): Promise<IGetPreRechargeResponse> => {
    const params = {
      localId: localId
    }
    const response = await api.get(`DashBoard/PreRecharge`, {params});
    return response.data;
  };

  const getAverageTicket = async (localId: string): Promise<IGetAverageTicketResponse> => {
    const params = {
      localId: localId
    }
    const response = await api.get(`DashBoard/AverageTicket`, {params});
    return response.data;
  };

  const getTurnoverTable = async (localId: string): Promise<IGetTurnoverTableResponse> => {
    const params = {
      localId: localId
    }
    const response = await api.get(`DashBoard/TurnoverTable`, {params});
    return response.data;
  };

  const getRankingProducts = async (localId: string,  filter?: IFilterValue): Promise<IGetRankingProductsResponse[]> => {

    const params = {
      SessaoPosIds: filter?.cashierIds?.map(item => item),
      LocalId: localId,
      DataFinal: filter?.startDate,
      DataInicial: filter?.endDate
    }

    const response = await api.get(`DashBoard/BuscarTopProdutosPorPeriodo?${qs.stringify(params,{
      arrayFormat: "repeat",

    })}`);
    return response.data;
  };

  const getRankingOperators = async (localId: string,  filter?: IFilterValue): Promise<IGetRankingOperatorsResponse[]> => {

    const params = {
      SessaoPosIds: filter?.cashierIds?.map(item => item),
      LocalId: localId,
      DataFinal: filter?.startDate,
      DataInicial: filter?.endDate
    }

    const response = await api.get(`DashBoard/BuscarTopOperadoresPorPeriodo?${qs.stringify(params,{
      arrayFormat: "repeat",

    })}`);
    return response.data;
  };
  const getRankingCustomers = async (localId: string,  filter?: IFilterValue): Promise<IGetRankingCustomersResponse[]> => {

    const params = {
      SessaoPosIds: filter?.cashierIds?.map(item => item),
      LocalId: localId,
      DataFinal: filter?.startDate,
      DataInicial: filter?.endDate
    }

    const response = await api.get(`DashBoard/BuscarTopClientesPorPeriodo?${qs.stringify(params,{
      arrayFormat: "repeat",

    })}`);
    return response.data;
  };

  const getSalesCategories = async (localId: string,  filter?: IFilterValue): Promise<IGetSalesCategoriesResponse[]> => {

    const params = {
      SessaoPosIds: filter?.cashierIds?.map(item => item),
      LocalId: localId,
      DataFinal: filter?.startDate,
      DataInicial: filter?.endDate
    }

    const response = await api.get(`DashBoard/BuscarVendasPorCategoriaNoPeriodo?${qs.stringify(params,{
      arrayFormat: "repeat",

    })}`);
    return response.data;
  };

  const getSalesDevices = async (localId: string,  filter?: IFilterValue): Promise<IGetSalesDevicesResponse[]> => {

    const params = {
      SessaoPosIds: filter?.cashierIds?.map(item => item),
      LocalId: localId,
      DataFinal: filter?.startDate,
      DataInicial: filter?.endDate
    }

    const response = await api.get(`DashBoard/BuscarDadosInformacoesPorDispositivoPorPeriodo?${qs.stringify(params,{
      arrayFormat: "repeat",

    })}`);
    return response.data;
  };

  return { getMyAccount, getAllMovements, getPosPaid, getCashless, getPreRecharge, getAverageTicket, getTurnoverTable, getRankingCustomers, getRankingOperators, getRankingProducts, getSalesCategories, getSalesDevices};
}

export default GeneralDashboardApi;
