import { FC, useCallback, useEffect, useState } from 'react'
import styles from './ComplementForm.module.scss'
import ProductComplementListForm from '../productComplementListForm/ProductComplementListForm';
import { v4 } from 'uuid';
import ProductComplementCategoryForm from '../productComplementCategoryForm/_ProductComplementCategoryForm';
import { IProductComplementCategoryFormValue } from '../interfaces/IProductComplementCategoryFormValue';
import { IComplementProductFormValue } from '../interfaces/IComplementProductFormValue';
import TabView from 'components/tab/TabView';
import { CompositionProductType } from 'modules/catalog/domain/enums/CatalogProductEnums';
export interface IComplementFormProps {
    //propertys
    onSubmit: (values: IComplementGroupFormValue) => void;
    onClose: () => void
    defaultValue?: IComplementGroupFormValue
    disabledEditCategoryAndProduct?: boolean
}

export interface IComplementGroupFormValue {
    id?: string;
    isProduct?: boolean;
    isEdit?: boolean;
    complementCategory: IProductComplementCategoryFormValue
    complementProducts: IComplementProductFormValue[];
};

enum EnumComplementStep {
    CATEGORY = 0,
    PRODUCTS
}

const ComplementForm: FC<IComplementFormProps> = ({ defaultValue, onClose, onSubmit, disabledEditCategoryAndProduct }) => {

    const [values, setValues] = useState<IComplementGroupFormValue>({
        id: v4(),
        complementCategory: { id: v4(), description: "", min: 0, max: 5, freeQuantity: 0 },
        complementProducts: [{
            id: v4(),
            name: "",
            value: 0,
            min: 0,
            max: 5,
            quantity: 1,
            type: CompositionProductType.VARIAVEL
        }]
    });

    useEffect(() => {
        if (defaultValue) {

            setValues({
                ...defaultValue,
                complementCategory: {
                    ...defaultValue.complementCategory,
                    id: defaultValue.complementCategory.id ?? v4()
                },
                complementProducts: [
                    ...defaultValue.complementProducts.map((item) => {
                        return {
                            ...item,
                            id: item.id ?? v4()
                        }
                    })
                ]
            });
        }
    }, [defaultValue])

    const [step, setstep] = useState(EnumComplementStep.CATEGORY);

    const onSubmitCategoryProducts = useCallback((complementCategory: IProductComplementCategoryFormValue) => {
        setValues((prev) => ({ ...prev, complementCategory: complementCategory }));
        setstep(EnumComplementStep.PRODUCTS);
    }, []);

    const onSubmitProducts = useCallback((products: IComplementProductFormValue[]) => {
        const _values = { ...values, complementProducts: products };
        onSubmit?.(_values);
    }, [onSubmit, values]);

    return (
        <div id={styles.ComplementForm} >
            <div className={styles.container} >
                <TabView
                    index={step || EnumComplementStep.CATEGORY}
                    tabs={[
                        { label: "Detalhes", value: EnumComplementStep.CATEGORY },
                        { label: "Produtos", value: EnumComplementStep.PRODUCTS },
                    ]}
                />
                {step === EnumComplementStep.CATEGORY &&
                    <ProductComplementCategoryForm
                        disabledEditCategory={disabledEditCategoryAndProduct}
                        defaultValues={values.complementCategory}
                        onSubmit={onSubmitCategoryProducts}
                        onCancel={onClose}
                    />
                }
                {step === EnumComplementStep.PRODUCTS &&
                    <ProductComplementListForm
                        disabledEditProduct={disabledEditCategoryAndProduct}
                        defaultValues={values.complementProducts}
                        onSubmit={onSubmitProducts}
                        onCancel={() => setstep(EnumComplementStep.CATEGORY)}
                    />
                }

            </div>
        </div >
    )
}
export default ComplementForm