import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import styles from './NesteRoles.module.scss';
import { Icon, IconButton, Switch } from '@material-ui/core';
import { IRoles } from './ISubMenu';

export const NestedRolesCard: FC<INestedRoleCardProps> = ({ submenu, values, onChange, defaultIsOpen }) => {

    const [isChecked, setIsChecked] = useState<boolean>(false);
    const [isOpen, setIsOpen] = useState(defaultIsOpen);

    const handlePermissionChange = useCallback((id: string) => {
        if (onChange) {
            const newValues = values?.find(v => v === id) ? values?.filter(v => v !== id) : [...values ?? [], id];
            onChange(newValues);

        }

    }, [onChange, values]);

    const allpermissions = useMemo(() => [
        ...submenu.Permissions,
        ...(submenu.Submenus?.flatMap(item => item.Permissions) ?? []),
        ...(submenu.Submenus?.flatMap(item => item.Submenus).flatMap(item => item?.Permissions) ?? [])
    ], [submenu.Permissions, submenu.Submenus]);

    useEffect(() => {
        const allChecked = allpermissions
            .filter(item => item)
            .map(permission => (permission as { Id: string; }).Id)
            .every(permissionId => values?.includes(permissionId));
        setIsChecked(allChecked);
    }, [allpermissions]);

    const handleCheckAllChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            setIsChecked(event.target.checked);
            if (onChange) {
                const newValues = event.target.checked
                    ? [
                        ...(values ?? []),
                        ...submenu.Permissions.map(permission => permission.Id),
                        ...(submenu.Submenus?.flatMap(submenu => submenu.Permissions.map(permission => permission.Id)) ?? []),
                        ...(submenu.Submenus?.flatMap(item => item.Submenus).flatMap(item => item?.Permissions.map(permission => permission.Id)) ?? []),
                    ]
                    : values?.filter(
                        v => !submenu.Permissions
                            .map(permission => permission.Id)
                            .includes(v)
                    );
                if (newValues) {
                    onChange(newValues as string[]);
                }
            }
        },
        [submenu.Permissions, submenu.Submenus, values, onChange]
    );

    const handleUncheckAllChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            setIsChecked(false);
            if (onChange) {
                const newValues = values?.filter(
                    v => ![...submenu.Permissions,
                    ...submenu.Submenus?.flatMap(submenu => submenu.Permissions) ?? [],
                    ...(submenu.Submenus?.flatMap(item => item.Submenus).flatMap(item => item?.Permissions ?? []) ?? [])].map(permission => permission.Id).includes(v)
                );
                if (newValues) {
                    onChange(newValues as string[]);
                }
            }
        },
        [onChange, values, submenu.Permissions, submenu.Submenus]
    );

    const onClickColapse = useCallback(() => {
        setIsOpen(prev => !prev);
    }, []);

    return (
        !allpermissions.length ? null
            :
            <div className={styles.card}>
                <div className={styles.titleContainer}>
                    <div className={styles.title}>{submenu.Title}</div>
                    <div style={{ display: 'flex' }}>

                        <div className={styles.checkbox}>
                            {!!allpermissions.length && <Switch
                                checked={isChecked}
                                onChange={isChecked ? handleUncheckAllChange : handleCheckAllChange}
                                id={`check-all-${submenu.Id}`} />}
                        </div>
                        <IconButton onClick={onClickColapse}><Icon>expand_more</Icon></IconButton>
                    </div>
                </div>
                {isOpen && <div className={styles.accordion}>

                    {submenu.Permissions.map((permission) => (
                        <div key={permission.Id} className={styles.checkbox}>
                            <Switch
                                checked={!!values?.find(v => v === permission.Id) ?? false}
                                onChange={() => handlePermissionChange(permission.Id)}
                                id={permission.Id} />
                            <label htmlFor={permission.Id}>{permission.Description}</label>
                        </div>
                    ))}
                    {submenu.Submenus && submenu.Submenus.map((subsubmenu) => (
                        <NestedRolesCard defaultIsOpen={true} key={subsubmenu.Id} submenu={subsubmenu} values={values} onChange={onChange} />
                    ))}
                </div>}
            </div>
    );
};
interface INestedRoleCardProps {
    submenu: IRoles;
    values?: string[];
    onChange?: (values: string[]) => void;
    defaultIsOpen: boolean;
}
