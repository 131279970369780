import { IGetAllMovementsResponse } from "modules/dashboardTicket/domain/dto/IGetAllMovementsResponse";
import { IDashboardTicketApiService } from "modules/dashboardTicket/domain/interfaces/IMyAccountApiService";
import { IAllMovements, ITransactions } from "modules/dashboardTicket/presentation/interfaces/IAllMovements";
import { IFilterValue } from "modules/dashboardTicket/presentation/interfaces/IFilter";


const GetAllMovementsUseCase = async (service: IDashboardTicketApiService, localId: string, filter?: IFilterValue): Promise<IAllMovements> => {
    const response: IGetAllMovementsResponse = await service.getAllMovements(localId, filter);

    const parse: IAllMovements = {
        allType: response.totaisPorTipo,
        avgCashTicket: response.ticketMedioCash,
        avgTicketCashless: response.ticketMedioCashless,
        avgTicketPersonCash: response.ticketMedioPorPessoaCash,
        avgTicketPersonCashless: response.ticketMedioPorPessoaCashless,
        endDate: response.dataFim,
        startDate: response.dataInicio,
        grandTotal: response.totalGeral,
        transactions: response.transacoes.map(item => ({
            dateTransactions: item.dataTransacao,
            moneyValue: item.valorEmDinheiro,
            creditValue: item.valorEmCredito,
            debitValue: item.valorEmDebito,
            cashlessValue: item.valorEmCashless,
            outhersValue: item.valorEmOutro,
            valorTotal: item.valorTotal,
            total: item.total,
            tipos: item.tipos
        })) as ITransactions[],        
     };
    return parse;
}

export default GetAllMovementsUseCase;