import { format, subDays } from 'date-fns';
import GetSelectedLocalsUseCase from 'modules/dashboard/application/useCases/GetSelectedLocalsUseCase';
import SetSelectedLocalsUseCase from 'modules/dashboard/application/useCases/SetSelectedLocalsUseCase';
import { useLocal } from 'modules/local/presentation/context/LocalContext'
import { useCallback, useEffect, useState } from 'react'
import SalesDashboardService from 'services/api/salesDashboard/SalesDashboardService';
import { DashboardSelectedLocals } from 'services/repository/dashboard/DashboardSelectedLocals';
import GetTurnoverTableUseCase from '../application/useCases/GetTurnoverTableUseCase';
import GetRankingOperatorsUseCase from '../application/useCases/GetRankingOperatorsUseCase';
import GetRankingProductsSalesUseCase from '../application/useCases/GetRankingProductsUseCase';
import GetSalesBarChartUseCase from '../application/useCases/GetSalesBarChartUseCase';
import { IFilterValue, IlocalFilter, PeriodEnum } from './components/filter/IFilter';
import { ILocalResumeItem } from './components/localResume/ILocalResume';
import { IGetDataParams } from './interfaces/IGetDataParams';
import GetRemovedChartsUseCase from '../application/useCases/removedCharts/GetRemovedChartsUseCase';
import { DashboardRemovedCharts } from 'services/repository/dashboard/DashboardRemovedCharts';
import SetRemovedChartsUseCase from '../application/useCases/removedCharts/SetRemovedChartsUseCase';
import GetRankingCategoryUseCase from '../application/useCases/GetRankingCategoryUseCase';
import GetSessionsUseCase from '../application/useCases/GetSessionsUseCase';
import { getCategoryRankingStoreUseCase, getRankingStoreUseCase } from '../application/useCases/GetRakingStoresUseCase';
import GetRankingDeviceSalesUseCase from '../application/useCases/GetDeviceSalesUseCase';
import GetRankingProductsCategoryUseCase from '../application/useCases/GetRankingProductsCategoryUseCase';
import GetRankingClientsUseCase from '../application/useCases/GetRankingClientsUseCase';
import GetPaymentFormEquipmentUseCase from '../application/useCases/GetPaymentFormEquipmentUseCase';
import { useError } from './contexts/error/ErrorContext';
import GetRankingSubCategoryUseCase from '../application/useCases/GetRankingSubCategoryUseCase';
import GetProdutosSemSplitUseCase from '../application/useCases/GetProdutosSemSplitUseCase';

const salesDashboardService = SalesDashboardService();

const UseSalesDashboardPage = () => {
    const { locals, currentLocal } = useLocal();
    const [filter, setFilter] = useState<IFilterValue>({
        locals: [],
        sessions: [],
        startDate: format(subDays(new Date(), 7), "yyyy-MM-dd"),
        endDate: format(new Date(), "yyyy-MM-dd"),
        period: PeriodEnum.LAST_SESSION
    } as IFilterValue)
    const [removedCharts, setRemovedCharts] = useState(() => {
        return GetRemovedChartsUseCase(DashboardRemovedCharts) || []
    })
    const isFilterEmpty = Object.keys(filter).length === 0;
    const { clearErrors } = useError();
    const [ initialFilter, setInitialFilter ] = useState(false);

    const onChangeFilterHandle = (value: IFilterValue) => {
        setInitialFilter(false);
        setFilter(value);
        clearErrors();
    }

    const handleRemoveLocal = (filter: IFilterValue, localResume: ILocalResumeItem) => {
        SetSelectedLocalsUseCase(DashboardSelectedLocals, filter.locals.filter(item => item.id !== localResume.placeId).map(x => x.id))
        return { ...filter, locals: filter?.locals.filter(item => item.id !== localResume.placeId) ?? [] }
    }

    const onClickRemoveLocal = useCallback((localResume: ILocalResumeItem) => {
        setFilter(prev => handleRemoveLocal(prev, localResume));
    }, [])

    const onRemoveChart = useCallback((chartId?: string) => {
        if (chartId)
            setRemovedCharts(prev => {
                const filtered = prev.filter(x => x !== chartId);
                SetRemovedChartsUseCase(DashboardRemovedCharts, filtered);
                return filtered;
            })
    }, []);

    const onAddChart = useCallback((chartId?: string) => {
        if (chartId)
            setRemovedCharts(prev => {
                SetRemovedChartsUseCase(DashboardRemovedCharts, [...prev, chartId]);
                return [...prev, chartId];
            })
    }, []);

    /**
     * Métodos de GET DATA
     */
    const getTurnoverTable = useCallback(async ({ localId, page, pageSize, sortField, sortOrientation }: IGetDataParams) => {
        return await GetTurnoverTableUseCase(salesDashboardService, localId!, { filter, page, pageSize, sortField, sortOrientation });
    }, [filter])

    const getSalesBarChart = useCallback(async () => {
        return GetSalesBarChartUseCase(salesDashboardService, { filter })

    }, [filter])

    const getRankingProducts = useCallback(async ({ page, pageSize, sortField, sortOrientation, groupBy }: IGetDataParams, productGroupId?: string) => {
        return GetRankingProductsSalesUseCase(salesDashboardService, { filter, page, pageSize, sortField, sortOrientation, groupBy }, productGroupId)

    }, [filter])

    const getRankingOperators = useCallback(async ({ page, pageSize, sortField, sortOrientation }) => {
        return GetRankingOperatorsUseCase(salesDashboardService, { filter, page, pageSize, sortField, sortOrientation })
    }, [filter])

    const getSessions = useCallback(async (local: IlocalFilter) => {
        return await GetSessionsUseCase(salesDashboardService, local);
    }, [])

    const getRankingCategory = useCallback(async ({ page, sortField, sortOrientation }: IGetDataParams, parentCategoryId?: string) => {
        return GetRankingCategoryUseCase(salesDashboardService, { filter, page, sortField, sortOrientation }, parentCategoryId);
    }, [filter])

    const getRankingSubCategory = useCallback(async (params: IGetDataParams, subCategoryId?: string) => {
        return GetRankingSubCategoryUseCase(salesDashboardService, { filter, page: params.page, sortField: params.sortField, sortOrientation: params.sortOrientation, groupBy: params.groupBy }, subCategoryId)
    }, [filter])

    const getProductRankingCategory = useCallback(async (categoryId: string, skip?: number) => {
        return GetRankingProductsCategoryUseCase(salesDashboardService, categoryId, filter)
    }, [filter])

    const getRankingStore = useCallback(async (skip?: number) => {
        return getRankingStoreUseCase(salesDashboardService, filter)
    }, [filter])

    const getCategoryStore = useCallback(async (storeId?: string, skip?: number) => {
        return getCategoryRankingStoreUseCase(salesDashboardService, storeId, filter)
    }, [filter]
    )
    const getRankingDeviceSales = useCallback(async ({ page, sortField, sortOrientation }: IGetDataParams) => {
        return GetRankingDeviceSalesUseCase(salesDashboardService, { filter, page, sortField, sortOrientation })
    }, [filter])

    const getRankingClients = useCallback(async ({ page, pageSize }: IGetDataParams) => {
        return await GetRankingClientsUseCase(salesDashboardService, { filter, page, pageSize });
    }, [filter])

    const getPaymentFormEquipment = useCallback(async () => {
        return await GetPaymentFormEquipmentUseCase(salesDashboardService, { filter });
    }, [filter])

    const getProdutosSemSplit = useCallback(async () => {
        if (currentLocal) {
            return await GetProdutosSemSplitUseCase(salesDashboardService, currentLocal.id)
        }
    }, [currentLocal])


    /**
     * Métodos de UseEffects
     */
    useEffect(() => {
        const selectedLocalsStoraged = GetSelectedLocalsUseCase(DashboardSelectedLocals) || undefined;
        let selectedLocals: IlocalFilter[] = [];

        if (selectedLocalsStoraged) {
            selectedLocals = locals?.filter(x => selectedLocalsStoraged.includes(x.id)) || [];
        } else {
            selectedLocals = locals?.filter(x => x.id === currentLocal?.id) || locals?.slice(0, 1) || [];
        }
        setFilter(prev => ({
            ...prev,
            locals: selectedLocals
        }))
    }, [currentLocal?.id, locals]);

    return ({
        locals,
        filter,
        isFilterEmpty,
        removedCharts,
        initialFilter,
        setInitialFilter,
        onChangeFilterHandle,
        onClickRemoveLocal,
        onRemoveChart,
        onAddChart,
        getTurnoverTable,
        getSalesBarChart,
        getRankingProducts,
        getRankingOperators,
        getSessions,
        getRankingCategory,
        getRankingSubCategory,
        getProductRankingCategory,
        getRankingStore,
        getCategoryStore,
        getRankingDeviceSales,
        getRankingClients,
        getPaymentFormEquipment,
        getProdutosSemSplit
    })
}

export default UseSalesDashboardPage