import React, { FC, useMemo } from 'react';
import { InvoiceEditOrView, InvoiceSupplyValues } from './IInvoiceFormValue';
import List, { ColumnConfigs } from '../ui/list/List';
import DefaultActionButtonBuilder from '../ui/defaultListActionButtons/DefaultListActionButtons';
import { KeyboardArrowRight } from '@mui/icons-material';
import { formatCurrency } from '../../utils/Format';
import { useParams } from 'react-router-dom';
interface IInvoiceSupplyFormProps {
  onClickEdit: (item: InvoiceSupplyValues) => void;
  onClickDelete: (item: InvoiceSupplyValues) => void;
  onClickTaxes: (item: InvoiceSupplyValues) => void
  supplies: InvoiceSupplyValues[];
}
export const InvoiceSupplyList: FC<IInvoiceSupplyFormProps> = ({
  onClickDelete, onClickEdit, supplies, onClickTaxes
}) => {
  const { type } = useParams<{ id?: string, type?: string }>();

  const columnsConfigs: ColumnConfigs<InvoiceSupplyValues> = {
    supplyCode: {
      title: "Código",
      renderRow: (value, item) => item.supplyCode,
    },
    supplyName: {
      title: "Produto",
      renderRow: (value, item) => item.supplyName,
    },
    quantity: {
      title: "Quantidade",
      renderRow: (value, item) => item.quantity,
    },
    unitPrice: {
      title: "Valor unitário",
      renderRow: (value, item) => formatCurrency(item.unitPrice),
    },
    storageLocationName: {
      title: "Local de estoque",
      renderRow: (value, item) => item.storageLocationName
    },
    totalTaxes: {
      title: "Valor impostos",
      renderRow: (value, item) => {
        return (
          <div style={{ display: 'flex', paddingTop: '5px', cursor: 'pointer' }} onClick={() => onClickTaxes(item)}>
            <span>{formatCurrency(item.icms + item.confins + item.pis + item.st + item.ipi)}</span>
            <span>< KeyboardArrowRight /></span>
          </div>
        )
      }
    },
    totalDiscount: {
      title: "Valor desconto",
      renderRow: (value, item) => {
        const subtotal = item.quantity * item.unitPrice;
        const totalDiscount = subtotal * item.discountPercentage / 100 + (item.discountValue ?? 1);
        return (
          formatCurrency(totalDiscount)
        )

      }
    },
    total: {
      title: "Valor total",
      renderRow: (value, item) => {
        const subtotal = item.quantity * item.unitPrice;
        const totalTaxes = item.icms + item.confins + item.pis + item.st + item.ipi;
        const totalDiscount = subtotal * item.discountPercentage / 100 + (item.discountValue ?? 1);
        const total = subtotal + totalTaxes - totalDiscount
        return (
          formatCurrency(total)
        )

      }
    },
  };



  const actionButtons = useMemo(() => DefaultActionButtonBuilder<InvoiceSupplyValues & { id: string }>({
    onDelete: (id, value) => onClickDelete(value),
    onEdit: (id, value) => onClickEdit(value)
  }),
    [onClickDelete, onClickEdit]);


  return (
      <List
        list={supplies}
        columnsConfigs={columnsConfigs}
        actionButtons={type !== InvoiceEditOrView.VIEW ? actionButtons : undefined}
        hidePagintion
      />
  );
};
