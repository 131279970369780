import styles from "./Balance.module.scss";

interface BalanceProps {
    title: string,
    value: string,
    available?: boolean,
    last?: boolean
}

const Balance = ({ title, value, available, last }: BalanceProps) => {

    return (
        <div className={!last ? styles.container : ''}>
            <p className={styles.title}>{title}</p>
            <p className={available ? styles.value_available : styles.value}>{value}</p>
        </div>
    );
};
export default Balance;