import { FormControlLabel, MenuItem, Switch } from "@material-ui/core";
import { Checkbox } from "@mui/material";
import Input from "components/ui/input/Input";
import FormItemContainer from "components/ui/inputContainer/FormItemContainer";
import { CustomerFieldType } from "modules/config/deviceConfig/presentation/interfaces/IDeviceProfile";
import { FC, FormEvent, Fragment, useCallback, useEffect, useMemo, useState } from "react";
import FooterButtons from "../../../footerButtons/FooterButtons";
import styles from "./DeviceSystemForm.module.scss";
import { IDeviceOperationItemValue, IOperationItem, PrintType, SystemType } from "./interfaces/IDeviceSystemValue";

const initialValues: IDeviceOperationItemValue[] = [
  {
    type: SystemType.PRE_CASHLESS,
    name: "Pré-pago Cashless",
    enabled: false,
    printType: PrintType.NONE,
    isRemotePrinterEnabled: false,
    isPreRechargeEnabled: false,
    isPrinterLogoEnabled: false,
    isPrinterQrCodeEnabled: false,
    isPrinterCodeBarEnabled: false,
    isPrinterProductEnabled: false,
    isPrinterObservationsEnabled: false,
    customerIdentification: [
      {
        required: false,
        identification: {
          name: "CPF",
          value: CustomerFieldType.DOCUMENT,
        },
      },
      {
        required: false,
        identification: {
          name: "Nome",
          value: CustomerFieldType.NAME,
        },
      },
      {
        required: false,
        identification: {
          name: "Telefone",
          value: CustomerFieldType.PHONE,
        },
      },
      {
        required: false,
        identification: {
          name: "Email",
          value: CustomerFieldType.EMAIL,
        },
      },
      {
        required: false,
        identification: {
          name: "Etiqueta",
          value: CustomerFieldType.TAG,
        },
      },
      {
        required: false,
        identification: {
          name: "Data",
          value: CustomerFieldType.BIRTHDAY,
        },
      },
      {
        required: false,
        identification: {
          name: "Identificador",
          value: CustomerFieldType.IDENTIFIER,
        },
      },
    ],
  },
  {
    type: SystemType.POST_ACCOUNT,
    name: "Pós-pago Comanda",
    enabled: false,
    printType: PrintType.NONE,
    isRemotePrinterEnabled: false,
    isPreRechargeEnabled: false,
    isPrinterLogoEnabled: false,
    isPrinterQrCodeEnabled: false,
    isPrinterCodeBarEnabled: false,
    isPrinterProductEnabled: false,
    isPrinterObservationsEnabled: false,
    customerIdentification: [
      {
        required: false,
        identification: {
          name: "CPF",
          value: CustomerFieldType.DOCUMENT,
        },
      },
      {
        required: false,
        identification: {
          name: "Nome",
          value: CustomerFieldType.NAME,
        },
      },
      {
        required: false,
        identification: {
          name: "Telefone",
          value: CustomerFieldType.PHONE,
        },
      },
      {
        required: false,
        identification: {
          name: "Email",
          value: CustomerFieldType.EMAIL,
        },
      },
      {
        required: false,
        identification: {
          name: "Etiqueta",
          value: CustomerFieldType.TAG,
        },
      },
      {
        required: false,
        identification: {
          name: "Data",
          value: CustomerFieldType.BIRTHDAY,
        },
      },
      {
        required: false,
        identification: {
          name: "Identificador",
          value: CustomerFieldType.IDENTIFIER,
        },
      },
    ],
  },
  {
    type: SystemType.TICKET,
    name: "Pré-pago Ficha",
    enabled: false,
    printType: PrintType.NONE,
    isRemotePrinterEnabled: false,
    isPreRechargeEnabled: false,
    isPrinterLogoEnabled: false,
    isPrinterQrCodeEnabled: false,
    isPrinterCodeBarEnabled: false,
    isPrinterProductEnabled: false,
    isPrinterObservationsEnabled: false,
    customerIdentification: [
      {
        required: false,
        identification: {
          name: "CPF",
          value: CustomerFieldType.DOCUMENT,
        },
      },
      {
        required: false,
        identification: {
          name: "Nome",
          value: CustomerFieldType.NAME,
        },
      },
      {
        required: false,
        identification: {
          name: "Telefone",
          value: CustomerFieldType.PHONE,
        },
      },
      {
        required: false,
        identification: {
          name: "Email",
          value: CustomerFieldType.EMAIL,
        },
      },
      {
        required: false,
        identification: {
          name: "Etiqueta",
          value: CustomerFieldType.TAG,
        },
      },
      {
        required: false,
        identification: {
          name: "Data",
          value: CustomerFieldType.BIRTHDAY,
        },
      },
      {
        required: false,
        identification: {
          name: "Identificador",
          value: CustomerFieldType.IDENTIFIER,
        },
      },
    ],
  },
];

export interface IDeviceSystemFormProps {
  //propertys
  defaultValue?: IDeviceOperationItemValue[];
  operations?: IOperationItem[];
  onModified: (modified: boolean) => void;
  onSubmit: (value: IDeviceOperationItemValue[]) => void;
  onClickBack: (value: IDeviceOperationItemValue[]) => void;
}

const OperationMethodForm: FC<IDeviceSystemFormProps> = ({
  onSubmit,
  defaultValue,
  onModified,
  onClickBack,
  operations,
}) => {
  const treatInitialValues = useMemo(
    () =>
      operations?.map<IDeviceOperationItemValue>((operation) => {
        const defaultItem = defaultValue?.find((defaultItem) => defaultItem.type === operation.type);
        if (defaultItem) {
          let customerIdentifications = defaultItem.customerIdentification?.length > 0
          ? defaultItem.customerIdentification
          : initialValues.find((it) => it.type === operation.type)!.customerIdentification;

          const difference = initialValues[0].customerIdentification.filter(item1 => !customerIdentifications.find(item2 => item2.identification.name === item1.identification.name));

          if (difference.length > 0) {
            for (let i = 0; i < difference.length; i++) {
              const item = difference[i];
              
              customerIdentifications.push({
                enabled: false,
                identification: item.identification,
                required: false,
              });
            }
          }
          return {
            ...defaultItem,
            name: defaultItem.name ? defaultItem.name : operation.name,
            customerIdentification: customerIdentifications,
          };
        } else {
          return {
            ...operation,
            enabled: false,
            type: operation.type,
            printType: PrintType.NONE,
            isRemotePrinterEnabled: SystemType.TICKET ? false : true,
            isPreRechargeEnabled: SystemType.TICKET ? false : true,
            isPrinterLogoEnabled: false,
            isPrinterQrCodeEnabled: false,
            isPrinterCodeBarEnabled: false,
            isPrinterProductEnabled: false,
            isPrinterObservationsEnabled: false,
            isRequestDocumentOnActivateEnabled: false,
            customerIdentification: [
              {
                required: false,
                identification: {
                  name: "CPF",
                  value: CustomerFieldType.DOCUMENT,
                },
              },
              {
                required: false,
                identification: {
                  name: "Nome",
                  value: CustomerFieldType.NAME,
                },
              },
              {
                required: false,
                identification: {
                  name: "Telefone",
                  value: CustomerFieldType.PHONE,
                },
              },
              {
                required: false,
                identification: {
                  name: "Email",
                  value: CustomerFieldType.EMAIL,
                },
              },
              {
                required: false,
                identification: {
                  name: "Etiqueta",
                  value: CustomerFieldType.TAG,
                },
              },
              {
                required: false,
                identification: {
                  name: "Data",
                  value: CustomerFieldType.BIRTHDAY,
                },
              },
              {
                required: false,
                identification: {
                  name: "Identificador",
                  value: CustomerFieldType.IDENTIFIER,
                },
              },
            ],
          };
        }
      }),
    [defaultValue, operations]
  );

  const [values, setValues] = useState<IDeviceOperationItemValue[]>(initialValues);

  useEffect(() => {
    treatInitialValues && setValues(treatInitialValues);
  }, [treatInitialValues]);

  const onChangeSystemParamHandle = useCallback(
    (systemValue: IDeviceOperationItemValue) => {
      const includes = values.find((item) => item.type === systemValue.type);
      if (includes) {
        const newValues = values.map((item) => {
          if (item.type === systemValue.type) {
            return systemValue;
          } else {
            return item;
          }
        });
        setValues(newValues);
      }
      onModified(true);
    },
    [onModified, values]
  );

  const submitHandle = useCallback(
    (ev: FormEvent<HTMLFormElement>) => {
      ev.preventDefault();
      onSubmit(values);
    },
    [onSubmit, values]
  );

  return (
    <form id={styles.DeviceSystemForm} onSubmit={submitHandle}>
      <div className={styles.container}>
        <span>Como seus clientes vão realizar o pagamento nesse perfil de vendas?</span>
        <FormItemContainer flex={0}>
          {values.map((item, key) => (
            <FormItemContainer key={key}>
              <DeviceItemForm values={item} onChange={onChangeSystemParamHandle} />
            </FormItemContainer>
          ))}
        </FormItemContainer>
      </div>
      <div className={styles.footer}>
        <FooterButtons onClickBack={() => onClickBack(values)} labelNext="Salvar e continuar" nextType="submit" />
      </div>
    </form>
  );
};

interface DeviceItemFormProps {
  onChange: (values: IDeviceOperationItemValue & { enabled: boolean }) => void;
  values: IDeviceOperationItemValue;
}

const DeviceItemForm: FC<DeviceItemFormProps> = ({ onChange, values }) => {
  const onChangePrintTypeHandle = useCallback(
    (value: PrintType) => {
      if (values) {
        onChange({ ...values, printType: value, enabled: true });
      }
    },
    [values, onChange]
  );

  const onChangePrint = useCallback(
    (value: boolean, key: string) => {
      if (values) {
        onChange({ ...values, [key]: value });
      }
    },
    [values, onChange]
  );

  const onChangeField = useCallback(
    (value: string, key: string) => {
      onChange({ ...values, [key]: value });
    },
    [values, onChange]
  );

  const onChangeSystemTypeEnabled = useCallback(
    (checked: boolean) => {
      if (values) {
        onChange({ ...values, enabled: checked });
      }
    },
    [values, onChange]
  );

  const onChangeRequired = useCallback(
    (value: CustomerFieldType, checked: boolean) => {
      onChange({
        ...values,
        customerIdentification: values.customerIdentification.map((it) => ({
          ...it,
          required: it.identification.value === value ? checked : it.required,
        })),
      });
    },
    [onChange, values]
  );

  const onChangeEnabled = useCallback(
    (value: CustomerFieldType, checked: boolean) => {
      onChange({
        ...values,
        customerIdentification: values.customerIdentification.map((it) => ({
          ...it,
          enabled: it.identification.value === value ? checked : it.enabled,
        })),
      });
    },
    [onChange, values]
  );

  return (
    <div id={styles.DeviceSystemFormItem}>
      <FormItemContainer>
        <FormControlLabel
          checked={values?.enabled}
          onChange={(ev, checked) => onChangeSystemTypeEnabled(checked)}
          control={<Switch color="secondary" />}
          label={values?.name}
        />
        {values?.enabled && (
          <div className={styles.more}>
            <FormItemContainer label={"Tipo de impressão"} className={styles.formItemContainer}>
              <Input
                value={values?.printType}
                onChange={(ev) => onChangePrintTypeHandle(Number(ev.target.value) as PrintType)}
                variant={"outlined"}
                name={"printType"}
                select
              >
                <MenuItem value={PrintType.TICKET}>Ficha</MenuItem>
                <MenuItem value={PrintType.RESUME}>Resumo</MenuItem>
                <MenuItem value={PrintType.GROUPED_RESUME}>Agrupar por categoria</MenuItem>
                <MenuItem value={PrintType.SIMPLIFIED}>Simplificada</MenuItem>
                <MenuItem value={PrintType.NONE}>Não Imprimir</MenuItem>                
              </Input>
            </FormItemContainer>
            <FormItemContainer className={styles.formItemContainer}>
              {values.type === SystemType.PRE_CASHLESS && (
                <FormControlLabel
                  checked={values?.isPreRechargeEnabled}
                  label={"Importar Pré-recarga"}
                  onChange={(_, checked) => {
                    onChangePrint(checked, 'isPreRechargeEnabled');
                  }}
                  control={<Checkbox color="secondary" />}
                />
              )}
              <FormControlLabel
                checked={values?.isRemotePrinterEnabled}
                label={"Impressão Remota"}
                onChange={(_, checked) => {
                  onChangePrint(checked, 'isRemotePrinterEnabled');
                }}
                control={<Checkbox color="secondary" />}
              />
               {values?.printType === PrintType.SIMPLIFIED && (
                <>
                  <FormControlLabel
                    checked={values?.isPrinterLogoEnabled}
                    label={"Imprimir logo ?"}
                    onChange={(_, checked) => {
                      onChangePrint(checked, 'isPrinterLogoEnabled');
                    }}
                    control={<Checkbox color="secondary" />}
                  />
                  <FormControlLabel
                    checked={values?.isPrinterQrCodeEnabled}
                    label={"Imprimir QR Code ?"}
                    onChange={(_, checked) => {
                      onChangePrint(checked, 'isPrinterQrCodeEnabled');
                    }}
                    control={<Checkbox color="secondary" />}
                  />
                   <FormControlLabel
                    checked={values?.isPrinterCodeBarEnabled}
                    label={"Imprimir Código de barras ?"}
                    onChange={(_, checked) => {
                      onChangePrint(checked, 'isPrinterCodeBarEnabled');
                    }}
                    control={<Checkbox color="secondary" />}
                  />
                  <FormControlLabel
                    checked={values?.isPrinterProductEnabled}
                    label={"Imprimir categoria produto ?"}
                    onChange={(_, checked) => {
                      onChangePrint(checked, 'isPrinterProductEnabled');
                    }}
                    control={<Checkbox color="secondary" />}
                  />
                  <FormControlLabel
                    checked={values?.isPrinterObservationsEnabled}
                    label={"Imprimir observações do pedido?"}
                    onChange={(_, checked) => {
                      onChangePrint(checked, 'isPrinterObservationsEnabled');
                    }}
                    control={<Checkbox color="secondary" />}
                  />
                </>
              )}
            </FormItemContainer>

            <p>Identificação do cliente</p>

            <div className={styles.identificationContainer}>
              {values.customerIdentification.map((item, index) => (
                <div className={styles.identificationItem} key={index}>
                  <FormControlLabel
                    checked={item.enabled}
                    onChange={(ev, checked) => onChangeEnabled(item.identification.value, checked)}
                    control={<Switch color="secondary" />}
                    label={item.identification.name}
                    className={styles.switch}
                  />
                  {!!item.enabled && (
                    <FormControlLabel
                      checked={!!item.required}
                      onChange={(ev, checked) => onChangeRequired(item.identification.value, checked)}
                      control={<Checkbox color="secondary" size="small" />}
                      label={"Obrigatório"}
                    />
                  )}
                </div>
              ))}
            </div>
            
            <FormItemContainer flex={2} label={"Label do sistema"}>
                <Input 
                    variant={"outlined"} 
                    value={values?.systemLabel} 
                    inputProps={{ maxLength: 100 }}
                    onChange={ev => onChangeField(ev.target.value, "systemLabel")} 
                />
            </FormItemContainer>
          </div>
        )}
      </FormItemContainer>
    </div>
  );
};

export default OperationMethodForm;
