import { ICloseEventResponse, IPendingCardResponse } from 'modules/events/domain/dto/ICloseEventDetailsResponse';
import {
  IEditEventRequest,
  IEventsRequest,
} from 'modules/events/domain/dto/IEventsRequest';
import { IPaginatedList } from "models/api/IApi";
import {
  ICashierResponse,
  IClosePendingRequest,
  IEventNameListResponse,
  IEventsResponse,
  IPosPaidResponse,
  IRequestReports,
} from 'modules/events/domain/dto/IEventsResponse';
import { api } from 'services/api/Api';
import { IEventsApi } from '../../../../modules/events/application/interfaces/IEventsService';
import { IGetEventsPeriodResumeResponse } from 'modules/events/domain/dto/IGetEventsPeriodResumeResponse';
import { IGetEventsResumeResponse } from 'modules/events/domain/dto/IEventResumeResponse';
import { IGetOrganizerEventsResponse } from 'modules/events/domain/dto/IGetOrganizerEventsResponse';
import MeepTicketsApi from 'services/api/meepTickets/MeepTicketsApi';
import { IGetOrganizerEventDataContent } from 'modules/meepTickets/domain/dto/IGetOrganizerEventResponse';

export const EventsApi = (): IEventsApi => {
  const getEventDetails = async (eventId: string, localId: string): Promise<IEventsResponse> => {
    const result = await api.get(`/Event/GetEventDetail/${eventId}?localId=${localId}`);
    return result.data;
  };
  const getEventsResume = async (eventId: string[], localId: string): Promise<IGetEventsResumeResponse> => {
    const result = await api.get<IGetEventsResumeResponse>(`/SuperApp/Event/Events/Resume?localId=${localId}&` + eventId.map(item => `eventId=${item}`).join("&"));
    return result.data;
  };

  const getEvents = async (localId: string, filter: number, page: number, month?: number, year?: number, keyword?: string): Promise<IPaginatedList<IEventsResponse>> => {
    const result = await api.get(`/EventSummary?localId=${localId}&filter=${filter}&page=${page}&pagesize=10&month=${month}&year=${year}&search=${keyword}`);
    return result.data;
  };

  const getOrganizerEvents = async (localId: string): Promise<IGetOrganizerEventsResponse[]> => {
    //temporary
    const response = await MeepTicketsApi().getOrganizerEvent(localId, 0, 200);

    const result = response.data.content.map((item: IGetOrganizerEventDataContent) => ({
      id: item.id,
      name: item.name,
      startDate: item.startDate,
      endDate: item.endDate,
    }));
    return result;
  };

  const integrateEvent = async (eventId: string, ioticketsEventId: number, localId: string): Promise<void> => {
    await api.post(`/MeepTickets/IntegrateEvent?localId=${localId}`, { eventId, IoticketsEventId: ioticketsEventId });
  };

  const getEventsPeriodResume = async (localId: string, month?: number, year?: number): Promise<IGetEventsPeriodResumeResponse> => {
    const result = await api.get(`/SuperApp/Event/Resume/${localId}/${month}/${year}`);
    return result.data;
  };

  const getOngoingEvents = async (localId: string): Promise<IEventsResponse[]> => {
    const result = await api.get(`/SuperApp/Event/OngoingEvents?localId=${localId}`);
    return result.data;
  };

  const createNewEvent = async (event: IEventsRequest): Promise<void> => {
    const result = await api.post(`/SuperApp/Event/AddEvent`, { ...event });
    return result.data;
  };

  const editEvent = async (event: IEditEventRequest): Promise<any> => {
    const result = await api.patch(`/SuperApp/Event/EditEvent`, { ...event });
    return result.data;
  };

  const deleteEvent = async (eventId: string, localId: string): Promise<void> => {
    await api.delete(`/SuperApp/Event/DeleteEvent/${eventId}?localId=${localId}`);
  };

  const getOpenCashier = async (localId: string): Promise<ICashierResponse> => {
    const result = await api.get(`/SuperApp/Event/GetEventCashier?LocalId=${localId}`);
    return result.data;
  };

  const selectCashier = async (eventId: string, cashierId: string, localId: string): Promise<void> => {
    const body = { eventId, cashierId };
    await api.post(`/SuperApp/Event/AddEventCashier?localId=${localId}`, body);
  };

  const getPosPaidList = async (
    localId: string,
    cashierId?: string,
    page?: number,
    pageSize?: number
  ): Promise<IPaginatedList<IPosPaidResponse>> => {
    let query = `?localId=${localId}`;
    query += cashierId ? `&cashierId=${cashierId}` : "";
    query += page ? `&page=${page}` : "&page=0";
    query += pageSize ? `&pageSize=${pageSize}` : "";
    const result = await api.get(`/SuperApp/Event/GetOrderPad${query}`);
    return result.data;
  };

  const getClosingReports = async (
    eventId: string,
    reports: IRequestReports,
    localId: string,
  ): Promise<any> => {
    const result = await api.post(`/SuperApp/Event/closingReports/${eventId}?localId=${localId}`, {
      ...reports,
    });
    return result.data;
  };

  const closeEvent = async (localId: string): Promise<any> => {
    const result = await api.post(`/SuperApp/Event/CloseEvent/${localId}`);
    return result.data;
  };

  const getClosingEventDetails = async (
    eventId: string,
    localId: string,
  ): Promise<ICloseEventResponse> => {
    const result = await api.get(`/SuperApp/Event/Resume/${eventId}?localId=${localId}`, { params: { disableError: true } });

    return result.data;
  };

  const getPendingCards = async (eventId: string, localId: string): Promise<IPendingCardResponse[]> => {
    return Promise.resolve([]);
  };

  const getPendingComammands = async (eventId: string, localId: string): Promise<IPendingCardResponse[]> => {
    return Promise.resolve([]);
  };

  const getPendingSharedCommands = async (eventId: string, localId: string): Promise<IPendingCardResponse[]> => {
    return Promise.resolve([]);
  };

  const closePending = async (pendingItem: IClosePendingRequest): Promise<void> => {
    await api.post(`/comandaseletronicas/FecharComanda`, pendingItem);

  }
  const getEventsAutocompleteList = async (
    localId: string
  ): Promise<IEventNameListResponse[]> => {
    const result = await api.get(`Event/GetEventsNameByLocal/${localId}`)
    return result.data;
  }

  return {
    getEvents,
    getEventsResume,
    createNewEvent,
    getEventsPeriodResume,
    getOngoingEvents,
    getEventDetails,
    editEvent,
    deleteEvent,
    getOpenCashier,
    selectCashier,
    getPosPaidList,
    getClosingReports,
    closeEvent,
    getClosingEventDetails,
    getPendingCards,
    getPendingComammands,
    getPendingSharedCommands,
    closePending,
    getEventsAutocompleteList,
    getOrganizerEvents,
    integrateEvent
  };
};
