import { FC, useEffect, useState } from 'react'
import DashboarContainer from './components/dashboarContainer/DashboarContainer'
import Filter from './components/filter/Filter'
import TurnoverTable from './components/turnoverTable/TurnoverTable'
import GridDashboard from './components/gridDashboard/GridDashboard'
import RakingProducts from './components/rakingProducts/RakingProducts'
import LocalResume from './components/localResume/LocalResume'
import SalesBarChart from './components/salesBarChart/SalesBarChart'
import styles from './SalesDashboardPage.module.scss'
import UseSalesDashboardPage from './UseSalesDashboardPage'
import RankingOperators from './components/rakingOperators/RankingOperators'
import CardValues from './components/cardValues/CardValues'
import { dashboardContainerEnum } from './interfaces/DashboardContainerEnum'
import CategoryRanking from './components/categoryRanking/CategoryRanking'
import RankingStores from './components/rankingStores/RankingStores'
import UseDimension from 'components/dimension/UseDimension'
import RankingDevices from './components/rankingDevices/RankingDevices'
import RankingClients from './components/rakingClients/RankingClients'
import GradientTable from './components/gradientTable/GradientTable'
import PieSalesChart from './components/pieSales/PieSalesChart'
import { LocalResumeConsumer, LocalResumeProvider } from './contexts/localResume/LocalResumeContext'
import { RemovedCard } from './components/dashboarContainer/removedCard/RemovedCard'
import { PeriodEnum } from './components/filter/IFilter'
import ModalProdutosSemSplit from './components/modalProdutosSemSplit/ModalProdutosSemSplit'
import { IProdutosSemSplitResponse } from '../domain/dtos/IProdutosSemSplitResponse'


const SalesDashboardPage: FC = () => {
    const {
        locals,
        filter,
        removedCharts,
        initialFilter,
        setInitialFilter,
        onChangeFilterHandle,
        onClickRemoveLocal,
        onRemoveChart,
        onAddChart,
        getTurnoverTable,
        getSalesBarChart,
        getRankingProducts,
        getRankingOperators,
        getRankingCategory,
        getRankingSubCategory,
        getSessions,
        getRankingDeviceSales,
        getRankingClients,
        getPaymentFormEquipment,
        getProdutosSemSplit,
    } = UseSalesDashboardPage();

    const { isMobile } = UseDimension()

    const [listProdutosSemSplit, setListProdutosSemSplit] = useState<IProdutosSemSplitResponse | undefined>()

    useEffect(() => {
        const fetchData = async () => {
            const response = await getProdutosSemSplit();
            setListProdutosSemSplit(response);
        };

        fetchData();
    }, [getProdutosSemSplit]);

    return (
        <LocalResumeProvider>

            <LocalResumeConsumer>
                {({ localResume, loading, getLocalResume }) => (<div id={styles.SalesDashboardPage} >
                    <GridDashboard>
                        {(listProdutosSemSplit?.TotalRecords ?? 0) > 0 && <ModalProdutosSemSplit list={listProdutosSemSplit} />}
                        <DashboarContainer isChartContainer={false}>
                            <Filter
                                onSubmit={(value) => onChangeFilterHandle(value)}
                                locals={locals ?? []}
                                defaultValues={filter}
                                getSessions={getSessions}
                            />
                        </DashboarContainer>

                        <DashboarContainer id={dashboardContainerEnum.LOCAL_RESUME} isChartContainer={false} minHeight={100}>
                            <LocalResume
                                localResume={localResume}
                                loading={loading}
                                getLocalResume={getLocalResume}
                                filter={filter}
                                onPressClose={onClickRemoveLocal}
                                setInitialFilter={setInitialFilter}
                            />
                        </DashboarContainer>

                        <DashboarContainer
                            id={dashboardContainerEnum.SALES_BAR}
                            minHeight={200}
                            removedCharts={removedCharts}
                            onRemoveChart={onRemoveChart}
                            onAddChart={onAddChart}
                            title={"Faturamento"}
                            tooltip={filter.period === PeriodEnum.LAST_SESSION ?
                                "Visualize o faturamento por dia e hora baseado no seu último caixa. Caso o seu caixa tenha sido fechado em uma quinta, você visualizará as quintas anteriores." :
                                filter.period === PeriodEnum.SESSION ?
                                    'Visualize a comparação de faturamento dos caixas selecionados.' :
                                    'Visualize o faturamento por dia e hora baseado no período selecionado. Caso você selecione 4 dias, você visualizará os 4 dias anteriores.'}
                        >
                            <SalesBarChart filter={filter} selectedLocals={filter.locals} getData={getSalesBarChart} />
                        </DashboarContainer>

                        <DashboarContainer
                            id={dashboardContainerEnum.CARD_VALUES}
                            removedCharts={removedCharts}
                            minHeight={150}
                            onRemoveChart={onRemoveChart}
                            onAddChart={onAddChart}
                            borderless
                            title={"Valores"}
                            tooltip={"Visualização de valores consumidos, taxa de serviço e valores em aberto. Ticket médio dos estabelecimentos visualizado por mesas e pessoas."}
                        >
                            <CardValues />
                        </DashboarContainer>

                        <DashboarContainer
                            id={dashboardContainerEnum.SALES_PLACE}
                            minHeight={400}
                            removedCharts={removedCharts}
                            onRemoveChart={onRemoveChart}
                            onAddChart={onAddChart}
                            title={"Vendas por Estabelecimento"}
                            tooltip="Valor total de venda de cada estabelecimento selecionado."
                            span={2}
                        >
                            <PieSalesChart
                                data={localResume?.records?.map(item => ({ name: item.name, value: item.received, color: item.color }))}
                                isLoading={loading}
                                format
                            />
                        </DashboarContainer>

                        <DashboarContainer
                            id={dashboardContainerEnum.GRADIENT_TABLE}
                            minHeight={400}
                            removedCharts={removedCharts}
                            onRemoveChart={onRemoveChart}
                            onAddChart={onAddChart}
                            title={"Forma de pagamento X Equipamento"}
                            tooltip="Formas de pagamento mais utilizadas para cada tipo de equipamento."
                            span={4}
                        >
                            {initialFilter && <GradientTable
                                title="Forma de pagamento X Equipamento"
                                getData={getPaymentFormEquipment}
                            />}
                        </DashboarContainer>

                        <DashboarContainer
                            id={dashboardContainerEnum.RANKING_OPERATORS}
                            removedCharts={removedCharts}
                            minHeight={400}
                            onRemoveChart={onRemoveChart}
                            onAddChart={onAddChart}
                            title={"Top Operadores"}
                            tooltip="Ranking dos 10 operadores que realizaram o maior valor de recarga/consumo no período. Exporte para visualizar o rank completo."
                            span={3}
                        >
                            {initialFilter && <RankingOperators
                                getRankingOperators={getRankingOperators} />}
                        </DashboarContainer>

                        <DashboarContainer
                            id={dashboardContainerEnum.RANKING_PRODUCTS}
                            minHeight={400}
                            removedCharts={removedCharts}
                            onRemoveChart={onRemoveChart}
                            onAddChart={onAddChart}
                            title={"Top Produtos"}
                            tooltip="Ranking dos 10 produtos mais foram consumidos no período. Exporte para visualizar o rank completo."
                            span={3}
                        >
                            {initialFilter && <RakingProducts 
                                isMobile={isMobile}
                                getProductList={getRankingProducts} />}
                        </DashboarContainer>

                        <DashboarContainer
                            id={dashboardContainerEnum.RANKING_CATEGORY}
                            title={"Venda por categoria pai"}
                            tooltip="Ranking das categorias pai que mais foram consumidas no período."
                            minHeight={400}
                            span={4}
                        >
                            {initialFilter && <RankingStores
                                getTopParent={getRankingCategory}
                                getSubCategory={getRankingSubCategory}
                            />}
                        </DashboarContainer>

                        <DashboarContainer
                            id={dashboardContainerEnum.RANKING_SUBCATEGORY}
                            title={"Venda por categoria"}
                            tooltip="Ranking das categorias que mais foram consumidas no período."
                            minHeight={400}
                            span={2}
                        >
                            {initialFilter && <CategoryRanking
                                getData={getRankingSubCategory}
                                getSubItems={getRankingSubCategory}
                            />}
                        </DashboarContainer>

                        <DashboarContainer
                            id={dashboardContainerEnum.GIRO_MESA}
                            minHeight={150}
                            title={"Giro de mesa"}
                            tooltip="Quantidade de vezes que a mesa foi ativa durante o período."
                        >
                            {initialFilter && <TurnoverTable 
                                selectedLocals={filter.locals}
                                getData={getTurnoverTable} />}
                        </DashboarContainer>

                        <DashboarContainer
                            id={dashboardContainerEnum.RANKING_CLIENTS}
                            removedCharts={removedCharts}
                            onRemoveChart={onRemoveChart}
                            minHeight={400}
                            onAddChart={onAddChart}
                            removable
                            title={"Top clientes"}
                            tooltip="Ranking dos clientes que realizaram o maior valor de recarga/consumo no período."
                            span={3}
                        >
                            {initialFilter && <RankingClients getRankingClients={getRankingClients} />}
                        </DashboarContainer>

                        <DashboarContainer
                            id={dashboardContainerEnum.RANKING_DEVICE}
                            removedCharts={removedCharts}
                            onRemoveChart={onRemoveChart}
                            minHeight={400}
                            onAddChart={onAddChart}
                            removable
                            title={"Vendas por dispositivos"}
                            tooltip="Ranking dos dispositivos que realizaram o maior valor de recarga/consumo no período."
                            span={3}
                        >
                            {initialFilter && <RankingDevices getRankingDevice={getRankingDeviceSales} />}
                        </DashboarContainer>

                        {!!removedCharts.length &&
                            <DashboarContainer span={3} isChartContainer={false} minHeight={300}>
                                <RemovedCard onRemoveChart={onRemoveChart} removedCharts={removedCharts} />
                            </DashboarContainer>
                        }
                    </GridDashboard>
                </div>)
                }
            </LocalResumeConsumer>
        </LocalResumeProvider>
    )
}
export default SalesDashboardPage