import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import styles from './CategoryList.module.scss'
import { ICategoryItem } from './ICategoryItem'
import { IProductItem } from '../productList/IProductItem'
import ProductList from '../productList/ProductList'
import { Button, Icon, IconButton, Menu, MenuItem, Tooltip } from '@material-ui/core'
import { Separator } from '../../ui/Separator'
import useOnScreen from '../onScreen/UseOnScreen'
import { DeleteOutlined, EditOutlined } from '@material-ui/icons'
import ContentPasteGoIcon from '@mui/icons-material/ContentPasteGo';
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd'

export interface ICategoryItemProps {
    categoryItem: ICategoryItem;
    defaultProductVisibility?: boolean;
    onClickDelete?: (item: ICategoryItem) => void
    onClickEdit?: (item: ICategoryItem) => void
    onClickEditProduct?: (item: IProductItem) => void
    onClickCopyProduct?: (item: IProductItem) => void
    onClickRemoveProduct?: (values: IProductItem) => Promise<void>
    onClickDeleteProduct?: (values: IProductItem) => Promise<void>
    onClickAddProduct?: (item: ICategoryItem) => void
    getProducts?: (item: ICategoryItem) => Promise<IProductItem[]>
    onChangeEnabledProduct?: (product: IProductItem, value: boolean) => void
    onChangeFeaturedProduct?: (product: IProductItem, value: boolean) => void
    hideEmptyCategories?: boolean
    onCheckProduct?: (product: IProductItem, value: boolean) => void
    onClickProductAccess?: (product: IProductItem) => void
    disabledHidProduct?: boolean
    onChangeHide?: (product: IProductItem, value: boolean) => void
    currentCatalogId?: string
    dragHandleProps?: DraggableProvidedDragHandleProps | undefined;
    isDragInDropDisabled?: boolean;
}

interface SavedProduct {
    categoryId: string;
    showProducts: boolean;
}

const CategoryItem = ({ dragHandleProps, 
    categoryItem, 
    onClickAddProduct, 
    disabledHidProduct, 
    onClickProductAccess, 
    onClickRemoveProduct, 
    defaultProductVisibility = true, 
    onCheckProduct, 
    hideEmptyCategories, 
    onClickDelete, 
    onClickEdit, 
    onClickEditProduct, 
    onChangeHide, 
    currentCatalogId, 
    getProducts, 
    onChangeEnabledProduct, 
    onChangeFeaturedProduct, 
    onClickCopyProduct, 
    onClickDeleteProduct,
    isDragInDropDisabled }: ICategoryItemProps) => {
    const [products, setProducts] = useState<IProductItem[]>();
    const [isLoadingProducts, setIsLoadingProducts] = useState(false);
    const containerRef = useRef<HTMLDivElement>(null)
    const onScreen = useOnScreen(containerRef);
    const [isVisible, setIsVisible] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [trigger, setTrigger] = useState(false)
    const shared = false
    const openMoreMenuHandle = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const closeMoreMenuHandle = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        if (onScreen) {
            setIsVisible(true);
        }
    }, [onScreen])

    useEffect(() => {
        if (categoryItem.productList) {
            setProducts(categoryItem.productList)
        }
    }, [categoryItem.productList])

    var getProductsHandle = useCallback(async (categoryItem: ICategoryItem) => {
        if (getProducts) {
            try {
                setIsLoadingProducts(true);
                const response: IProductItem[] = await getProducts(categoryItem) as IProductItem[];
                setProducts(response);
            } finally {
                setIsLoadingProducts(false);
            }
        }
    }, [getProducts])

    const onClickDeleteHandle = useCallback(() => {
        onClickDelete?.(categoryItem);
        closeMoreMenuHandle();
    }, [categoryItem, onClickDelete])

    const onClickEditHandle = useCallback(() => {
        onClickEdit?.(categoryItem);
        closeMoreMenuHandle();
    }, [categoryItem, onClickEdit])


    const onClickRemoveProductHandle = useCallback(async (categoryItem: ICategoryItem, values: IProductItem) => {
        try {
            await onClickRemoveProduct?.(values);
            getProductsHandle?.(categoryItem);
        } finally {

        }
    }, [getProductsHandle, onClickRemoveProduct])

    const onClickDeleteProductHandle = useCallback(async (categoryItem: ICategoryItem, values: IProductItem) => {
        try {
            await onClickDeleteProduct?.(values);
            getProductsHandle?.(categoryItem);
        } finally {

        }
    }, [getProductsHandle, onClickDeleteProduct])

    useEffect(() => {
        if ((categoryItem.showProducts === undefined || categoryItem.showProducts) && isVisible) {
            getProductsHandle(categoryItem);
        }
    }, [categoryItem, getProductsHandle, isVisible, categoryItem.showProducts])

    const onShowProductsHandle = (categoryId: string, value: boolean) => {
        const savedProducts: SavedProduct[] = JSON.parse(sessionStorage.getItem('savedProducts') ?? '[]');

        const index = savedProducts.findIndex(item => item.categoryId === categoryId);

        if (index !== -1) {
            savedProducts[index].showProducts = value;
        } else {
            savedProducts.push({ categoryId, showProducts: value });
        }

        sessionStorage.setItem('savedProducts', JSON.stringify(savedProducts));
        const findSavedProducts = savedProducts.find(item => item.categoryId === categoryItem.id)
        categoryItem.showProducts = findSavedProducts?.showProducts;
        setTrigger(!trigger)
    }

    useEffect(() => {
        const getSavedProductsFromStorage = (): SavedProduct[] => {
            return JSON.parse(sessionStorage.getItem('savedProducts') ?? '[]');
        }
        const savedProducts: SavedProduct[] = getSavedProductsFromStorage();
        const findSavedProducts = savedProducts.find(item => item.categoryId === categoryItem.id)
        categoryItem.showProducts = findSavedProducts?.showProducts;
    }, [categoryItem, trigger])

    var menu = useMemo(() =>
        (onClickEdit || onClickDelete) && <>
            <IconButton onClick={openMoreMenuHandle}><Icon>more_vert</Icon></IconButton>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={!!anchorEl}
                onClose={closeMoreMenuHandle}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                {onClickEdit && <MenuItem onClick={onClickEditHandle} style={{ gap: "8px" }}><EditOutlined /> Editar categoria</MenuItem>}
                {onClickDelete && <MenuItem onClick={onClickDeleteHandle} style={{ gap: "8px" }}><DeleteOutlined />Excluir categoria</MenuItem>}
            </Menu>
        </>, [anchorEl, onClickDelete, onClickDeleteHandle, onClickEdit, onClickEditHandle])

    return (
        !(hideEmptyCategories && products?.length === 0) ?
            <div ref={containerRef} id={styles.CategoryItem}>
                <>
                    <div className={styles.title}>
                        <div className={styles.titleContent}>
                            {!isDragInDropDisabled && <div className={styles.titleIcon} {...dragHandleProps}>
                                <Icon >drag_indicator</Icon>
                            </div>}
                            <b>{categoryItem.description}</b>{shared && <ContentPasteGoIcon className={styles.shared} />}                            <div className={styles.categoryStatus}>
                                {categoryItem?.parentCategory?.name ? <Tooltip title="Categoria pai"><span className={styles.parentCategory}>{categoryItem?.parentCategory?.name}</span></Tooltip> : null}
                                {categoryItem.isVisibleInPOS ? <Tooltip title="Visível na POS"><Icon fontSize='small' color='secondary'>phonelink_ring</Icon></Tooltip> : null}
                                {categoryItem.isVisibleInDashboard ? <Tooltip title="Visivel no Dashboard"><Icon fontSize='small' color='secondary'>bar_chart_4_bars</Icon></Tooltip> : null}
                            </div>
                        </div>
                        <div className={styles.actions}>
                            {menu}
                            {
                                !disabledHidProduct && <>
                                    {(categoryItem.showProducts === undefined || categoryItem.showProducts) ?
                                        <IconButton onClick={() => onShowProductsHandle(categoryItem.id, false)}><Icon>expand_less</Icon></IconButton>
                                        :
                                        <IconButton onClick={() => onShowProductsHandle(categoryItem.id, true)}><Icon>expand_more</Icon></IconButton>}
                                </>
                            }
                        </div>
                    </div>
                    {((categoryItem.showProducts === undefined || categoryItem.showProducts) || disabledHidProduct) &&
                        <>
                            <ProductList
                                isDragInDropDisabled={isDragInDropDisabled ?? false}
                                onClickCopyProduct={onClickCopyProduct}
                                isLoading={isLoadingProducts && isVisible}
                                onClickEditProduct={onClickEditProduct}
                                onClickRemoveProduct={onClickRemoveProduct ? (product) => onClickRemoveProductHandle(categoryItem, product) : undefined}
                                onClickDeleteProduct={onClickDeleteProduct ? (product) => onClickDeleteProductHandle(categoryItem, product) : undefined}
                                onChangeFeaturedProduct={onChangeFeaturedProduct}
                                onCheckProduct={onCheckProduct}
                                onChangeEnabledProduct={onChangeEnabledProduct}
                                products={products ?? []}
                                onClickProductAccess={onClickProductAccess}
                                currentCatalogId={currentCatalogId}
                                onChangeHide={onChangeHide}
                                onSortList={function (list: IProductItem): void {
                                    throw new Error('Function not implemented.')
                                }}
                            />

                            {!!onClickAddProduct && <Button color={"secondary"} style={{ fontWeight: '600', textTransform: 'inherit' }} variant='text' onClick={() => onClickAddProduct(categoryItem)}><Icon fontSize={'small'}>add</Icon>Cadastrar produto</Button>}
                        </>
                    }
                    <Separator />
                </>
            </div >
            : null
    )
}
export default CategoryItem