
import { useState } from 'react';
import styles from './ModalProdutosSemSplit.module.scss'
import { DialogTitle, Dialog, DialogContent, DialogActions, Button } from '@mui/material'
import { IProdutosSemSplitResponse } from 'modules/salesDashboard/domain/dtos/IProdutosSemSplitResponse';
import { useHistory } from 'react-router-dom';

export interface IModalProps {
    list?: IProdutosSemSplitResponse
}

const ModalProdutosSemSplit = ({ list }: IModalProps) => {
    const [open, setOpen] = useState(true)
    const { push } = useHistory();

    return (
        <Dialog
            fullWidth={true}
            maxWidth={'sm'}
            open={open}
            onClose={() => setOpen(false)}
        >
            <div id={styles.Modal}>
                <DialogTitle style={{ color: "#333333" }}>Produtos sem Split</DialogTitle>
                <DialogContent className={styles.container}>
                    <p>Você possui produtos que não estão configurados em nenhuma regra de Split, por esse motivo eles se encontram desativados.</p>
                    <p className={styles.qtsProdutos}>{list?.TotalRecords} produto(s) sem split.</p>
                    <DialogContent className={styles.content}>
                        {list?.Records.map(item => {
                            return (
                                <div className={styles.box}>
                                    <p>{item.ProductName}</p>
                                </div>
                            )
                        })}

                    </DialogContent>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)} variant='outlined'>Fechar</Button>
                    <Button onClick={() => push('/private/transacoes/split')} variant='contained'>Configurar split</Button>
                </DialogActions>
            </div>
        </Dialog>

    )
}

export default ModalProdutosSemSplit