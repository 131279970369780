import { useCallback, useState } from "react";
import { IFilterValue } from "../interfaces/IFilter";
import { ISalesDevices } from "../interfaces/ISalesDevices";
import { useLocal } from "modules/local/presentation/context/LocalContext";
import GeneralDashboardApi from "services/api/generalDashboard/GeneralDashboardApi";
import GetSalesDevicesUseCase from "modules/generalDashboard/application/useCases/GetSalesDevicesUseCase";

export interface IParamsUseSalesDevices {
  localId?: string;
  sessionId?: string;
}

const UseSalesDevices = (filter: IFilterValue | undefined) => {
  const [isLoading, setIsLoading] = useState<boolean>();
  const [salesDevices, setSalesDevices] = useState<ISalesDevices[]>();
  const { currentLocal } = useLocal();

  const getSalesDevices = useCallback(
    async (params?: IParamsUseSalesDevices) => {
      try {
        const serviceGeneral = GeneralDashboardApi();
        setIsLoading(true);
        if (currentLocal) {
          const response = await GetSalesDevicesUseCase(
            serviceGeneral,
            currentLocal.id,
            {
              ...filter,
            }
          );

          setSalesDevices(response);

          return response;
        }
      } finally {
        setIsLoading(false);
      }
    },
    [currentLocal, filter]
  );

  return {
    salesDevices,
    isLoading,
    getSalesDevices,
    setSalesDevices,
  };
};

export default UseSalesDevices;
