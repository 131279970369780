import { LinearProgress } from '@material-ui/core'
import { MenuItem, Pagination, TextField } from '@mui/material'
import { INFCeResponse } from 'modules/kds/domain/dto/nfe/NFCeResponse'
import React, { FC, useMemo } from 'react'
import { IIssueNFCe, INFCeItem } from '../../interfaces/IIssueNFE'
import { IKDSItem, IKDSItemStep } from '../KDSItem/IKDSItem'
import KDSItem, { KDSStepAction } from '../KDSItem/KDSItem'
import { IKDSGroupedList } from './IKDSGroupedList'
import styles from './KDSGroupedList.module.scss'
export interface IKDSGroupedListProps {
    //propertys
    groupedList?: IKDSGroupedList
    isLoading?: boolean
    getData: (page: number, pageSize?: number) => void
    changePagination?: (page: number, pageSize?: number) => void
    pagination?: { page: number, pageSize?: number }
    onClickAction?: (action: KDSStepAction, item: IKDSItem) => Promise<IKDSItemStep>
    onClickPrint: (item: IKDSItem) => Promise<void>
    postIssueNfe: (item: IIssueNFCe) => Promise<INFCeResponse[] | undefined>
    issueNFCe: INFCeResponse[];
    summaryView: boolean;
    setSummaryView: React.Dispatch<React.SetStateAction<boolean>>
}
const KDSGroupedList: FC<IKDSGroupedListProps> = ({
    groupedList,
    isLoading,
    getData,
    changePagination,
    pagination,
    onClickAction,
    onClickPrint,
    postIssueNfe,
    issueNFCe,
    summaryView,
    setSummaryView,
}) => {
    const countPage = useMemo(() => Math.ceil((groupedList?.count ?? 1) / (pagination?.pageSize ?? 50)), [groupedList?.count, pagination?.pageSize])

    const list = useMemo(() => groupedList?.items.map((group, index) => <div key={index + group.name} className={styles.groupItem}>
        <h2 className={styles.name}>{group.name}</h2>
        <div className={styles.KDSItemsList}>
            {group.kdsItems.map((item, index) => <KDSItem
                key={index + item.id}
                postIssueNfe={postIssueNfe}
                kdsItem={item}
                issueNFCe={issueNFCe}
                onClickAction={onClickAction}
                onClickPrint={onClickPrint}
                summaryView={summaryView}
                setSummaryView={setSummaryView}
            />)}
        </div>
    </div>), [groupedList?.items, issueNFCe, onClickAction, onClickPrint, postIssueNfe, setSummaryView, summaryView])

    return (
        <div id={styles.KDSGroupedList} >
            {isLoading && !!groupedList?.items.length ? <LinearProgress className={styles.linearProgress} /> : <div className={styles.linearProgress} />}
            <div className={styles.container} >
                {list}
                {/* <Pagination count={countPage} onChange={(_, page) => changePagination?.(page, pagination?.pageSize)} page={((pagination?.page ?? 1))} ></Pagination> */}
                <div className={styles.conteinerPagination}>
                    <div className={styles.boxMultiplePage}>
                        <span>Exibir</span>
                        <TextField variant='outlined' size='small' select onChange={(ev) => changePagination?.(1, Number(ev.target.value))}>
                            <MenuItem className={styles.textPage} value={10} >10</MenuItem>
                            <MenuItem className={styles.textPage} value={20} >20</MenuItem>
                            <MenuItem className={styles.textPage} value={50} >50</MenuItem>
                        </TextField>
                    </div>
                    <Pagination
                        variant="outlined"
                        className={styles.paginationNumber}
                        shape="rounded"
                        count={countPage}
                        sx={{
                            '.MuiPaginationItem-root': {
                                borderColor: '#BDBDBD',
                                color: '#001537',
                            },
                            '.Mui-selected': {
                                fontWeight: 'bold',
                                borderColor: '#001537',
                            },
                        }}
                        onChange={(_, page) => changePagination?.(page, pagination?.pageSize)} page={((pagination?.page ?? 1))}
                    />
                </div>
            </div>
            {isLoading && !!groupedList?.items.length ? <LinearProgress className={styles.linearProgress} /> : <div className={styles.linearProgress} />}
        </div>
    )
}
export default KDSGroupedList