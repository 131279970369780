import React, { FC, useCallback, useEffect, useState } from 'react'
import styles from './AddOrUpdateProductPage.module.scss'
import ProductForm from '../componentes/productForm/ProductForm'
import GetCatalogProductCategoryListUseCase from 'modules/catalog/application/useCases/GetCatalogProductCategoryListUseCase'
import { useLocal } from 'modules/local/presentation/context/LocalContext'
import { ICatalogSelect, ICategorySelect, IEventSelect, IPrinterSelect, IProductFormValue } from '../componentes/productForm/IProducFormValuet'
import CatalogService from 'services/api/catalog/CatalogService'

import { useParams } from 'react-router'
import GetProductUseCase from 'modules/catalog/application/useCases/GetProductUseCase'
import AddProductUseCase from 'modules/catalog/application/useCases/AddProductUseCase'
import EditProductUseCase from 'modules/catalog/application/useCases/EditProductUseCase'
import { useHistory } from 'react-router-dom'
import { useUi } from 'contexts/userInterface/UserInterfaceContext'
import GetDefaultPrinterListUseCase from 'modules/catalog/application/useCases/GetIMainPrinterListUseCase'
import { ProductsApi } from 'services/api/products/ProductsApi'
import GetStoresListUseCase from 'modules/catalog/application/useCases/GetStoresListUseCase'
import UseQueryParams from '../hooks/UseQueryParams'
import GetCatalogListsUseCase from 'modules/catalog/application/useCases/GetCatalogListsUseCase'
import { IframePage } from 'modules/oldPortal/presentation/components/iframe/IframePage'
import { ArrowBackIosRounded } from '@material-ui/icons'
import { v4 } from "uuid"
import GetEventsUseCase from 'modules/catalog/application/useCases/GetEventsUseCase'
import GetSmartPrinterUseCase from 'modules/config/smartPrinterConfig/application/useCases/GetSmartPrinterUseCase'
import PrinterApi from 'services/api/config/printer/PrinterApi'
import { PrinterTypeEnum } from 'modules/config/smartPrinterConfig/presentation/components/printerSectorConfig/PrinterSectorConfig'


const catalogService = CatalogService();
const productsService = ProductsApi();

export interface IAddProductPageProps {
    //propertys
}
const AddOrUpdateProductPage: FC<IAddProductPageProps> = () => {

    const [categoryId, setCategoryId] = useState<string>();
    const { currentLocal } = useLocal();

    //Edit Product 
    const [productId, setProductId] = useState<string>();
    const [defaultProductValues, setDefaultProductValues] = useState<IProductFormValue>()


    const [catalogId, setCatalogId] = useState<string>();
    const [filterCategories, setFilterCategories] = useState<string>();
    const [productCategoryList, setProductCategoryList] = useState<ICategorySelect[]>();
    const [catalogList, setCatalogList] = useState<ICatalogSelect[]>();
    const [printerList, setPrinterList] = useState<IPrinterSelect[]>();
    const [sectorPrinterList, setSectorPrinterList] = useState<IPrinterSelect[]>();
    const [storesList, setStoresList] = useState<IPrinterSelect[]>();
    const [eventsList, setEventsList] = useState<IEventSelect[]>();
    const { showLoading, hideLoading } = useUi();
    const { action } = useParams<{ action: string }>()
    const { push } = useHistory();
    const queryParams = UseQueryParams();

    const [isLoadingProduct, setIsLoadingProduct] = useState(0);

    useEffect(() => {
        const _productId = queryParams.get("productId");
        const _catalogId = queryParams.get("catalogId");
        const _categoryId = queryParams.get("categoryId");
        const _filterCategories = queryParams.get("filterCategories");
        setProductId(_productId ?? undefined);
        setCategoryId(_categoryId ?? undefined);
        setCatalogId(_catalogId ?? undefined);
        setFilterCategories(_filterCategories ?? undefined)
    }, [action, queryParams]);

    const showLoadingForm = () => {
        setIsLoadingProduct(prev => prev + 1);
    }
    const hideLoadingForm = () => {
        setIsLoadingProduct(prev => prev - 1);
    }

    const getProduct = useCallback(async (localId: string, productId: string, productCategoryList: ICategorySelect[], catalogList: ICatalogSelect[], printerList: IPrinterSelect[]) => {
        try {
            showLoadingForm();
            var response = await GetProductUseCase(catalogService, localId, productId, productCategoryList, catalogList, printerList);

            setDefaultProductValues({
                ...response,
                observations: response.observations.map((observation) => ({
                    ...observation,
                    options: observation.options.map((option) => ({
                        ...option,
                        optionId: v4(),
                    })),
                    observationId: v4(),
                })),
            });
        } finally {
            hideLoadingForm();
        }
    }, [])

    useEffect(() => {
        if (currentLocal && productId && productCategoryList && catalogList && printerList) {
            getProduct(currentLocal.id, productId, productCategoryList, catalogList, printerList);
        }
    }, [catalogList, currentLocal, getProduct, printerList, productCategoryList, productId])

    const getCategory = useCallback(
        async (localId: string) => {
            if (currentLocal) {
                try {
                    showLoadingForm();
                    const categoryResponse = await GetCatalogProductCategoryListUseCase(catalogService, localId);
                    setProductCategoryList(categoryResponse);
                } finally {
                    hideLoadingForm();
                }
            }
        },
        [currentLocal],
    )
    const getCatalogs = useCallback(
        async (localId: string) => {
            if (currentLocal) {
                try {
                    showLoadingForm();
                    const categoryResponse = await GetCatalogListsUseCase(catalogService, localId);
                    setCatalogList(categoryResponse);
                } finally {
                    hideLoadingForm();
                }
            }
        },
        [currentLocal],
    )

    const getPrinters = useCallback(async (localId: string) => {
        if (currentLocal) {
            try {
                showLoadingForm();
                const printersResponse = await GetDefaultPrinterListUseCase(productsService, localId);
                setPrinterList(printersResponse);
            } finally {

                hideLoadingForm();
            }
        }
    }, [currentLocal]);

    const getSectorPrinters = useCallback(async (localId: string) => {
        if (currentLocal) {
            try {
                const printerService = PrinterApi;
                showLoadingForm();
                const printersResponse = await GetSmartPrinterUseCase(printerService, localId);
                setSectorPrinterList(printersResponse.list.filter(printer => printer.type === PrinterTypeEnum.order));
            } finally {

                hideLoadingForm();
            }
        }
    }, [currentLocal])
    const getStoress = useCallback(async (localId: string) => {
        if (currentLocal) {
            try {
                showLoadingForm();
                const storessResponse = await GetStoresListUseCase(catalogService, localId);
                setStoresList(storessResponse);
            } finally {
                hideLoadingForm();
            }
        }

    }, [currentLocal])
    const getEvents = useCallback(async (localId: string) => {
        if (currentLocal) {
            try {
                showLoadingForm();
                const storessResponse = await GetEventsUseCase(catalogService, localId);
                setEventsList(storessResponse);
            } finally {
                hideLoadingForm();
            }
        }

    }, [currentLocal])

    useEffect(() => {
        if (currentLocal) {
            getCategory(currentLocal.id);
            getCatalogs(currentLocal.id);
            getPrinters(currentLocal.id);
            getStoress(currentLocal.id);
            getEvents(currentLocal.id);
            getSectorPrinters(currentLocal.id);
        }
    }, [currentLocal, getCatalogs, getCategory, getEvents, getPrinters, getSectorPrinters, getStoress])

    const onSubmitHandler = useCallback(async (values: IProductFormValue) => {
        try {
            showLoading()
            if (currentLocal) {
                const filteredObservations = values.observations.filter(observation => observation.description.trim() !== '');

                const updatedValues = {
                    ...values,
                    observations: filteredObservations
                };
                if (action === 'add') {
                     await AddProductUseCase(catalogService, currentLocal.id, updatedValues);
                } else {
                    if (productId) {
                         await EditProductUseCase(catalogService, currentLocal.id, productId, updatedValues);
                    } else {
                        throw new Error("ProductId is required");
                    }
                }
                if (filterCategories) {
                    push(`/private/catalog/${catalogId}?filterCategories=true`)
                } else {
                    push(`/private/catalog/${catalogId}`)
                }
            }
        } finally {
            hideLoading();
        }

    }, [action, catalogId, currentLocal, filterCategories, hideLoading, productId, push, showLoading])

    const onCancelHandler = useCallback(() => {
        if (filterCategories) {
            push(`/private/catalog/${catalogId}?filterCategories=true`)
        } else {
            push(`/private/catalog/${catalogId}`)
        }

    }, [catalogId, filterCategories, push]);

    if (action === "schedule") {
        return (
            <>
                <div onClick={onCancelHandler} className={styles.clickBack}>
                    <ArrowBackIosRounded />
                    <p>Voltar</p>
                </div>
                <IframePage src="/produtos/agendamento" />
            </>
        )
    }

    return (
        <div id={styles.AddProductPage}>
            <div className={styles.container}>
                <ProductForm
                    disabledComplementGroup={currentLocal?.systemIdentifier === 0}
                    isLoading={!!isLoadingProduct}
                    defaultCatalogId={catalogId}
                    defaultCategoryId={categoryId}
                    defaultValue={defaultProductValues}
                    categories={productCategoryList}
                    catalogs={catalogList}
                    printers={printerList ?? []}
                    events={eventsList ?? []}
                    stores={storesList ?? []}
                    onCancel={onCancelHandler}
                    onSubmit={onSubmitHandler}
                    sectorPrinters={sectorPrinterList}
                />
            </div>
        </div>
    )
}
export default AddOrUpdateProductPage

