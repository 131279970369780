import { IGetRankingOperatorsResponse } from "modules/dashboardTicket/domain/dto/IGetRankingOperatorsResponse";
import { IDashboardTicketApiService } from "modules/dashboardTicket/domain/interfaces/IMyAccountApiService";
import { IFilterValue } from "modules/dashboardTicket/presentation/interfaces/IFilter";
import { IRankingOperators } from "modules/dashboardTicket/presentation/interfaces/IRankingOperators";


const GetRankingOperatorsUseCase = async (service: IDashboardTicketApiService, localId: string, filter?: IFilterValue): Promise<IRankingOperators[]> => {

    const response: IGetRankingOperatorsResponse[] = await service.getRankingOperators(localId, filter);

    const parse: IRankingOperators[] = response.map(item => {
        return {
            name: item.nome,
            value: item.valor,
        };
    });

    return parse;

}

export default GetRankingOperatorsUseCase;