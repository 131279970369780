import React, { FC, MutableRefObject, RefObject, useCallback, useEffect, useState } from 'react'
import { IMainPrinterItem } from './changePrinterModal/IMainPrinterItem'
import { useLocal } from 'modules/local/presentation/context/LocalContext'
import { IProductItem } from '../categoryAndProducts/productList/IProductItem'
import { IProductActionEnum } from '../../interfaces/IProductActionEnum'
import { IStoreItem } from './changeStoreModal/IStore'
import { useUi } from 'contexts/userInterface/UserInterfaceContext'
import GetDefaultPrinterListUseCase from 'modules/catalog/application/useCases/GetIMainPrinterListUseCase'
import GetStoreListUseCase from 'modules/catalog/application/useCases/GetLojaListUseCase'
import { ProductsApi } from 'services/api/products/ProductsApi'
import ChangeDefaultPrinterUseCase from '../../../application/useCases/ChangeDefaultPrinterUseCase'
import ChangeStoreUseCase from '../../../application/useCases/ChangeStoreUseCase'
import ChangeStockControlUseCase from '../../../application/useCases/ChangeStockControlUseCase'
import ChangeTaxDataUseCase from '../../../application/useCases/ChangeTaxDataUseCase'
import DeleteProductsUseCase from '../../../application/useCases/DeleteProductsUseCase'



const ServiceProduct = ProductsApi();

const UseProductActions = (getData: () => Promise<void>, selectedProducts: IProductItem[]) => {
    const { currentLocal } = useLocal();
    const [changePrinterModalOpen, setChangePrinterModalOpen] = useState(false)
    const [changeStockControlModalOpen, setChangeStockControlModalOpen] = useState(false)
    const [changeStoreModalOpen, setChangeStoreModalOpen] = useState(false)
    const [changeTaxDataModalOpen, setChangeTaxDataModalOpen] = useState(false)
    const [deleteProductsModalOpen, setDeleteProductsModalOpen] = useState(false)
    // const [selectedProducts, setSelectedProducts] = useState<IProductItem[]>([])

    const [printers, setPrinters] = useState<IMainPrinterItem[]>([])
    const [stores, setStores] = useState<IStoreItem[]>([])

    const { toast, showLoading, hideLoading } = useUi()

    const onClickAction = useCallback(async (actionType: IProductActionEnum) => {
        if (!selectedProducts.length) {
            toast("Selecione ao menos um produto", "error");
            return
        }

        // setSelectedProducts(_selectedProducts!)
        changeModalOpen(actionType, true)
    }, [selectedProducts, toast])

    useEffect(() => {
        if (currentLocal) {
            GetDefaultPrinterListUseCase(ServiceProduct, currentLocal.id).then(resp => setPrinters(resp))
            GetStoreListUseCase(ServiceProduct, currentLocal.id).then(resp => setStores(resp))
        }
    }, [currentLocal])

    const changeModalOpen = (actionType: IProductActionEnum, open: boolean) => {
        switch (actionType) {
            case IProductActionEnum.CHANGE_PRINTER:
                setChangePrinterModalOpen(open)
                break;
            case IProductActionEnum.CHANGE_STOCK_CONTROL:
                setChangeStockControlModalOpen(open)
                break;
            case IProductActionEnum.CHANGE_STORE:
                setChangeStoreModalOpen(open)
                break;
            case IProductActionEnum.CHANGE_TAX_DATA:
                setChangeTaxDataModalOpen(open)
                break;
            case IProductActionEnum.DELETE_PRODUCTS:
                setDeleteProductsModalOpen(open)
                break;
        }
    }

    const onCloseModals = useCallback(() => {
        setChangePrinterModalOpen(false)
        setChangeStockControlModalOpen(false)
        setChangeStoreModalOpen(false)
        setChangeTaxDataModalOpen(false)
        setDeleteProductsModalOpen(false)
        // setSelectedProducts([])
    }, [])

    const onChangePrinter = useCallback(async (printerId?: string) => {
        if (!selectedProducts) {
            toast("Selecione ao menos um produto ", 'error');
            return
        }
        showLoading();
        try {
            await ChangeDefaultPrinterUseCase(ServiceProduct, selectedProducts.map(x => x.id), printerId)
            onCloseModals()
            getData()
            toast("Ação executada com sucesso", 'success')
        } catch {
            toast("Ocorreu um erro com a operação. Tente novamente", 'error')
        } finally {
            hideLoading()
        }
    }, [showLoading, selectedProducts, onCloseModals, getData, hideLoading, toast])

    const onChangeStore = useCallback(async (storeId: string) => {
        if (!selectedProducts) {
            toast("Selecione ao menos um produto ", 'error');
            return
        }
        showLoading();
        try {
            await ChangeStoreUseCase(ServiceProduct, selectedProducts.map(x => x.id), storeId)
            onCloseModals()
            getData()
            toast("Ação executada com sucesso", 'success')
        } catch {
            toast("Ocorreu um erro com a operação. Tente novamente", 'error')
        } finally {
            hideLoading()
        }
    }, [showLoading, selectedProducts, onCloseModals, getData, hideLoading, toast])

    const onChangeStockControl = useCallback(async (stockControl: boolean) => {
        if (!selectedProducts) {
            toast("Selecione ao menos um produto ", 'error');
            return
        }

        showLoading();


        try {
            await ChangeStockControlUseCase(ServiceProduct, selectedProducts.map(x => x.id), stockControl)
            onCloseModals()
            getData()
            toast("Ação executada com sucesso", 'success')
        } catch {
            toast("Ocorreu um erro com a operação. Tente novamente", 'error')
        } finally {
            hideLoading()
        }
    }, [showLoading, selectedProducts, onCloseModals, getData, hideLoading, toast])

    const onChangeTaxData = useCallback(async (taxData: { cfop: string, cest: string, ncm: string }) => {
        if (!selectedProducts) {
            toast("Selecione ao menos um produto ", 'error');
            return
        }
        showLoading();
        try {
            await ChangeTaxDataUseCase(ServiceProduct, selectedProducts.map(x => x.id), taxData)
            onCloseModals()
            getData()
            toast("Ação executada com sucesso", 'success')
        } catch {
            toast("Ocorreu um erro com a operação. Tente novamente", 'error')
        } finally {
            hideLoading()
        }
    }, [showLoading, selectedProducts, onCloseModals, getData, toast, hideLoading])

    const onDeleteProducts = useCallback(async () => {
        if (!selectedProducts) {
            toast("Selecione ao menos um produto ", 'error');
            return
        }
        showLoading();
        try {
            await DeleteProductsUseCase(ServiceProduct, selectedProducts.map(x => x.id))
            onCloseModals()
            getData()
            toast("Ação executada com sucesso", 'success')
        } catch {
            toast("Ocorreu um erro com a operação. Tente novamente", 'error')
        } finally {
            hideLoading()
        }
    }, [showLoading, selectedProducts, onCloseModals, getData, hideLoading, toast])

    return ({
        onClickAction,
        changePrinterModalOpen,
        changeStockControlModalOpen,
        changeStoreModalOpen,
        changeTaxDataModalOpen,
        deleteProductsModalOpen,
        onCloseModals,
        onChangePrinter,
        onChangeStore,
        onChangeStockControl,
        onChangeTaxData,
        onDeleteProducts,
        printers,
        stores,
    })
}


export default UseProductActions