import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import styles from "modules/saleForDevice/presentation/SaleForDevicePage.module.scss";
import CashClosingFilter from "./components/cashClosingFilter/CashClosingFilter";
import { UseCashClosingPage } from "./hooks/UseCashClosingPage";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { Divider, Radio, Skeleton } from "@mui/material";
import { IPostCashClosingResponse, ISessionResult } from "../domain/dto/IPostCashClosingResponse";
import { useUi } from "contexts/userInterface/UserInterfaceContext";
import UseDimension from "components/dimension/UseDimension";
import { useLocal } from "modules/local/presentation/context/LocalContext";
import CashClosingHeader from "./components/cashClosingHeader/CashClosingHeader";
import { Info } from "@material-ui/icons";
import CashClosingItem from "./components/cashClosingItem/CashClosingItem";
import CashClosingItemMobile from "./components/cashClosingItemMobile/CashClosingItemMobile";
import CashClosingTotalItem from "./components/cashClosingTotalItem/CashClosingTotalItem";
import Sidesheet from "components/sidesheet/Sidesheet";
import CashiersSidesheet from "modules/saleForDevice/presentation/components/cashiersSidesheet/CashiersSidesheet";
import SidesheetFeedback from "components/sidesheet/sidesheetFeedback/SidesheetFeedback";
import { moneyMaskNumber } from "services/utils/Money";
import * as XLSX from "xlsx";

interface IXLSXExportData {
  Origem: string;
  Cancelado: string;
  Cashless: string;
  Debito: string;
  DebitoNT: string;
  Credito: string;
  CreditoNT: string;
  Dinheiro: string;
  Desconto: string;
  Voucher: string;
  Outros: string;
  EmAberto: string;
  Suprimento: string;
  Sangria: string;
  Total: string;
}

const CashClosingPage = () => {
  const {
    isLoading,
    reportData: response,
    cashiers,
    params,
    setParams,
    postCashClosingReport,
    getCashiers,
  } = UseCashClosingPage();
  const { dimensions } = UseDimension();
  const { showLoading, hideLoading } = useUi();
  const { currentLocal } = useLocal();

  const [openSidesheet, setOpenSidesheet] = useState(false);
  const [openExportSidesheet, setOpenExportSidesheet] = useState(false);
  const [exportSidesheetStep, setExportSidesheetStep] = useState(1);
  const [exportType, setExportType] = useState<"pdf" | "xlsx">("xlsx");
  const [onExport, setOnExport] = useState<string | null>(null);

  const pdfRef = useRef<HTMLDivElement>(null);

  const reportData = useMemo(() => response ?? {} as IPostCashClosingResponse, [response]);
  const cashiersByPeriod = useMemo(() => cashiers ?? [], [cashiers]);
  const showExportButton = useMemo(
    () =>
      !isLoading &&
      !!Object.keys(reportData).length &&
      !!reportData.resultadoSessao.TotalGeral &&
      !!reportData.resultadoSessao.Categorias,
    [isLoading, reportData]
  );

  useEffect(() => {
    getCashiers()
  }, [getCashiers]);

  useEffect(() => {
    if (params.sessaoId || (params.dataInicio && params.dataFim)) {
      postCashClosingReport();
    }
  }, [params, postCashClosingReport]);

  const skeletons = Array.from({ length: 4 }, (_, index) => (
    <Skeleton key={index} variant="text" sx={{ fontSize: '40px', marginBottom: "10px" }} />
  ));

  const handleOpenSidesheet = useCallback((startDate: Date, endDate: Date) => {
    setOpenSidesheet(true);
    getCashiers(startDate, endDate);
  }, [getCashiers]);

  const generatePDF = useCallback(async () => {
    if (!pdfRef.current) return;

    const pdf = new jsPDF({ orientation: 'landscape', format: [800, 600] });
    const imgWidth = pdf.internal.pageSize.getWidth();
    const pageHeight = 1260;
    const padding = 20;

    const elements = pdfRef.current.querySelectorAll(".div-to-capture");
    const divs = Array.from(elements).filter((element) => element instanceof HTMLElement);

    let currentHeight = 0;
    let divsByPage: Element[] = [];
    let pages: Element[] = [];

    for (const div of divs) {
      const { height } = div.getBoundingClientRect();

      if (currentHeight + height < pageHeight) {
        currentHeight += height;
        divsByPage.push(div);
      } else {
        const uniqueDiv = document.createElement("div");

        divsByPage.forEach(element => {
          uniqueDiv.appendChild(element.cloneNode(true));
        });

        pages.push(uniqueDiv);
        currentHeight = height;
        divsByPage = [div];
      }
    }

    if (divsByPage.length) {
      const uniqueDiv = document.createElement("div");

      divsByPage.forEach(element => {
        uniqueDiv.appendChild(element.cloneNode(true));
      });

      pages.push(uniqueDiv);
    }

    for (let i = 0; i < pages.length; i++) {
      const tempDiv = pages[i] as HTMLElement;
      tempDiv.style.width = "1915px";

      if (i === pages.length - 1) {
        tempDiv.style.height = "100%";
      }

      document.body.appendChild(tempDiv);

      const canvas = await html2canvas(tempDiv);
      const imgData = canvas.toDataURL("image/png");
      const imgHeight = (canvas.height * imgWidth) / canvas.width;

      pdf.addImage(imgData, "PNG", padding, padding, imgWidth - 2 * padding, imgHeight - 2 * padding);

      if (i !== pages.length - 1) {
        pdf.addPage();
      }

      document.body.removeChild(tempDiv);
    }

    setOnExport(null);
    hideLoading();
    setExportSidesheetStep(exportSidesheetStep + 1);

    const fileName = "venda_por_equipamento.pdf";
    pdf.save(fileName);
  }, [hideLoading, setExportSidesheetStep, exportSidesheetStep]);

  const generateXLSX = useCallback(() => {
    if (reportData) {
      const deviceData = reportData.resultadoSessao.Categorias.flatMap(
        (category) => category.Itens.reduce((array, item, itemIndex, itemsArray) => {
          const itemData = {
            Origem: item.Nome,
            Cancelado: moneyMaskNumber(item.Cancelado ?? 0),
            Cashless: moneyMaskNumber(item.Cashless ?? 0),
            Debito: moneyMaskNumber(item.Debito ?? 0),
            DebitoNT: moneyMaskNumber(item.DebitoNT ?? 0),
            Credito: moneyMaskNumber(item.Credito ?? 0),
            CreditoNT: moneyMaskNumber(item.CreditoNT ?? 0),
            Dinheiro: moneyMaskNumber(item.Dinheiro ?? 0),
            Desconto: moneyMaskNumber(item.Desconto ?? 0),
            Voucher: moneyMaskNumber(item.Voucher ?? 0),
            Outros: moneyMaskNumber(item.Outro ?? 0),
            EmAberto: moneyMaskNumber(item.EmAberto ?? 0),
            Suprimento: moneyMaskNumber(item.Suprimento ?? 0),
            Sangria: moneyMaskNumber(item.Sangria ?? 0),
            Total: moneyMaskNumber(item.TotalItem ?? 0),
          };

          array = [...array, itemData];

          if (itemIndex === itemsArray.length - 1) {
            const lastItemData = {
              Origem: category.Nome,
              Cancelado: moneyMaskNumber(category.Cancelado ?? 0),
              Cashless: moneyMaskNumber(category.Cashless ?? 0),
              Debito: moneyMaskNumber(category.Debito ?? 0),
              DebitoNT: moneyMaskNumber(category.DebitoNT ?? 0),
              Credito: moneyMaskNumber(category.Credito ?? 0),
              CreditoNT: moneyMaskNumber(category.CreditoNT ?? 0),
              Dinheiro: moneyMaskNumber(category.Dinheiro ?? 0),
              Desconto: moneyMaskNumber(category.Desconto ?? 0),
              Voucher: moneyMaskNumber(category.Voucher ?? 0),
              Outros: moneyMaskNumber(category.Outro ?? 0),
              EmAberto: moneyMaskNumber(category.EmAberto ?? 0),
              Suprimento: moneyMaskNumber(category.Suprimento ?? 0),
              Sangria: moneyMaskNumber(category.Sangria ?? 0),
              Total: moneyMaskNumber(category.TotalCategoria ?? 0),
            };
            array = [...array, lastItemData];
          }

          return array;
        }, [] as IXLSXExportData[])
      );

      const totalData = {
        Origem: "Total Geral",
        Cancelado: moneyMaskNumber(reportData.resultadoSessao.CanceladoTotal ?? 0),
        Cashless: moneyMaskNumber(reportData.resultadoSessao.CashlessTotal ?? 0),
        Debito: moneyMaskNumber(reportData.resultadoSessao.DebitoTotal ?? 0),
        DebitoNT: moneyMaskNumber(reportData.resultadoSessao.DebitoNTTotal ?? 0),
        Credito: moneyMaskNumber(reportData.resultadoSessao.CreditoTotal ?? 0),
        CreditoNT: moneyMaskNumber(reportData.resultadoSessao.CreditoNTTotal ?? 0),
        Dinheiro: moneyMaskNumber(reportData.resultadoSessao.DinheiroTotal ?? 0),
        Desconto: moneyMaskNumber(reportData.resultadoSessao.DescontoTotal ?? 0),
        Voucher: moneyMaskNumber(reportData.resultadoSessao.VoucherTotal ?? 0),
        Outros: moneyMaskNumber(reportData.resultadoSessao.OutrosTotal ?? 0),
        EmAberto: moneyMaskNumber(reportData.resultadoSessao.EmAbertoTotal ?? 0),
        Suprimento: moneyMaskNumber(reportData.resultadoSessao.SuprimentoTotal ?? 0),
        Sangria: moneyMaskNumber(reportData.resultadoSessao.SangriaTotal ?? 0),
        Total: moneyMaskNumber(reportData.resultadoSessao.TotalGeral ?? 0),
      } as IXLSXExportData;

      deviceData.push(totalData);

      const workbook = XLSX.utils.book_new();
      const worksheet = XLSX.utils.json_to_sheet(deviceData);
      XLSX.utils.book_append_sheet(
        workbook,
        worksheet,
        "venda_por_equipamento"
      );

      const blob = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
      const blobUrl = URL.createObjectURL(
        new Blob([blob], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        })
      );

      const link = document.createElement("a");
      link.href = blobUrl;
      link.setAttribute("download", "venda_por_equipamento.xlsx");
      document.body.appendChild(link);

      link.click();
      document.body.removeChild(link);

      setOnExport(null);
      hideLoading();
      setExportSidesheetStep(exportSidesheetStep + 1);
    }
  }, [exportSidesheetStep, hideLoading, reportData]);

  const handleOnClickExport = useCallback(async () => {
    showLoading();
    if (params.dataInicio && params.dataFim) {
      await getCashiers(new Date(params.dataInicio ?? ""), new Date(params.dataFim ?? ""));
    }
    if (exportType === "pdf") {
      setOnExport("pdf");
      setTimeout(() => {
        generatePDF();
      }, 2000);
      return;
    }
    setOnExport("xlsx");
    setTimeout(() => {
      generateXLSX();
    }, 2000);
  }, [exportType, generatePDF, showLoading, getCashiers, params.dataInicio, params.dataFim, generateXLSX]);

  const calculateTotal = useCallback((data: ISessionResult) => {
    return (Object.keys(data) as (keyof ISessionResult)[]).reduce((sum, key) => {
        if (key !== 'Categorias') {
            return sum + data[key] as number;
        }
        return sum;
    }, 0);
  }, []);  

  return (
    <div id={styles.CashClosingPage} ref={pdfRef}>
      <CashClosingFilter
        params={params}
        setParams={setParams}
        handleOpenSidesheet={handleOpenSidesheet}
        handleOnClickExport={() => setOpenExportSidesheet(true)}
        showExportButton={showExportButton}
        isLoading={isLoading}
      />

      {isLoading && (
        <div style={{ marginTop: "1rem" }}>
          {skeletons}
        </div>
      )}
      {!!Object.keys(reportData).length && calculateTotal(reportData.resultadoSessao) ? (
        <>
          <div
            className={styles.table}
            style={{ overflowX: dimensions.width < 2075 && dimensions.width > 650 ? "scroll" : "hidden" }}
          >
            {onExport === "pdf" && (
              <div className={`${styles.pdfTitle} div-to-capture`}>
                <h2>{currentLocal?.name}</h2>
                <h3>Relatório de fechamento de caixa</h3>
                <div className={styles.pdfCashier}>
                  <h4>{params.sessaoId ? "Caixa" : "Período"}</h4>
                  {params.sessaoId
                    ? (<span>{cashiers.find((it) => it.id === params.sessaoId)?.name ?? ""}</span>)
                    : (
                      <div className={styles.cashier} >
                        {cashiersByPeriod?.length ? cashiersByPeriod.map((cashier) => (<span key={cashier.id}>{cashier.name}</span>)) : <div />}
                      </div>
                    )}
                </div>
              </div>
            )}
            {dimensions.width > 650 && <CashClosingHeader params={params} setParams={setParams} />}
            {!!reportData.resultadoSessao.Categorias.length && reportData.resultadoSessao.Categorias.map((category, index) => (
              <>
                {dimensions.width > 650
                  ? <CashClosingItem item={category} key={category.Nome} />
                  : <CashClosingItemMobile item={category} key={category.Nome} />
                }
                {index !== reportData.resultadoSessao.Categorias.length - 1 && <Divider className={`${styles.divider} div-to-capture`} style={{ margin: "1rem 0 1.625rem" }} />}
              </>
            ))}
            <CashClosingTotalItem item={reportData} onExport={onExport} />
          </div>
          <div className={`${styles.footerInfo} div-to-capture`}>
            <span><Info />Observação:</span>
            <p>1 - O valor de <b>venda cashless</b> não entra no total, uma vez que já está presente na recarga/consumo do cashless nas formas de pagamento Crédito/Débito/Dinheiro.</p>
            <p>2 - É considerado como <b>cancelado</b> apenas vendas que foram faturadas (pagas) e em seguida cancelada. <b>Cancelamento de itens</b> durante a operação de PÓS-PAGO antes do pagamento não são considerados aqui.</p>
            <p>3 - O Valor de <b>venda em aberto</b> se refere a contas fechadas manualmente com valor em aberto e não entra no valor total.</p>
          </div>
        </>
      ) : null}
      {!isLoading && !!Object.keys(reportData).length && !reportData.resultadoSessao.TotalGeral && !reportData.resultadoSessao.Categorias.length && (
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", gap: "10px", height: "100%" }}>
          <img src="/assets/img/empty-box.png" alt="" />
          <span><b>Não há dados</b> para exibição.</span>
        </div>
      )}

      {openSidesheet &&
        <Sidesheet
          open={openSidesheet}
          onClose={() => setOpenSidesheet(false)}
          title={
            <h2>
              Caixas do <b>período</b>
            </h2>
          }
          content={<CashiersSidesheet cashiersByPeriod={cashiersByPeriod} isLoading={isLoading} />}
          continueButton="Fechar"
          handleContinueButton={() => setOpenSidesheet(false)}
          currentStep={1}
          totalSteps={1}
          notTotalHeight
        />
      }

      {openExportSidesheet &&
        <Sidesheet
          open={openExportSidesheet}
          onClose={() => {
            setOpenExportSidesheet(false);
            setExportSidesheetStep(1);
          }}
          title={<h2>Exportar</h2>}
          content={
            <div className={styles.exporSidesheet}>
              <span>Selecione abaixo uma opção para exportar o relatório</span>
              <div>
                <Radio
                  size="medium"
                  sx={{
                    color: "#32008E",
                    "&.Mui-checked": {
                      color: "#32008E",
                    },
                  }}
                  checked={exportType === "xlsx"}
                  onChange={(_, checked) => setExportType("xlsx")}
                  id="export-xlsx"
                />{" "}
                <label htmlFor="export-xlsx">XLSX</label>
              </div>
              <div>
                <Radio
                  size="medium"
                  sx={{
                    color: "#32008E",
                    "&.Mui-checked": {
                      color: "#32008E",
                    },
                  }}
                  checked={exportType === "pdf"}
                  onChange={(_, checked) => setExportType("pdf")}
                  id="export-pdf"
                />{" "}
                <label htmlFor="export-pdf">PDF</label>
              </div>
            </div>
          }
          continueButton="Exportar"
          handleContinueButton={handleOnClickExport}
          cancelButton
          currentStep={exportSidesheetStep}
          totalSteps={2}
          notTotalHeight
          feedback={
            <SidesheetFeedback
              text="Relatório exportado com sucesso!"
              success
            />
          }
        />
      }
    </div>
  );
}

export default CashClosingPage;