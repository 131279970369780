import { ICatalogService } from "modules/catalog/domain/interface/ICatalogService";


const DeleteProductUseCase = async (
  service: ICatalogService,
  productId: string,
): Promise<void> => {
  return await service.deleteProduct(productId);
};

export default DeleteProductUseCase;
