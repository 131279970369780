import React from 'react'
import styles from './DashboardTicketPage.module.scss'
import { MyAccount } from '../components/myAccount/MyAccount'
import { AllMovements } from '../components/allMovements/AllMovements'
import TransactionChart from '../components/transactionChart/TransactionChart'
import RankingProducts from '../components/rakingProducts/RankingProducts'
import DashboardContainer from '../components/dashboardContainer/DashboardContainer'
import RankingOperators from '../components/rankingOperators/RankingOperators'
import RankingCustomers from '../components/rankingCustomers/RankingCustomers'
import SalesCategories from '../components/rankingCategories/RankingCategories'
import SalesDevice from '../components/salesDevices/SalesDevice'
import GridDashboard from '../components/gridDashboard/GridDashboard'
import UseMyAccount from '../hooks/UseMyAccount'
import UseAllMovements from '../hooks/UseAllMovements'
import UseRankingProducts from '../hooks/UseRankingProducts'
import UseRankingOperators from '../hooks/UseRankingOperators'
import UseRankingCustomers from '../hooks/UseRankingCustomers'
import UseSalesCategories from '../hooks/UseSalesCategories'
import UseSalesDevices from '../hooks/UseSalesDevices'
import { useHistory } from 'react-router-dom'
import { UseDashboardTicketPage } from './UseDashboardTicketPage'
import DashTicketFilter from '../components/dashTicketFilter/DashTicketFilter'
import { AvgTicket } from '../components/avgTicket/AvgTicket'
import UseServiceRate from '../hooks/UseServiceRate'


export const DashboardTicketPage = () => {

  
  const { getSession, session, onChangeFilterHandle, filterType, setFilterType, filter, filterSearch, setFilterSearch } = UseDashboardTicketPage();
  const history = useHistory();
  const myAccount = UseMyAccount();
  const allMovements = UseAllMovements(filter);
  const rankingProducts = UseRankingProducts(filter);
  const rankingOperators = UseRankingOperators(filter);
  const rankingCustomers = UseRankingCustomers(filter);
  const salesCategories = UseSalesCategories(filter);
  const salesDevices = UseSalesDevices(filter);
  const serviceRate = UseServiceRate(filter);

  const goMyAccount = () => {
    history.push('/private/contaDigital')
  }

  return (
    <div id={styles.DashboardTicketPage}>
      <MyAccount {...myAccount} goMyAccount={goMyAccount} />

      <DashTicketFilter
        onSubmit={(value) => onChangeFilterHandle(value)}
        defaultValues={filter}
        filterType={filterType}
        setFilterType={setFilterType}
        getCashiersByPeriod={getSession}
        cashiers={session}
        setFilterSearch={setFilterSearch}
        filterSearch={filterSearch}
      />

      <AllMovements {...allMovements} />

      <TransactionChart transactions={allMovements.allMovements?.transactions} />

      <AvgTicket {...allMovements} {...serviceRate} />

      <DashboardContainer
        minHeight={400}
        title={"Top Produtos"}
        span={6}
        hideIcons
      >
        <RankingProducts {...rankingProducts} />
      </DashboardContainer>

      <GridDashboard>
        <DashboardContainer
          minHeight={400}
          title={"Top Operadores"}
          span={3}
          hideIcons
        >
          <RankingOperators {...rankingOperators} />
        </DashboardContainer>

        <DashboardContainer
          minHeight={400}
          title={"Top Clientes"}
          span={3}
          hideIcons
        >
          <RankingCustomers {...rankingCustomers} />
        </DashboardContainer>

        <DashboardContainer
          minHeight={400}
          title={"Venda por categoria"}
          span={3}
          hideIcons
        >
          <SalesCategories {...salesCategories} />
        </DashboardContainer>

        <DashboardContainer
          minHeight={400}
          title={"Venda por dispositivo"}
          span={3}
          hideIcons
        >
          <SalesDevice  {...salesDevices} />
        </DashboardContainer>
      </GridDashboard>
    </div>
  )
}
