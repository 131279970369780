import { useBreadcumbs } from "components/breadcumbs/BreadcumbsContext";
import Layout from "components/layout/presentation/Layout";
import { IframePage } from "modules/oldPortal/presentation/components/iframe/IframePage";
import React, { FC, useEffect } from "react";
export interface IFaturasProps {
  //propertys
}
const Faturas: FC<IFaturasProps> = () => {
  const { updateRouters } = useBreadcumbs();
  useEffect(() => {
    updateRouters([
      {
        title: "Faturas",
      },
    ]);

    return () => {
      updateRouters([]);
    };
  }, [updateRouters]);

  return (
    <Layout>
      <IframePage src={"/faturas"} />
    </Layout>
  );
};

export default Faturas;
