import { useLocal } from "modules/local/presentation/context/LocalContext";
import { useCallback, useState } from "react";
import { IFilterValue } from "../interfaces/IFilter";
import { IRankingOperators } from "../interfaces/IRankingOperators";
import GeneralDashboardApi from "services/api/generalDashboard/GeneralDashboardApi";
import GetRankingOperatorsUseCase from "modules/generalDashboard/application/useCases/GetRankingOperatorsUseCase";


export interface IParamsUseRankingOperators {
  localId?: string,
  sessionId?: string,
  period?: number,
}

const UseRankingOperators = (filter: IFilterValue | undefined) => {

  const [isLoading, setIsLoading] = useState<boolean>();
  const [rankingOperators, setRankingOperators] = useState<IRankingOperators[]>()
  const { currentLocal } = useLocal();

  const getRankingOperators = useCallback(
    async (params?: IParamsUseRankingOperators) => {
      try {
        const serviceGeneral = GeneralDashboardApi();

        setIsLoading(true);
        if (currentLocal) {
          const response = await GetRankingOperatorsUseCase(serviceGeneral,
            currentLocal.id,
            {
              ...filter,
            },
          );

          setRankingOperators(response);

          return response
        }
      } finally {
        setIsLoading(false);
      }
    },
    [currentLocal, filter]
  );


  return {
    rankingOperators,
    isLoading,
    getRankingOperators,
    setRankingOperators,
  };
};

export default UseRankingOperators;
