import React, { useCallback, useEffect } from "react";
import { TransitionProps } from "@material-ui/core/transitions";
import { Dialog, DialogContent, IconButton, TextField } from "@mui/material";
import { Slide } from "@material-ui/core";
import styles from "./SimpleTrialModal.module.scss";
import Button from "components/ui/Button/Button";
import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { ISimpleTrialModalSchema, simpleTrialModalSchema } from "modules/plans/domain/schema/simpleTrialModalSchema";
import { Close } from "@material-ui/icons";
import { UseSimpleTrialModal } from "./UseSimpleTrialModal";
import InputMasked from "components/inputMasked/InputMasked";
import axios from "axios";
import { useUi } from "contexts/userInterface/UserInterfaceContext";
import UseUserContext from "modules/user/domains/presentation/hooks/UseUserContext";

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children?: React.ReactElement<any, any> },
    ref: React.Ref<unknown>
  ) {
    return <Slide direction="up" ref={ref} {...props} style={{ backgroundColor: "transparent" }} />;
});

export interface ISimpleTrialModalProps {
    open: boolean;
    onClose: () => void;
}

const SimpleTrialModal = ({ open, onClose }: ISimpleTrialModalProps) => {
    const { user } = UseUserContext();
    const { showLoading, hideLoading } = useUi();
    const { isLoading, address, handleRegister } = UseSimpleTrialModal(onClose);
    
    const {
        register,
        handleSubmit,
        formState: { errors },
        setError,
        clearErrors,
        getValues,
        control,
        reset
    } = useForm<ISimpleTrialModalSchema>({
        resolver: zodResolver(simpleTrialModalSchema),
    });

    useEffect(() => {
        reset({
            name: user?.name !== "Usuário Temporário" ? user?.name : "",
            document: user?.document,
            phone: user?.phoneNumber ?? "",
            cep: address?.CEP,
            street: address?.street,
            number: address?.number,
            complement: address?.complement ?? "",
            neighborhood: address?.neighborhood,
            city: address?.city,
            state: address?.state,
            country: address?.country ?? "Brasil",
        });
    }, [address, reset, user]);

    const handleSearchByCEP = useCallback(async (value: string) => {
        const cep = value.replace(/\D/g, "");
        const validateCEP = /^[0-9]{8}$/;

        if(validateCEP.test(cep)) {
            try {
                showLoading();
                const response = await axios.get(`https://viacep.com.br/ws/${cep}/json/`);
                
                if (!response.data.erro) {
                    clearErrors("cep");
                    reset({
                        ...getValues(),
                        street: response.data.logradouro,
                        neighborhood: response.data.bairro,
                        city: response.data.localidade,
                        state: response.data.uf,
                        country: "Brasil",
                    });
                } else {
                    setError("cep", { message: "CEP inválido!" });
                }
            } finally {
                hideLoading();
            }            
        } else {
            setError("cep", { message: "CEP inválido!" });
        }
    }, [clearErrors, getValues, hideLoading, reset, setError, showLoading]);

    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            PaperProps={{
                style: { backgroundColor: 'transparent', boxShadow: 'none', maxWidth: "800px" },
            }}
        >
            <div id={styles.SimpleTrialModal}>
                <img src="/assets/icon/saas/bg-modal-data-saas.svg" alt="" className={styles.modalBg}/>
                <img src="/assets/icon/saas/pos-modal-bg-2.svg" alt="" className={styles.modalBgBottom}/>
                <IconButton onClick={onClose}><Close /></IconButton>
                <DialogContent className={styles.modalContent}>
                    <div className={styles.info}>
                        <h1>📄</h1>
                        <p>Antes de continuar,<br/><b>complete seu cadastro!</b></p>
                        <span>Sabia que 89% dos estabelecimentos que utilizam Mee consideram este o sistema de gestão mais completo do mercado?</span>
                    </div>
                    <form onSubmit={handleSubmit(handleRegister)}>
                        <div className={styles.column}>
                            <label htmlFor="name" className={styles.required}>Nome e sobrenome</label>
                            <TextField
                                variant="outlined"
                                fullWidth
                                size="small"
                                placeholder="Digite seu nome"
                                {...register("name")}
                                helperText={errors.name?.message}
                                error={!!errors.name}
                                disabled={isLoading}
                            />
                        </div>

                        <div className={styles.row}>
                            <div className={styles.column}>
                                <label htmlFor="document" className={styles.required}>CPF</label>
                                <Controller
                                    name="document"
                                    control={control}
                                    render={({ field }) => (
                                        <InputMasked
                                            name="document"
                                            variant="outlined"
                                            fullWidth
                                            mask="999.999.999-99"
                                            placeholder="Digite seu CPF"
                                            value={field.value}
                                            onChange={(e: any) => field.onChange(e.target.value.replace(/\D/g, ""))}
                                            helperText={errors.document?.message}
                                            error={!!errors.document}
                                            disabled={isLoading}
                                        />
                                    )}
                                />
                            </div>
                            <div className={styles.column}>
                                <label htmlFor="phone" className={styles.required}>Telefone</label>
                                <Controller
                                    name="phone"
                                    control={control}
                                    render={({ field }) => (
                                        <InputMasked
                                            name="phone"
                                            variant="outlined"
                                            fullWidth
                                            mask="(99) 99999-9999"
                                            placeholder="Digite seu telefone"
                                            value={field.value}
                                            onChange={(e: any) => field.onChange(e.target.value.replace(/\D/g, ""))}
                                            helperText={errors.phone?.message}
                                            error={!!errors.phone}
                                            disabled={isLoading}
                                        />
                                    )}
                                />
                            </div>
                        </div>

                        <div className={styles.column}>
                            <label htmlFor="establishmentDocument" className={styles.required}>CNPJ</label>
                            <Controller
                                name="establishmentDocument"
                                control={control}
                                render={({ field }) => (
                                    <InputMasked
                                        name="establishmentDocument"
                                        variant="outlined"
                                        fullWidth
                                        mask="99.999.999/9999-99"
                                        placeholder="Digite seu CNPJ"
                                        value={field.value}
                                        onChange={(e: any) => field.onChange(e.target.value.replace(/\D/g, ""))}
                                        helperText={errors.establishmentDocument?.message}
                                        error={!!errors.establishmentDocument}
                                        disabled={isLoading}
                                    />
                                )}
                            />
                        </div>

                        <div className={styles.column}>
                            <label htmlFor="cep" className={styles.required}>CEP do seu estabelecimento</label>
                            <Controller
                                name="cep"
                                control={control}
                                render={({ field }) => (
                                    <InputMasked
                                        name="cep"
                                        variant="outlined"
                                        fullWidth
                                        mask="99999-999"
                                        placeholder="Digite seu CEP"
                                        value={field.value}
                                        onChange={(e: any) => field.onChange(e.target.value.replace(/\D/g, ""))}
                                        onBlur={(ev) => handleSearchByCEP(ev.target.value)}
                                        helperText={errors.cep?.message}
                                        error={!!errors.cep}
                                        disabled={isLoading}
                                    />
                                )}
                            />
                        </div>

                        <div className={styles.column}>
                            <label htmlFor="street" className={styles.required}>Endereço</label>
                            <TextField
                                variant="outlined"
                                fullWidth
                                size="small"
                                placeholder="Digite seu endereço completo"
                                {...register("street")}
                                helperText={errors.street?.message}
                                error={!!errors.street}
                                disabled={isLoading}
                            />
                        </div>

                        <div className={styles.row}>
                            <div className={styles.column}>
                                <label htmlFor="number" className={styles.required}>Número</label>
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    size="small"
                                    placeholder="Digite o número"
                                    {...register("number")}
                                    helperText={errors.number?.message}
                                    error={!!errors.number}
                                    type="tel"
                                    disabled={isLoading}
                                />
                            </div>
                            <div className={styles.column}>
                                <label htmlFor="neighborhood" className={styles.required}>Bairro</label>
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    size="small"
                                    placeholder="Digite o bairro"
                                    {...register("neighborhood")}
                                    helperText={errors.neighborhood?.message}
                                    error={!!errors.neighborhood}
                                    disabled={isLoading}
                                />
                            </div>
                            <div className={styles.column}>
                                <label htmlFor="complement">Complemento</label>
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    size="small"
                                    placeholder="Digite complemento"
                                    {...register("complement")}
                                    helperText={errors.complement?.message}
                                    error={!!errors.complement}
                                    disabled={isLoading}
                                />
                            </div>
                        </div>

                        <Button type="submit">Pronto!</Button>
                    </form>
                </DialogContent>
            </div>
        </Dialog>
    )
}

export default SimpleTrialModal;