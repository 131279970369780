import { IGetAverageTicketResponse } from "modules/generalDashboard/domain/dto/IGetAverageTicketResponse";
import { IGeneralDashboardApiService } from "modules/generalDashboard/domain/interfaces/IGeneralDashboardApiService";



const GetAverageTicketUseCase = async (service: IGeneralDashboardApiService, localId: string): Promise<IGetAverageTicketResponse> => {
    const response = await service.getAverageTicket(localId);

    return response;
}

export default GetAverageTicketUseCase;