import { LinearProgress, TextField } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import { Pagination } from '@mui/material'
import MenuItem from '@mui/material/MenuItem'
import { INFCeResponse } from 'modules/kds/domain/dto/nfe/NFCeResponse'
import React, { FC, useMemo } from 'react'
import { IIssueNFCe } from '../../interfaces/IIssueNFE'
import { IKDSItem, IKDSItemStep } from '../KDSItem/IKDSItem'
import KDSItem, { KDSStepAction } from '../KDSItem/KDSItem'
import { IKDSList } from "./IKDSList"
import styles from './KDSGrid.module.scss'
import { useLocal } from 'modules/local/presentation/context/LocalContext'
import SaasKDSItem from '../KDSItem/SaasKDSItem'
export interface IKDSGridProps {
    //propertys
    list?: IKDSList
    isLoading?: boolean
    getData: (page: number, pageSize?: number) => void
    changePagination?: (page: number, pageSize?: number) => void
    pagination?: { page: number, pageSize?: number }
    onClickAction?: (action: KDSStepAction, item: IKDSItem) => Promise<IKDSItemStep>
    onClickPrint: (item: IKDSItem) => Promise<void>
    issueNFCe: INFCeResponse[]
    postIssueNfe: (item: IIssueNFCe) => Promise<INFCeResponse[] | undefined>
    summaryView: boolean;
    setSummaryView: React.Dispatch<React.SetStateAction<boolean>>
}
const KDSGrid: FC<IKDSGridProps> = ({
  list,
  isLoading,
  pagination,
  onClickAction,
  getData,
  changePagination,
  onClickPrint,
  postIssueNfe,
  issueNFCe,
  summaryView,
  setSummaryView,
}) => {
    const { currentLocal } = useLocal();
  // const onChangePage = useCallback((page: number) => {
  //     getData(page, 50)
  // }, [getData])

  const countPage = useMemo(
    () => Math.ceil((list?.count ?? 1) / (pagination?.pageSize ?? 50)),
    [list, pagination]
  );

  const isSaas = useMemo(()=>currentLocal?.systemIdentifier === 1,[currentLocal]);

  const cardList = useMemo(
    () =>
      list?.items.map((item, index) =>
        isSaas ? (
          <SaasKDSItem
            summaryView={summaryView}
            setSummaryView={setSummaryView}
            key={item.id}
            kdsItem={item}
            onClickAction={onClickAction}
            onClickPrint={onClickPrint}
            postIssueNfe={postIssueNfe}
            issueNFCe={issueNFCe}
          />
        ) : (
          <KDSItem
            summaryView={summaryView}
            setSummaryView={setSummaryView}
            key={item.id}
            kdsItem={item}
            onClickAction={onClickAction}
            onClickPrint={onClickPrint}
            postIssueNfe={postIssueNfe}
            issueNFCe={issueNFCe}
          />
        )
      ),
    [
      list,
      isSaas,
      summaryView,
      setSummaryView,
      onClickAction,
      onClickPrint,
      postIssueNfe,
      issueNFCe,
    ]
  );

  return (
    <div id={styles.KDSGrid}>
      {isLoading && !!list?.items.length ? (
        <LinearProgress className={styles.linearProgress} />
      ) : (
        <div className={styles.linearProgress} />
      )}
      {isLoading && !list?.items.length && (
        <div className={styles.container}>
          {[...Array(25)].map((_, index) => (
            <div key={index} className={styles.skelleton}>
              <Skeleton width={`100%`} variant="rect" height={40} />
              <Skeleton width={`90%`} height={30} />
              <Skeleton width={`90%`} height={30} />
              <Skeleton width={`90%`} height={30} />
              <Skeleton width={`90%`} height={30} />
              <Skeleton width={`90%`} variant="rect" height={100} />
            </div>
          ))}
        </div>
      )}
      <div className={styles.container}>{cardList}</div>
      <div className={styles.conteinerPagination}>
        <div className={styles.boxMultiplePage}>
          <span>Exibir</span>
          <TextField
            variant="outlined"
            size="small"
            select
            onChange={(ev) => changePagination?.(1, Number(ev.target.value))}
          >
            <MenuItem className={styles.textPage} value={10}>
              10
            </MenuItem>
            <MenuItem className={styles.textPage} value={20}>
              20
            </MenuItem>
            <MenuItem className={styles.textPage} value={50}>
              50
            </MenuItem>
          </TextField>
        </div>
        <Pagination
          variant="outlined"
          className={styles.paginationNumber}
          shape="rounded"
          count={countPage}
          sx={{
            ".MuiPaginationItem-root": {
              borderColor: "#BDBDBD",
              color: "#001537",
            },
            ".Mui-selected": {
              fontWeight: "bold",
              borderColor: "#001537",
            },
          }}
          onChange={(_, page) => changePagination?.(page, pagination?.pageSize)}
          page={pagination?.page ?? 1}
        />
      </div>

      {/* {pagination && <KDSPagination
                pageSize={50}
                dataLenght={list?.items.length ?? 0}
                onChangePage={changePagination}
                page={pagination?.page}
            />} */}
      {isLoading && !!list?.items.length ? (
        <LinearProgress className={styles.linearProgress} />
      ) : (
        <div className={styles.linearProgress} />
      )}
    </div>
  );
};
export default KDSGrid