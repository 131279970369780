import React from "react";
import styles from "./PreRecharge.module.scss";
import { IGetPreRechargeResponse } from "modules/generalDashboard/domain/dto/IGetPreRechargeResponse";
import { IPreRecharge } from "../../interfaces/IPreRecharge";
import { CircularProgress } from "@material-ui/core";
import { useUi } from "contexts/userInterface/UserInterfaceContext";

interface ICashlessProps {
  getPreRecharge: () => Promise<IGetPreRechargeResponse | undefined>;
  preRechargeData: IPreRecharge | undefined;
  isLoading: boolean | undefined;
}

const PreRecharge: React.FC<ICashlessProps> = ({ isLoading }) => {


  const {isMobile} = useUi();

  return (
    <div id={styles.PreRecharge}>
      <div className={styles.topTitle}>
        <p>Pré-recarga</p>
      </div>
      <div className={!isMobile ? styles.container : styles.containerMobile }>
        <div className={styles.containerLeft}>
          <div className={styles.top}>
            <p>Total</p>
            {isLoading ? (
              <div className={styles.loading}>
                <CircularProgress size={16} color="inherit" />{" "}
              </div>
            ) : (
              <span>R$ 50.0000,00</span>
            )}
          </div>
          <div className={styles.bottom}>
            <div>
              <p>Pix</p>
              {isLoading ? (
                <div className={styles.loading}>
                  <CircularProgress size={16} color="inherit" />{" "}
                </div>
              ) : (
                <span>R$ 25.000,00</span>
              )}
            </div>
            <div>
              <p>Crédito</p>
              {isLoading ? (
                <div className={styles.loading}>
                  <CircularProgress size={16} color="inherit" />{" "}
                </div>
              ) : (
                <span>R$ 25.000,00</span>
              )}
            </div>
          </div>
        </div>
        {
          !isMobile &&
            <div className={styles.separator}></div>
        }
        <div className={styles.containerRight}>
          <div className={styles.bottom}>
            <div>
              <p className={styles.totalText}>Valor total ativado</p>
              {isLoading ? (
                <div className={styles.loading}>
                  <CircularProgress size={16} color="inherit" />{" "}
                </div>
              ) : (
                <p className={styles.totalValue}>R$ 35.000,00</p>
              )}
            </div>
            <div>
              <p className={styles.totalText}>Valor residual</p>
              {isLoading ? (
                <div className={styles.loading}>
                  <CircularProgress size={16} color="inherit" />{" "}
                </div>
              ) : (
                <p className={styles.totalValue}>R$ 12.000,00</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PreRecharge;
