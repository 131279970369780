import { useMemo, useState } from 'react';
import styles from './OnboardingCard.module.scss';
import { Icon } from '@material-ui/core';
import { UseOnboardingCard } from './UseOnboardingCard';
import { useHistory } from 'react-router-dom';
import { usePlans } from 'modules/plans/presentation/hooks/PlansContext';

export const OnboardingCard = () => {
    const [opened, setOpened] = useState(false);
    const [isToShow, setIsToShow] = useState(true);
    const { isMenuCompleted, isTeamCompleted, isSalesCompleted, loading } = UseOnboardingCard();

    const history = useHistory();
    const { subscription } = usePlans();

    const isSaas = useMemo(
        () => subscription?.category && subscription.category.name === "saas", [subscription]
    );

    if ((isMenuCompleted && isTeamCompleted) || loading) {
        return null;
    }

    const getPercentage = () => {
        return 100 / 2 * ([isMenuCompleted, isTeamCompleted].filter(x => x).length);
    }

    const closeButton = () => {
        return (
            <button onClick={() => setIsToShow(false)} className={styles.closeButton}><Icon>close</Icon></button>
        )
    }

    if (!isToShow) return <></>;

    return (
        <div className={styles.container}>
            {
                !opened ?
                <button className={styles.closedCardButton} onClick={() => setOpened(true)}>
                    {closeButton()}
                    {/* <div className={styles.bullet}>{[isMenuCompleted, isTeamCompleted, isSalesCompleted].filter(x => !x).length}</div> */}
                    <img src="/assets/icon/engine.svg" alt="" />
                    Comece por aqui!
                </button> :
                <div className={styles.openedCard}>
                    <button onClick={() => setOpened(false)} className={styles.expandButton}>
                        <img src="/assets/icon/engine.svg" alt="" />
                        <Icon>expand_more</Icon>
                    </button>

                    <h1>Comece por aqui!</h1>
                    <span className={styles.description}>
                        Para usar o nosso sistema, configure algumas funcionalidades essenciais:
                    </span>

                    <div className={styles.progressContainer}>
                        <div className={styles.progress}><div style={{ width: `${getPercentage()}%` }} /></div>
                        <span>{getPercentage()}%</span>
                    </div>

                    <button className={`${styles.actionButtons} ${isTeamCompleted ? styles.checked : ''}`} onClick={() => history.push('/private/equipe/cargos')}>
                        <img src={`/assets/icon/onboarding-radio${isTeamCompleted ? '-checked' : ''}.svg`} alt="" />
                        <div>
                            <span>Crie sua equipe</span>
                            <span>(2 minutos)</span>
                        </div>
                    </button>
                    
                    <button className={`${styles.actionButtons} ${isMenuCompleted ? styles.checked : ''}`} onClick={() => history.push('/private/catalog')}>
                        <img src={`/assets/icon/onboarding-radio${isMenuCompleted ? '-checked' : ''}.svg`} alt="" />
                        <div>
                            <span>Cadastre seu cardápio</span>
                            <span>(3 minutos)</span>
                        </div>
                    </button>

                    <button className={`${styles.actionButtons} ${isSalesCompleted ? styles.checked : ''}`} onClick={() => history.push('/private/gestaoVendas')}>
                        <img src={`/assets/icon/onboarding-radio${isSalesCompleted ? '-checked' : ''}.svg`} alt="" />
                        <div>
                            <span>Configure sua forma de vender</span>
                            <span>(5 minutos)</span>
                        </div>
                    </button>

                    {isSaas && (
                        <button className={`${styles.actionButtons} ${isSalesCompleted ? styles.checked : ''}`} onClick={() => history.push('/private/terminal')}>
                            <img src={`/assets/icon/onboarding-radio${isSalesCompleted ? '-checked' : ''}.svg`} alt="" />
                            <div>
                                <span>Gerencie terminais</span>
                                <span>(5 minutos)</span>
                            </div>
                        </button>
                    )}
                </div>
            }
        </div>
    )
}