import { useCallback, useState } from "react";
import GetHubByOwnerIdUseCase from "modules/config/integrationsConfig/application/useCases/GetHubByOwnerIdUseCase";
import { useLocal } from "modules/local/presentation/context/LocalContext";
import IntegrationApi from "services/api/config/integration/IntegrationApi";
import { IGetHubByOwnerIdResponse } from "modules/config/integrationsConfig/domain/dto/IGetHubByOwnerIdResponse";
import PostHubRefreshKeyUseCase from "modules/config/integrationsConfig/application/useCases/PostHubRefreshKeyUseCase";
import { IPostHubRefreshKeyResponse } from "modules/config/integrationsConfig/domain/dto/IPostHubRefreshKeyResponse";
import PostHubToggleActivationUseCase from "modules/config/integrationsConfig/application/useCases/PostHubToggleActivationUseCase";

const service = IntegrationApi();

export const UseConfigMeepHub = () => {
    const { currentLocal } = useLocal();

    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingToken, setIsLoadingToken] = useState(false);
    const [hubByOwnerId, setHubByOwnerId] = useState<IGetHubByOwnerIdResponse>();
    const [refreshTokenResponse, setRefreshTokenResponse] = useState<IPostHubRefreshKeyResponse>();
    const [hubActive, setHubActive] = useState(false);

    const getHubToken = useCallback(async () => {
        if (currentLocal) {
          try {
            setIsLoadingToken(true);
            const response = await GetHubByOwnerIdUseCase(service, currentLocal?.id);
            setHubByOwnerId(response);
            setHubActive(response.enabled);
          } finally {
            setIsLoadingToken(false);
          }
        }
      }, [currentLocal]);

    const postHubRefreshKeyUseCase = useCallback(async () => {
        if (currentLocal) {
          try {
            setIsLoading(true);
            const response = await PostHubRefreshKeyUseCase(service, currentLocal?.id);
            setRefreshTokenResponse(response);
          } finally {
            setIsLoading(false);
          }
        }
      }, [currentLocal]);

    const postHubToggleActivation = useCallback(async (enable: boolean) => {
        if (currentLocal) {
          try {
            setIsLoading(true);
            setHubActive(enable);
            const response = await PostHubToggleActivationUseCase(service, {
              localId: currentLocal.id,
              enabled: enable,
            });
            return response;
          } catch (error) {
            setHubActive(enable);
          } finally {
            setIsLoading(false);
          }
        }
      }, [currentLocal]);
  
  return {
    isLoadingToken,
    isLoading,
    hubByOwnerId,
    refreshTokenResponse,
    hubActive,
    getHubToken,
    postHubRefreshKeyUseCase,
    postHubToggleActivation    
  };
};
