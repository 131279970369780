import React from "react";
import styles from "./Cashless.module.scss";
import { IGetCashlessResponse } from "modules/generalDashboard/domain/dto/IGetCashlessResponse";
import { ICashless } from "../../interfaces/ICashless";
import { CircularProgress } from "@material-ui/core";
import { useUi } from "contexts/userInterface/UserInterfaceContext";

interface ICashlessProps {
  getCashless: () => Promise<IGetCashlessResponse | undefined>
  cashlessData: ICashless | undefined
  isLoading: boolean | undefined
}

const Cashless: React.FC<ICashlessProps> = ({getCashless, cashlessData, isLoading}) => {

  const {isMobile} = useUi();

  return (
    <div id={styles.Cashless}>
      <div className={styles.topTitle}>
        <p>Cashless</p>
      </div>
      <div className={!isMobile ? styles.container : styles.containerMobile }>
        <div className={styles.containerLeft}>
          <div className={styles.top}>
            <p>Total recarregado</p>
            {
              isLoading ? 
              <div className={styles.loading}><CircularProgress  size={16} color="inherit" /> </div>
              :
              <span>R$ 50.00000,00</span>
            }
          </div>
          <div className={styles.bottom}>
            <div>
              <p>Total consumido</p>
              {
              isLoading ? 
              <div className={styles.loading}><CircularProgress  size={16} color="inherit" /> </div>
              :
              <span>R$ 25.000,00</span>
              }
            </div>
            <div>
              <p>Total taxa de serviço</p>
              {
              isLoading ? 
              <div className={styles.loading}><CircularProgress  size={16} color="inherit" /> </div>
              :
              <span>R$ 2.000,00</span>
              }
            </div>
            <div>
              <p>Residual</p>
              {
              isLoading ? 
              <div className={styles.loading}><CircularProgress  size={16} color="inherit" /> </div>
              :
              <span>R$ 2.500,00</span>
              }
            </div>          
          </div>
        </div>
        {
          !isMobile &&
          <div className={styles.separator}></div>
        }
        <div  className={styles.containerRight}>
          <div  className={styles.top}>
            <p>Total de cartões</p>
            {
              isLoading ? 
              <div className={styles.loading}><CircularProgress  size={16} color="inherit" /> </div>
              :
              <span className={styles.valueTop}>500</span>
            }
          </div>
          <div  className={styles.bottom}>
            <div>
              <p className={styles.totalText}>Cartões ativos</p>
              {
              isLoading ? 
                <div className={styles.loading}><CircularProgress  size={16} color="inherit" /> </div>
                :
                <p className={styles.totalValue}>200</p>
              }
               {
              isLoading ? 
                <div className={styles.loading}><CircularProgress  size={16} color="inherit" /> </div>
                :
                <span className={styles.valueActivation}>(valor ativação: R$ 1.000,00)</span>
               }
            </div>
            <div>
              <p className={styles.totalText}>Total taxa de serviço</p>
              {
              isLoading ? 
                <div className={styles.loading}><CircularProgress  size={16} color="inherit" /> </div>
                :
                <p className={styles.totalValue}>R$ 2.000,00</p>
              }
               {
                isLoading ? 
                <div className={styles.loading}><CircularProgress  size={16} color="inherit" /> </div>
                :
                <span className={styles.valueDeactivation}>(valor desativação: R$ 1.000,00)</span>
               }
            </div> 
          </div>
        </div>
      </div>
    </div>
  );
};

export default Cashless;
