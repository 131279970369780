import {
  IPaymentLinkValuesForm,
  IPaymentlinkValuesErrors,
} from "modules/paymentLink/presentation/interfaces/IPaymentValuesFroms";

export const validateValuesForm = (
  formData: IPaymentLinkValuesForm,
  setErrors: React.Dispatch<React.SetStateAction<IPaymentlinkValuesErrors>>
) => {
  let _errors: IPaymentlinkValuesErrors = {};
  let hasError = false;
  if (!formData?.productId?.trim()) {
    _errors = { ..._errors, product: "Campo obrigatório" };
    hasError = true;
  }
  if (!formData.price) {
    _errors = { ..._errors, price: "Campo obrigatório" };
    hasError = true;
  }
  if (Number(formData.price) === 0) {
    _errors = { ..._errors, price: "Insira um valor maior que zero" };
    hasError = true;
  }
  if (!formData.credit && !formData.ticket && !formData.pix) {
    _errors = {
      ..._errors,
      paymentForm: "Selecione ao menos um meio de pagamento",
    };
    hasError = true;
  }
  if (formData.credit && !formData.installments) {
    _errors = { ..._errors, installments: "Selecione o numero de parcelas" };
    hasError = true;
  }
  if (formData.credit && Number(formData.installments) === 0) {
    _errors = { ..._errors, installments: "Insira um valor maior que zero" };
    hasError = true;
  }
  // if (formData.credit && Number(formData.tax) === 0) {
  //   _errors = { ..._errors, tax: "Insira a taxa de juros" };
  //   hasError = true;
  // }

  setErrors(_errors);
  return !hasError;
};
