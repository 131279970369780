import { Button, CircularProgress } from "@material-ui/core";
import { Autocomplete, TextField } from "@mui/material";
import { FC, FormEvent, useCallback, useEffect, useRef, useState } from "react";
import styles from "./DashTicketFilter.module.scss";
import { useUi } from "contexts/userInterface/UserInterfaceContext";
import { ISession } from "../../interfaces/ISession";
import { IFilterValue, enumPeriodType } from "../../interfaces/IFilter";
import { Refresh } from "@mui/icons-material";

export interface IFilterProps {
    onSubmit: (value: IFilterValue) => void;
    cashiers: ISession[] | undefined;
    defaultValues?: IFilterValue;
    isLoading?: boolean;
    filterType: string;
    getCashiersByPeriod: (quantity?: number | undefined) => Promise<ISession[] | undefined>;
    setFilterType: React.Dispatch<React.SetStateAction<string>>;
    setFilterSearch: React.Dispatch<React.SetStateAction<enumPeriodType>>
    filterSearch: enumPeriodType
}

const filterTypes = [
    {
        value: "cashier",
        description: "Caixa",
    },
    {
        value: "period",
        description: "Período",
    },
];

const TypesSearches = [
    {
        value: enumPeriodType.PerMonth,
        description: "Por mês",
    },
    {
        value: enumPeriodType.last7Days,
        description: "Últimos 7 dias",
    },
    {
        value: enumPeriodType.last24Hours,
        description: "Últimas 24 horas",
    },
    {
        value: enumPeriodType.lastHour,
        description: "Última hora",
    },
];

const DashTicketFilter: FC<IFilterProps> = ({
    onSubmit,
    cashiers,
    defaultValues,
    filterType,
    setFilterType,
    isLoading,
    filterSearch,
    setFilterSearch
}) => {
    const { toast } = useUi();

    const [values, setValues] = useState<IFilterValue>({
        cashierIds: [],
    });

    const refRefresh = useRef<boolean>(false)


    const onRefresh = useCallback(() => {
        refRefresh.current = true
    },[])

    useEffect(() => {
        if (defaultValues) {
          setValues(defaultValues);
        }
    }, [defaultValues]);

    const submitHandle = useCallback(
        async (ev: FormEvent<HTMLFormElement>) => {
            ev.preventDefault();
            if (filterType === "period") {              
              if(filterSearch === enumPeriodType.PerMonth) {
                const currentDate = new Date();
                const endDate = new Date(currentDate);
                endDate.setMonth(endDate.getMonth() - 1);
                onSubmit({
                  ...values,
                    period: enumPeriodType.PerMonth,
                    startDate: currentDate.toISOString(),
                    endDate: endDate.toISOString(),
                });
              } else {
                onSubmit({
                  ...values,
                  period: filterSearch
                });          
              }
            } else {
                if (!values.cashierIds?.length) {
                    if(refRefresh.current) {
                        onSubmit({
                            ...values,
                            period: enumPeriodType.PerCashier,
                        })
                        refRefresh.current = false
                    } else {
                        toast("Selecione pelo menos um caixa.", "error");
                        return;
                    }                   
                } else {
                  onSubmit({
                    ...values,
                    period: enumPeriodType.PerCashier
                  });
                }          
            }
        },
        [filterSearch, filterType, onSubmit, toast, values]
    );

    const changeAutoCompleteSessionHandle = useCallback(
        (values?: ISession[]) => {
            setValues((prev) => ({ ...prev, cashierIds: values ?? [] }));
        },
        []
    );

    const getSelectedSessionsCountLabel = (count: number) => {
        const firstCashier = cashiers?.find((x) => x.sessionId === (values.cashierIds && values.cashierIds[0].sessionId));

        return (
            <>
                <div className={styles.selectedCashierItem}>{firstCashier?.sessionName}</div>
                {count > 1 && `+${count - 1} selecionados`}
            </>
        );
    };

    return (
        <div id={styles.DashTicketFilter}>
            <form onSubmit={submitHandle}>
                <div>

                    <Autocomplete
                        className={styles.input}
                        style={{ flex: 2, minWidth: 350 }}
                        size="small"
                        disableClearable
                        renderInput={(props) => (
                            <div className={styles.inputContainer}>
                                <span>
                                    Filtrar por                                
                                </span>
                                <TextField
                                    maxRows={1}
                                    label={undefined}
                                    name="filterType"
                                    {...props}
                                />
                            </div>
                        )}
                        isOptionEqualToValue={(option, values) =>
                            option.value === values.value
                        }
                        options={filterTypes}
                        getOptionLabel={(option) => option.description}
                        onChange={(ev, value) => setFilterType(value.value)}
                        value={filterTypes.find((x) => x.value === filterType)}
                    />
                    {filterType === "cashier" && (
                        <Autocomplete
                            className={styles.input}
                            multiple
                            style={{ flex: 2, minWidth: 350 }}
                            size="small"
                            renderTags={() =>
                                getSelectedSessionsCountLabel(values.cashierIds?.length || 0)
                            }
                            renderInput={(props) => (
                                <div className={styles.inputContainer}>
                                    <span>Filtrar por caixa</span>
                                    <TextField
                                        maxRows={1}
                                        placeholder={
                                            (values.cashierIds?.length ?? 0) > 0
                                                ? ""
                                                : "Selecione um ou mais caixas"
                                        }
                                        name={"session"}
                                        {...props}
                                        InputLabelProps={{ shrink: false }}
                                    />
                                </div>
                            )}
                            renderOption={(props, option) => {
                                return (
                                    <span {...props} key={option.sessionId}>
                                        {option.sessionName}
                                    </span>
                                );
                            }}
                            isOptionEqualToValue={(option, values) => option.sessionId === values.sessionId}
                            options={cashiers ?? []}
                            getOptionLabel={(option) => option.sessionName}
                            onChange={(ev, value) => changeAutoCompleteSessionHandle(value)}
                            value={values.cashierIds}
                        />
                    )}
                    {filterType === "period" && (
                        <Autocomplete
                            className={styles.input}
                            style={{ flex: 2, minWidth: 350 }}
                            size="small"
                            disableClearable
                            renderInput={(props) => (
                                <div className={styles.inputContainer}>
                                    <span>
                                        Período{" "}
                                    </span>
                                    <TextField
                                        maxRows={1}
                                        label={undefined}
                                        name="filterType"
                                        {...props}
                                    />
                                </div>
                            )}
                            isOptionEqualToValue={(option, values) =>
                                option.value === values.value
                            }
                            options={TypesSearches}
                            getOptionLabel={(option) => option.description}
                            onChange={(ev, value) => setFilterSearch(value.value)}
                            value={TypesSearches.find((x) => x.value === filterSearch)}
                        />
                    )}
                    <div className={styles.containerButton}>
                        <Button
                            className={styles.button}
                            variant={"contained"}
                            color={"primary"}
                            type={"submit"}
                        >
                            Buscar
                            {isLoading && <CircularProgress />}
                        </Button>
                    </div>
                </div>

                <div className={styles.refreshButton}>
                    <Button  className={styles.button} onClick={onRefresh} variant="text" type={"submit"}>
                    <Refresh/> <u> Atualizar dados</u> 
                    </Button>
                </div>
            </form>
        </div>
    );
};
export default DashTicketFilter;
