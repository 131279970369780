import React from "react";
import { PieChart, Pie, Cell, Tooltip, Legend } from "recharts";
import { IPosPaid } from "../../interfaces/IPosPaid";
import { IGetPosPaidResponse } from "modules/generalDashboard/domain/dto/IGetPosPaidResponse";
import styles from "./PosPaidPieChart.module.scss";
import { CreditCard } from "@mui/icons-material";
import { moneyMaskNumber } from "services/utils/Money";
import { useUi } from "contexts/userInterface/UserInterfaceContext";
import { CircularProgress } from "@material-ui/core";

const data: IPosPaid = {
  totalCommands: 100,
  totalClosedCommands: 100,
  totalAmountCommandsWithServiceFeePaid: 100,
  totalCommandsWithServiceFeePaid: 100,
  totalAmountCommandsWithoutServiceFeePaid: 100,
  totalCommandsWithoutServiceFeePaid: 100,
  totalAmountCommandsClosedManually: 100,
  totalCommandsClosedManually: 100,
  totalClosedCommandsWithoutConsumption: 100,


 totalOpenCommands: 500, // total comandas abertas   
 totalAmountCommandsOpenedWithConsumption : 100,    // total de comanda sem consumo
 totalCommandsOpenedWithConsumption: 100,
 totalAmountCommandsOpenedPaid: 100,   
 totalCommandsOpenedPaid: 100,
 totalCommandsOpenedWithoutConsumption: 100, 

}

interface IPosPaidPieChartProps {
  getPosPaid: () => Promise<IGetPosPaidResponse | undefined>;
  posPaidData: IPosPaid | undefined;
  isLoading: boolean | undefined;
}

const PosPaidPieChart: React.FC<IPosPaidPieChartProps> = ({ isLoading }) => {
  const { isMobile } = useUi();

  const COLORS = ["#32008E", "#CAAEFF", "#001537"];

  const pieData = [
    { name: "Comandas fechadas", value: data.totalClosedCommands },
    { name: "Comandas abertas", value: data.totalOpenCommands },
  ];

  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
  }: any) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
      >
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

  return (
    <div id={styles.PosPaidPieChart}>
      <div className={styles.top}>
        <p>Pós-pago</p>
      </div>
      <div className={!isMobile ? styles.container : styles.containerMobile}>
        <div className={styles.containerleft}>
          <p>Total de comandas</p>
          <span>{data.totalCommands}</span>
        </div>
        {!isMobile && <div className={styles.separator}></div>}
        <div className={styles.containerRight}>
          <PieChart width={170} height={170}>
            <Pie
              data={pieData}
              cx={85}
              cy={85}
              labelLine={false}
              label={renderCustomizedLabel}
              outerRadius={80}
              fill="#8884d8"
              dataKey="value"
            >
              {pieData.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={COLORS[index % COLORS.length]}
                />
              ))}
            </Pie>
            <Tooltip formatter={(value: number) => `Quantidade: ${value}`} />
          </PieChart>
          <div className={styles.contentPosPaid}>
            <div className={styles.containerClosed}>
              <p className={styles.topPaid}>
                {" "}
                <CreditCard className={styles.paidIcon} />
                Comandas fechadas:
                {isLoading ? (
                  <div className={styles.loading}>
                    <CircularProgress size={16} color="inherit" />{" "}
                  </div>
                ) : (
                  <p className={styles.mid}>{data.totalClosedCommands}</p>
                )}
              </p>

              {isLoading ? (
                <div className={styles.loading}>
                  <CircularProgress size={16} color="inherit" />{" "}
                </div>
              ) : (
                <p className={styles.textClosed}>
                  <span>Com taxa de serviço paga: <b>{data.totalCommandsWithServiceFeePaid}</b></span>                  
                  <b>({moneyMaskNumber(data.totalAmountCommandsWithServiceFeePaid)})</b>
                </p>
              )}

              {isLoading ? (
                <div className={styles.loading}>
                  <CircularProgress size={16} color="inherit" />{" "}
                </div>
              ) : (
                <p className={styles.textClosed}>
                  <span>Sem taxa de serviço paga: <b>{data.totalClosedCommandsWithoutConsumption}</b></span>
                  <b>({moneyMaskNumber(data.totalAmountCommandsWithoutServiceFeePaid)})</b>
                </p>
              )}

              {isLoading ? (
                <div className={styles.loading}>
                  <CircularProgress size={16} color="inherit" />{" "}
                </div>
              ) : (
                <p className={styles.textClosed}>
               <span>   Fechada manualmente: <b>{data.totalCommandsClosedManually}</b></span>
                  <b>({moneyMaskNumber(data.totalAmountCommandsClosedManually)})</b>
                </p>
              )}
              {isLoading ? (
                <div className={styles.loading}>
                  <CircularProgress size={16} color="inherit" />{" "}
                </div>
              ) : (
                <p className={styles.textClosed}>
                 <span> Sem consumo: </span>
                  <b>({moneyMaskNumber(data.totalClosedCommandsWithoutConsumption)})</b>
                </p>
              )}
            </div>
            <div className={styles.containerOpened}>
              <p className={styles.topNotPaid}>                
                <CreditCard className={styles.notePaidIcon} />
                Comandas abertas:
                {isLoading ? (
                  <div className={styles.loading}>
                    <CircularProgress size={16} color="inherit" />{" "}
                  </div>
                ) : (
                  <p className={styles.mid}>{data.totalOpenCommands}</p>
                )}
              </p>
              {isLoading ? (
                <div className={styles.loading}>
                  <CircularProgress size={16} color="inherit" />{" "}
                </div>
              ) : (
                <p className={styles.textOpened}>
                  <span> Com consumo: <b>{data.totalCommandsOpenedWithConsumption}</b></span>
                  <b>({moneyMaskNumber(data.totalAmountCommandsOpenedWithConsumption)})</b>
                </p>
              )}
              {isLoading ? (
                <div className={styles.loading}>
                  <CircularProgress size={16} color="inherit" />{" "}
                </div>
              ) : (
                <p className={styles.textOpened}>
                 <span> Totalmente paga: <b>{data.totalCommandsOpenedPaid}</b></span>
                  <b>({moneyMaskNumber(data.totalAmountCommandsOpenedPaid)})</b>
                </p>
              )}
              {isLoading ? (
                <div className={styles.loading}>
                  <CircularProgress size={16} color="inherit" />{" "}
                </div>
              ) : (
                <p className={styles.textOpened}>
                  <span> Sem consumo: </span>
                  <b>({moneyMaskNumber(data.totalCommandsOpenedWithoutConsumption)})</b>
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PosPaidPieChart;
