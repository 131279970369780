import { IGetPosPaidResponse } from "modules/generalDashboard/domain/dto/IGetPosPaidResponse";
import { IGeneralDashboardApiService } from "modules/generalDashboard/domain/interfaces/IGeneralDashboardApiService";



const GetPosPaidUseCase = async (service: IGeneralDashboardApiService, localId: string): Promise<IGetPosPaidResponse> => {
    const response = await service.getPosPaid(localId);

    return response;
}

export default GetPosPaidUseCase;