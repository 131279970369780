import { IKDSService } from "modules/kds/domain/interfaces/IKDSService"
import { IKDSItemStep } from "modules/kds/presentation/components/KDSItem/IKDSItem"
import { KDSStepAction } from "modules/kds/presentation/components/KDSItem/KDSItem"

const ChangeKDSStepUseCase = (service: IKDSService, { localId, itemId, action }: { localId: string, itemId: string, action: KDSStepAction }): Promise<IKDSItemStep> => {
    const response = service.changeKDSStep({
        itemId,
        action,
    })
    return (response)
}
export default ChangeKDSStepUseCase