import { INotificationResponse } from "modules/notification/application/dtos/INotificationResponse";
import { INotificationServices } from "modules/notification/domain/interfaces/INotificationServices"
import { api } from "../Api";
import { NotificationProps } from "components/layout/domain/dto/INotificationProps";
import { NotificationType } from "modules/notification/domain/TypeNotification";
import { read } from "fs";
import { IGetNotificationsByTypeResponse } from "components/layout/domain/dto/IGetNotificationsByTypeResponse";

const NotificationApi = (): INotificationServices => {
    const getNotifications: () => Promise<INotificationResponse[]> = async () => {
        const result = await api.get<IGetNotificationsByTypeResponse[]>(
            "/MensagemDashboard/Notificacao",
        );
        const response: INotificationResponse[] = result.data.map((notificacao) => {
            return {
                id: notificacao.Id,
                startDate: notificacao.DataInicio,
                description: notificacao.Texto,
                title: notificacao.Titulo,
                type: NotificationType.DEFAULT,
                read: notificacao.MensagemLida
            }
        })
        return response
    }




    const saveReadNotification = async (id: string): Promise<void> => {
        await api.post("/MensagemDashboard/Salvar", { id });
    };


    return ({ getNotifications, saveReadNotification })
}
export default NotificationApi;
