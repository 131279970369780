import React, { FC, useCallback, useEffect, useState } from 'react'
import RankingList from '../rakingList/RankingList';
import { moneyMaskNumber } from 'services/utils/Money';
import { ISalesDevices } from '../../interfaces/ISalesDevices';
import { IParamsUseSalesDevices } from '../../hooks/UseSalesDevices';
import { useUi } from 'contexts/userInterface/UserInterfaceContext';

export interface ISalesDeviceProps {
    getSalesDevices: (params?: IParamsUseSalesDevices) => Promise<ISalesDevices[] | undefined>
    salesDevices: ISalesDevices[] | undefined
    isLoading: boolean | undefined
}

const SalesDevice: FC<ISalesDeviceProps> = ({ getSalesDevices, isLoading, salesDevices }) => {

    const {isMobile} = useUi();

    useEffect(() => {
        getSalesDevices();
    }, [getSalesDevices]) 
    
    return (
        <div>
            <RankingList
                // keyProp="id"
                list={salesDevices}  
                isLoading={isLoading}
                colunms={{
                    category: { title: "Nome da categoria" },
                    averageTicketPerPerson: { title: "Ticket médio pessoa", hide: isMobile },
                    averageCommandTicket: { title: "Ticket médio comanda", hide: isMobile },
                    value: { title: "Total", formatValue: (value) => moneyMaskNumber(value) },
                }}
            />
        </div>
    )
}
export default SalesDevice