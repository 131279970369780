import styles from "./ForProductItem.module.scss";
import { IProducts } from "modules/saleForProduct/domain/dto/IPostSaleForProductResponse";
import { moneyMaskNumber } from "services/utils/Money";

interface IForProductItem {
  item: IProducts;
}

const ForProductItem = ({ item }: IForProductItem) => {
  return (
    <div id={styles.ForProductItem}>
      <div className={styles.productItem}>
        <span>{item.categoriaNome}</span>
        <span style={{ width: "100px" }}>{item.produtoNome}</span>
        <span>{item.quantidade ?? 0}</span>
        <span>{moneyMaskNumber(item.cashless ?? 0)}</span>
        <span>{moneyMaskNumber(item.debito ?? 0)}</span>
        <span>{moneyMaskNumber(item.credito ?? 0)}</span>
        {/* <span>PIX</span> */}
        <span>{moneyMaskNumber(item.dinheiro ?? 0)}</span>
        <span>{moneyMaskNumber(item.voucher ?? 0)}</span>
        <span>{moneyMaskNumber(item.divisao ?? 0)}</span>
        <span>{moneyMaskNumber(item.outros ?? 0)}</span>
        <span>{moneyMaskNumber(item.desconto ?? 0)}</span>
        <span>{moneyMaskNumber(item.valor ?? 0)}</span>
      </div>
    </div>
  );
};
export default ForProductItem;
