import { ChangeKDSStepRequest } from "modules/kds/domain/dto/changeKDSStep/ChangeStepRequest"
import { ChangeKDSStepResponse } from "modules/kds/domain/dto/changeKDSStep/ChangeStepResponse"
import { IGetKdsGroupedListResponse } from "modules/kds/domain/dto/getKdsList/GetKdsGroupedListResponse"
import { IGetKdsListRequest } from "modules/kds/domain/dto/getKdsList/GetKdsListRequest"
import { IGetKdsListResponse } from "modules/kds/domain/dto/getKdsList/GetKdsListResponse"
import { IGetKdsStepsRequest } from "modules/kds/domain/dto/getKdsSteps/GetKdsStepsRequest"
import { IGetKdsStepsResponse } from "modules/kds/domain/dto/getKdsSteps/GetKdsStepsResponse"
import { IGetKdsStepsTotalRequest } from "modules/kds/domain/dto/getKdsSteps/GetKdsStepsTotalRequest"
import { GetKdsStepsTotalsResponse } from "modules/kds/domain/dto/getKdsSteps/GetKdsStepsTotalsResponse"
import { IGetKdsStoresRequest } from "modules/kds/domain/dto/getKdsStores/GetKdsStoresRequest"
import { IGetKdsStoresResponse } from "modules/kds/domain/dto/getKdsStores/GetKdsStoresResponse"
import { GetMeepFoodConfigurationResponse } from "modules/kds/domain/dto/getMeepFoodConfiguration/GetMeepFoodConfigurationResponse"
import { IGetIfoodStatusResponse } from "modules/kds/domain/dto/getStatusIfood/GetStatusIfoodResponse"
import { INFCeRequest } from "modules/kds/domain/dto/nfe/NFCeRequest"
import { INFCeResponse } from "modules/kds/domain/dto/nfe/NFCeResponse"
import { IGetKdsTableRequest } from "modules/kds/domain/dto/_getTables/GetKdsTableRequest"
import { IGetKdsTableResponse } from "modules/kds/domain/dto/_getTables/GetKdsTableResponse"
import { IKDSService } from "modules/kds/domain/interfaces/IKDSService"
import { api } from "../Api"
import AntiCorruptionKDSService from "./AntiCorruption/AntiCorruptionKDSService"
import { IDeviceSelect } from "modules/kds/domain/dto/getDevice/IGetDeviceListResponse"
import { ILocalAddressRequest } from "modules/kds/domain/dto/address/ILocalAddressRequest"
import { ILocalAddressResponse } from "modules/kds/domain/dto/address/ILocalAddressResponse"


const antiCorruptionKDSService = AntiCorruptionKDSService(api)


const KDSService = (): IKDSService => {
  const getKDSList = async (request: IGetKdsListRequest): Promise<IGetKdsListResponse> => {
    return antiCorruptionKDSService.getKDSListFromOldApi(request);
  }
  const getKDSGroupedList = async (request: IGetKdsListRequest): Promise<IGetKdsGroupedListResponse> => {
    return antiCorruptionKDSService.getKDSListGroupedByTableFromOldApi(request);
  }
  const getSteps = async (request: IGetKdsStepsRequest): Promise<IGetKdsStepsResponse> => {
    return antiCorruptionKDSService.getKdsStepsFromOldApi(request)
  }

  const postIssueNfe = async (request: INFCeRequest): Promise<INFCeResponse[]> => {
    const response = await api.post<INFCeResponse[]>(`/v2/NFCe/Emitir`, request);
    return response.data;
  }

  const getStepsTotals = async (request: IGetKdsStepsTotalRequest): Promise<GetKdsStepsTotalsResponse> => {
    return antiCorruptionKDSService.getKdsStepsTotalFromOldApi(request)

  }

  const getDeviceList = async (localId: string): Promise<IDeviceSelect> => {
    return antiCorruptionKDSService.deviceListFromOldApi(localId);
  }

  const getStores = async (request: IGetKdsStoresRequest): Promise<IGetKdsStoresResponse> => {
    return antiCorruptionKDSService.getKdsStoresFromOldApi(request)
  }
  const getTables = async (request: IGetKdsTableRequest): Promise<IGetKdsTableResponse> => {
    return antiCorruptionKDSService.getKdsTablesFromOldApi(request)
  }

  const changeKDSStep = async (request: ChangeKDSStepRequest): Promise<ChangeKDSStepResponse> => {
    return antiCorruptionKDSService.changeStepFromOldApi(request)
  }

  const getMeepFoodConfiguration = async (localId: string): Promise<GetMeepFoodConfigurationResponse> => {
    const respomse = await api.get<GetMeepFoodConfigurationResponse>("/MeepFood/Configuration/" + localId)
    return respomse.data;
  }

  const getIfoodStatus = async (localId: string): Promise<IGetIfoodStatusResponse> => {
    const respomse = await api.get<IGetIfoodStatusResponse>("/kds/ifoodMerchantStatus/" + localId, { params: { disableError: true } })
    return respomse.data;
  }

  const updateLocalAddress = async (request: ILocalAddressRequest): Promise<void> => {
    const payload = {
      ownerId: request.localId,
      street: request.street,
      number: request.number,
      complement: request.complement,
      neighborhood: request.neighborhood,
      zipCode: request.CEP,
      city: request.city,
      uf: request.state,
      country: request.country
    }

    const response = await api.put(`/saas/local`, payload);
    return response.data;
  }

  const getLocalAddress = async (localId: string): Promise<ILocalAddressResponse> => {
    const response = await api.get(`/MeepFood/GetAddressLocal/${localId}`);

    const resp: ILocalAddressResponse = {
      localId,
      street: response.data.endereco,
      number: response.data.numero,
      complement: response.data.complemento,
      neighborhood: response.data.bairro,
      CEP: response.data.CEP,
      state: response.data.uf,
      city: response.data.cidade,
      country: response.data.country,
    }

    return resp;
  }

  return ({
    getKDSList,
    getKDSGroupedList,
    getSteps,
    getStores,
    getTables,
    changeKDSStep,
    getMeepFoodConfiguration,
    getIfoodStatus,
    getStepsTotals,
    postIssueNfe,
    getDeviceList,
    updateLocalAddress,
    getLocalAddress
  })
}
export default KDSService
