import { IGetRankingCustomersResponse } from "modules/dashboardTicket/domain/dto/IGetRankingCustomersResponse";
import { IDashboardTicketApiService } from "modules/dashboardTicket/domain/interfaces/IMyAccountApiService";
import { IFilterValue } from "modules/dashboardTicket/presentation/interfaces/IFilter";
import { IRankingCustomers } from "modules/dashboardTicket/presentation/interfaces/IRankingCustomers";


const GetRankingCustomersUseCase = async (service: IDashboardTicketApiService, localId: string,  filter?: IFilterValue): Promise<IRankingCustomers[]> => {

    const response: IGetRankingCustomersResponse[] = await service.getRankingCustomers(localId, filter);

    const parse: IRankingCustomers[] = response.map(item => {
        return {
            name: item.nome,
            value: item.valor,
        };
    });

    return parse;

}
export default GetRankingCustomersUseCase;