import React, { FC, useEffect, useRef } from "react";
import KDSGrid from "./components/KDSGrid/KDSGrid";
import styles from "./KDSPage.module.scss";
import UseKDSPage from "./UseKDSPage";
import ModalStatusIfood from "./components/modalStatusIfood/ModalStatusIfood";
import UseKdsPageTutorials from "./UseKdsPageTutorials";
import SaasKDSFilter from "./components/KDSFilter/SaasKDSFilter";
import { IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";

export interface ISaasKDSPageProps {
    //propertys
}
const SaasKDSPage: FC<ISaasKDSPageProps> = () => {
    const {
        onSubmitFilter,
        kdsList,
        stepList,
        storeList,
        isLoading,
        tableList,
        pagination,
        ifoodStatus,
        openStatusModal,
        enableRefresh,
        filter,
        kdsAudioPlay,
        getKDSList,
        onChangePage,
        ChangeKDSStep,
        sendToLocalPrinter,
        handleHideStatusModal,
        getStateTotalHandle,
        total,
        setTotal,
        audioShowPause,
        postIssueNfe,
        audioShowPlay,
        issueNFCe,
        summaryView,
        setSummaryView,
        handleSummaryView,
        isLoadingStep,
        isButtonDisabled,
        setFilter,
        deviceList,
    } = UseKDSPage();

    const { showOrderKDSTutorial } = UseKdsPageTutorials();

    const divRef = useRef<HTMLDivElement>(null);

    const [bannerOpen, setBannerOpen] = React.useState(true);

    useEffect(() => {
        if (kdsList?.count) {
            showOrderKDSTutorial(divRef);
        }
    }, [kdsList?.count, showOrderKDSTutorial]);

    return (
        <div id={styles.SaasKDSPage}>
            {bannerOpen && <div className={styles.bannerConfig}>
                <IconButton className={styles.bannerCloseButton} onClick={() => setBannerOpen(false)} size="small"><Close /></IconButton>
                <div>
                    <h2><b>Gestor de Pedidos</b> do seu estabelecimento</h2>
                    <span>Aqui estão todos os pedidos realizados, seja no PDV físico ou no PDV digital. Altere o status do pedido conforme o andamento.</span>
                </div>
                <img src="/assets/icon/saas/browser_icon_KDS.svg" alt="" />
            </div>}
            <div className={styles.filterContainer}>
                <SaasKDSFilter
                    tables={tableList}
                    steps={stepList}
                    stores={storeList}
                    isLoading={isLoadingStep}
                    onSubmit={onSubmitFilter}
                    enableRefresh={enableRefresh}
                    getStepTotals={getStateTotalHandle}
                    setTotal={setTotal}
                    total={total}
                    isButtonDisabled={isButtonDisabled}
                    defaultValues={filter}
                    summaryView={summaryView}
                    setSummaryView={setSummaryView}
                    handleSummaryView={handleSummaryView}
                    setFilter={setFilter}
                    deviceList={deviceList}
                    saas
                    kdsAudioPlay={kdsAudioPlay}
                    audioShowPause={audioShowPause}
                    audioShowPlay={audioShowPlay}
                />
            </div>
            <div className={styles.contentStatusInfo}>
                {openStatusModal && ifoodStatus?.merchant?.validations && (
                    <ModalStatusIfood
                        handleCloseModal={handleHideStatusModal}
                        validations={ifoodStatus?.merchant?.validations}
                    />
                )}
            </div>
            {(kdsList?.count ?? 0) > 0 
                ? (
                    <div className={styles.listContainer} ref={divRef}>
                        <KDSGrid
                            onClickAction={ChangeKDSStep}
                            pagination={pagination}
                            getData={getKDSList}
                            list={kdsList}
                            isLoading={isLoading}
                            changePagination={onChangePage}
                            onClickPrint={sendToLocalPrinter}
                            postIssueNfe={postIssueNfe}
                            issueNFCe={issueNFCe}
                            summaryView={summaryView}
                            setSummaryView={setSummaryView}
                        />
                    </div>
                ) : (
                    <div className={styles.emptyContainer}>
                        <img src="/assets/icon/saas/kds-empty.svg" alt="" />
                        <p>Sem pedidos por aqui!</p>
                        <span>Acompanhe seus pedidos quando chegarem</span>
                    </div>
                )
            }
        </div>
    );
};
export default SaasKDSPage;
