import { Drawer, Icon } from "@material-ui/core";
import styles from './EmailDetails.module.scss';
import { useHistory } from "react-router-dom";
import { UseEmailDetails } from "./UseEmailDetails";
import { addMonths, format } from "date-fns";

interface EmailDetailsProps {
    open: boolean;
    onClose: () => void;
}

export const EmailDetails = ({ open, onClose }: EmailDetailsProps) => {
    const { loading, data, featureEmail } = UseEmailDetails();
    const history = useHistory();

    return (
        <Drawer anchor="right" open={open} onClose={onClose}>
            <div className={styles.container}>
                <header>
                    <h1>E-mail</h1>
                    <button onClick={onClose}>
                        <Icon className={styles.closeIcon}>close</Icon>
                    </button>
                </header>

                <p>
                    Essa é a sua ferramenta de envio de e-mails para seus clientes.
                    Você pode utilizá-la para enviar comunicações por meio do <span>CRM.</span>
                </p>

                <div className={styles.currentDataContainer}>
                    <div>
                        <span>Renova em:</span>
                        <b>01/{format(addMonths(new Date(), 1), 'MM/yyyy')}</b>
                    </div>

                    <div>
                        <div className={styles.progressBar}><div style={{ width: ((data?.report.totalSent || 0) / (data?.report.totalPlan || 1))}} /></div>
                        <span><b>{new Intl.NumberFormat("pt-BR").format(data?.report.totalSent || 0)}</b>/{new Intl.NumberFormat("pt-BR").format(data?.report.totalPlan || 0)} e-mails</span>
                    </div>
                </div>

                <div className={styles.separator}>
                    <div />
                    <span>Plano atual</span>
                    <div />
                </div>

                <div className={styles.addonsContainer}>
                    <div>
                        <img src="/assets/icon/plans/email-outlined-purple.svg" alt="" />

                        <b>{featureEmail?.name}</b>
                        <span>{featureEmail?.purchaseDescription}</span>
                    </div>
                </div>

                <div className={styles.tip}>
                    <span>Aumente sua quantidade de emails para envio e <b>atinja mais clientes!</b></span>
                    <button onClick={() => history.push('/private/email/purchase')}>Contratar mais</button>
                </div>

                <div className={styles.buttonsContainer}>
                    <button onClick={onClose}>Fechar</button>
                </div>
            </div>
        </Drawer>
    )
}