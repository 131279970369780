import { IconButton, TextField } from '@material-ui/core';
import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined';
import { Autocomplete } from '@material-ui/lab';
import Button from 'components/ui/Button/Button';
import { Modal } from 'components/ui/modal/Modal';
import { AddCircle } from "@material-ui/icons";
import {
  IGuest,
  IPeopleList,
  PreCheckinStatus,
} from 'modules/events/presentation/pages/eventGuestsLists/interfaces/IEventGuestsListsPage';
import React, { FC, useCallback, useMemo, useRef } from 'react';
import style from './AlphabeticalGuestList.module.scss';
import checkIcon from './icons/check_icon.svg';
import pendingIcon from './icons/pending_icon.svg';
import waitingIcon from './icons/waiting_icon.svg';
import attentionIcon from './icons/attention_icon.svg';
import { UseAlphabeticalGuestList } from './UseAlphabeticalGuestList';
import { IListObj } from 'modules/events/presentation/utils/utils';
import FlagIcon from '@mui/icons-material/Flag';
import { isBefore } from 'date-fns';
import UseOnScreen from 'modules/catalog/presentation/componentes/categoryAndProducts/onScreen/UseOnScreen';

interface IAlphabeticalGuestListProps {
  guestList: IPeopleList;
  refreshList: () => Promise<void>;
  goToAddGuests: () => void;
}

const AlphabeticalGuestList: FC<IAlphabeticalGuestListProps> = ({
  guestList,
  refreshList,
  goToAddGuests
}) => {
  const {
    handleDeleteUser,
    filterByGuest,
    promoterList,
    filterByPromoter,
    filtered,
    userToDelete,
    showDeleteModal,
    toggleDeleteModal,
    deleteGuest,
    checkinCounter,
    filterByCpf,
    listCpfList,
    handlePreCheckin,
    preCheckinUser,
    togglePreCheckinModal,
    setPrecheckinUser,
    showPrecheckinModal,
    mountCategories,
    addCustomerInCategory,
    removeCustomerFromCategory
  } = UseAlphabeticalGuestList(guestList, refreshList);

  const RenderPeopleCard = useCallback((obj: IGuest, key: number) => {
    return (
      <IsVisibleContainer>
        <div key={key}>
          <div className={style.guest}>
            <div>
              <div className={style.alertListName}>
                <h2>{obj.name}</h2>
                {obj.isOnAlert && (
                  <div className={style.alertList}>
                    <div className={style.alertListButton}><FlagIcon fontSize='small'>vertical_align_bottom</FlagIcon>Alerta</div>
                  </div>
                )}
              </div>

              <div className={style.categoriesContainer}>
                {
                  mountCategories(obj).map(item => (
                    <button onClick={() => item.isActive ? removeCustomerFromCategory(item.id, obj.id) : addCustomerInCategory(item.id, obj.id)} className={item.isActive ? style.active : ''}>
                      <img src={`/assets/icon/customerCategory/${item.isActive ? 'white' : 'gray'}/${item.icon}.png`} alt="" />
                    </button>
                  ))
                }
              </div>

              <p>{`Lista: ${guestList.name}`}</p>
              <p>{`Descrição: ${guestList.description}`}</p>
              <p>{`CPF: ${obj.cpf}`}</p>
              <p>{obj.promoterName === 'Integração' && isBefore(new Date(obj.invitedAt), new Date(guestList.closingDateTime)) && guestList.name !== "Lista padrão" ? <span style={{ color: 'var(--secondary' }}>Entrou pelo link</span> : `Promoter: ${obj.promoterName}`}</p>
            </div>
            <div>
              <img
                src={obj.checkinAt ? checkIcon : obj.preCheckinStatus !== PreCheckinStatus.Waiting ? pendingIcon : waitingIcon}
                alt="" style={{ opacity: guestList.isActive ? 1 : 0.5 }}
              />
              {obj.checkinAt ? (
                <>
                  <p>Check-in realizado</p>
                  <p>
                    {new Date(obj.checkinAt).toLocaleString([], {
                      dateStyle: 'short',
                      timeStyle: 'short',
                    })}
                  </p>
                </>
              ) : obj.preCheckinStatus !== PreCheckinStatus.Waiting ? (

                <div className={style.pending} style={{ opacity: guestList.isActive ? 1 : 0.5 }}>
                  <p>Check-in pendente</p>
                  <Button variant="outlined" onClick={() => setPrecheckinUser(obj)} disabled={!guestList.isActive}>Validar</Button>
                </div>
              ) : (
                <p>Aguardando Checkin</p>
              )}
            </div>
            <IconButton onClick={() => handleDeleteUser(obj.id, obj.name)} disabled={!guestList.isActive}>
              <DeleteForeverOutlinedIcon />{' '}
            </IconButton>
          </div>
        </div>
      </IsVisibleContainer>
    );
  }, [addCustomerInCategory, guestList.closingDateTime, guestList.description, guestList.isActive, guestList.name, handleDeleteUser, mountCategories, removeCustomerFromCategory, setPrecheckinUser]);


  const list = useMemo(() => {
    return (filtered && filtered.map((item, key) => RenderPeopleCard(item, key)))
  }, [RenderPeopleCard, filtered]);

  return (
    <div id={style.GuestContainer}>
      <div className={style.filterContainer}>
        <h1>{guestList.name}</h1>
        <div>
          <Autocomplete
            options={listCpfList}
            getOptionLabel={(option: IListObj) => option.name}
            onChange={filterByCpf}
            renderInput={params => (
              <TextField label="CPF" color={'secondary'} {...params} />
            )}
          />

          <Autocomplete
            options={guestList.people.reduce((options: IGuest[], current) => current.name.length > 0 ? [...options, current] : options, [])}
            getOptionLabel={(option: IGuest) => option.name}
            onChange={filterByGuest}
            renderInput={params => (
              <TextField label="Nome" color={'secondary'} {...params} />
            )}
          />

          <Autocomplete
            options={promoterList}
            getOptionLabel={(option: IListObj) => option.name}
            onChange={filterByPromoter}
            renderInput={params => (
              <TextField label="Promoter" color={'secondary'} {...params} />
            )}
          />
        </div>
      </div>
      <div>
        <div className={style.counter}>
          <Button fullWidth={false} onClick={goToAddGuests} className={style.addListButton} color='secondary' disabled={!guestList.isActive}>
            {guestList.isActive ? "Adicionar convidado" : "Adicionar pessoa"}
            <AddCircle />
          </Button>
          <p>{`Check-in : ${checkinCounter(filtered)}`}</p>
        </div>
      </div>

      {list}

      {userToDelete && (
        <Modal
          title="Remover Convidado"
          open={showDeleteModal}
          toggleModal={toggleDeleteModal}
          onConfirm={() => deleteGuest(userToDelete.id)}>
          <p>{`Tem certeza que deseja remover o convidado ${userToDelete.name} da lista?`}</p>
        </Modal>
      )}

      {preCheckinUser && (
        <Modal
          title="Validação"
          open={showPrecheckinModal}
          toggleModal={togglePreCheckinModal}
          onConfirm={() => handlePreCheckin(preCheckinUser.id, preCheckinUser.isGuestListCheckinEnabledWithTicketValidator)}>
          <div className={style.preCheckin}>
            <img
              src={attentionIcon}
              alt="attentionIcon"
            />
            <p>Você deseja realizar a validação do cliente?</p>
            <p>{preCheckinUser.name} - {preCheckinUser.cpf}</p>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default AlphabeticalGuestList;


const IsVisibleContainer: FC = ({ children }) => {
  const divRef = useRef<HTMLDivElement>(null);
  const isVisible = UseOnScreen(divRef);

  return <div ref={divRef} style={{ minHeight: 150 }}>
    {isVisible && children}
  </div>

}