import UseDimension from "components/dimension/UseDimension";
import styles from "modules/saleForDevice/presentation/components/forDeviceTotalItem/ForDeviceTotalItem.module.scss";
import { moneyMaskNumber } from "services/utils/Money";
import { IPostSaleForProductResponse } from "modules/saleForProduct/domain/dto/IPostSaleForProductResponse";

interface IForProductTotalItemProps {
  item: IPostSaleForProductResponse;
}

const ForProductTotalItem = ({ item }: IForProductTotalItemProps) => {
  const { dimensions } = UseDimension();

  return (
    <div id={styles.ForProductTotalItem}>
      {dimensions.width > 650 ? (
        <div className={styles.totalItem}>
          <span>Total Geral:</span>
          <span>{item.totalQuantidade ?? 0}</span>
          <span>{moneyMaskNumber(item.totalCashless ?? 0)}</span>
          <span>{moneyMaskNumber(item.totalDebito ?? 0)}</span>
          <span>{moneyMaskNumber(item.totalCredito ?? 0)}</span>
          {/* <span>PIX</span> */}
          <span>{moneyMaskNumber(item.totalDinheiro ?? 0)}</span>
          <span>{moneyMaskNumber(item.totalVoucher ?? 0)}</span>
          <span>{moneyMaskNumber(item.totalDivisao ?? 0)}</span>
          <span>{moneyMaskNumber(item.totalOutros ?? 0)}</span>
          <span>{moneyMaskNumber(item.totalDesconto ?? 0)}</span>
          <span>{moneyMaskNumber(item.totalGeral ?? 0)}</span>
        </div>
      ) : (
        <div className={styles.totalItemMobile}>
          <div className={styles.item}>
            <span>Total geral</span>
          </div>
          <div className={styles.item}>
            <span className={styles.title}>Quantidade:</span>
            <span>{item.totalQuantidade ?? 0}</span>
          </div>
          <div className={styles.item}>
            <span className={styles.title}>Cashless:</span>
            <span>{moneyMaskNumber(item.totalCashless ?? 0)}</span>
          </div>
          <div className={styles.item}>
            <span className={styles.title}>Débito:</span>
            <span>{moneyMaskNumber(item.totalDebito ?? 0)}</span>
          </div>
          <div className={styles.item}>
            <span className={styles.title}>Crédito:</span>
            <span>{moneyMaskNumber(item.totalCredito ?? 0)}</span>
          </div>
          {/* <div className={styles.item}>
            <span className={styles.title}>PIX:</span>
            <span>PIX</span>
          </div> */}
          <div className={styles.item}>
            <span className={styles.title}>Dinheiro:</span>
            <span>{moneyMaskNumber(item.totalDinheiro ?? 0)}</span>
          </div>
          <div className={styles.item}>
            <span className={styles.title}>Voucher:</span>
            <span>{moneyMaskNumber(item.totalVoucher ?? 0)}</span>
          </div>
          <div className={styles.item}>
            <span className={styles.title}>Divisão:</span>
            <span>{moneyMaskNumber(item.totalDivisao ?? 0)}</span>
          </div>
          <div className={styles.item}>
            <span className={styles.title}>Outros:</span>
            <span>{moneyMaskNumber(item.totalOutros ?? 0)}</span>
          </div>
          <div className={styles.item}>
            <span className={styles.title}>Desconto:</span>
            <span>{moneyMaskNumber(item.totalDesconto ?? 0)}</span>
          </div>
          <div className={styles.item}>
            <span className={styles.title}>Valor:</span>
            <span>{moneyMaskNumber(item.totalGeral ?? 0)}</span>
          </div>
        </div>
      )}
    </div>
  );
};
export default ForProductTotalItem;
