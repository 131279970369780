import { FC, useEffect, useMemo, useState } from "react";
import styles from "./ContaDigitalPage.module.scss";
import { MenuItem, Select } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material/";
import Balance from "../components/balance/Balance";
import Card from "../components/card/Card";
import iconTransfer from "../assets/icon-transfer.svg";
import iconWithdraw from "../assets/icon-withdraw.svg";
import iconPay from "../assets/icon-pay.svg";
import iconAnticipate from "../assets/icon-anticipate.svg";
import iconExtract from "../assets/icon-extract.svg";
import TransferForm from "../components/transferForm/TransferForm";
import { ITransferFormItem } from "../components/transferForm/interfaces/ITransferForm";
import { UseContaDigitalPage } from "./UseContaDigitalPage";
import SaqueForm from "../components/saqueForm/SaqueForm";
import { ISaqueFormItem } from "../components/saqueForm/interfaces/ISaqueForm";
import PayForm from "../components/payForm/PayForm";
import { IPayFormItem } from "../components/payForm/interfaces/IPayForm";
import { UseGestaoVendas } from "modules/gestaoVendas/presentation/hooks/UseGestaoVendas";
import { formatarValor } from "./utils";
import Sidesheet from "components/sidesheet/Sidesheet";
import SidesheetFeedback from "components/sidesheet/sidesheetFeedback/SidesheetFeedback";
import { useHistory } from "react-router-dom";
import { useLocal } from "modules/local/presentation/context/LocalContext";
import Button from "components/ui/Button/Button";
import { usePlans } from "modules/plans/presentation/hooks/PlansContext";

const ContaDigitalPage: FC = () => {
  const [visible, setVisible] = useState(true);
  const [openTransfer, setOpenTransfer] = useState<string | null>(null);
  const [stepTransfer, setStepTransfer] = useState(1);
  const [openSaque, setOpenSaque] = useState<string | null>(null);
  const [stepSaque, setStepSaque] = useState(1);
  const [openPay, setOpenPay] = useState<string | null>(null);
  const [stepPay, setStepPay] = useState(1);
  const [removeAccount, setRemoveAccount] = useState<string | null>(null);
  const [stepRemove, setStepRemove] = useState(1);
  const [dadosBancariosId, setDadosBancariosId] = useState<
    string | undefined
  >();

  const {
    contasDigitais,
    account,
    handleChange,
    balance,
    postWithdrawal,
    getDigitalAccountBalance,
    sendConfirmationSms,
    postPayAccount,
    bankDetail,
    deleteDadoBancario,
    totalLock
  } = UseContaDigitalPage();

  const { getSegmentos, responseConfigLocal } = UseGestaoVendas(true);
  const { push } = useHistory();
  const { currentLocal } = useLocal();

  const { subscription } = usePlans();

  useEffect(() => {
    getSegmentos();
  }, [getSegmentos]);

  useEffect(() => {
    getDigitalAccountBalance(account?.id);
  }, [account]);

  const isPilot = useMemo(() => {
    if (currentLocal?.id.toUpperCase() === ("9518c0c2-9bf2-0367-d7f4-a3cd4a496d8b").toUpperCase()) {//Lanchonete BJV
      return true
    }

    return subscription?.isPilot
  }, [subscription, currentLocal])

  const onSubmitTransfer = (values: ITransferFormItem) => {
    if (stepTransfer === 1) {
      setStepTransfer(2);
    } else if (stepTransfer === 2) {
      sendConfirmationSms(() => setStepTransfer(3));
    } else if (stepTransfer === 3) {
      postWithdrawal(
        Number(values.valor),
        () => {
          setStepTransfer(4);
          getDigitalAccountBalance(account?.id);
        },
        true,
        values
      ).then(() => { });
    }
  };

  const onSubmitSaque = (values: ISaqueFormItem) => {
    postWithdrawal(Number(values.valor), () => {
      setStepSaque(2);
      getDigitalAccountBalance(account?.id);
    });
  };

  const onSubmitPay = (values: IPayFormItem) => {
    if (stepPay === 1) {
      sendConfirmationSms(() => setStepPay(2));
    } else if (stepPay === 2) {
      postPayAccount(values).then(() => {
        setStepPay(3);
      });
    }
  };

  if ((currentLocal?.systemIdentifier === 1 && !isPilot)) {
    return (
      <div className={styles.empty}>
        <img src="/assets/icon/wallet.svg" alt="" />
        <p>Parece que você não utiliza nossa Conta Digital</p>
        <span>
          Essa é uma funcionalidade disponível para quem quer receber pelo Mee by Meep: vender com as nossas  maquininhas e fazer o recebimento dos valores
        </span>
        <Button
          onClick={() => window.open("https://api.whatsapp.com/send?phone=5531971119818&text=Oi!%20Tenho%20interesse%20em%20usar%20novas%20funcionalidades!", "_blank")}
        >
          Tenho interesse!
        </Button>
      </div>
    )
  }

  return (
    <>
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={styles.box}>
            <div className={styles.box_select}>
              <label className={`${styles.label_select}`}>
                Conta Digital <span className={styles.required}>*</span>
              </label>
              <Select
                onChange={handleChange}
                value={account ? account.id : ""}
                style={{ width: "100%" }}
              >
                {contasDigitais &&
                  contasDigitais?.map((conta) => {
                    return (
                      <MenuItem value={conta.id}>{conta.description}</MenuItem>
                    );
                  })}
              </Select>
            </div>
            <div className={styles.icon} onClick={() => setVisible(!visible)}>
              {visible ? <Visibility /> : <VisibilityOff />}
            </div>
          </div>
          {balance ? (
            <div className={styles.balance}>
              <Balance
                title="Saldo disponível"
                value={
                  visible
                    ? formatarValor(balance?.balanceResult.balanceAvailable)
                    : "R$ --,--"
                }
                available={true}
              />
              <Balance
                title="Saldo pendente"
                value={
                  visible
                    ? formatarValor(balance?.balanceResult.balanceToRelease)
                    : "R$ --,--"
                }
              />
              <Balance
                title="Saldo retido"
                value={
                  visible
                    ? formatarValor(balance?.balanceResult.totalBalanceRetained)
                    : "R$ --,--"
                }
              />
              <Balance
                title="Saldo bloqueado"
                value={
                  visible
                    ? formatarValor(totalLock ? totalLock.TotalValue : 0)
                    : "R$ --,--"
                }
                last={true}
              />
            </div>
          ) : (
            <div className={styles.loading}>
              <img src="/assets/img/loader.gif" alt="" />
            </div>
          )}
        </div>
        <div className={styles.box_cards}>
          <div className={styles.slider}>
            <Card
              text="Transferir"
              icon={iconTransfer}
              action={() => setOpenTransfer("true")}
            />
            <Card
              text="Sacar"
              icon={iconWithdraw}
              action={() => setOpenSaque("true")}
            />
            <Card
              text="Pagar"
              icon={iconPay}
              action={() => setOpenPay("true")}
            />
            <Card
              text="Antecipar"
              icon={iconAnticipate}
              action={() => push(`/private/contaDigital/new/antecipate/${account?.id}`)}
            />
            <Card
              text="Extrato"
              icon={iconExtract}
              action={() =>
                push(`/private/contaDigital/new/extrato/${account?.id}`)
              }
            />
          </div>
        </div>
      </div>

      <TransferForm
        open={openTransfer}
        setOpen={setOpenTransfer}
        onSubmit={onSubmitTransfer}
        step={stepTransfer}
        setStep={setStepTransfer}
        balance={balance}
        bankDetail={bankDetail}
        ConfirmationSms={() => sendConfirmationSms()}
        taxaBoletoTerceiro={responseConfigLocal?.percentualTaxaTEDTerceiros.toString()}
        setRemoveAccount={setRemoveAccount}
        setDadosBancariosId={setDadosBancariosId}
        dadosBancariosId={dadosBancariosId}
      />

      <SaqueForm
        onSubmit={onSubmitSaque}
        stepSaque={stepSaque}
        setStepSaque={setStepSaque}
        openSaque={openSaque}
        setOpenSaque={setOpenSaque}
        account={account}
        balance={balance}
      />

      <PayForm
        onSubmit={onSubmitPay}
        step={stepPay}
        setStep={setStepPay}
        open={openPay}
        setOpen={setOpenPay}
        account={account}
        balance={balance}
        ConfirmationSms={() => sendConfirmationSms()}
        taxaBoletoTerceiro={responseConfigLocal?.percentualTaxaTEDTerceiros.toString()}
      />

      <Sidesheet
        open={removeAccount}
        onClose={() => {
          setRemoveAccount(null);
        }}
        title={
          <h2>
            Remover <b>conta</b>
          </h2>
        }
        content={<SidesheetFeedback text={"Deseja remover a conta?"} />}
        currentStep={stepRemove}
        totalSteps={2}
        notTotalHeight
        continueButton="Remover"
        handleContinueButton={() =>
          deleteDadoBancario(dadosBancariosId, () => setStepRemove(2))
        }
        cancelButton
        feedback={
          <SidesheetFeedback text={"Conta removida com sucesso"} success />
        }
      />
    </>
  );
};
export default ContaDigitalPage;
