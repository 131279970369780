import { useLocal } from "modules/local/presentation/context/LocalContext";
import { useCallback, useState } from "react";
import DashboardTicketApi from "services/api/dashboardTicket/DashboardTicketApi";
import { IAllMovements } from "../interfaces/IAllMovements";
import GetAllMovementsUseCase from "modules/dashboardTicket/application/useCases/GetAllMovementsUseCase";
import { IFilterValue } from "../interfaces/IFilter";

export interface IParamsUseAllMovements {
  localId?: string,
  sessionId?: string,
  period?: number,
}

const UseAllMovements = (filter: IFilterValue | undefined) => {

  const [isLoading, setIsLoading] = useState<boolean>();
  const [allMovements, setAllMovements] = useState<IAllMovements>()
  const { currentLocal } = useLocal();

  const getAllMovements= useCallback(
    async (params?: IParamsUseAllMovements) => {
      try {
        const servicePermutation = DashboardTicketApi();
        setIsLoading(true);
        if (currentLocal) {
          const response = await GetAllMovementsUseCase(servicePermutation,
            currentLocal.id,
            {
              ...filter,
              period: filter?.period ?? 4,
            },
          );

          setAllMovements(response);

          return response
        }
      } finally {
        setIsLoading(false);
      }
    },
    [currentLocal, filter]
  );


  return {
    allMovements,
    isLoading,
    getAllMovements,
    setAllMovements,
  };
};

export default UseAllMovements;
