import { useLocal } from "modules/local/presentation/context/LocalContext";
import { useCallback, useState } from "react";
import GeneralDashboardApi from "services/api/generalDashboard/GeneralDashboardApi";
import GetPreRechargeUseCase from "modules/generalDashboard/application/useCases/GetPreRechargeUseCase";
import { IPreRecharge } from "../interfaces/IPreRecharge";



const UsePreRecharge = () => {

  const [isLoading, setIsLoading] = useState<boolean>();
  const [preRechargeData, setPreRechargeData] = useState<IPreRecharge>()
  const { currentLocal } = useLocal();

  const getPreRecharge= useCallback(
    async () => {
      try {
        const serviceGeneral = GeneralDashboardApi();
        setIsLoading(true);
        if (currentLocal) {
          const response = await GetPreRechargeUseCase(serviceGeneral,
            currentLocal.id,          
          );

          setPreRechargeData(response);

          return response
        }
      } finally {
        setIsLoading(false);
      }
    },
    [currentLocal]
  );


  return {
    preRechargeData,
    isLoading,
    getPreRecharge,
    setPreRechargeData,
  };
};

export default UsePreRecharge;
