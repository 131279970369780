import { Icon } from '@material-ui/core';
import styles from './PublicGuestList.module.scss';
import { PublicGuestListForm } from './components/form/PublicGuestListForm';
import './PublicGuestList.css';
import { PublicGuestListSuccess } from './components/success/PublicGuestListSuccess';
import { UsePublicGuestList } from './UsePublicGuestList';
import { format } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR'
import { PublicGuestListFail } from './components/fail/PublicGuestListFail';
import { Drawer } from '@mui/material';

export const PublicGuestList = () => {
    const {
        loading,
        data,
        isModalOpen,
        setIsModalOpen,
        isSuccess,
        setIsSuccess,
        handleSubmit,
        invalidDocs,
        isDisabled,
        listNotFound,
        saveLoading,
        usersToMigrate,
        isFailModalOpen,
        setIsFailModalOpen
    } = UsePublicGuestList();

    const formatDate = (dateString: string) => {
        if (!dateString) return '';
        const date = new Date(dateString);
        const formattedDate = format(date, "dd MMM yyyy - HH'h'", { locale: ptBR });
        return formattedDate;
    }

    return (
        <div className={styles.container}>
            {
                !loading &&
                <>
                    <header>
                        <div>
                            <img src="/assets/img/LogoMeep.svg" alt="" />
                        </div>
                    </header>

                    <div className={styles.content}>
                        {
                            listNotFound ?
                                <>
                                    <div className={styles.notFound}>
                                        <img src="/assets/icon/publicGuestList/not-found.svg" alt="" />
                                        <span>
                                            Lista de convidado <b>não encontrada!</b>
                                        </span>
                                    </div>
                                </> :
                                <>
                                    <div className={styles.imageContainer} style={{
                                        backgroundImage: data.event?.imageUrl ? `url(${data.event.imageUrl})` : ''
                                    }}>
                                        <div className={styles.row}>
                                            <span>{data.event?.name}</span>

                                            <div className={styles.availableAt}>
                                                <Icon>access_time</Icon>
                                                <span className={styles.text}>Lista disponível até: <strong>{formatDate(data.avaliableAt)}</strong></span>
                                            </div>
                                        </div>
                                        <div className={styles.gradient} />
                                    </div>

                                    <div className={styles.infos}>
                                        <div className={styles.mAvailableAt}>
                                            <Icon>access_time</Icon>
                                            <span className={styles.text}>Lista disponível até: {formatDate(data.avaliableAt)}</span>
                                        </div>
                                        <div>
                                            <Icon>storefront</Icon>
                                            <span className={styles.text}>{data.event?.localName}</span>
                                        </div>
                                        <div className={styles.vSeparator} />
                                        <div>
                                            <Icon>today</Icon>
                                            <span className={styles.text}>{data.event?.date ? format(new Date(data.event.date), "dd MMM - yyyy '•' HH:mm") : ''}</span>
                                        </div>
                                        <div className={styles.vSeparator} />
                                        <div>
                                            <Icon>place</Icon>
                                            <span className={styles.text}>{data.event?.localAddress}</span>
                                        </div>
                                    </div>

                                    <div className={`${styles.mainCard} ${isDisabled ? styles.disabled : ''}`}>
                                        <img src="/assets/img/guest-list-ticket-left.svg" alt="" className={styles.bgImageLeft} />
                                        <img src="/assets/img/guest-list-ticket-right.svg" alt="" className={styles.bgImageRight} />
                                        {
                                            !isDisabled ?
                                                <>
                                                    <span className={styles.title}>
                                                        <Icon className={styles.celebration}>celebration</Icon>
                                                        <span>Você acaba de ser convidado para a lista {data.promoter}!</span>
                                                        <Icon className={styles.celebration}>celebration</Icon>
                                                    </span>
                                                    <span className={styles.description}>{data.linkDescription}
                                                    </span>
                                                    <button type="button" onClick={() => setIsModalOpen(true)}>
                                                        Adicionar CPF na lista
                                                        <Icon>add_circle</Icon>
                                                    </button>
                                                </> :
                                                <span className={styles.disabledText}>
                                                    <Icon className={styles.closeIcon}>close</Icon>
                                                    <span>Esta lista de convidado <b>não está mais disponível!</b></span>
                                                    <Icon className={styles.closeIcon}>close</Icon>
                                                </span>
                                        }
                                    </div>

                                    <div className={styles.description}>
                                        {data.event?.description}
                                    </div>
                                </>
                        }
                    </div>
                </>
            }

            <Drawer
                open={isModalOpen}
                anchor='right'
                PaperProps={{
                    sx: {
                        width: '100%',
                        maxWidth: 600
                    }
                }}
            >
                {
                    !isSuccess ?
                        <PublicGuestListForm loading={saveLoading} invalidDocs={invalidDocs} onClose={() => setIsModalOpen(false)} onSubmit={handleSubmit} /> :
                        <PublicGuestListSuccess usersToMigrate={usersToMigrate} onClose={() => { setIsModalOpen(false); setIsSuccess(false) }} />
                }
            </Drawer>

            <Drawer open={isFailModalOpen} anchor='right'>
                <PublicGuestListFail onClose={() => setIsFailModalOpen(false)} isMigrate />
            </Drawer>
        </div>
    )
}