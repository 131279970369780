import { FC, useCallback, useState } from 'react'
import { FormDeviceProfileStep } from "../../../../domain/interface/FormDeviceProfileSteps"
import FooterButtons from '../../footerButtons/FooterButtons'
import OtherButton from './components/otherButton/OtherButton'
import styles from './OtherParamsForm.module.scss'
import { PointOfSale } from '@mui/icons-material'
import { IResponseConfigLocal } from 'modules/gestaoVendas/domain/dtos/IGetSegmentos'
import { SystemIdentifierEnum } from 'modules/gestaoVendas/domain/enum/GetSegmentosEnum'

export interface IOtherParamsFormProps {
    showSuccess?: boolean
    modified: boolean;
    onModified: (modified: boolean) => void;
    onClick(value: FormDeviceProfileStep): void
    onClickBack: () => void;
    onClickSave: (value: { sketchFlag: boolean }) => void;
    onClickFinsh: (value: number) => void
    formStep: FormDeviceProfileStep;
    systemIdentifier?: SystemIdentifierEnum;
}

const OtherParamsForm: FC<IOtherParamsFormProps> = ({
    showSuccess,
    modified,
    onModified,
    onClick,
    onClickBack,
    onClickFinsh,
    onClickSave,
    formStep,
    systemIdentifier
}) => {
    const [sketch] = useState(false);

    const onSubmitHandle = useCallback(() => {
        if (showSuccess || formStep === FormDeviceProfileStep.OTHER) {
            onClickFinsh(FormDeviceProfileStep.CONCLUDE);
        } else {
            onClickSave({ sketchFlag: sketch })
        }
    }, [formStep, onClickFinsh, onClickSave, showSuccess, sketch])

    return (
        <div id={styles.OtherParamsForm} >
            <span>Você pode realizar outras configurações como vincular informações fiscais, cardápios e operadores.</span>
            <div className={styles.container} >
                {/* <OtherButton label="Impressora" onClick={() => { onClick(FormDeviceSteps.PRINTER) }} icon={"print"} /> */}
                {/* <OtherButton label="Cardápio" onClick={() => { onClick(FormDeviceProfileStep.MENU) }} icon={"menu_book"} /> */}
                <OtherButton label="Parâmetros gerais" onClick={() => { onClick(FormDeviceProfileStep.PARAMS) }} icon={<img src='/assets/icon/general_params.svg' alt="Parâmetros gerais" />} />
                <OtherButton label="Informações fiscais" onClick={() => { onClick(FormDeviceProfileStep.INVOICE) }} icon={<img src='/assets/icon/file.svg' alt="Informações fiscais" />} />
                <OtherButton label="Operação" onClick={() => { onClick(FormDeviceProfileStep.OPERATION_PARAMS) }} icon={<img src='/assets/icon/operation_params.svg' alt="Parâmetros de operação" />} />
                {/* <OtherButton label="Operadores" onClick={() => { onClick(FormDeviceProfileStep.OPERATOR) }} icon={buildIcon("person")} /> */}
                <OtherButton label="Usabilidade" onClick={() => { onClick(FormDeviceProfileStep.USABILITY) }} icon={<img src='/assets/icon/usability_params.svg' alt="Parâmetros de usabilidade" />} />
                <OtherButton label="Interface" onClick={() => { onClick(FormDeviceProfileStep.USER_INTERFACE) }} icon={<img src='/assets/icon/interface_params.svg' alt="Parâmetros de interface" />} />
                <OtherButton label="Impressão" onClick={() => { onClick(FormDeviceProfileStep.PRINTER) }} icon={<img src='/assets/icon/file.svg' alt="Impressão" />} />
                <OtherButton label="PagSeguro" onClick={() => { onClick(FormDeviceProfileStep.PAG_SEGURO) }} icon={<img src='/assets/icon/file.svg' alt="PagSeguro" />} />
                {systemIdentifier === SystemIdentifierEnum.Saas && <OtherButton label="Terminal de vendas" onClick={() => { onClick(FormDeviceProfileStep.TERMINAL) }} icon={<PointOfSale className={styles.icon} />} />}
                {/* <OtherButton label="Totem" onClick={() => { onClick(FormDeviceSteps.KIOSK) }} icon={buildIcon("phone_iphone")} /> */}
            </div>
            {/* {!showSuccess && */}
            {/* <FormItemContainer flex={0}>
                <FormControlLabel
                    checked={sketch}
                    onChange={(ev, checked) => setSketch(checked)}
                    control={<Switch color='secondary' />}
                    label={"Manter como rascunho"}
                />
            </FormItemContainer> */}
            {/* } */}
            <FooterButtons labelNext='Salvar e continuar' onClickBack={onClickBack} onClickNext={onSubmitHandle} />
        </div>
    )
}



export default OtherParamsForm