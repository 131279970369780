import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import styles from './InvoiceForm.module.scss'
import { useFieldArray, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Box, ButtonContainer, Container, InputContainer, Row } from '../ui/form/FormContainers';
import { Icon, TextField } from '@material-ui/core';
import { IInvoiceFormValue, IInvoiceSchema, InvoiceEditOrView, InvoiceSupplyValues } from './IInvoiceFormValue';
import { PartnerListResponse } from 'modules/meepErp/application/dtos/partner/PartnerDtos';
import AutocompleteInput from '../autocomplete/AutocompleteInput';
import InvoiceSupplyForm from '../../pages/invoice/AddOrUpdateInvoice/invoiceSupplyForm/InvoiceSupplyForm';
import { IGetSupplyListRequest } from 'modules/meepErp/application/dtos/supply/IGetSupplyListRequest';
import { IGetSupplyItemResponse, IGetSupplyListResponse } from 'modules/meepErp/application/dtos/supply/IGetLSupplyListResponse';
import TextFieldMoney from '../ui/TextField/TextFieldMoney';
import { InvoiceSupplyList } from './InvoiceSupplyList';
import { IGetStockOperationListRequest } from 'modules/meepErp/application/dtos/stockOperations/IGetStockOperationListRequest';
import { IGetStockOperationListResponse, IStockOperationItemResponse } from 'modules/meepErp/application/dtos/stockOperations/IGetStockOperationListResponse';
import { moneyMaskNumber } from 'services/utils/Money';
import Sidesheet from 'components/sidesheet/Sidesheet';
import { IGetStorageLocalsListRequest } from 'modules/meepErp/application/dtos/storageLocals/IGetStorageLocalsListRequest';
import { IGetStorageLocalsListResponse } from 'modules/meepErp/application/dtos/storageLocals/IGetStorageLocalsListResponse';
import Button from 'components/ui/Button/Button';
import { useHistory, useParams } from 'react-router-dom';
import { formatCurrency } from '../../utils/Format';
import { Autocomplete } from '@mui/material';
import { OperationsTypes } from 'modules/meepErp/models/operations/_OperationsType';
import { IGetQuantityStockResponse } from 'modules/meepErp/application/dtos/invoice/IGetQuantityStockResponse';
export interface IInvoiceFormProps {
    onSubmit: (values: IInvoiceFormValue) => Promise<any>
    defaultValues?: IInvoiceFormValue;
    getPartnerList: (request: { name?: string, document?: string, page: number, pageSize: number }) => Promise<PartnerListResponse>;
    currentUser?: {
        id: string;
        name: string;
    },
    disabled?: boolean;
    partnerList?: PartnerListResponse;
    getSupplyList: (request: IGetSupplyListRequest) => Promise<IGetSupplyListResponse>
    // unitList: IGetUnitItemResponse[];
    getOperationList?: IStockOperationItemResponse[]
    getSupply: (supplyId: string) => Promise<IGetSupplyItemResponse>
    getSupplyStorageLocation: (request: IGetStorageLocalsListRequest) => Promise<IGetStorageLocalsListResponse>
    onClickCancel?: () => void;
    isSubmintting?: boolean;
    getQuantityStock: (storageLocationId: string, supplyId: string) => Promise<IGetQuantityStockResponse>
}

const InvoiceForm: FC<IInvoiceFormProps> = ({
    onSubmit,
    defaultValues,
    currentUser,
    disabled,
    getPartnerList,
    getSupplyList,
    getSupply,
    getSupplyStorageLocation,
    getOperationList,
    isSubmintting,
    getQuantityStock
}) => {


    const { register, handleSubmit, setValue, reset, watch, formState, control } = useForm<IInvoiceFormValue>({
        resolver: zodResolver(IInvoiceSchema),
        defaultValues: defaultValues ?? {
            partnerId: '',
            createdById: currentUser?.id ?? '',
            operationId: '',
            currency: 'BRL',
            issueDate: new Date().toISOString().split('T')[0],
            date: new Date().toISOString().split('T')[0],
            supplies: [],
            shipping: 0,
            increase: 0,
            insurance: 0,
            discountValue: 0,
            others: 0,
            stHighlighted: 0,
        },
    });
    const { push } = useHistory()

    const supplies = useFieldArray({ control, name: 'supplies' });

    const { id, type } = useParams<{ id?: string, type?: string }>();

    const [openDrawer, setOpenDrawer] = useState(false);
    const [openTaxes, setOpenTaxes] = useState(false);

    const [editDrawer, setEditDrawer] = useState<InvoiceSupplyValues>();

    const [selectedPartner, setSelectedPartner] = useState<{
        id: string;
        document: string;
        name: string;
    } | null>(null);

    useEffect(() => {
        if (defaultValues) {
            reset(defaultValues);
            setSelectedPartner({
                id: defaultValues.partnerId,
                document: defaultValues.partnerDocument,
                name: defaultValues.partnerName,
            });
        }
    }, [defaultValues, reset])

    const onSubmitSupplyForm = useCallback((values: InvoiceSupplyValues) => {
        if (editDrawer) {
            // setValue("supplies", supplies.fields.map(x => x.id === editDrawer.id ? values : x))
            supplies.update(supplies.fields.findIndex(x => x.supplyId === editDrawer.supplyId), values)
            setEditDrawer(undefined);
        } else {
            console.log("ADD SUPPLY ID", values.supplyId);
            supplies.append(values);
        }
        setOpenDrawer(false);
    }, [editDrawer, supplies])


    const onCloseDrawer = useCallback(() => {
        setOpenDrawer(false);
        setEditDrawer(undefined);
    }, [])

    const values = watch();

    const ICMS = useMemo(() => {
        return supplies.fields.reduce((acc, item) => acc + item.icms, 0)
    }, [supplies.fields])
    const IPI = useMemo(() => {
        return supplies.fields.reduce((acc, item) => acc + item.ipi, 0)
    }, [supplies.fields])
    const PIS = useMemo(() => {
        return supplies.fields.reduce((acc, item) => acc + item.pis, 0)
    }, [supplies.fields])
    const CONFINS = useMemo(() => {
        return supplies.fields.reduce((acc, item) => acc + item.confins, 0)
    }, [supplies.fields])
    const ST = useMemo(() => {
        return supplies.fields.reduce((acc, item) => acc + item.st, 0)
    }, [supplies.fields])

    const discounts = useMemo(() => {
        return supplies.fields.reduce((acc, item) => acc + (item.discountValue ? item.discountValue : 0) + (item.discountPercentage ? (item.discountPercentage * item.unitPrice / 100) : 0), 0) + (values.discountValue ?? 0)
    }, [supplies.fields, values.discountValue])

    const totalPrice = useMemo(() => {
        return supplies.fields.reduce((acc, item) => acc + (item.unitPrice * item.quantity), 0) + (values.shipping ?? 0) + (values.increase ?? 0) + (values.insurance ?? 0) + (values.others ?? 0) - discounts +
            (values.stHighlighted ?? 0) + ICMS + IPI + PIS + CONFINS + ST
    }, [supplies.fields, values.shipping, values.increase, values.insurance, values.others, values.stHighlighted, discounts, ICMS, IPI, PIS, CONFINS, ST])

    const fees = useMemo(() => ((values.increase ?? 0) + (values.insurance ?? 0) + (values.shipping ?? 0)) / (supplies.fields.reduce((acc, item) => acc + (item.quantity), 0) + (editDrawer ? 0 : 1))
        , [values.increase, values.insurance, values.shipping, supplies.fields, editDrawer])


    const getPartner = (request: any) => {
        const keyword = request.keyword;

        // Verifica se o keyword é um número ou uma letra
        const isNumber = /^\d+$/.test(keyword);

        return getPartnerList({
            ...request,
            [isNumber ? 'document' : 'name']: keyword,
        });
    };

    const filteredOperations = getOperationList?.filter(item => item.name === OperationsTypes.PURCHASE_NOTE)
    return (<>
        <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
            <Container center >
                <Container subContainer title='1. Informações Gerais'  >
                    {id &&
                        <Row>
                            <InputContainer label='Data de inclusão' flex={0}>
                                <TextField
                                    {...register("createdAt")}
                                    variant="outlined"
                                    disabled
                                    value={watch("createdAt") ? new Date(watch("createdAt") ?? '').toLocaleDateString() : ''}
                                />
                            </InputContainer>
                            <InputContainer label='Usuário de inclusão' >
                                <TextField
                                    value={watch("createdByName") ?? ''}
                                    variant="outlined"
                                    disabled
                                />
                            </InputContainer>
                        </Row>
                    }
                    <Row>
                        <InputContainer style={{ maxWidth: '500px' }} label='Tipo de operação' requerid>
                            <Autocomplete
                                options={filteredOperations ?? []}
                                fullWidth
                                getOptionLabel={(option) => option.name}
                                onChange={(event, value) => {
                                    setValue("operationId", value?.id ?? '')
                                }}
                                value={getOperationList?.find((item) => item.id === values.operationId) ?? null}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        fullWidth
                                        error={!!formState.errors.operationId}
                                        helperText={formState.errors.operationId?.message}
                                        disabled={disabled}
                                    />
                                )}
                            />
                        </InputContainer>
                    </Row>
                    {watch('operationId') &&
                        <>
                            <Row>
                                <InputContainer label='Número da nota'>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        {...register("number")}
                                        error={!!formState.errors.number}
                                        helperText={formState.errors.number?.message}
                                        disabled={disabled || type === InvoiceEditOrView.VIEW}
                                        inputProps={{ maxLength: 15 }}
                                    />
                                </InputContainer>
                                <InputContainer label='Série' requerid>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        {...register("series")}
                                        error={!!formState.errors.series}
                                        helperText={formState.errors.series?.message}
                                        disabled={disabled || type === InvoiceEditOrView.VIEW}
                                        inputProps={{ maxLength: 3 }}
                                    />
                                </InputContainer>
                                <InputContainer label='Chave de acesso' requerid>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        {...register("accessKey")}
                                        error={!!formState.errors.accessKey}
                                        helperText={formState.errors.accessKey?.message}
                                        disabled={disabled || type === InvoiceEditOrView.VIEW}
                                        inputProps={{ maxLength: 44 }}
                                    />
                                </InputContainer>
                            </Row>
                            <Row>
                                <InputContainer label='Data de emissão' requerid>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        type="date"
                                        {...register("issueDate")}
                                        error={!!formState.errors.issueDate}
                                        helperText={formState.errors.issueDate?.message}
                                        disabled={disabled || type === InvoiceEditOrView.VIEW}
                                    />
                                </InputContainer>

                                <InputContainer htmlFor={"Parceiro"} flex={8} label='Parceiro' requerid>
                                    <AutocompleteInput
                                        disabled={disabled || type === InvoiceEditOrView.VIEW}
                                        getOptionLabel={(option) => `${option?.name}`}
                                        textInput={selectedPartner?.name}
                                        // textInput={selectedPartner && `${selectedPartner?.name} - ${selectedPartner?.document}`}
                                        getOptionDescription={(option) => `${option?.document}`}
                                        // type='Number'
                                        getList={(request) => getPartner(request)}
                                        onSelect={(value) => {
                                            value && setValue("partnerId", value.id)
                                            value && setSelectedPartner(value);
                                        }}
                                        value={selectedPartner}
                                        error={!!formState.errors.partnerId}
                                        helperText={formState.errors.partnerId?.message}
                                    />
                                </InputContainer>
                            </Row>
                            <Row>
                                <InputContainer label='Data de entrada/saída' requerid flex={0}>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        type="date"
                                        {...register("date")}
                                        error={!!formState.errors.date}
                                        helperText={formState.errors.date?.message}
                                        disabled={disabled || type === InvoiceEditOrView.VIEW}
                                    />
                                </InputContainer>
                            </Row>
                        </>
                    }

                </Container >
                {watch('operationId') &&
                    <>
                        <Container subContainer title='2. Insumos' >
                            {type !== InvoiceEditOrView.VIEW &&
                                <Button variant='outlined'
                                    endIcon={<Icon className={styles.iconAdd}>add</Icon>}
                                    style={{ width: 200 }}
                                    onClick={() => setOpenDrawer(true)}
                                >
                                    Adicionar insumo
                                </Button>
                            }
                            <InvoiceSupplyList
                                onClickEdit={
                                    (item: InvoiceSupplyValues) => {
                                        setEditDrawer(item);
                                        setOpenDrawer(true);
                                    }
                                }
                                onClickDelete={
                                    (item: InvoiceSupplyValues) => {
                                        supplies.remove(supplies.fields.findIndex(x => x.supplyId === item.supplyId))
                                    }
                                }
                                supplies={supplies.fields}
                                onClickTaxes={(item: InvoiceSupplyValues) => {
                                    setEditDrawer(item);
                                    setOpenTaxes(true);
                                }}
                            />
                        </Container>
                        <Container subContainer title='3. Encargos' >
                            <Row>
                                <InputContainer
                                    label="Frete">
                                    <TextFieldMoney
                                        value={watch("shipping", 0)}
                                        onChange={(ev) => setValue("shipping", Number(ev.target.value))}
                                        error={!!formState.errors.shipping}
                                        helperText={formState.errors.shipping?.message}
                                        disabled={disabled || type === InvoiceEditOrView.VIEW}
                                    />

                                </InputContainer>
                                <InputContainer
                                    label="Acrécimo">
                                    <TextFieldMoney
                                        value={watch("increase", 0)}
                                        onChange={(ev) => setValue("increase", Number(ev.target.value))}
                                        error={!!formState.errors.increase}
                                        helperText={formState.errors.increase?.message}
                                        disabled={disabled || type === InvoiceEditOrView.VIEW}
                                    />
                                </InputContainer>
                                <InputContainer
                                    label="Seguro">
                                    <TextFieldMoney
                                        value={watch("insurance", 0)}
                                        onChange={(ev) => setValue("insurance", Number(ev.target.value))}
                                        error={!!formState.errors.insurance}
                                        helperText={formState.errors.insurance?.message}
                                        disabled={disabled || type === InvoiceEditOrView.VIEW}
                                    />
                                </InputContainer>
                                <InputContainer label='Desconto'>
                                    <TextFieldMoney
                                        value={watch("discountValue")}
                                        onChange={(ev) => setValue("discountValue", Number(ev.target.value))}
                                        error={!!formState.errors.discountValue}
                                        helperText={formState.errors.discountValue?.message}
                                        disabled={disabled || type === InvoiceEditOrView.VIEW}
                                    />
                                </InputContainer>
                            </Row>
                        </Container>
                        <Container subContainer title='4. Tributos' >
                            <Row>
                                <InputContainer label='ICMS'>
                                    <TextFieldMoney
                                        value={ICMS}
                                        disabled
                                    />
                                </InputContainer>
                                <InputContainer label='IPI'>
                                    <TextFieldMoney
                                        value={IPI}
                                        disabled
                                    />
                                </InputContainer>
                                <InputContainer label='PIS'>
                                    <TextFieldMoney
                                        value={PIS}
                                        disabled
                                    />
                                </InputContainer>
                                <InputContainer label='CONFINS'>
                                    <TextFieldMoney
                                        value={CONFINS}
                                        disabled
                                    />
                                </InputContainer>
                                <InputContainer label='ST'>
                                    <TextFieldMoney
                                        value={ST}
                                        disabled
                                    />
                                </InputContainer>
                            </Row>
                            <Box>
                                <div>

                                    <label>Total de descontos</label><span>{moneyMaskNumber(discounts)}</span>
                                </div>
                                <div>
                                    <label>Total</label><span>{moneyMaskNumber(totalPrice)}</span>
                                </div>
                            </Box>


                        </Container>
                        <Container subContainer title='5. Totalizador de nota' >
                            <Row>

                                <InputContainer label='Outros'>
                                    <TextFieldMoney
                                        value={watch("others")}
                                        onChange={(ev) => setValue("others", Number(ev.target.value))}
                                        error={!!formState.errors.others}
                                        helperText={formState.errors.others?.message}
                                        disabled={disabled || type === InvoiceEditOrView.VIEW}
                                    />
                                </InputContainer>
                                <InputContainer label='Valor destacado'>
                                    <TextFieldMoney
                                        value={watch("stHighlighted")}
                                        onChange={(ev) => setValue("stHighlighted", Number(ev.target.value))}
                                        error={!!formState.errors.stHighlighted}
                                        helperText={formState.errors.stHighlighted?.message}
                                        disabled={disabled || type === InvoiceEditOrView.VIEW}
                                    />
                                </InputContainer>
                            </Row>
                            <Row>
                                <InputContainer label='Observações'>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        multiline
                                        minRows={2}
                                        maxRows={4}
                                        {...register("observation")}
                                        error={!!formState.errors.observation}
                                        helperText={formState.errors.observation?.message}
                                        disabled={disabled || type === InvoiceEditOrView.VIEW}
                                    />
                                </InputContainer>
                            </Row>
                        </Container>
                    </>
                }


                <ButtonContainer>
                    <Button color='secondary' onClick={() => push('/private/meeperp/invoices/')} fullWidth={false} variant='outlined'>Cancelar</Button>
                    <Button
                        variant="contained"
                        color="secondary"
                        type="submit"
                        disabled={disabled || isSubmintting}
                        fullWidth={false}
                    >
                        {
                            isSubmintting ? "Concluindo..." : "Concluir"
                        }
                    </Button>
                </ButtonContainer>

            </Container >
        </form >

        <Sidesheet
            open={openDrawer} onClose={onCloseDrawer} title={<h2>Insumo</h2>}>
            <InvoiceSupplyForm
                defaultValues={editDrawer}
                getSupplyList={getSupplyList}
                onSubmit={onSubmitSupplyForm}
                getSupply={getSupply}
                onClickCancel={onCloseDrawer}
                getSupplyStorageLocation={getSupplyStorageLocation}
                fees={fees}
                getQuantityStock={getQuantityStock}
            />
        </Sidesheet>
        <Sidesheet
            open={openTaxes}
            onClose={() => {
                setOpenTaxes(false)
                setEditDrawer(undefined)
            }}
            title={<h2>Impostos</h2>}
            content={
                <div className={styles.taxes}>
                    <div>
                        <div className={styles.taxesProduct}>
                            <span className={styles.title}>Produto</span>
                            <span className={styles.value}>{editDrawer?.supplyName}</span>
                        </div>
                        <div className={styles.taxesContainer}>
                            <div className={styles.taxesItem}>
                                <span className={styles.title}>Valor ICMS:</span>
                                <span className={styles.value}>{editDrawer && formatCurrency(editDrawer?.icms)}</span>
                            </div>
                            <div className={styles.taxesItem}>
                                <span className={styles.title}>Valor IPI:</span>
                                <span className={styles.value}>{editDrawer && formatCurrency(editDrawer?.ipi)}</span>
                            </div>
                            <div className={styles.taxesItem}>
                                <span className={styles.title}>Valor PIS:</span>
                                <span className={styles.value}>{editDrawer && formatCurrency(editDrawer?.pis)}</span>
                            </div>
                            <div className={styles.taxesItem}>
                                <span className={styles.title}>Valor COFINS:</span>
                                <span className={styles.value}>{editDrawer && formatCurrency(editDrawer?.confins)}</span>
                            </div>
                            <div className={styles.taxesItem}>
                                <span className={styles.title}>Valor ST:</span>
                                <span className={styles.value}>{editDrawer && formatCurrency(editDrawer?.st)}</span>
                            </div>
                        </div>
                    </div>
                    <Box style={{ flex: 'initial' }}>
                        <div>
                            <label>Valor Total</label>
                            <span>{editDrawer && formatCurrency(editDrawer.icms + editDrawer.ipi + editDrawer.pis + editDrawer.confins + editDrawer.st)}</span>
                        </div>
                    </Box>
                </div>
            }
            currentStep={1}
            totalSteps={1}
            notTotalHeight
            continueButton="Fechar"
            handleContinueButton={() => {
                setOpenTaxes(false)
                setEditDrawer(undefined)
            }}

        />

    </>
    )
}
export default InvoiceForm



