import { IGetCatalogProductResponse } from "modules/catalog/domain/dtos/IGetCatalogProductResponse"
import { IComplementGroupFormValue } from "modules/catalog/presentation/componentes/complement/complementForm/ComplementForm";
import { ICatalogSelect, ICategorySelect, IPrinterSelect, IProductFormValue } from "modules/catalog/presentation/componentes/productForm/IProducFormValuet"

const ProductResponseToValue = (response: IGetCatalogProductResponse, categories?: ICategorySelect[], catalogs?: ICatalogSelect[], printers?: IPrinterSelect[]): IProductFormValue => {

    const category = categories?.find(item => item.id === response.categoryId);
    const complements = response.complements.map<IComplementGroupFormValue>(complementResponseItem => ({
        complementCategory: {
            id: complementResponseItem.complementCategory.id,
            categoryId: complementResponseItem.complementCategory.categoryId, //Edit ProductCategory
            description: complementResponseItem.complementCategory.description,
            min: complementResponseItem.complementCategory.min,
            max: complementResponseItem.complementCategory.max,
            freeQuantity: complementResponseItem.complementCategory.freeQuantity,
        },
        complementProducts: complementResponseItem.complementProducts
    }))
    const values: IProductFormValue = {
        catalogs: response.catalogs,
        category: category,
        defaultPrinter: response.defaultPrinter,
        //detail
        name: response.name,
        productInformation: response.productInformation,
        type: response.type,
        value: response.value,
        imageUrl: response.imageUrl,

        //indetification
        internalCode: response.internalCode,
        integrationCode: response.integrationCode,
        barcode: response.barcode,

        //advance
        genderRestriction: response.genderRestriction,
        expireTime: response.expireTime,
        maxQuantityPerUser: response.maxQuantityPerUser,
        stockControl: response.stockControl,
        enabled: response.enabled,
        blockPrint: response.blockPrint,
        tare: response.tare,
        storeId: response.storeId,
        dontShowInKDS: response.dontShowInKDS,
        adultHood: response.adultHood,
        serviceFeeExemption: response.serviceFeeExemption,
        reservedQuantity: response.reservedQuantity,

        //invoice
        cest: response.cest,
        cfop: response.cfop,
        ncm: response.ncm,

        //Additionals And Composition
        featured: response.featured,
        productComposition: response.productComposition,
        freeAdditionalQuantity: response.freeAdditionalQuantity,
        complements: complements,
        observations: response.observations,
        productGeneralObservation: response.productGeneralObservation,
        sectorPrinterIds: response.sectorPrinterIds,
        event: response.event,
    }
    return (values)
}
export default ProductResponseToValue
