import { FC, Fragment, RefObject, useCallback, useMemo } from "react";
import styles from "./Menu.module.scss";
import MenuItem from "./menuItem/MenuItem";
import packageJson from "../../../../../package.json";
import UseDimension from "components/dimension/UseDimension";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";
import { ExitToApp } from "@mui/icons-material";
import { useAuth } from "modules/auth/presentation/context/AuthContext";
import { useMenuContext } from "./context/MenuContext";
import { Icon } from "@material-ui/core";
import { Skeleton } from "components/skeleton/Skeleton";

export interface IMenuProps {
  opened?: boolean;
  onOpenItem?: () => void;
  setOpenMenu?: React.Dispatch<React.SetStateAction<boolean>>;
  onLoadFirstItem?: (divRef:RefObject<HTMLDivElement>) => void;
}

const Menu: FC<IMenuProps> = ({ opened, onOpenItem, setOpenMenu, onLoadFirstItem }) => {
  const { logout } = useAuth();
  const { isMobile } = UseDimension();
  const { menuPosition, setMenuPosition, menuListContext, menuLoading } = useMenuContext();

  const menuList = useMemo(() => menuListContext ?? [], [menuListContext]);

  const { push } = useHistory();
  const { path } = useRouteMatch();
  const { listId, guestListId } = useParams<{ listId: string, guestListId: string }>();


  const onClickRoute = useCallback(
    (route: string, title?: string) => {
      if (menuPosition === 1 && path.includes("private/event/") && title?.toLowerCase() !== "favoritos") {
        if (listId) {
          return push(`${route.trim()}/${listId}`);
        }
        if (guestListId) {
          return push(`${route.trim()}/${guestListId}`);
        }
        const eventId = localStorage.getItem("@eventId");

        if (route === "/private/event/dashboard/Customer" && eventId) {
          return push(`${route.trim()}/${eventId}`);
        }

        return push(`${route.trim()}/${eventId}`);
      }
      push(route.trim());
    },
    [push, menuPosition, path, guestListId, listId]
  );

  return (
    <div id={styles.adminMenu}>
      <div>
        {menuPosition === 1 && opened && (
          <div
            className={styles.backLocalMenu}
            onClick={() => {
              setMenuPosition(2);
              push("/private/eventos/list");
            }}
          >
            <Icon fontSize="medium">keyboard_arrow_left</Icon>
            <span className={styles.backLocalMenuText}>Voltar</span>
          </div>
        )}
        {menuLoading
          ? <Skeleton items={15} cardStyle={styles.skeletonMenu} />
          : menuList.map((menuItem, index) => (
            <Fragment key={index}>
              {
                <MenuItem
                  key={index}
                  opened={opened}
                  onOpen={onOpenItem}
                  menuItem={menuItem}
                  onClickRoute={onClickRoute}
                  setOpenMenu={setOpenMenu}
                  onLoad={menuItem.title.toLowerCase().includes("vender agora") ? onLoadFirstItem : undefined}
                />
              }
            </Fragment>
          ))}
        {isMobile && (
          <div className={styles.logout}>
            <span onClick={logout}>
              <ExitToApp /> Sair
            </span>
          </div>
        )}
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          opacity: 0.8,
          color: "#333",
          padding: "10px 0px 20px",
          fontSize: "0.75rem",
        }}
      >
        v{packageJson.version}
      </div>
    </div>
  );
};

export default Menu;
