import { api } from "../Api";
import { IGetTrackInteractionsResponse } from "modules/crm/models/dtos/trackInteractions/IGetTrackInteractionsResponse";
import { ITrackInteractionsService } from "modules/crm/models/interface/ITrackInteractionsService";

const TrackInteractionsApi = (): ITrackInteractionsService => {
  const baseUrl = "https://crm-api.meep.cloud/api";

  const getInteractions = async (localId: string, document: string): Promise<IGetTrackInteractionsResponse> => {
    const response = await api.get(`${baseUrl}/Customers`, { params: { ownerId: localId, document, disableError: true } });
    return response.data;
  };

  return {
    getInteractions,
  };
};

export default TrackInteractionsApi;
