import styles from "./ForProductItemMobile.module.scss";
import { IProducts } from "modules/saleForProduct/domain/dto/IPostSaleForProductResponse";
import { moneyMaskNumber } from "services/utils/Money";

interface IForProductItemMobileProps {
  item: IProducts;
}

const ForProductItemMobile = ({ item }: IForProductItemMobileProps) => {
  return (
    <div id={styles.ForProductItemMobile}>
      <div className={styles.productItem}>
        <div className={styles.item}>
          <span className={styles.title}>Categoria:</span>
          <span>{item.categoriaNome}</span>
        </div>
        <div className={styles.item}>
          <span className={styles.title}>Produto</span>
          <span>{item.produtoNome}</span>
        </div>
        <div className={styles.item}>
          <span className={styles.title}>Quantidade:</span>
          <span>{item.quantidade ?? 0}</span>
        </div>
        <div className={styles.item}>
          <span className={styles.title}>Cashless:</span>
          <span>{moneyMaskNumber(item.cashless ?? 0)}</span>
        </div>
        <div className={styles.item}>
          <span className={styles.title}>Débito:</span>
          <span>{moneyMaskNumber(item.debito ?? 0)}</span>
        </div>
        <div className={styles.item}>
          <span className={styles.title}>Crédito:</span>
          <span>{moneyMaskNumber(item.credito ?? 0)}</span>
        </div>
        {/* <div className={styles.item}>
          <span className={styles.title}>PIX:</span>
          <span>PIX</span>
        </div> */}
        <div className={styles.item}>
          <span className={styles.title}>Dinheiro:</span>
          <span>{moneyMaskNumber(item.dinheiro ?? 0)}</span>
        </div>
        <div className={styles.item}>
          <span className={styles.title}>Voucher:</span>
          <span>{moneyMaskNumber(item.voucher ?? 0)}</span>
        </div>
        <div className={styles.item}>
          <span className={styles.title}>Divisão:</span>
          <span>{moneyMaskNumber(item.divisao ?? 0)}</span>
        </div>
        <div className={styles.item}>
          <span className={styles.title}>Outros:</span>
          <span>{moneyMaskNumber(item.outros ?? 0)}</span>
        </div>
        <div className={styles.item}>
          <span className={styles.title}>Desconto:</span>
          <span>{moneyMaskNumber(item.desconto ?? 0)}</span>
        </div>
        <div className={styles.item}>
          <span className={styles.title}>Valor:</span>
          <span>{moneyMaskNumber(item.valor ?? 0)}</span>
        </div>
      </div>
    </div>
  );
};
export default ForProductItemMobile;
