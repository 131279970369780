import React, { useEffect, useState } from 'react'
import UseSession from '../hooks/UseSession'
import { IFilterValue, enumPeriodType } from '../interfaces/IFilter';

export const UseDashboardTicketPage = () => {
  const [filterType, setFilterType] = useState("cashier");
  const [filterSearch, setFilterSearch] = useState(enumPeriodType.last24Hours);
  const { getSession, session } = UseSession()


  const [filter, setFilter] = useState<IFilterValue>();

  useEffect(() => {
    getSession();
  }, [getSession])


  const onChangeFilterHandle = (value: IFilterValue) => {
    const { cashierIds, period, ...rest } = value;
    setFilter({
      cashierIds,
      period,
      ...rest,
    });
  };

  return (
    {
      getSession,
      session,
      onChangeFilterHandle,
      filter,
      setFilterSearch,
      filterSearch,
      setFilterType,
      filterType
    }
  )
}
