import { useLocal } from "modules/local/presentation/context/LocalContext";
import { useCallback, useState } from "react";
import GeneralDashboardApi from "services/api/generalDashboard/GeneralDashboardApi";
import { ICashless } from "../interfaces/ICashless";
import GetCashlessUseCase from "modules/generalDashboard/application/useCases/GetCashlessUseCase";



const UseCashless = () => {

  const [isLoading, setIsLoading] = useState<boolean>();
  const [cashlessData, setCashlessData] = useState<ICashless>()
  const { currentLocal } = useLocal();

  const getCashless= useCallback(
    async () => {
      try {
        const serviceGeneral = GeneralDashboardApi();
        setIsLoading(true);
        if (currentLocal) {
          const response = await GetCashlessUseCase(serviceGeneral,
            currentLocal.id,          
          );

          setCashlessData(response);

          return response
        }
      } finally {
        setIsLoading(false);
      }
    },
    [currentLocal]
  );


  return {
    cashlessData,
    isLoading,
    getCashless,
    setCashlessData,
  };
};

export default UseCashless;
