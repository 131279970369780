import { IDashboardTicketApiService } from "modules/dashboardTicket/domain/interfaces/IMyAccountApiService";
import { ISession } from "modules/dashboardTicket/presentation/interfaces/ISession";
import { IGetSessionResponse } from "modules/dashboardTicket/domain/dto/IGetSessionResponse";

const GetSessionUseCase = async (service: IDashboardTicketApiService, localId: string, quantity: number): Promise<ISession[]> => {
    const response: IGetSessionResponse[] = await service.getSession(localId, quantity);

    const parse: ISession[] = response.map(item => {
       return {
            startDate: item.dataInicio,
            endDate: item.dataFim,
            closedBy: item.fechadoPor,
            openedBy:item.abertoPor,
            sessionId: item.sessaoId,
            sessionName: item.sessaoNome
        };
    })

    return parse;
}

export default GetSessionUseCase;