import { FC, useCallback, useEffect, useMemo, useState } from "react";
import styles from "./TerminalConfigForm.module.scss";
import { Autocomplete, Checkbox, IconButton, Switch, TextField } from "@mui/material";
import { ICatalogSelect } from "modules/catalog/presentation/componentes/productForm/IProducFormValuet";
import { DeviceType } from "modules/equipamento/domain/enum/DeviceType";
import { DeleteOutlined, Edit, OpenInNewOutlined } from "@material-ui/icons";

export interface IItemSelector {
    id: string;
    name: string;
}

export interface ITerminalConfigFormValueDefault {
    id: string;
    isTicket: boolean;
    isPosPaid: boolean;
    catalog: IItemSelector | null;
    isTableMap: boolean;
    isOneOperator: boolean;
    receiptPrinterSectorId: IItemSelector | null;
}

export interface ITerminalConfigFormValue {
    isTicket?: boolean;
    isPosPaid?: boolean;
    catalog?: IItemSelector | null;
    printerSector?: IItemSelector | null;
    isTableMap?: boolean;
    isOneOperator?: boolean;
}

export interface ITerminalConfigFormProps {
    deviceType: DeviceType;
    defaultValue?: ITerminalConfigFormValueDefault;
    catalogs: ICatalogSelect[];
    onChange: (value: ITerminalConfigFormValue) => Promise<string>;
    hasProfile: boolean;
    posDeviceId?: string;
    setDeletePos?: React.Dispatch<React.SetStateAction<string | null>>;
    onClickEditProfileHandle?: (profileId: string) => void;
    printerSectors?: IItemSelector[];
}

const TerminalConfigForm: FC<ITerminalConfigFormProps> = ({
    deviceType,
    defaultValue,
    catalogs,
    onChange,
    hasProfile,
    posDeviceId,
    setDeletePos,
    onClickEditProfileHandle,
    printerSectors
}) => {
    const [isTicket, setIsTicket] = useState<boolean>(false);
    const [isPosPaid, setIsPosPaid] = useState<boolean>(false);
    const [isTableMap, setIsTableMap] = useState<boolean>(false);
    const [isOneOperator, setIsOneOperator] = useState<boolean>(false);
    const [catalog, setCatalog] = useState<IItemSelector | null>(null);
    const [printerSector, setPrinterSector] = useState<IItemSelector | null>(null);

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const values = useMemo(() => ({
        isTicket,
        isPosPaid,
        catalog,
        isTableMap,
        isOneOperator,
        printerSector
    }), [isTicket, isPosPaid, catalog, isTableMap, isOneOperator, printerSector]);

    const catalogOptions = useMemo(() => catalogs.map((item) => {
        return ({
            id: item.id,
            name: item.description
        })
    }) ?? [], [catalogs]);

    const navigateIntegrations = useCallback(() => {
        window.open("https://portal-dev.meep.cloud/private/pdv/impressoras-inteligentes", '_blank');
    }, []);

    const onChangeIsTicketHandle = useCallback(async (value: boolean) => {
        try {
            setIsLoading(true);
            setIsTicket(value);
            await onChange({ ...values, isTicket: value });
        } catch {
            setIsTicket(!value);
        } finally {
            setIsLoading(false);
        }
    }, [onChange, values]);

    const onChangeIsPosPaidHandle = useCallback(async (value: boolean) => {
        try {
            setIsLoading(true);
            setIsPosPaid(value);
            await onChange({ ...values, isPosPaid: value });
        } catch {
            setIsPosPaid(!value);
        } finally {
            setIsLoading(false);
        }
    }, [onChange, values]);

    const onChangeIsTableMapHandle = useCallback(async (value: boolean) => {
        try {
            setIsLoading(true);
            setIsTableMap(value);
            await onChange({ ...values, isTableMap: value });
        } catch {
            setIsTableMap(!value);
        } finally {
            setIsLoading(false);
        }
    }, [onChange, values]);

    const onChangeIsOneOperatorHandle = useCallback(async (value: boolean) => {
        try {
            setIsLoading(true);
            setIsOneOperator(value);
            await onChange({ ...values, isOneOperator: value });
        } catch {
            setIsOneOperator(!value);
        } finally {
            setIsLoading(false);
        }
    }, [onChange, values]);

    const onChangeCatalogHandle = useCallback(async (value: IItemSelector | null) => {
        try {
            setIsLoading(true);
            setCatalog(value);
            await onChange({ ...values, catalog: value });
        } catch {
            setCatalog(null);
        } finally {
            setIsLoading(false);
        }
    }, [onChange, values]);

    const onChangePrinterSectorHandle = useCallback(async (value: IItemSelector | null) => {
        try {
            setIsLoading(true);
            setPrinterSector(value);
            await onChange({ ...values, printerSector: value });
        } catch {
            setPrinterSector(null);
        } finally {
            setIsLoading(false);
        }
    }, [onChange, values]);

    useEffect(() => {
        if (defaultValue) {
            setIsTicket(defaultValue.isTicket);
            setIsPosPaid(defaultValue.isPosPaid);
            setIsTableMap(defaultValue.isTableMap);
            setIsOneOperator(defaultValue.isOneOperator);
            setCatalog(defaultValue.catalog);
            setPrinterSector(defaultValue.receiptPrinterSectorId);
        }
    }, [defaultValue]);

    return (
        <div id={styles.TerminalConfigForm} >
            <div className={styles.config}>
                <div className={styles.operation}>
                    <span className={styles.configTitle}>Operação</span>
                    <div className={styles.operationOptions}>
                        <div className={styles.checkBoxContainer}>
                            <Checkbox
                                disabled={isLoading || !hasProfile}
                                checked={isPosPaid}
                                onChange={(e) => onChangeIsPosPaidHandle(e.target.checked)}
                                style={{ padding: 0, color: isPosPaid ? "#32008E" : "#333333" }}
                            />
                            <span>Mesa/Comanda</span>
                        </div>
                        <div className={styles.checkBoxContainer}>
                            <Checkbox
                                disabled={isLoading || !hasProfile}
                                checked={isTicket}
                                onChange={(e) => onChangeIsTicketHandle(e.target.checked)}
                                style={{ padding: 0, color: isTicket ? "#32008E" : "#333333" }}
                            />
                            <span>Balcão</span>
                        </div>

                    </div>
                    {!isPosPaid && !isTicket && <div className={styles.operationAlert}>
                        <p>Nenhuma operação selecionada</p>
                        <span>Não será possível efetuar pedidos ou realizar pagamentos</span>
                    </div>}
                </div>
                <div className={styles.catalogs}>
                    <label htmlFor="catalog" className={styles.required}>Cardápio vinculado</label>
                    <Autocomplete
                        value={catalog}
                        disabled={isLoading || !hasProfile}
                        onChange={(event: any, newValue: IItemSelector | null) => {
                            onChangeCatalogHandle(newValue);
                        }}
                        options={catalogOptions}
                        getOptionLabel={(option) => option.name}
                        noOptionsText="Nenhuma opção encontrada"
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                name="catalog"
                                placeholder="Selecione um cardápio"
                                InputProps={{
                                    style: { padding: "0 20px 0 10px" },
                                    ...params.InputProps,
                                }}
                                variant="outlined"
                            />
                        )}
                        fullWidth
                    />
                    {!catalog && <div className={styles.operationAlert}>
                        <p>Nenhuma cardápio selecionado</p>
                        <span>Não será possível visualizar nenhum produto.</span>
                    </div>}
                    {/* </div> */}

                    {/* <div className={styles.catalogs}> */}
                    {
                        deviceType === DeviceType.TERMINAL && printerSectors && deviceType === DeviceType.TERMINAL &&
                        <>
                            <div className={styles.print}>
                                <label htmlFor="printer" className={styles.required}>Impressora vinculada</label>
                                <IconButton onClick={navigateIntegrations}><OpenInNewOutlined fontSize="small" /></IconButton>
                            </div>
                            <Autocomplete
                                value={printerSector}
                                disabled={isLoading || !hasProfile}
                                onChange={(event: any, newValue: IItemSelector | null) => {
                                    onChangePrinterSectorHandle(newValue);
                                }}
                                options={printerSectors}
                                getOptionLabel={(option) => option.name}
                                noOptionsText="Nenhuma opção encontrada"
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        name="printer"
                                        placeholder="Selecione uma impressora"
                                        InputProps={{
                                            style: { padding: "0 20px 0 10px" },
                                            ...params.InputProps,
                                        }}
                                        variant="outlined"
                                    />
                                )}
                                fullWidth
                            />
                        </>
                    }
                </div>

                {deviceType === DeviceType.TERMINAL
                    ? (
                        <div className={styles.terminalFlags}>
                            <span className={styles.configTitle}>Personalização</span>
                            <div className={styles.switchContainer}>
                                <div className={styles.flagInfo}>
                                    <p>Pedir a senha do operador apenas uma vez</p>
                                    <span>Esta opção faz com que <b>não</b> seja necessário digitar a senha do operado a cada pedido.</span>
                                    {/* <span>Esta opção faz com que retorne à tela para digitar a senha</span> */}
                                </div>
                                <Switch
                                    disabled={isLoading || !hasProfile}
                                    checked={isOneOperator}
                                    onChange={(_, checked) => onChangeIsOneOperatorHandle(checked)}
                                />
                            </div>
                            <div className={styles.switchContainer}>
                                <div className={styles.flagInfo}>
                                    <p>Visualizar como mapa de mesa</p>
                                    <span>Esta opção faz com que todas as mesas estejam visíveis, ao invés de digitar o número</span>
                                </div>
                                <Switch
                                    disabled={isLoading || !hasProfile}
                                    checked={isTableMap}
                                    onChange={(_, checked) => onChangeIsTableMapHandle(checked)}
                                />
                            </div>
                        </div>
                    ) : (
                        <div className={styles.terminalFlags}>
                            <span className={styles.configTitle}>Configurações avançadas</span>
                            <div className={styles.posContainer}>
                                <div className={styles.posFlag}>
                                    <p>Editar mais configurações</p>
                                    <span>Clique para editar seu PDV físico e configurá-lo conforme o seu negócio.</span>
                                </div>
                                <IconButton onClick={() => onClickEditProfileHandle && onClickEditProfileHandle(defaultValue?.id ?? "")} disableRipple><Edit /></IconButton>
                            </div>
                        </div>
                    )
                }
            </div>
            {deviceType === DeviceType.POS && (
                <div className={styles.deletePos} onClick={() => setDeletePos && setDeletePos(posDeviceId ?? "")}>
                    <DeleteOutlined />
                    <span>Remover máquina</span>
                </div>
            )}
        </div>
    )
}

export default TerminalConfigForm;