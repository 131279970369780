import React, { useEffect, useState } from 'react'
import styles from './AllMovements.module.scss'
import { AttachMoney, CreditCard, Money } from '@mui/icons-material'
import { IParamsUseAllMovements } from '../../hooks/UseAllMovements'
import { IAllMovements } from '../../interfaces/IAllMovements'
import { moneyMaskNumber } from 'services/utils/Money'
import { CircularProgress } from '@mui/material'

interface IAllmovimentsProps {
    getAllMovements: (params?: IParamsUseAllMovements) => Promise<IAllMovements | undefined>
    allMovements: IAllMovements | undefined
    isLoading: boolean | undefined
}

export const AllMovements: React.FC<IAllmovimentsProps> = ({ allMovements, getAllMovements, isLoading }) => {
    const [creditTotal, setCreditTotal] = useState<number>(0);
    const [debitTotal, setDebitTotal] = useState<number>(0);
    const [moneyTotal, setMoneyTotal] = useState<number>(0);
    const [othersTotal, setOthersTotal] = useState<number>(0);

    useEffect(() => {
        getAllMovements();
    }, [getAllMovements])

    useEffect(() => {
        if (allMovements) {
            const credit = allMovements.allType.find(item => item.name === 'Crédito')?.value || 0;
            const debit = allMovements.allType.find(item => item.name === 'Débito')?.value || 0;
            const money = allMovements.allType.find(item => item.name === 'Dinheiro')?.value || 0;
            const others = allMovements.allType.find(item => item.name === 'Outros')?.value || 0;

            setCreditTotal(credit);
            setDebitTotal(debit);
            setMoneyTotal(money);
            setOthersTotal(others);
        }
    }, [allMovements]);
    return (
        <div id={styles.AllMovements}>
            <div className={styles.top}>
                <p>Todas movimentações</p>
            </div>
            <div className={styles.mid}>
                <p>Total</p>
                {
                      isLoading ? 
                        <div className={styles.loading}><CircularProgress  size={16} color="inherit" /> </div>
                        :
                        <p>{moneyMaskNumber(allMovements?.grandTotal)}</p>
                }
            </div>
            <div className={styles.bottom}>
                <div>
                    <p>Crédito <CreditCard className={styles.credit} /> </p>
                    {
                        isLoading ? 
                        <div className={styles.loading}><CircularProgress  size={16} color="inherit" /> </div>
                        :
                        <p>{moneyMaskNumber(creditTotal)}</p>
                    }
                </div>
                <div className={styles.separator}></div>
                <div>
                    <p>Débito  <CreditCard className={styles.debit} /></p>
                    {
                        isLoading ? 
                        <div className={styles.loading}><CircularProgress  size={16} color="inherit" /> </div>
                        :
                        <p>{moneyMaskNumber(debitTotal)}</p>
                    }
                </div>
                <div className={styles.separator}></div>
                <div>
                    <p>Dinheiro <Money className={styles.money} /></p>
                    {
                        isLoading ? 
                        <div className={styles.loading}><CircularProgress  size={16} color="inherit" /> </div>
                        :
                        <p>{moneyMaskNumber(moneyTotal)}</p>
                    }
                </div>
                <div className={styles.separator}></div>
                <div>
                    <p>Outros <AttachMoney className={styles.others} /></p>
                    {
                        isLoading ? 
                        <div className={styles.loading}><CircularProgress  size={16} color="inherit" /> </div>
                        :
                        <p>{moneyMaskNumber(othersTotal)}</p>
                    }
                </div>
            </div>
        </div>
    )
}
