
import { Button, FormControlLabel, Icon, Radio, RadioGroup } from '@material-ui/core';
import styles from './CategoryForm.module.scss'
import { ChangeEvent, FC, FormEvent, useCallback, useEffect, useState } from 'react';
import { v4 } from 'uuid';
import ButtonsForm from '../ui/ButtonsForm';
import { ContainerLabel } from '../ui/ContainerLabel';
import { InputNumber } from '../ui/InputNumber';
import { Separator } from '../ui/Separator';
import { InputLabel } from '../ui/InputLabel';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Column from '../ui/Column';
import { Switch } from '@mui/material';
import { ILocalItem, ILocalList } from 'modules/local/presentation/interfaces/ILocalResume';
import LocalService from 'services/api/local/LocalService';
import GetLocalsListUseCase from 'modules/local/application/useCases/GetLocalsListUseCase';
import { InputImage } from '../ui/InputImage';



export interface ISelectCategory {
    id: string;
    description: string
}

export interface ISelectLocal {
    id: string;
    name: string
}


export interface ICatalogProductCategoryFormValue {
    description: string
    mainCategory?: ISelectCategory | null,
    invoiceSplitLocal?: ISelectLocal | null,
    showInDashboard?: boolean,
    showInPOS?: boolean,
    index?: number,
    shared?: boolean,
    locais?: ILocalItem[],
    fotoCategoria?: string
    id?: string
}

export interface ICatalogProductCategoryFormProps {
    //propertys
    defaultValues?: ICatalogProductCategoryFormValue;
    onSubmit: (values: ICatalogProductCategoryFormValue) => void
    mainCategories: ISelectCategory[]
    invoiceSplitLocals: ISelectLocal[]
    onCancel: () => void
    isLoading?: boolean
}


const CatalogProductCategoryForm: FC<ICatalogProductCategoryFormProps> = ({
    defaultValues,
    onSubmit,
    mainCategories,
    invoiceSplitLocals,
    isLoading,
    onCancel
}) => {

    const [values, setValues] = useState<ICatalogProductCategoryFormValue>({
        description: "",
        shared: false,
        locais: []
    });
    const [search, setSearch] = useState('')
    const [locals, setLocals] = useState<ILocalList>()

    const [error, setError] = useState<{ description?: string }>({});

    const [advanceOptions, setAdvanceOptions] = useState(false);
    const [fotoCategoria, setFotoCategoria] = useState<string | undefined>()

    const [hasMainCategory, setHasMainCategory] = useState(false);
    const [hasInvoiceSplit, setHasInvoiceSplit] = useState(false);
    const localCurrent = localStorage.getItem('@localCurrent');
    const ownerData = localCurrent && JSON.parse(localCurrent);

    useEffect(() => {
        if (ownerData.id && values.id) {
            setFotoCategoria(`${process.env.REACT_APP_MEEP_CUSTOM_BLOB_URL}/${ownerData.id}/${values.id}.jpg?v=0`)
        }
    }, [ownerData.id, values.id])

    useEffect(() => {
        if (values.shared) {
            const objA = {
                id: ownerData.id,
                imgUrl: ownerData.imgUrl,
                name: ownerData.name,
                cashierClosing: ownerData.cashierClosing,
                cashierOpening: ownerData.cashierOpening,

            }
            setValues(prev => {
                let returnObject = { ...prev }
                if (ownerData && values.locais?.length === 0) {
                    returnObject.locais = returnObject.locais || []
                    returnObject.locais.push(objA)
                }
                return returnObject
            });
        } else {
            setValues((prev) => ({ ...prev, locais: [] }));
        }
    }, [values.shared])

    const getLocals = useCallback(
        async () => {
            const service = LocalService();
            const response = await GetLocalsListUseCase(service, { page: 1, pageSize: 500, search: search });
            setLocals(response);
        },
        [search],
    );


    useEffect(() => {
        getLocals()
    }, [search])

    useEffect(() => {
        if (defaultValues) {
            setValues(defaultValues);
            if (defaultValues.mainCategory) {
                setHasMainCategory(true)
            }
            if (defaultValues.invoiceSplitLocal) {
                setHasInvoiceSplit(true)
            }
            if (defaultValues.showInDashboard || defaultValues.showInPOS || defaultValues.index || defaultValues.invoiceSplitLocal || defaultValues.mainCategory) {
                setAdvanceOptions(true)
            }
        }
    }, [defaultValues])


    const changeHandle = useCallback((ev: ChangeEvent<HTMLInputElement>) => {
        setValues((prev) => ({ ...prev, [ev.target.name]: ev.target.value }));
    }, []);
    const changeCategoryHandle = useCallback((_, value: ISelectCategory | null) => {
        setValues((prev) => ({ ...prev, mainCategory: value }));
    }, []);

    const changeShowInPOSHandle = useCallback((_, value: boolean) => {
        setValues((prev) => ({ ...prev, showInPOS: value }));
    }, []);
    const changeShowInDashboardHandle = useCallback((_, value: boolean) => {
        setValues((prev) => ({ ...prev, showInDashboard: value }));
    }, []);

    const changeLocalInvoiceSplitHandle = useCallback((_, value: ISelectLocal | null) => {
        setValues((prev) => ({ ...prev, invoiceSplitLocal: value }));
    }, []);

    const changeHasSharedHandle = useCallback((_, value: string) => {
        if (value === '1') {
            setValues((prev) => ({ ...prev, shared: false }));
        } else {
            setValues((prev) => ({ ...prev, shared: true }));
        }
    }, [])

    const changeLocalsHandle = useCallback((_, values: ILocalItem[] | null) => {
        setValues((prev) => ({ ...prev, locais: values || [] }));
    }, []);


    const validate = useCallback(() => {
        let error = false;
        if (!values.description) {
            setError(prev => ({ ...prev, description: "Descrição é obrigatária" }))
            error = false;
        } else {
            setError({})
            error = true;
        }
        return error;

    }, [values.description])

    const submitHandle = useCallback((ev: FormEvent<HTMLFormElement>) => {
        ev.preventDefault();
        if (validate()) {
            onSubmit?.(values);
        }
    }, [onSubmit, validate, values])

    const changeImageHandle = useCallback((image: string) => {
        setValues((prev) => ({ ...prev, fotoCategoria: image }));
    }, []);

    return (
        <form id={styles.CatalogProductCategoryForm} onSubmit={submitHandle} >
            <Column className={styles.column}>
                <div className={styles.imageInput} >
                    <InputImage size={180} value={values.fotoCategoria ?? fotoCategoria} onChange={changeImageHandle} />
                    <span>
                        Formatos: JPEG e PNG<br />
                        Resolução ideal: 400x400
                    </span>
                </div>
                <InputLabel name={"description"} error={!!error.description} helperText={error.description} value={values.description} label="Descrição" onChange={changeHandle} ></InputLabel>
                {false &&
                    <div className={styles.shared}>
                        <p>Categoria <b>compartilhada?</b></p>
                        <ContainerLabel>
                            <RadioGroup row onChange={changeHasSharedHandle} value={values.shared ? 0 : 1}>
                                <FormControlLabel value={0} control={<Radio />} label="Sim" />
                                <FormControlLabel value={1} control={<Radio />} label="Não" />
                            </RadioGroup>
                        </ContainerLabel>
                        {values.shared &&
                            <Autocomplete
                                fullWidth
                                renderInput={(params) =>
                                    <InputLabel
                                        label={"Locais:"}
                                        {...params}
                                        onChange={(e) => setSearch(e.target.value)}
                                        placeholder={values.locais?.length === 0 ? "Selecione os locais para compartilhar o produto" : ''} />
                                }
                                options={locals?.items ?? []}
                                getOptionLabel={(option) => option.name}
                                multiple
                                value={values.locais}
                                onChange={changeLocalsHandle}
                                size='small'
                            />
                        }
                    </div>
                }
                <Separator />
                <div>
                    <Button color='secondary' variant='text' onClick={() => setAdvanceOptions(prev => !prev)}>Informações Avançadas <Icon>{advanceOptions ? 'close' : 'expand_more'}</Icon></Button>
                </div>
                {
                    advanceOptions &&
                    <>
                        <ContainerLabel>
                            <Switch
                                color={'primary'} checked={hasMainCategory} onChange={(_, value) => { setHasMainCategory(value) }}></Switch>É uma subcategoria?
                        </ContainerLabel>


                        {!values.invoiceSplitLocal && hasMainCategory && <ContainerLabel>
                            <Column>
                                <Autocomplete
                                    fullWidth
                                    renderInput={(params) => <InputLabel label={"Selecione uma categoria pai:"} {...params} />}
                                    options={mainCategories ?? []}
                                    getOptionLabel={(option) => option.description}
                                    value={values.mainCategory}
                                    onChange={changeCategoryHandle}
                                    size='small'
                                />
                                <div style={{ opacity: 0.5 }}>
                                    A categoria que você está criando estará contida na que for selecionada. Ex.: Ao criar a categoria bebidas, você pode usar refrigerante como sub-categoria.
                                </div>
                            </Column>
                        </ContainerLabel>
                        }
                        {!!invoiceSplitLocals.length && <>
                            <ContainerLabel>
                                <Switch
                                    color={'primary'}
                                    checked={hasInvoiceSplit}
                                    onChange={(_, value) => { setHasInvoiceSplit(value) }} />
                                Possui split fiscal?
                            </ContainerLabel>
                            {!values.mainCategory && hasInvoiceSplit && <ContainerLabel>
                                <Autocomplete
                                    fullWidth
                                    renderInput={(params) => <InputLabel label={"Selecione um local de split fiscal:"} {...params} />}
                                    options={invoiceSplitLocals ?? []}
                                    getOptionLabel={(option) => option.name}
                                    value={values.invoiceSplitLocal}
                                    onChange={changeLocalInvoiceSplitHandle}
                                    size='small'
                                />
                            </ContainerLabel>}
                        </>}
                        {/* <ContainerLabel label={"Seu cliente pode adionar complemento gratuitamente?"} >
                    <RadioGroup
                    row
                    value={hasFreeQuantity ? 1 : 0}
                    onChange={onChangeHasFreeQuantityRequired}
                    >
                    <FormControlLabel value={0} control={<Radio />} label="Não" />
                    <FormControlLabel value={1} control={<Radio />} label="Sim" />
                    </RadioGroup>
                </ContainerLabel> */}


                        <ContainerLabel>
                            <Switch
                                color={'primary'}
                                checked={values.showInPOS}
                                onChange={changeShowInPOSHandle}
                            />
                            Exibir na POS?
                        </ContainerLabel>
                        <ContainerLabel>
                            <Switch
                                color={'primary'} checked={values.showInDashboard} onChange={changeShowInDashboardHandle}></Switch>Exibir no Dashboard
                        </ContainerLabel>
                    </>
                }

            </Column>
            <div>
                <ButtonsForm onCancel={onCancel} color='primary' width="100px" />
            </div>
        </form >
    )
}
export default CatalogProductCategoryForm;