import { IGetSalesDevicesResponse } from "modules/generalDashboard/domain/dto/IGetSalesDevicesResponse";
import { IGeneralDashboardApiService } from "modules/generalDashboard/domain/interfaces/IGeneralDashboardApiService";
import { IFilterValue } from "modules/generalDashboard/presentation/interfaces/IFilter";

const GetSalesDevicesUseCase = async (service: IGeneralDashboardApiService, localId: string, filter?: IFilterValue): Promise<IGetSalesDevicesResponse[]> => {

    const response = await service.getSalesDevices(localId, filter);

    return response;

}

export default GetSalesDevicesUseCase;