import React from "react";
import styles from "./AverageTicket.module.scss";
import { CircularProgress } from "@material-ui/core";
import { useUi } from "contexts/userInterface/UserInterfaceContext";
import { IAverageTicket } from "../../interfaces/IAverageTicket";
import { IGetAverageTicketResponse } from "modules/generalDashboard/domain/dto/IGetAverageTicketResponse";

interface ICashlessProps {
  getAverageTicket: () => Promise<IGetAverageTicketResponse | undefined>;
  averageTicketData: IAverageTicket | undefined;
  isLoading: boolean | undefined;
}

const AverageTicket: React.FC<ICashlessProps> = ({ averageTicketData, isLoading }) => {
  const { isMobile } = useUi();

  const showContainerLeft = averageTicketData?.ticketPayment !== undefined && averageTicketData?.ticketIdentifiedClient !== undefined;
  const showContainerMid = averageTicketData?.cashlessRecharge !== undefined && averageTicketData?.cashlessConsumed !== undefined;
  const showContainerRight = averageTicketData?.posPaid !== undefined &&
    averageTicketData?.posPaidPerson !== undefined &&
    averageTicketData?.posPaidTicketZero !== undefined;

  const containerCount = [showContainerLeft, showContainerMid, showContainerRight].filter(Boolean).length;

  return (
    <div id={styles.AverageTicket}>
      <div className={styles.topTitle}>
        <p>Ticket médio</p>
      </div>
      <div className={!isMobile ? styles.container : styles.containerMobile}>
        {showContainerLeft && (
          <div className={styles.containerLeft}>
            <div className={styles.top}>
              <p>Ticket médio de ficha</p>
              <p>Pagamento</p>
              {isLoading ? (
                <div className={styles.loading}>
                  <CircularProgress size={16} color="inherit" />{" "}
                </div>
              ) : (
                <span>R$ {averageTicketData.ticketPayment.toFixed(2)}</span>
              )}
            </div>
            <div className={styles.bottom}>
              <p>Ticket médio de ficha</p>
              <p>Cliente identificado</p>
              {isLoading ? (
                <div className={styles.loading}>
                  <CircularProgress size={16} color="inherit" />{" "}
                </div>
              ) : (
                <span>R$ {averageTicketData.ticketIdentifiedClient.toFixed(2)}</span>
              )}
            </div>
          </div>
        )}
        {containerCount > 1 && showContainerLeft && showContainerMid && !isMobile && <div className={styles.separator}></div>}
        {showContainerMid && (
          <div className={styles.containerMid}>
            <div>
              <p>Ticket médio de ficha</p>
              <p>Recarga</p>
              {isLoading ? (
                <div className={styles.loading}>
                  <CircularProgress size={16} color="inherit" />{" "}
                </div>
              ) : (
                <span>R$ {averageTicketData.cashlessRecharge.toFixed(2)}</span>
              )}
            </div>
            <div>
              <p>Ticket médio de ficha</p>
              <p>Consumo</p>
              {isLoading ? (
                <div className={styles.loading}>
                  <CircularProgress size={16} color="inherit" />{" "}
                </div>
              ) : (
                <span>R$ {averageTicketData.cashlessConsumed.toFixed(2)}</span>
              )}
            </div>
          </div>
        )}
        {containerCount === 3 && showContainerMid && showContainerRight && !isMobile && <div className={styles.separator}></div>}
        {showContainerRight && (
          <div className={styles.containerRight}>
            <div>
              <p>Ticket médio pós-pago • Conta</p>
              {isLoading ? (
                <div className={styles.loading}>
                  <CircularProgress size={16} color="inherit" />{" "}
                </div>
              ) : (
                <span>R$ {averageTicketData.posPaid.toFixed(2)}</span>
              )}
            </div>
            <div>
              <p>Ticket médio pós-pago • Pessoa</p>
              {isLoading ? (
                <div className={styles.loading}>
                  <CircularProgress size={16} color="inherit" />{" "}
                </div>
              ) : (
                <span>R$ {averageTicketData.posPaidPerson.toFixed(2)}</span>
              )}
            </div>
            <div>
              <p className={styles.totalText}>Ticket zero pós-pago</p>
              {isLoading ? (
                <div className={styles.loading}>
                  <CircularProgress size={16} color="inherit" />{" "}
                </div>
              ) : (
                <span className={styles.totalValue}>{averageTicketData.posPaidTicketZero}</span>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AverageTicket;
