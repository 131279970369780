import { useLocal } from "modules/local/presentation/context/LocalContext";
import { useCallback, useState } from "react";
import DashboardTicketApi from "services/api/dashboardTicket/DashboardTicketApi";
import { ISort } from "interfaces/ISort";
import { IRankingProducts } from "../interfaces/IRankingProducts";
import GetRankingProductsUseCase from "modules/dashboardTicket/application/useCases/GetRankingProductsUseCase";
import { IFilterValue } from "../interfaces/IFilter";

export interface IParamsUseRankingProducts {
  localId?: string,
  sessionId?: string,
  period?: number,
  sort?: ISort
}

const UseRankingProducts = (filter: IFilterValue | undefined) => {

  const [isLoading, setIsLoading] = useState<boolean>();
  const [rankingProducts, setRankingProducts] = useState<IRankingProducts[]>()
  const { currentLocal } = useLocal();

  const getRankingProducts = useCallback(
    async (params?: IParamsUseRankingProducts) => {
      try {
        const servicePermutation = DashboardTicketApi();
        setIsLoading(true);
        if (currentLocal) {
          const response = await GetRankingProductsUseCase(servicePermutation,
            currentLocal.id,
            {
              ...filter,
              period: filter?.period ?? 4,
            },
            params?.sort ?? { orientation: 'desc', type: 'Quantidade' }
          );

          setRankingProducts(response);

          return response
        }
      } finally {
        setIsLoading(false);
      }
    },
    [currentLocal, filter]
  );


  return {
    rankingProducts,
    isLoading,
    getRankingProducts,
    setRankingProducts,
  };
};

export default UseRankingProducts;
