import React, { FC } from "react";
import KDSFilter from "./components/KDSFilter/KDSFilter";
import KDSGrid from "./components/KDSGrid/KDSGrid";
import styles from "./KDSPage.module.scss";
import UseKDSPage from "./UseKDSPage";
import { ArrowDropDown, ArrowDropUp, Circle } from "@mui/icons-material";
import ModalStatusIfood from "./components/modalStatusIfood/ModalStatusIfood";
import KDSGroupedList from "./components/KDSGroupedList/KDSGroupedList";
import SoundOn from "./components/Icons/SoundOn.svg";
import SoundOff from "./components/Icons/SoundOff.svg";
import { ErrorMessage } from "components/ui/errorMessage/ErrorMessage";
import { Switch } from "@mui/material";
import { UseConfigIFood } from "modules/config/integrationsConfig/presentation/pages/configIFood/UseConfigIFood";
import UseKdsPageTutorials from "./UseKdsPageTutorials";

export interface IKDSPageProps {
  //propertys
}
const KDSPage: FC<IKDSPageProps> = () => {
  const {
    onSubmitFilter,
    kdsList,
    stepList,
    storeList,
    isLoading,
    tableList,
    pagination,
    ifoodStatus,
    openStatusModal,
    enableRefresh,
    filter,
    kdsGroupedList,
    kdsAudioPlay,
    getKDSList,
    onChangePage,
    ChangeKDSStep,
    sendToLocalPrinter,
    handleShowStatusModal,
    handleHideStatusModal,
    getStateTotalHandle,
    getKDSGroupedList,
    total,
    setTotal,
    audioShowPause,
    postIssueNfe,
    audioShowPlay,
    issueNFCe,
    summaryView,
    setSummaryView,
    handleSummaryView,
    isLoadingStep,
    isButtonDisabled,
    setFilter,
    deviceList,
  } = UseKDSPage();

  const { getIFoodStatus } = UseConfigIFood();

  const  {showOrderKDSTutorial } = UseKdsPageTutorials();

  const divRef = React.useRef<HTMLDivElement>(null);

  // React.useEffect(() => {
  //   if(kdsList?.count){
  //     showOrderKDSTutorial(divRef);
  //   }
  // }, [kdsList?.count, showOrderKDSTutorial]);

  return (
    <div id={styles.KDSPage}>
      <div className={styles.infoBeta}>
        {getIFoodStatus?.isActive && ifoodStatus?.ifoodIntegration && (
          <>
            <div className={styles.contentStatusIfood}>
              <span>
                <Circle
                  className={
                    getIFoodStatus?.isActive
                      ? styles.statusOn
                      : styles.statusOff
                  }
                />
                Status IFood:
                {getIFoodStatus?.isActive ? (
                  <span>Online</span>
                ) : (
                  <span>Offline</span>
                )}
              </span>
              {openStatusModal
                ? getIFoodStatus?.isActive && (
                    <ArrowDropUp
                      onClick={handleHideStatusModal}
                      style={{ cursor: "pointer" }}
                      fontSize="small"
                    />
                  )
                : getIFoodStatus?.isActive && (
                    <ArrowDropDown
                      onClick={handleShowStatusModal}
                      style={{ cursor: "pointer" }}
                      fontSize="small"
                    />
                  )}
            </div>
          </>
        )}

        {
          <>
            <div className={styles.soundContentStatus}>
              <span onClick={kdsAudioPlay ? audioShowPause : audioShowPlay}>
                <img src={kdsAudioPlay ? SoundOn : SoundOff} alt="Sound On" />{" "}
                Som de Alerta:
                {kdsAudioPlay ? <span>Ativado</span> : <span>Desativado</span>}
              </span>
            </div>
          </>
        }

        <div className={styles.refresh}>
          <Switch
            color="secondary"
            defaultChecked={true}
            onChange={(ev, value) => (enableRefresh.current = value)}
          />{" "}
          Atualizar Automaticamente
          <Switch
            color="secondary"
            checked={summaryView}
            onChange={handleSummaryView}
          />{" "}
          Versão completa do KDS
        </div>
      </div>
      <div className={styles.contentStatusInfo}>
        {openStatusModal && ifoodStatus?.merchant?.validations && (
          <ModalStatusIfood
            handleCloseModal={handleHideStatusModal}
            validations={ifoodStatus?.merchant?.validations}
          />
        )}
      </div>
      <div className={styles.filterContainer}>
        <KDSFilter
          tables={tableList}
          steps={stepList}
          stores={storeList}
          isLoading={isLoadingStep}
          onSubmit={onSubmitFilter}
          enableRefresh={enableRefresh}
          getStepTotals={getStateTotalHandle}
          setTotal={setTotal}
          total={total}
          isButtonDisabled={isButtonDisabled}
          defaultValues={filter}
          summaryView={summaryView}
          setSummaryView={setSummaryView}
          handleSummaryView={handleSummaryView}
          setFilter={setFilter}
          deviceList={deviceList}
        />
      </div>
      {(kdsList?.count ?? 0) === 0 && (
        <ErrorMessage
          type="warning"
          customMessage="Nenhum pedido encontrado."
        />
      )}
      {(kdsList?.count ?? 0) > 0 && !filter.groupedByTable && (
        <div className={styles.listContainer} ref={divRef}>
          <KDSGrid
            onClickAction={ChangeKDSStep}
            pagination={pagination}
            getData={getKDSList}
            list={kdsList}
            isLoading={isLoading}
            changePagination={onChangePage}
            onClickPrint={sendToLocalPrinter}
            postIssueNfe={postIssueNfe}
            issueNFCe={issueNFCe}
            summaryView={summaryView}
            setSummaryView={setSummaryView}
          />
        </div>
      )}

      {(kdsList?.count ?? 0) > 0 && filter.groupedByTable && (
        <div className={styles.listContainer}>
          <KDSGroupedList
            onClickAction={ChangeKDSStep}
            pagination={pagination}
            issueNFCe={issueNFCe}
            getData={getKDSGroupedList}
            groupedList={kdsGroupedList}
            isLoading={isLoading}
            changePagination={onChangePage}
            postIssueNfe={postIssueNfe}
            onClickPrint={sendToLocalPrinter}
            summaryView={summaryView}
            setSummaryView={setSummaryView}
          />
        </div>
      )}
    </div>
  );
};
export default KDSPage;
