import React, { FC, useCallback, useEffect, useState } from 'react'
import styles from './SupplyForm.module.scss'
import { ButtonContainer, Container, IconButtonContainer, InputContainer, Row } from '../../ui/form/FormContainers'
import { FormControlLabel, FormHelperText, Icon, IconButton, MenuItem, Radio, RadioGroup, Switch, TextField } from '@material-ui/core'
import { DEFAULT_VALUE, ISupplyFormValues, ISupplySchema, SupplyConversionFactorEnum, SupplyTypeEnum } from './ISupplyFormValues'
import { useFieldArray, useForm } from 'react-hook-form'
import Sidesheet from 'components/sidesheet/Sidesheet'
import SelectSupplyParentGroup, { ISupplyGroupList } from '../suplyGroup/suplyGroupForm/selectSupplyParentGroup/SelectSupplyParentGroup'
import { IGetUnitListResponse } from 'modules/meepErp/application/dtos/unit/IGetLUnitListResponse'
import { Autocomplete } from '@material-ui/lab'
import TextFieldMoney from '../../ui/TextField/TextFieldMoney'
import { zodResolver } from '@hookform/resolvers/zod'
import { useHistory, useParams } from 'react-router-dom'
import { IGetStorageLocalsListResponse } from 'modules/meepErp/application/dtos/storageLocals/IGetStorageLocalsListResponse'
import Button from 'components/ui/Button/Button'
import { IGetSupplyNameRequest, IGetSupplyNameResponse } from 'modules/meepErp/application/dtos/supply/supplyGroup/IGetSupplyNameDtos'
import { IGetSupplyListRequest } from 'modules/meepErp/application/dtos/supply/IGetSupplyListRequest';
import AutocompleteInput from '../../autocomplete/AutocompleteInput'
import { IGetSupplyStockListRequest, IGetSupplyStockListResponse, ISupplyStockItemResponse } from 'modules/meepErp/application/dtos/supplyStock/IGetSupplyStockListResponse'
import { IGetCompositionsItemResponse } from 'modules/meepErp/application/dtos/supply/IGetCompositionsResponse'
export interface ISupplyFormProps {
    //propertys
    disabled?: boolean;
    isLoading?: boolean;
    isSubmitting?: boolean;
    defaultValues?: ISupplyFormValues;
    onSubmit: (values: ISupplyFormValues) => void;
    getSupplyGroupList: (request: { parentId?: string }) => Promise<ISupplyGroupList>
    units?: IGetUnitListResponse;
    storageLocation?: IGetStorageLocalsListResponse;
    products?: { id: string, name: string }[];
    GetSearchNameSupplyGroup: (request: IGetSupplyNameRequest) => Promise<IGetSupplyNameResponse>
    getSupplyStockList: (request: IGetSupplyStockListRequest) => Promise<IGetSupplyStockListResponse>
    getCompositions: (supplyId: string) => Promise<IGetCompositionsItemResponse[]>
}
const SupplyForm: FC<ISupplyFormProps> = ({
    disabled,
    defaultValues,
    onSubmit,
    getSupplyGroupList,
    units,
    storageLocation: storageLocationId,
    products,
    isLoading,
    GetSearchNameSupplyGroup,
    getSupplyStockList,
    getCompositions
}) => {
    const { register,
        handleSubmit,
        formState: { errors, isSubmitting },
        getValues,
        setValue,
        control,
        watch,
        reset
    } = useForm<ISupplyFormValues>({
        defaultValues: defaultValues,
        resolver: zodResolver(ISupplySchema)
    });
    const { id } = useParams<{ id?: string; }>();
    const { push } = useHistory()

    const values = watch(); // usado para colocar valores no select

    const conversions = useFieldArray({
        control,
        name: 'conversions'
    });

    const compositions = useFieldArray({
        control,
        name: 'compositions'
    });

    const [opensSupplyGroup, setOpensSupplyGroup] = useState<boolean>(false);
    const [listLocalStock, setListLocalStock] = useState<IGetCompositionsItemResponse[]>([]);

    const getSupplyListHandler = useCallback(async (request: IGetSupplyListRequest) => {
        return await getSupplyStockList(request)
    }, [getSupplyStockList])

    const getSupplyAutoComplete = (request: any) => {
        const keyword = request.keyword;

        // Verifica se o keyword é um número ou uma letra
        const isNumber = /^\d+$/.test(keyword);

        return getSupplyListHandler({
            ...request,
            [isNumber ? 'code' : 'name']: isNumber ? Number(keyword) : keyword,
        });
    };

    const getListLocalStock = useCallback(async (supplyId: string) => {
        const response = await getCompositions(supplyId)
        setListLocalStock(response)
    }, [getCompositions])
    

    useEffect(() => {
        if (defaultValues && id) {
            reset({
                ...defaultValues,
                supplyType: defaultValues.meepProductId ? SupplyTypeEnum.PRODUCT : SupplyTypeEnum.NEW_SUPPLY,
                product: defaultValues.meepProductId,
                supplyGroup: {
                    id: defaultValues.supplyGroupId,
                    name: defaultValues.supplyGroupName,
                },
                conversions: defaultValues.conversions.map((conversion) => ({
                    ...conversion,
                })),
            });
        } else {
            reset(DEFAULT_VALUE)
        }
    }, [defaultValues, reset, id]);
    console.log(errors, 'storageLocationId')

    return (
        <form id={styles.SupplyForm} onSubmit={handleSubmit(onSubmit)}>
            <Container maxWidth={2000}>

                <Container subContainer title='1. Tipo insumo'>
                    {id ? (
                        <InputContainer label={"Produto de venda"}>
                            <TextField type="text"
                                value={values.supplyType === SupplyTypeEnum.PRODUCT ? 'Sim' : 'Não'}
                                disabled={true}
                                fullWidth={false}
                            />
                        </InputContainer>
                    ) : (
                        <InputContainer>
                            <RadioGroup
                                row
                                value={watch('supplyType')?.toString()}
                                onChange={(e) => {
                                    setValue('supplyType', Number(e.target.value) as SupplyTypeEnum)
                                    setValue('product', undefined);
                                }}
                            >
                                <FormControlLabel disabled={disabled} value={SupplyTypeEnum.PRODUCT.toString()} control={<Radio />} label="Usar produto de venda" />
                                <FormControlLabel disabled={disabled} value={SupplyTypeEnum.NEW_SUPPLY.toString()} control={<Radio />} label="Criar novo insumo" />
                            </RadioGroup>
                        </InputContainer>)}

                    {watch('supplyType') === SupplyTypeEnum.PRODUCT &&
                        <Row>
                            <Autocomplete
                                options={products ?? []}
                                fullWidth
                                getOptionLabel={(option) => option.name}
                                size='small'
                                value={
                                    products?.find((product) => product.id === watch('product')) ||
                                    products?.find((product) => product.id === values.meepProductId) || null
                                }
                                onChange={(e, value) => {
                                    if (!value) return
                                    setValue('product', value?.id)
                                    setValue('name', value?.name)
                                    if (id) {
                                        setValue('product', values.meepProductId)
                                    }
                                }}
                                disabled={id ? true : false}
                                renderInput={(params) => <TextField
                                    {...params}
                                    label='Produto'
                                    fullWidth
                                    size='small'
                                    error={!!errors.product}
                                    helperText={errors.product?.message}
                                />}
                            />
                        </Row>}
                </Container>
                {
                    (watch('supplyType') === SupplyTypeEnum.NEW_SUPPLY || watch('product') || id) &&
                    <>
                        <Container subContainer title='2. Dados gerais'>
                            {!!watch(`code`) &&
                                <Row>
                                    <InputContainer maxWidth={200} label="Código">
                                        <TextField disabled type="text" {...register('code')} />
                                    </InputContainer>
                                </Row>
                            }
                            <Row>
                                {watch('supplyType') === SupplyTypeEnum.NEW_SUPPLY &&
                                    <InputContainer label="Nome" requerid>
                                        <TextField type="text" {...register('name')}
                                            error={!!errors.name}
                                            helperText={errors.name?.message}
                                        />
                                    </InputContainer>
                                }
                                <InputContainer label="Grupo de insumo" requerid maxWidth={watch('supplyType') === SupplyTypeEnum.PRODUCT ? 500 : undefined}>
                                    <TextField
                                        onClick={() => setOpensSupplyGroup(true)}
                                        value={getValues('supplyGroup')?.name}
                                        error={!!errors.supplyGroup}
                                        placeholder='Selecionar Grupo de insumo'

                                    />
                                </InputContainer>
                            </Row>
                            <Row>
                                <InputContainer label="Descrição">
                                    <TextField
                                        type="text" {...register('description')}
                                        error={!!errors.description}
                                        helperText={errors.description?.message}
                                    />
                                </InputContainer>
                            </Row>
                            <Row>
                                <InputContainer label="Unidade" requerid>
                                    <Autocomplete
                                        options={units?.items ?? []}
                                        getOptionLabel={(option) => option.symbol}
                                        size='small'
                                        fullWidth
                                        getOptionSelected={(option, value) => {
                                            return option.id === value.id
                                        }}
                                        value={
                                            units?.items.find((unit) => unit.id === watch('measurementUnitId')) ||
                                            units?.items.find((unit) => unit.id === values.measurementUnitId) || null
                                        }
                                        onChange={(e, value) => {
                                            if (!value) return
                                            setValue('measurementUnitId', value?.id ?? null)
                                        }}
                                        renderInput={(params) => <TextField
                                            {...params}
                                            fullWidth
                                            size='small'
                                            error={!!errors.measurementUnitId}
                                            helperText={errors.measurementUnitId?.message}
                                        />}
                                        disabled={id ? true : false}
                                    />
                                </InputContainer>
                                <InputContainer label="Estoque mínimo" requerid>
                                    <TextField
                                        type="number"
                                        {...register('minimumStock', { min: 0, valueAsNumber: true })}
                                        error={!!errors.minimumStock}
                                        helperText={errors.minimumStock?.message}
                                    />
                                </InputContainer>
                            </Row>
                            {watch('supplyType') === SupplyTypeEnum.PRODUCT &&
                                <Row>
                                    <InputContainer label="Local de estoque" requerid>
                                        <Autocomplete
                                            options={storageLocationId?.items ?? []}
                                            getOptionLabel={(option) => option.name}
                                            size='small'
                                            onChange={(e, value) => {
                                                if (!value) return
                                                setValue('storageLocationId', value?.id ?? null)
                                            }}
                                            fullWidth
                                            value={storageLocationId?.items.find((locals) => locals.id === watch('storageLocationId'))}
                                            renderInput={(params) => <TextField
                                                {...params}
                                                fullWidth
                                                size='small'
                                                error={!!errors.storageLocationId}
                                                helperText={errors.storageLocationId?.message}
                                            />}

                                        />
                                    </InputContainer>
                                </Row>
                            }
                            {/* <Row>
                                <InputContainer label={"Peso bruto"}>
                                    <TextField
                                        type="number"
                                        {...register('grossWeight', { min: 0, valueAsNumber: true })}
                                        error={!!errors.grossWeight}
                                        helperText={errors.grossWeight?.message}
                                    />
                                </InputContainer>
                                <InputContainer label={"Peso líquido"}>
                                    <TextField
                                        type="number"
                                        {...register('netWeight', { min: 0, valueAsNumber: true })}
                                        error={!!errors.netWeight}
                                        helperText={errors.netWeight?.message}
                                    />
                                </InputContainer>
                            </Row> */}
                        </Container>
                        <Container subContainer title={'3. Conversão'}>
                            <div>
                                <Button
                                    fullWidth={false}
                                    variant='outlined'
                                    endIcon={<Icon className={styles.iconAdd}>add</Icon>} onClick={() => conversions.append({
                                        measurementUnitId: '',
                                        measurementUnitSymbol: '',
                                        quantity: 0,
                                        factor: SupplyConversionFactorEnum.MULTIPLY
                                    })}>Adicionar</Button>
                            </div>
                            {
                                conversions.fields.map((conversion, index) => (
                                    <Row>
                                        <InputContainer label="Unidade" requerid>
                                            <Autocomplete
                                                options={units?.items.filter(item => item.id !== watch('measurementUnitId')) ?? []}
                                                size='small'
                                                getOptionLabel={(option) => option.symbol}
                                                fullWidth
                                                getOptionSelected={(option, value) => {
                                                    return option.id === value.id
                                                }}
                                                value={units?.items.find((unit) => unit.id === watch(`conversions.${index}.measurementUnitId`)) ?? null}
                                                onChange={(e, value) => {
                                                    if (!value) return
                                                    setValue(`conversions.${index}.measurementUnitId`, value?.id)
                                                }}
                                                renderInput={(params) => <TextField  {...params}
                                                    fullWidth
                                                    size='small'
                                                    error={!!errors.conversions?.[index]?.measurementUnitId}
                                                    helperText={errors.conversions?.[index]?.measurementUnitId?.message}
                                                />}
                                            />
                                        </InputContainer>
                                        <IconButtonContainer>
                                            <IconButton disabled>
                                                <Icon>
                                                    sync_alt
                                                </Icon>
                                            </IconButton>
                                        </IconButtonContainer>
                                        <InputContainer label='Quantidade' requerid>
                                            <TextField
                                                type="number" {...register(`conversions.${index}.quantity`, { valueAsNumber: true })}
                                                helperText={errors.conversions?.[index]?.quantity?.message}
                                                error={!!errors.conversions?.[index]?.quantity}
                                            />
                                        </InputContainer>
                                        <InputContainer label="Fator" requerid>
                                            <TextField
                                                select
                                                error={!!errors.conversions?.[index]?.factor}
                                                helperText={errors.conversions?.[index]?.factor?.message}
                                                value={
                                                    values.conversions[index].factor?.toString() === SupplyConversionFactorEnum.MULTIPLY.toString() ?
                                                        SupplyConversionFactorEnum.MULTIPLY.toString() :
                                                        SupplyConversionFactorEnum.DIVIDE.toString()
                                                }
                                                onChange={(e) => {
                                                    setValue(`conversions.${index}.factor`, Number(e.target.value))
                                                }}
                                            >
                                                <MenuItem value={SupplyConversionFactorEnum.MULTIPLY.toString()}>Multiplicar</MenuItem>
                                                <MenuItem value={SupplyConversionFactorEnum.DIVIDE.toString()}>Dividir</MenuItem>
                                            </TextField>

                                        </InputContainer>
                                        <IconButtonContainer>
                                            <IconButton onClick={() => conversions.remove(index)}>
                                                <Icon>delete</Icon>
                                            </IconButton>
                                        </IconButtonContainer>
                                    </Row>
                                ))
                            }
                            <FormHelperText
                                error={!!errors.conversions}
                            >
                                {errors.conversions?.message}
                            </FormHelperText>
                        </Container>
                        <Container subContainer title={'4. Composição'} >
                            <div>
                                <Button
                                    fullWidth={false}
                                    variant='outlined'
                                    endIcon={<Icon className={styles.iconAdd}>add</Icon>}
                                    onClick={
                                        () => compositions.append({
                                            code: '',
                                            supplyStockId: '',
                                            supplyName: '',
                                            quantity: 0,
                                            measurementUnitSymbol: '',
                                            cost: 0,
                                            updateStock: false,
                                            compositionSupplyId: ''
                                        })}
                                >
                                    Adicionar
                                </Button>
                            </div>

                            {
                                compositions.fields.map((item, index) => (
                                    <Row key={index} style={{ paddingBottom: 4 }}>
                                        <InputContainer label={"Insumo"} flex={2} requerid>
                                            <AutocompleteInput
                                                getList={(request) => getSupplyAutoComplete(request)}
                                                onSelect={(item?: ISupplyStockItemResponse) => {
                                                    if (!item)
                                                        return;
                                                    setValue(`compositions.${index}.supplyName`, item.supplyName);
                                                    setValue(`compositions.${index}.compositionSupplyId`, item.supplyId);
                                                    getListLocalStock(item.supplyId)
                                                }}
                                                textInput={watch(`compositions.${index}.supplyName`)}
                                                getOptionLabel={(item: ISupplyStockItemResponse) => item.code}
                                                getOptionDescription={(item: ISupplyStockItemResponse) => item.supplyName}
                                                error={!!errors.compositions?.[index]?.supplyName}
                                                helperText={errors.compositions?.[index]?.supplyName?.message}
                                            />
                                        </InputContainer>
                                        <InputContainer label={"Local de Estoque"} requerid>
                                            <Autocomplete
                                                size='small'
                                                fullWidth
                                                options={listLocalStock}
                                                disabled={!values.compositions?.[index]?.supplyName}
                                                getOptionLabel={(option) => option.storageLocationName}
                                                renderInput={(params) => <TextField
                                                    {...params}
                                                    fullWidth
                                                    size='small'
                                                    helperText={errors.compositions?.[index]?.storageLocationId?.message}
                                                    error={!!errors.compositions?.[index]?.storageLocationId}
                                                />}
                                                value={{
                                                    storageLocationName: values.compositions?.[index]?.storageLocationName ?? '',
                                                    storageLocationId: values.compositions?.[index]?.storageLocationId ?? ''
                                                }}
                                                onChange={(e, value) => {
                                                    if (!value) return
                                                    setValue(`compositions.${index}.storageLocationId`, value?.storageLocationId)
                                                    setValue(`compositions.${index}.cost`, value?.totalCost ?? 0)
                                                    setValue(`compositions.${index}.measurementUnitSymbol`, value?.measurementUnitSymbol ?? '')
                                                    setValue(`compositions.${index}.measurementUnitId`, value.measurementUnitId);
                                                    setValue(`compositions.${index}.storageLocationName`, value.storageLocationName)

                                                }}

                                            />
                                        </InputContainer>
                                        <InputContainer maxWidth={150} label={"Atualiza estoque"}>
                                            <TextField
                                                select
                                                value={
                                                    watch(`compositions.${index}.updateStock`) ? 'true' : 'false'
                                                }
                                                onChange={(e) => {
                                                    setValue(`compositions.${index}.updateStock`, e.target.value === 'true')
                                                    console.log(e.target.value)
                                                }}
                                            >
                                                <MenuItem value="true">Sim</MenuItem>
                                                <MenuItem value="false">Não</MenuItem>
                                            </TextField>
                                        </InputContainer>
                                        <InputContainer label={"Quantidade"} maxWidth={90} requerid>
                                            <TextField
                                                type="number"
                                                {...register(`compositions.${index}.quantity`, {
                                                    valueAsNumber: true
                                                })}
                                                error={!!errors.compositions?.[index]?.quantity}
                                                helperText={errors.compositions?.[index]?.quantity?.message}
                                            />
                                        </InputContainer>
                                        <InputContainer label={"Unidade"} maxWidth={160}>
                                            <TextField
                                                disabled
                                                value={watch(`compositions.${index}.measurementUnitSymbol`)}
                                            />
                                        </InputContainer>
                                        <InputContainer label={"Custo"} maxWidth={90}>
                                            <TextFieldMoney
                                                value={watch(`compositions.${index}.cost`)}
                                                disabled
                                            />
                                        </InputContainer>

                                        <IconButtonContainer>
                                            <IconButton onClick={() => compositions.remove(index)}>
                                                <Icon>delete</Icon>
                                            </IconButton>
                                        </IconButtonContainer>
                                    </Row>
                                ))

                            }
                            <FormHelperText error={!!errors.compositions}>
                                {errors.compositions?.message}
                            </FormHelperText>
                            {watch('compositions') && <div className={styles.total}>
                                <label>Total</label>
                                <span>
                                    {watch('compositions')?.map(item => item.cost).reduce((prev, current) => prev + current, 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                                </span>
                            </div>}
                        </Container>
                        <ButtonContainer>
                            <Button color='secondary' disabled={isSubmitting} onClick={() => push('/private/meeperp/supply')} fullWidth={false} variant='outlined'>Cancelar</Button>
                            <Button disabled={isSubmitting} type="submit" fullWidth={false} color='secondary'>Concluir</Button>
                        </ButtonContainer>
                    </>}
            </Container>

            <Sidesheet
                open={opensSupplyGroup}
                onClose={() => setOpensSupplyGroup(false)}
                title={<h2>Selecionar <b>grupo pai</b></h2>}
            >
                <div className={styles.sideSheet}>
                    <SelectSupplyParentGroup
                        getSupplyGroupList={getSupplyGroupList}
                        value={watch('supplyGroup')?.id}
                        onSelect={(item) => {
                            setValue('supplyGroup', item)
                            setOpensSupplyGroup(false)
                        }}
                        GetSearchNameSupplyGroup={GetSearchNameSupplyGroup}
                    />
                </div>
            </Sidesheet>
        </form >
    )
}
export default SupplyForm