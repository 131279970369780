import { IAddProductCategoryRequest } from "modules/catalog/domain/dtos/ISaveProductCategoryRequets"
import { ICatalogService } from "modules/catalog/domain/interface/ICatalogService"
import { ICatalogProductCategoryFormValue } from "modules/catalog/presentation/componentes/_catalogProductCategoryForm/CategoryForm"

const AddProductCategoryUseCase = async (service: ICatalogService, localId: string, values: ICatalogProductCategoryFormValue) => {
    const request: IAddProductCategoryRequest = {
        description: values.description,
        mainCategoryId: values.mainCategory?.id,
        invoiceSplitLocalId: values.invoiceSplitLocal?.id,
        showInDashboard: !!values.showInDashboard,
        showInPOS: !!values.showInPOS,
        index: values.index,
        locais: values.locais,
        shared: values.shared,
        fotoCategoria: values.fotoCategoria
    }

    await service.addProductCategory(localId, request);
}
export default AddProductCategoryUseCase