import { INotificationsService } from "components/layout/domain/interfaces/INotificationsService";
import { NotificationProps as NotificacaoResponse, NotificationProps } from "components/layout/domain/dto/INotificationProps";
import { ISaveReadNotificationRequest } from "components/layout/domain/dto/ISaveReadNotificationRequest";
import { IGetNotificationsByTypeResponse as IGetNotificacaoResponse } from "components/layout/domain/dto/IGetNotificationsByTypeResponse";
import { api } from "../Api";
import { SystemIdentifierEnum } from "modules/gestaoVendas/domain/enum/GetSegmentosEnum";

export const NotificationslApi = (): INotificationsService => {
  const getNotifications = async (
    token: string | null,
    systemIdentifier?: SystemIdentifierEnum,
  ): Promise<NotificationProps[]> => {
    const result = await api.get<NotificationProps[]>(
      "/MensagemDashboard/Buscar", {
        params: { systemIdentifier },
        headers: { Authorization: `bearer ${token}` },
      }
    );
    return result.data;
  };

  const getNotificationsByType = async (
    token: string | null,
    systemIdentifier: SystemIdentifierEnum,
    type?: string
  ): Promise<IGetNotificacaoResponse[]> => {
    const result = await api.get<IGetNotificacaoResponse[]>(
      "/MensagemDashboard/Notificacao", { 
        params: { tipo: type, systemIdentifier },
        headers: { Authorization: `bearer ${token}` },
      }
    );
    return result.data;
  };

  const saveReadNotification = async (
    request: ISaveReadNotificationRequest
  ): Promise<any> => {
    const result = await api.post<any>("/MensagemDashboard/Salvar", request);
    return result.data;
  };

  return {
    getNotifications,
    getNotificationsByType,
    saveReadNotification,
  };
};
