import { IGetRankingOperatorsResponse } from "modules/generalDashboard/domain/dto/IGetRankingOperatorsResponse";
import { IGeneralDashboardApiService } from "modules/generalDashboard/domain/interfaces/IGeneralDashboardApiService";
import { IFilterValue } from "modules/generalDashboard/presentation/interfaces/IFilter";

const GetRankingOperatorsUseCase = async (service: IGeneralDashboardApiService, localId: string, filter?: IFilterValue): Promise<IGetRankingOperatorsResponse[]> => {

    const response = await service.getRankingOperators(localId, filter);

    return response;

}

export default GetRankingOperatorsUseCase;