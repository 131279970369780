import GetMyAccountUseCase from "modules/generalDashboard/application/useCases/GetMyAccountUseCase";
import { useLocal } from "modules/local/presentation/context/LocalContext";
import { useCallback, useState } from "react";
import GeneralDashboardApi from "services/api/generalDashboard/GeneralDashboardApi";
import { IMyAccount } from "../interfaces/IMyAccount";

const UseMyAccount = () => {

  const [isLoading, setIsLoading] = useState<boolean>();
  const [myAccount, setMyAccount] = useState<IMyAccount>()
  const { currentLocal } = useLocal();

  const getMyAccount= useCallback(
    async () => {
      try {
        const serviceGeneral = GeneralDashboardApi();
        setIsLoading(true);
        if (currentLocal) {
          const response = await GetMyAccountUseCase(serviceGeneral,
            currentLocal.id,          
          );

          setMyAccount(response);

          return response
        }
      } finally {
        setIsLoading(false);
      }
    },
    [currentLocal]
  );


  return {
    myAccount,
    isLoading,
    getMyAccount,
    setMyAccount,
  };
};

export default UseMyAccount;
