import { IDashboardTicketApiService } from "modules/dashboardTicket/domain/interfaces/IMyAccountApiService";
import { api } from "../Api";
import { IGetMyAccountResponse } from "modules/dashboardTicket/domain/dto/IGetMyAccountResponse";
import { IGetAllMovementsResponse } from "modules/dashboardTicket/domain/dto/IGetAllMovementsResponse";
import { IGetRankingProductsResponse } from "modules/dashboardTicket/domain/dto/IGetRankingProductsResponse";
import { ISort } from "interfaces/ISort";
import { IGetRankingOperatorsResponse } from "modules/dashboardTicket/domain/dto/IGetRankingOperatorsResponse";
import { IGetRankingCustomersResponse } from "modules/dashboardTicket/domain/dto/IGetRankingCustomersResponse";
import { IGetSalesCategoriesResponse } from "modules/dashboardTicket/domain/dto/IGetSalesCategoriesResponse";
import { IGetSalesDevicesResponse } from "modules/dashboardTicket/domain/dto/IGetSalesDevicesResponse";
import { IGetSessionResponse } from "modules/dashboardTicket/domain/dto/IGetSessionResponse";
import { IFilterValue } from "modules/dashboardTicket/presentation/interfaces/IFilter";
import qs from "qs";
import { IGetServiceRateResponse } from "modules/dashboardTicket/domain/dto/IGetServiceRateResponse";


const DashboardTicketApi = (): IDashboardTicketApiService => {

  const getMyAccount = async (localId: string): Promise<IGetMyAccountResponse> => {  
    const response = await api.get(`ContaDigital/BuscarSaldoAtual/${localId}`);
    return response.data;
  };

  const getSession = async (localId: string, quantity: number): Promise<IGetSessionResponse[]> => {
    const params = {
      localClienteId: localId,
      quantidade: quantity,
    }
    const response = await api.get(`Relatorio/BuscarSessoesPOSDoLocal`, { params: params });
    return response.data;
  };

  const getAllMovements = async (localId: string,  filter?: IFilterValue): Promise<IGetAllMovementsResponse> => {

    const params = {
      PeriodoBusca: filter?.period,
      SessaoPosIds: filter?.cashierIds?.map(item => item.sessionId),
      LocalId: localId,
      DataFinal: filter?.startDate,
      DataInicial: filter?.endDate
    }

    const response = await api.get(`DashBoard/BuscarDadosGraficoMovimentacaoGeralPorPeriodo?${qs.stringify(params,{
      arrayFormat: "repeat",

    })}`);
    return response.data;
  };

  const getServiceRate = async (localId: string,  filter?: IFilterValue): Promise<IGetServiceRateResponse> => {

    const params = {
      PeriodoBusca: filter?.period,
      SessaoPosIds: filter?.cashierIds?.map(item => item.sessionId),
      LocalId: localId,
      DataFinal: filter?.startDate,
      DataInicial: filter?.endDate
    }

    const response = await api.get(`/DashBoard/BuscarTaxaServico?${qs.stringify(params,{
      arrayFormat: "repeat",

    })}`);
    return response.data;
  };

  const getRankingProducts = async (localId: string,  filter?: IFilterValue, sort?: ISort): Promise<IGetRankingProductsResponse[]> => {

    const params = {
      PeriodoBusca: filter?.period,
      SessaoPosIds: filter?.cashierIds?.map(item => item.sessionId),
      LocalId: localId,
      "sort.Orientation": sort?.orientation,
      "sort.Type": sort?.type,
      DataFinal: filter?.startDate,
      DataInicial: filter?.endDate
    }

    const response = await api.get(`DashBoard/BuscarTopProdutosPorPeriodo?${qs.stringify(params,{
      arrayFormat: "repeat",

    })}`);
    return response.data;
  };

  const getRankingOperators = async (localId: string,  filter?: IFilterValue): Promise<IGetRankingOperatorsResponse[]> => {

    const params = {
      PeriodoBusca: filter?.period,
      SessaoPosIds: filter?.cashierIds?.map(item => item.sessionId),
      LocalId: localId,
      DataFinal: filter?.startDate,
      DataInicial: filter?.endDate
    }

    const response = await api.get(`DashBoard/BuscarTopOperadoresPorPeriodo?${qs.stringify(params,{
      arrayFormat: "repeat",

    })}`);
    return response.data;
  };
  const getRankingCustomers = async (localId: string,  filter?: IFilterValue): Promise<IGetRankingCustomersResponse[]> => {

    const params = {
      PeriodoBusca: filter?.period,
      SessaoPosIds: filter?.cashierIds?.map(item => item.sessionId),
      LocalId: localId,
      DataFinal: filter?.startDate,
      DataInicial: filter?.endDate
    }

    const response = await api.get(`DashBoard/BuscarTopClientesPorPeriodo?${qs.stringify(params,{
      arrayFormat: "repeat",

    })}`);
    return response.data;
  };

  const getSalesCategories = async (localId: string,  filter?: IFilterValue): Promise<IGetSalesCategoriesResponse[]> => {

    const params = {
      PeriodoBusca: filter?.period,
      SessaoPosIds: filter?.cashierIds?.map(item => item.sessionId),
      LocalId: localId,
      DataFinal: filter?.startDate,
      DataInicial: filter?.endDate
    }

    const response = await api.get(`DashBoard/BuscarVendasPorCategoriaNoPeriodo?${qs.stringify(params,{
      arrayFormat: "repeat",

    })}`);
    return response.data;
  };

  const getSalesDevices = async (localId: string,  filter?: IFilterValue): Promise<IGetSalesDevicesResponse[]> => {

    const params = {
      PeriodoBusca: filter?.period,
      SessaoPosIds: filter?.cashierIds?.map(item => item.sessionId),
      LocalId: localId,
      DataFinal: filter?.startDate,
      DataInicial: filter?.endDate
    }

    const response = await api.get(`DashBoard/BuscarDadosInformacoesPorDispositivoPorPeriodo?${qs.stringify(params,{
      arrayFormat: "repeat",

    })}`);
    return response.data;
  };

  return { getMyAccount, getAllMovements, getServiceRate, getSession, getRankingProducts, getRankingOperators, getSalesDevices, getRankingCustomers, getSalesCategories };
}

export default DashboardTicketApi;
