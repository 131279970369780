import React, { useEffect, useState } from 'react'
import styles from './AvgTicket.module.scss'
import { AttachMoney, CreditCard, Money } from '@mui/icons-material'
import { IParamsUseAllMovements } from '../../hooks/UseAllMovements'
import { IAllMovements } from '../../interfaces/IAllMovements'
import { moneyMaskNumber } from 'services/utils/Money'
import { CircularProgress } from '@mui/material'
import { IParamsUseServiceRate } from '../../hooks/UseServiceRate'
import { IServiceRate } from '../../interfaces/IServiceRate'

interface IAvgTicketProps {
    getAllMovements: (params?: IParamsUseAllMovements) => Promise<IAllMovements | undefined>
    getServiceRate: (params?: IParamsUseServiceRate) => Promise<IServiceRate | undefined>
    allMovements: IAllMovements | undefined
    serviceRate: IServiceRate | undefined
    isLoading: boolean | undefined
    
}

export const AvgTicket: React.FC<IAvgTicketProps> = ({ allMovements, getAllMovements, isLoading, getServiceRate, serviceRate }) => {

    useEffect(() => {
        getAllMovements();
    }, [getAllMovements])

    useEffect(() => {
        getServiceRate();
    }, [getServiceRate])

    return (
        <div id={styles.AvgTicket}>            
            <div>
                <p>Ticket médio </p>
                <span>Crédito, débito e dinheiro</span>
                {
                    isLoading ? 
                    <div className={styles.loading}><CircularProgress  size={16} color="inherit" /> </div>
                    :
                    <p className={styles.value}>{moneyMaskNumber(allMovements?.avgCashTicket)}</p>
                }
            </div>
            <div>
                <p>Taxa de serviço</p>
                {
                    isLoading ? 
                    <div className={styles.loading}><CircularProgress  size={16} color="inherit" /> </div>
                    :
                    <p className={styles.value}>{moneyMaskNumber(serviceRate?.value)}</p>
                }
            </div>   
        </div>
    )
}
