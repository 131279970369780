import React, { FC, useCallback, useMemo, useState } from 'react'
import styles from './ImportProductSheet.module.scss'
import { useCSVReader } from 'react-papaparse';
import { RightDrawer } from 'components/layout/presentation/rightDrawer/RightDrawer';
import { Button, Icon, IconButton } from '@material-ui/core';
import ButtonsForm from '../ui/ButtonsForm';
import { moneyMaskNumber, moneyToFloat } from 'services/utils/Money';
import { v4 } from 'uuid';
import { useUi } from 'contexts/userInterface/UserInterfaceContext';
import { AlertModal } from '../alertModal/AlertModal';
import { VerticalAlignTopOutlined } from '@material-ui/icons';
import { InputMoney } from '../ui/InputMoney';
import { InputLabel } from '../ui/InputLabel';

export interface IDetectedProductValue {
    categoryName: string,
    productName: string,
    value: string,
    NCM: string,
    CEST: string,
    CFOP: string,
    barCode: string,
    internalCode: string,
    productType: string,
    productId: string,
    externalCode: string,
    error?: string,
}

export interface IImportProductSheetProps {
    //propertys
    onSubmit: (values: IDetectedProductValue[]) => Promise<void>
}
const ImportProductSheet: FC<IImportProductSheetProps> = ({ onSubmit }) => {
    const { CSVReader } = useCSVReader();
    const [inportDrawer, setInportDrawer] = useState(false);
    const [detectedProducts, setDetectedProducts] = useState<IDetectedProductValue[]>();
    const [openAlert, setOpenAlert] = useState(false);

    const { toast } = useUi();

    const onCloseHandle = useCallback(() => {
        setInportDrawer(false);
        setDetectedProducts(undefined);
    }, [])

    const onSubmitHandle = useCallback(async () => {
        try {
            if (detectedProducts) {
                await onSubmit(detectedProducts.map(item => ({ ...item, value: moneyToFloat(item.value ?? '').toFixed(2) })).filter(item => item.error === undefined))
                setInportDrawer(false);
                setDetectedProducts(undefined);
                setOpenAlert(false);
            }
        } catch {
            toast("Não foi possivel importar produtos", `error`)
        }
    }, [detectedProducts, onSubmit, toast])

    const onConfirmHandle = useCallback(async () => {
        setOpenAlert(true);
    }, [])

    const validateItem = useCallback((item: IDetectedProductValue, index: number) => {
        if (item.categoryName === "") {
            item.error = (item.error ?? "") + "Categoria não informada; ";
        }
        if (item.productName === "") {
            item.error = (item.error ?? "") + "Nome do produto não informado; ";
        }
        if (!item.value) {
            item.error = (item.error ?? "") + "Valor não informado; ";
        }
    }, [])

    const onUploadCsvHandle = useCallback((results: { data: string[][] }) => {
        let products: IDetectedProductValue[] = [];
        results.data.forEach((item, index) => {
            if (index === 0) return;

            products.push({
                categoryName: item[0],
                productName: item[1],
                value: Number(item[2]).toFixed(2),
                NCM: item[3],
                CEST: item[4],
                CFOP: item[5],
                barCode: item[6],
                internalCode: item[7],
                productId: v4(),
                externalCode: item[9],
                productType: item[10],
            })
        })

        products.forEach((item, index) => validateItem(item, index + 2))
        setDetectedProducts(products);
    }, [validateItem])


    const onPressDeleteHandle = useCallback((index: number) => {
        let products = [...detectedProducts ?? []];
        products.splice(index, 1);
        setDetectedProducts(products);
    }, [detectedProducts]);


    const onChangeValueHandle = useCallback((value: string, index: number) => {
        let products = [...detectedProducts ?? []];
        products[index].value = value;
        setDetectedProducts(products);
    }, [detectedProducts]);

    const onChangeNameHandle = useCallback((value: string, index: number) => {
        let products = [...detectedProducts ?? []];
        products[index].productName = value;
        setDetectedProducts(products);
    }, [detectedProducts]);

    const productList = useMemo(() => detectedProducts?.map((item, index) => {
        return (
            <div className={styles.detectedProductsItem} style={{ backgroundColor: item.error ? '#ffe6e6' : '#fff' }} key={item.productId}>
            <div className={styles.category}>{item.categoryName}</div>
            <div className={styles.title}>
                <div className={styles.name}>
                    <InputLabel
                        // label='Nome do produto'
                        variant='outlined' size='small'
                        value={item.productName}
                        onChange={(ev) => { onChangeNameHandle(ev.target.value, index) }}
                    />
                </div>
                <div className={styles.value}>
                    <InputMoney
                        // label='Valor'
                        value={moneyToFloat(item.value ?? '')}
                        onChange={(_, value) => onChangeValueHandle(moneyMaskNumber(value), index)}
                    /></div>
            </div>
            <div className={styles.description}>
                NCM:{item.NCM},
                CEST:{item.CEST},
                CFOP:{item.CFOP},
                codigo de barras:{item.barCode},
                codigo interno:{item.internalCode},
                {/* productId:{item.productId}, */}
                codigo de integração:{item.externalCode}
                tipo: {Number(item.productType)}
            </div>
            {item.error && <div className={styles.error}>{item.error}</div>}
            <IconButton
                size='small'
                style={{ position: 'absolute', right: 0, top: 0 }}
                onClick={() => onPressDeleteHandle(index)}
            >
                <Icon fontSize='small'>close</Icon>
            </IconButton>
        </div>
        )
    }
), [detectedProducts, onChangeNameHandle, onChangeValueHandle, onPressDeleteHandle]);

    const erroQuantity = useMemo(() => detectedProducts?.filter(item => item.error).length, [detectedProducts]);
    const alertText = useMemo(() => detectedProducts && erroQuantity ? <div>Há <b>{erroQuantity} produtos com erros</b>, serão importados <b>{detectedProducts.length - erroQuantity} de {detectedProducts.length}</b> produtos.</div> : `Serão importados ${detectedProducts?.length} produtos`, [detectedProducts, erroQuantity])

    return (
        <div id={styles.importProductSheet}>
            <Button
                variant='text'
                onClick={() => setInportDrawer(true)}
                className={styles.importButton}
            >
                Importar <VerticalAlignTopOutlined />
            </Button>
            <RightDrawer title={<div>Importar <b>produtos</b></div>} open={inportDrawer} onClose={onCloseHandle}>
                <div id={styles.importDrawer}>
                    <div className={styles.info}>
                        <Icon color='primary'>info_outlined</Icon> 
                        <p><strong>Palavras com acento</strong> podem desconfigurar. Caso queira, use o bloco de notas para editar.</p>
                    </div>
                    Selecione o arquivo que deseja importar com a listagem de produtos:

                    <CSVReader
                        onUploadAccepted={onUploadCsvHandle}
                    >
                        {({
                            getRootProps,
                            acceptedFile,
                            ProgressBar,
                            getRemoveFileProps,
                        }: any) => (
                            <div className={styles.csvReader} >
                                <div className={styles.buttons}>
                                    <Button fullWidth type='button' color='secondary' variant='outlined' {...getRootProps()} className={styles.browseFile}>
                                        <Icon>file_upload</Icon>   {!acceptedFile ? 'Escolher arquivo' : 'Escolher outro arquivo'}
                                    </Button>
                                </div>
                                <ProgressBar className={styles.progressBarBackgroundColor} />
                                {acceptedFile && <div className={styles.acceptedFile}>
                                    <div>
                                        {acceptedFile.name}
                                    </div>
                                    <Button {...getRemoveFileProps()} variant='outlined' color='secondary' size='small' className={styles.remove}>
                                        <Icon>close</Icon>
                                    </Button>
                                </div>}
                            </div >
                        )}

                    </CSVReader>

                    O formato do arquivo deve ser CSV, baixe a planilha de exemplo:
                    <a download={"ModeloImportacaoProduto.csv"} target='_blank' href="/download/ModeloImportacaoProduto.csv"><Button color='primary'>Baixar modelo de planilha</Button></a>

                    <div className={styles.detectedProductsList} >
                        {productList}
                    </div>
                    <div className={styles.buttons}>
                        <ButtonsForm onCancel={onCloseHandle} onSubmit={onConfirmHandle} />
                    </div>
                </div>
            </RightDrawer>
            <AlertModal
                open={openAlert}
                onClose={() => setOpenAlert(false)}
                title="Importação de produtos"
                text={alertText}
                confirmLabel={'Importar'}
                cancelLabel={'Cancelar'}
                onConfirm={onSubmitHandle} >
            </AlertModal>

        </div >
    )
}
export default ImportProductSheet
