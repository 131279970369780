import Button from "components/ui/Button/Button";
import React, { FC, useCallback, useState } from "react";
import style from "./EventCard.module.scss";
import UseEventCard from "./UseEventCard";
import { IconButton } from "@material-ui/core";
import { EventStatusEnum, IEvents, OriginType } from "modules/events/domain/models/IEvents";
import guestIcon from "../../../../assets/icons/guests_icon_outlined.svg";
import listIcon from "../../../../assets/icons/list_icon_outlined.svg";
import { Icon } from '@material-ui/core';
import Utils from "services/utils/Utils";
import { EventLink } from "../eventLink/EventLink";
import { useAuth } from "modules/auth/presentation/context/AuthContext";

export interface IEventCardResume {
  eventId: string,
  billing: number;
  averageTicketConsume: number;
  averageTicketIngress: number;
}

export interface IEventCardProps {
  event: IEvents;
  resume?: IEventCardResume
  integrated: boolean
}
const EventCard: FC<IEventCardProps> = ({ event, resume, integrated }) => {
  const {
    goToEventList,
    goToPeopleList,
    adminAccess,
    goToManageEvent,
    linkOpened,
    setLinkOpened,
    getOrganizerEvents,
    organizerEvents,
    onIntegrateMeepTickets
  } = UseEventCard();
  const [hasLink, setHasLink] = useState(!!event.ioticketEventId)

  const { hasAccessRole } = useAuth();

  const isHappening = (evt: IEvents): EventStatusEnum => {
    const now = new Date();
    const begin = new Date(evt.openingDates[0].startDate);
    const end = new Date(evt.openingDates[0].endDate);
    return now > begin && now <= end
      ? EventStatusEnum.HAPPENING
      : now > end
        ? EventStatusEnum.CLOSED
        : EventStatusEnum.SCHEDULED;
  };

  const happeningComponent = useCallback((evt: IEvents) => {
    const eventStatus = isHappening(evt);
    switch (eventStatus) {
      case EventStatusEnum.SCHEDULED:
        return (
          <div className={style.status}>
            <div className={style.icon} style={{ background: 'var(--positive)' }}></div>
            <p>Programado</p>
          </div>
        );
      case EventStatusEnum.CLOSED:
        return (
          <div className={style.status}>
            <div className={style.icon} style={{ background: 'var(--placeholder)' }}></div>
            <p>Encerrado</p>
          </div>
        );
      case EventStatusEnum.HAPPENING:
        return (
          <div className={style.status}>
            <div className={style.icon} style={{ background: 'var(--secondary-dark)' }}></div>
            <p>Acontecendo agora</p>
          </div>
        );
    }
  }, []);

  const onCloseLink = useCallback((success?: boolean) => {
    if (success)   {
      setHasLink(true)
    }
    setLinkOpened(false)
  }, [setLinkOpened])

  return (
    <div id={style.EventCard}>
      <div className={style.img}>
        <img src={event.imageUrl} alt="event_image" />
      </div>
      <div className={style.content}>
        <div className={style.title}>
          <div className={style.start}>
            <h2>
              <b>{event.name} - </b>
              {event?.openingDates[0].startDate && Utils.toDateAndTime(event?.openingDates[0].startDate)}
              {event?.openingDates[0].endDate ? ` até ${Utils.toDateAndTime(event?.openingDates[0].endDate)}` : ""}
            </h2>
          </div>
          <div className={style.end}>
            {happeningComponent(event)}
            <IconButton
              onClick={() => goToPeopleList(event.id, event.name)}
              title="Convidados"
              className={style.iconButton}
            >
              <img src={guestIcon} alt="guests_icon" />
            </IconButton>
            <IconButton
              className={style.iconButton}
              title="Lista de convidados"
              onClick={() => goToEventList(event.id, event.name)}
            >
              <img src={listIcon} alt="list_icon" />
            </IconButton>
          </div>
        </div>
        <div className={style.description}>
          <div className={style.body}>
            <div className={style.guests}>
              {resume && <div className={style.resumeValues}>
                {hasAccessRole("DashboardPosPago") && <div className={style.item}>
                  <label>Faturamento</label><b>{formatCurrency(resume.billing)}</b>
                </div>}
                <div className={style.item}>
                  <label>Ticket Médio - Consumo</label><b>{formatCurrency(resume.averageTicketConsume)}</b>
                </div>
                <div className={style.item}>
                  <label>Ticket Médio - Ingresso</label><b>{formatCurrency(resume.averageTicketIngress)}</b>
                </div>
              </div>}
              {event.originType === OriginType.Portal && (
                <div className={style.resumeValues}>
                  <div className={style.item}>
                    <label>Convidados: </label><b>{event.guestCount}</b>
                  </div>
                  <div className={style.item}>
                    <label>Ingressos vendidos: </label><b>{event.totalGuestSales + event.totalSalesNotInvited}</b>
                  </div>
                  <div className={style.item}>
                    <label>Check-in realizados: </label><b>{event.totalGuestCheckin + event.totalCheckinNotInvitedCount}</b>
                  </div>
                </div>
              )}
            </div>
            <div className={style.ioTicketsEvent}>
              {
                integrated && <>
                  <button type={'button'} onClick={() => setLinkOpened(true)}>
                    <div className={style.icon} style={{ background: hasLink ? 'var(--positive)' : 'var(--placeholder)' }}></div>
                    <p>
                      {
                        hasLink ? 'Vinculado à Meep Tickets' : 'Não Vinculado à Meep Tickets'
                      }
                    </p>
                    <Icon className={style.rightIcon}>chevron_right</Icon>
                  </button>
                </>
              }
            </div>
          </div>
          <div className={style.manageButton}>
            <Button
              variant="outlined"
              className={style.manager}
              fullWidth={false}
              onClick={() => goToManageEvent(event.id)}
              disabled={!adminAccess}
            >
              Entrar
            </Button>
          </div>
        </div>
      </div>
      <EventLink
        event={event}
        events={organizerEvents}
        getData={getOrganizerEvents}
        onSubmit={onIntegrateMeepTickets}
        open={linkOpened}
        onClose={onCloseLink}
      />
    </div>
  );
};
export default EventCard;

const formatCurrency = (value: number) => new Intl.NumberFormat("pt-BR", {
  style: "currency",
  currency: "BRL",
}).format(value);
