import React, { useCallback, useEffect, useMemo, useState } from "react";
import CrmHeader from "./crmUserContainer/CrmHeader";
import styles from "./CrmList.module.scss";
import CrmUserItem from "./crmUserContainer/CrmUserItem";
import { ICrmColumnsConfig, ICrmCustomerEventsColumnsConfig } from "./interfaces/IColumnsConfig";
import { ICrmCustomerData } from "../../intefaces/CrmUserData";
import { Popover } from "@material-ui/core";
import { Pagination, Skeleton } from "@mui/material";
import ColumnDragAndDropSelect from "../crmFilterForm/components/columnDragAndDropSelect/ColumnDragAndDropSelect";
import { ICustomerEventsListData } from "../../intefaces/ICustomerEventData";
import UseDimension from "components/dimension/UseDimension";
import ReactDOM from "react-dom";
import GetTrackInteractionsUseCase from "modules/crm/application/useCases/GetTrackInteractionsUseCase";
import { useLocal } from "modules/local/presentation/context/LocalContext";
import TrackInteractionsApi from "services/api/crm/TrackInteractionsApi";
import { usePlans } from "modules/plans/presentation/hooks/PlansContext";
import { FeatureTypeEnum } from "modules/plans/presentation/interfaces/FeatureTypeEnum";
import { IFidelityPlans } from "modules/fidelity/presentation/interfaces/IFidelityPlans";
import { SystemIdentifierEnum } from "modules/gestaoVendas/domain/enum/GetSegmentosEnum";

interface IListCrm {
  totalPages: number;
  data?: ICrmCustomerData[];
}

interface ICrmPropsData {
  list?: IListCrm;
  fidelityList: IFidelityPlans[];
  isLoading?: boolean;
  pagination?: { page?: number; pageSize?: number };
  onChangePagination: (value: { page?: number; pageSize?: number }) => void;
  onClickSelectAll: (value: boolean) => void;
  getCustomerEvents: (customerId: string, pagination: { page: number; pageSize: number; }) => Promise<ICustomerEventsListData>;
}

const trackInteractionsService = TrackInteractionsApi();

const freeColumns: ICrmColumnsConfig[] = [
  {
    property: "name",
    title: "Nome",
    style: { minWidth: 200 }
  },
  {
    property: "gender",
    title: "Sexo",
    style: { minWidth: 140, justifyContent: `center` }

  },
  {
    property: "age",
    title: "Idade",
    style: { minWidth: 50 }
  },
  {
    property: "customerFidelityPlan",
    title: "Nível de fidelidade",
    style: { minWidth: 110 },
    isPremium: true
  },
  {
    property: "surveyHealth",
    title: "NPS",
    style: { minWidth: 110 },
    isPremium: true
  },
];

const CrmList: React.FC<ICrmPropsData> = ({
  list,
  isLoading,
  fidelityList,
  onChangePagination,
  pagination,
  onClickSelectAll,
  getCustomerEvents
}) => {

  const { dimensions } = UseDimension()
  const { currentLocal } = useLocal();
  const { checkPermission } = usePlans();

  const [colunms, setColunms] = useState<ICrmColumnsConfig[]>([
    {
      property: "name",
      title: "Nome",
      style: { minWidth: 100 }
    },
    {
      property: "surveyHealth",
      title: "Saúde",
      style: { minWidth: 50, maxWidth: 50, justifyContent: `center`, flex: 0 }
    },
    {
      property: "customerFidelityPlan",
      title: "Nível de fidelidade",
      style: { minWidth: 140, maxWidth: 140, flex: 0 }

    },
    {
      property: "gender",
      title: "Sexo",
      style: { minWidth: 140, maxWidth: 140, justifyContent: `center`, flex: 0 }

    },
    {
      property: "age",
      title: "Idade",
      style: { minWidth: 50, maxWidth: 50, flex: 0, }
    },
    {
      property: "email",
      title: "E-Mail",
      style: {
        minWidth: 200,
        maxWidth: 200,
        overflow: "hidden",
      }
    },
    {
      property: "phoneNumber",
      title: "Celular",
      style: { minWidth: 125, maxWidth: 125, flex: 0 }
    },
    {
      property: "birthday",
      title: "Nascimento",
      style: { minWidth: 105, maxWidth: 105, flex: 0 }
      // hide: true,
    },
    {
      property: "document",
      title: "CPF",
      style: { minWidth: 110, maxWidth: 110, flex: 0, }
    },
  ]);

  const [colunmsClient, setColunmsClient] = useState<ICrmCustomerEventsColumnsConfig[]>([
    {
      title: "Data",
      property: "date",
      hide: false
    },
    {
      title: "Nome",
      property: "name",
      hide: false
    },
    {
      title: "Categoria",
      property: "categoryName",
      hide: false
    },
    {
      title: "Categoria de ingresso",
      property: "ticketCategoryName",
      hide: false
    },
    {
      title: "Total consumido",
      property: "totalConsumed",
      hide: false
    },
    {
      title: "Total de ingressos comprados",
      property: "totalTicketPurchased",
      hide: false
    },
  ]);

  const [colunmsSaasClient, setColunmsSaasClient] = useState<ICrmCustomerEventsColumnsConfig[]>([
    {
      title: "Data",
      property: "date",
      hide: false
    },
    {
      title: "Nome",
      property: "name",
      hide: false
    },
    {
      title: "Total consumido",
      property: "totalConsumed",
      hide: false
    },
  ]);

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement>();
  const [selectedUserItemData, setSelectedUserItemData] = useState<ICrmCustomerData>()

  const [npsOpen, setNpsOpen] = useState(false);

  const columnsLimit = useCallback((_columns: ICrmColumnsConfig[], width: number) => {

    if (width > 0) {
      const calcMax = (width: number) => {
        if (width <= 600) {
          return 1
        } else if (width <= 800) {
          return 2
        } else if (width <= 1024) {
          return 4
        } else {
          return 5
        }
      }
      const max = calcMax(width)
      let count = 0;
      const newValue = _columns.map((item, index) => {
        if (!item.hide) {
          count++
          if ((count) > max) {
            return { ...item, hide: true }
          } else {
            return item;
          }
        } else {
          return item;
        }
      })
      
      return newValue
    } else {
      return _columns
    }
  }, [])

  useEffect(() => {
    setColunms(prev => columnsLimit(prev, dimensions.width))
  }, [columnsLimit, dimensions.width])

  const onChangePageHandle = useCallback(
    (page: number) => {
      onChangePagination({ ...pagination, page });
    },
    [onChangePagination, pagination]
  );

  const onChangeColumns = useCallback((_columns: ICrmColumnsConfig[]) => {
    setColunms(columnsLimit([..._columns], dimensions.width));
  }, [columnsLimit, dimensions.width]);

  const onChangeColumnsClients = useCallback((_columns: ICrmCustomerEventsColumnsConfig[]) => {
    setColunmsClient([..._columns]);
    setColunmsSaasClient([..._columns]);
  }, []);

  const handleClose = () => {
    setAnchorEl(undefined);
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const Overlay = () => {
    return ReactDOM.createPortal(
      <div className={styles.overlay} />,
      document.getElementsByTagName(`body`)![0]
    );
  }

  const GetTrackInteractions = useCallback(async (document: string) => {
      const response = await GetTrackInteractionsUseCase(trackInteractionsService, currentLocal!.id, document)
      return response
  }, [currentLocal])

  const List = useMemo(
    () => (
      <div className={styles.list}>
        <CrmHeader
          columns={!checkPermission(FeatureTypeEnum.CRM) ? freeColumns : colunms}
          onClickSelectAll={onClickSelectAll}
          onClickMenu={handleClick}
        />
        {isLoading ? (
          <>
            <Skeleton variant="rectangular" width="100%" height={60} />
            <Skeleton variant="rectangular" width="100%" height={60} />
            <Skeleton variant="rectangular" width="100%" height={60} />
            <Skeleton variant="rectangular" width="100%" height={60} />
            <Skeleton variant="rectangular" width="100%" height={60} />
          </>
        ) : (
          !!list?.data?.length
            ? list?.data?.map((item: ICrmCustomerData, index) => item.name !== "Não informado" ? (
              <CrmUserItem
                key={index}
                userItemData={item}
                fidelityList={fidelityList}
                columns={!checkPermission(FeatureTypeEnum.CRM) ? freeColumns : colunms}
                columnsCustomerEvent={currentLocal?.systemIdentifier !== SystemIdentifierEnum.Saas ? colunmsClient : colunmsSaasClient}
                getCustomerEvents={getCustomerEvents}
                onOpenNps={() => { setNpsOpen(true); setSelectedUserItemData(item) }}
              />
            ) : undefined)
            : (
              <div className={styles.emptyContainer}>
                <img src="/assets/icon/empty-box.svg" alt="" />
                <p>
                  Ops, parece que você <b>ainda não tem informações</b><br /> para serem mostradas aqui!
                </p>
              </div>
            )
          )
        }
      </div>
    ),
    [checkPermission, colunms, colunmsClient, colunmsSaasClient, currentLocal?.systemIdentifier, fidelityList, getCustomerEvents, isLoading, list?.data, onClickSelectAll]
  );

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  return (
    <div className={styles.container}>
      {/* <Backdrop  open={open}> */}
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <h1 className={styles.title}>Colunas</h1>
          <ColumnDragAndDropSelect
            columns={colunms}
            onChange={onChangeColumns}
          />
          <h1 className={styles.title}>Dados do cliente</h1>
          <ColumnDragAndDropSelect
            columns={currentLocal?.systemIdentifier !== SystemIdentifierEnum.Saas ? colunmsClient : colunmsSaasClient}
            onChange={onChangeColumnsClients}
          />
          {/* <div className={styles.contentButtonConfirm}>
            <button className={styles.confirmButton}>Confirmar</button>
          </div> */}
        </Popover>
      {/* </Backdrop> */}
      {List}
      <Pagination
        variant="outlined"
        shape="rounded"
        style={{ alignSelf: dimensions.width > 500 ? "flex-end" : "center" }}
        sx={{
            '.MuiPaginationItem-root': {
                borderColor: '#BDBDBD',
                color: '#001537',
            },
            '.Mui-selected': {
                fontWeight: 'bold',
                borderColor: '#001537',
            },
        }}
        className={styles.contentPagination}
        count={list?.totalPages}
        page={pagination?.page ?? 1}
        onChange={(_, value) => onChangePageHandle(value)}
      />
    </div >
  );
};

export default CrmList;
