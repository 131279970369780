import styles from "./TransactionItemMobile.module.scss";
import { ITransactionsRecords } from "modules/transactionReport/domain/dto/IGetTransactionsResponse";
import { format } from "date-fns";
import { KeyboardArrowRightRounded } from "@material-ui/icons";

interface ITransactionItemMobileProps {
  item: ITransactionsRecords;
  handleFindPayment: (type: number) => string;
  setOpenDetailsSidesheet: React.Dispatch<React.SetStateAction<string>>;
  hasDetailAccess: boolean;
}

const TransactionItemMobile = ({
  item,
  handleFindPayment,
  setOpenDetailsSidesheet,
  hasDetailAccess,
}: ITransactionItemMobileProps) => {
  return (
    <div id={styles.TransactionItemMobile}>
      <div className={styles.item}>
        <span className={styles.title}>Data/hora transação</span>
        <span>{item.TransactionDate ? format(new Date(item.TransactionDate + "Z"), "dd/MM/yyyy' - 'HH:mm") : "-"}</span>
      </div>
      <div className={styles.item}>
        <span className={styles.title}>Equipamento</span>
        <span>{item.Equipment ?? "-"}</span>
      </div>
      <div className={styles.item}>
        <span className={styles.title}>Serial</span>
        <span>{item.Serial ?? "-"}</span>
      </div>
      <div className={styles.item}>
        <span className={styles.title}>Cartão</span>
        <span>{item.FinalCard ?? "-"}</span>
      </div>
      <div className={styles.item}>
        <span className={styles.title}>Bandeira</span>
        <span>{item.PaymentCardFlagDescription ?? "-"}</span>
      </div>
      <div className={styles.item}>
        <span className={styles.title}>Tipo de pagamento</span>
        <span>{handleFindPayment(item.PaymentType)}</span>
      </div>
      <div className={styles.item}>
        <span className={styles.title}>% da taxa</span>
        <span>{item.PercentFee ? `${item.PercentFee}%` : "-"}</span>
      </div>
      <div className={styles.item}>
        <span className={styles.title}>Taxa</span>
        <span>{item.FeeValue ? `R$ ${item.FeeValue.toString().replace(".", ",")}` : "-"}</span>
      </div>
      <div className={styles.item}>
        <span className={styles.title}>Recebimento</span>
        <span>{item.ReceiptDate ? format(new Date(item.ReceiptDate + "Z"), "dd/MM/yyyy") : "-"}</span>
      </div>
      <div className={styles.item}>
        <span className={styles.title}>Antecipado</span>
        <span>{item.Anticipation === "0" ? "Não" : "Sim"}</span>
      </div>
      <div className={styles.item}>
        <span className={styles.title}>Valor bruto</span>
        <span>{item.GrossValue ? `R$ ${item.GrossValue.toString().replace(".", ",")}` : "-"}</span>
      </div>
      <div className={styles.item}>
        <span className={styles.title}>Valor líquido</span>
        <span>{item.NetValue ? `R$ ${item.NetValue.toString().replace(".", ",")}` : "-"}</span>
      </div>
      <div className={styles.item}>
        <span className={styles.title}/>
        <div 
          className={styles.details}
          onClick={() => hasDetailAccess ? setOpenDetailsSidesheet(item.Id) : null}
          style={hasDetailAccess ? {} : { cursor: "default", color: "#5F5F5F" }}
        >
          <span>{item.StatusDescription}</span>
          <KeyboardArrowRightRounded />
        </div>
      </div>
    </div>
  );
};
export default TransactionItemMobile;
