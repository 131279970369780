import { useLocal } from "modules/local/presentation/context/LocalContext";
import { useCallback, useState } from "react";
import { IFilterValue } from "../interfaces/IFilter";
import { IRankingCustomers } from "../interfaces/IRankingCustomers";
import GeneralDashboardApi from "services/api/generalDashboard/GeneralDashboardApi";
import GetRankingOperatorsUseCase from "modules/generalDashboard/application/useCases/GetRankingOperatorsUseCase";


export interface IParamsUseRankingCustomers {
  localId?: string,
  sessionId?: string,
}

const UseRankingCustomers = (filter: IFilterValue | undefined) => {

  const [isLoading, setIsLoading] = useState<boolean>();
  const [rankingCustomers, setRankingCustomers] = useState<IRankingCustomers[]>()
  const { currentLocal } = useLocal();

  const getRankingCustomers = useCallback(
    async (params?: IParamsUseRankingCustomers) => {
      try {
        const serviceGeneral = GeneralDashboardApi();
        setIsLoading(true);
        if (currentLocal) {
          const response = await GetRankingOperatorsUseCase(serviceGeneral,
            currentLocal.id,
            {
              ...filter,
            },
          );

          setRankingCustomers(response);

          return response
        }
      } finally {
        setIsLoading(false);
      }
    },
    [currentLocal, filter]
  );


  return {
    rankingCustomers,
    isLoading,
    getRankingCustomers,
    setRankingCustomers,
  };
};

export default UseRankingCustomers;
