import { useBreadcumbs } from "components/breadcumbs/BreadcumbsContext";
import Layout from "components/layout/presentation/Layout";
import PaymentLinkPage from "modules/paymentLink/presentation/pages/PaymentLinkPage";
import { FC, useEffect } from "react";

const PaymentLink: FC = () => {
  const { updateRouters } = useBreadcumbs();

  useEffect(() => {
    updateRouters([
      {
        title: "Financeiro",
      },
      {
        label: "Financeiro",
        title: "Link de pagamento",
      }
    ]);

    return () => {
    updateRouters([]);
    };
  }, [updateRouters])
  

  return (
    <Layout>
      <PaymentLinkPage />
    </Layout>
  );
};
export default PaymentLink;
