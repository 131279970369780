import { useUi } from "contexts/userInterface/UserInterfaceContext";
import { useLocal } from "modules/local/presentation/context/LocalContext";
import { useCallback, useEffect, useState } from "react";
import { ProductsApi } from "services/api/products/ProductsApi";
import ChangeDefaultPrinterUseCase from "../application/useCases/changeDefaultPrinterUseCase/ChangeDefaultPrinterUseCase";
import ChangeStockControlUseCase from "../application/useCases/changeStockControlUseCase/ChangeStockControlUseCase";
import ChangeStoreUseCase from "../application/useCases/changeStoreUseCase/ChangeStoreUseCase";
import ChangeTaxDataUseCase from "../application/useCases/changeTaxDataUseCase/ChangeTaxDataUseCase";
import DeleteProductsUseCase from "../application/useCases/deleteProductsUseCase/DeleteProductsUseCase";
import GetImpressoraPadraoListUseCase from "../application/useCases/getImpressoraPadraoListUseCase/GetImpressoraPadraoListUseCase";
import GetLojaListUseCase from "../application/useCases/getLojaListUseCase/GetLojaListUseCase";
import { ImpressoraPadraoItem } from "../domain/models/product/ImpressoraPadrao";
import { LojaItem } from "../domain/models/product/Loja";
import { IProductActionEnum } from "./interfaces/IProductActionEnum";
import { IProductFilter } from "./interfaces/IProductFilter";
import { IProductItem } from "./interfaces/IProductListData";

const ServiceProduct = ProductsApi();

const UseProductActions = (getData: (filter: IProductFilter) => Promise<void>, filter: IProductFilter) => {
    const { currentLocal } = useLocal();
    const [changePrinterModalOpen, setChangePrinterModalOpen] = useState(false)
    const [changeStockControlModalOpen, setChangeStockControlModalOpen] = useState(false)
    const [changeStoreModalOpen, setChangeStoreModalOpen] = useState(false)
    const [changeTaxDataModalOpen, setChangeTaxDataModalOpen] = useState(false)
    const [deleteProductsModalOpen, setDeleteProductsModalOpen] = useState(false)
    const [selectedProducts, setSelectedProducts] = useState<IProductItem[]>([])

    const [printers, setPrinters] = useState<ImpressoraPadraoItem[]>([])
    const [stores, setStores] = useState<LojaItem[]>([])
    
    const { toast, showLoading, hideLoading } = useUi()

    const onClickAction = useCallback(async (actionType: IProductActionEnum, _selectedProducts?: IProductItem[]) => {
        if (!_selectedProducts?.length) {
            toast("Selecione ao menos um produto", "error");
            return
        } 
        
        setSelectedProducts(_selectedProducts!)
        changeModalOpen(actionType, true)
    }, [toast])

    useEffect(() => {
        if (currentLocal) {
            GetImpressoraPadraoListUseCase(ServiceProduct, currentLocal.id).then(resp => setPrinters(resp.records))
            GetLojaListUseCase(ServiceProduct, currentLocal.id).then(resp => setStores(resp.records))
        }
    }, [currentLocal])

    const changeModalOpen = (actionType: IProductActionEnum, open: boolean) => {
        switch (actionType) {
            case IProductActionEnum.CHANGE_PRINTER:
                setChangePrinterModalOpen(open)
                break;
            case IProductActionEnum.CHANGE_STOCK_CONTROL:
                setChangeStockControlModalOpen(open)
                break;
            case IProductActionEnum.CHANGE_STORE:
                setChangeStoreModalOpen(open)
                break;
            case IProductActionEnum.CHANGE_TAX_DATA:
                setChangeTaxDataModalOpen(open)
                break;
            case IProductActionEnum.DELETE_PRODUCTS:
                setDeleteProductsModalOpen(open)
                break;
        }
    }

    const onCloseModals = useCallback(() => {
        setChangePrinterModalOpen(false)
        setChangeStockControlModalOpen(false)
        setChangeStoreModalOpen(false)
        setChangeTaxDataModalOpen(false)
        setDeleteProductsModalOpen(false)
        setSelectedProducts([])
    }, [])

    const onChangePrinter = useCallback(async (printerId?: string) => {
        showLoading()
        try {
            await ChangeDefaultPrinterUseCase(ServiceProduct, selectedProducts.map(x => x.id), printerId)
            onCloseModals()
            getData(filter)
            toast("Ação executada com sucesso", 'success')
        } catch {
            toast("Ocorreu um erro com a operação. Tente novamente", 'error')
        } finally {
            hideLoading()
        }
    }, [showLoading, selectedProducts, onCloseModals, getData, filter, hideLoading, toast])

    const onChangeStore = useCallback(async (storeId: string) => {
        showLoading()
        try {
            await ChangeStoreUseCase(ServiceProduct, selectedProducts.map(x => x.id), storeId)
            onCloseModals()
            getData(filter)
            toast("Ação executada com sucesso", 'success')
        } catch {
            toast("Ocorreu um erro com a operação. Tente novamente", 'error')
        } finally {
            hideLoading()
        }
    }, [showLoading, selectedProducts, onCloseModals, getData, filter, hideLoading, toast])

    const onChangeStockControl = useCallback(async (stockControl: boolean) => {
        showLoading()
        try {
            await ChangeStockControlUseCase(ServiceProduct, selectedProducts.map(x => x.id), stockControl)
            onCloseModals()
            getData(filter)
            toast("Ação executada com sucesso", 'success')
        } catch {
            toast("Ocorreu um erro com a operação. Tente novamente", 'error')
        } finally {
            hideLoading()
        }
    }, [showLoading, selectedProducts, onCloseModals, getData, filter, hideLoading, toast])

    const onChangeTaxData = useCallback(async (taxData: { cfop: string, cest: string, ncm: string }) => {
        showLoading()
        try {
            await ChangeTaxDataUseCase(ServiceProduct, selectedProducts.map(x => x.id), taxData)
            onCloseModals()
            getData(filter)
            toast("Ação executada com sucesso", 'success')
        } catch {
            toast("Ocorreu um erro com a operação. Tente novamente", 'error')
        } finally {
            hideLoading()
        }
    }, [showLoading, selectedProducts, onCloseModals, getData, filter, hideLoading, toast])

    const onDeleteProducts = useCallback(async () => {
        showLoading()
        try {
            await DeleteProductsUseCase(ServiceProduct, selectedProducts.map(x => x.id))
            onCloseModals()
            getData(filter)
            toast("Ação executada com sucesso", 'success')
        } catch {
            toast("Ocorreu um erro com a operação. Tente novamente", 'error')
        } finally {
            hideLoading()
        }
    }, [showLoading, selectedProducts, onCloseModals, getData, filter, hideLoading, toast])

    return ({
        onClickAction,
        changePrinterModalOpen,
        changeStockControlModalOpen,
        changeStoreModalOpen,
        changeTaxDataModalOpen,
        deleteProductsModalOpen,
        onCloseModals,
        onChangePrinter,
        onChangeStore,
        onChangeStockControl,
        onChangeTaxData,
        onDeleteProducts,
        printers,
        stores,
    })
}

export default UseProductActions