import { useBreadcumbs } from 'components/breadcumbs/BreadcumbsContext'
import React, { FC, useCallback, useEffect, useMemo } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { PushApprovalPage } from 'modules/admin/pushApproval/presentation/pages/PushApprovalPage'
import { UseMenuList } from 'components/layout/presentation/menu/UseMenuList'
import { IframePage } from 'modules/oldPortal/presentation/components/iframe/IframePage'
import MenuConfigPage from 'modules/admin/menuConfig/presentation/pages/MenuConfigPage'
import PosSolicitationsPage from 'modules/admin/posSolicitations/presentation/PosSolicitationsPage'

export interface IAdminPageProps {
    //propertys
}

export interface IMenuItem {
    title: string,
    tab: string,
    role?: string,
    component?: React.ReactNode;
}

const adminRoutes = [
    {
        route: "/private/admin/aprovacao",
        component: <PushApprovalPage />,

    },
    {
        route: "/private/admin/menuConfig",
        component: <MenuConfigPage />,

    },
    {
        route: "/private/admin/solicitacoesPos",
        component: <PosSolicitationsPage />,

    },
]

const AdminPage: FC<IAdminPageProps> = () => {
    const { tab, subTab } = useParams<{ tab: string, subTab: string }>();
    const { updateRouters } = useBreadcumbs();
    const { menuSettings } = UseMenuList();
    const location = useLocation();

    const adminItems = useMemo(() => {
        const admin = menuSettings.find((item) => item.title === "Admin");
        return admin?.submenus ?? [];
    }, [menuSettings]);

    useEffect(() => {
        const currentTab = adminItems.find(item => 
            item.submenus.length 
            ? item.submenus.find((it) => it.route && tab === it.route.split("/").slice(3)[0])
            : item.route && tab === item.route.split("/").slice(3)[0]
        );
        
        const routers = [
            {
            title: "Admin",
            },
            {
                label: "Admin",
                title: currentTab?.title
            }
        ];

        if (currentTab?.submenus?.length) {
            routers.push({
              label: currentTab?.submenus?.length ? currentTab?.title : "",
              title:
                currentTab?.submenus?.find((item) => item.route === location.pathname)?.title ??
                currentTab?.title,
            });
        }

        updateRouters(routers)
        return () => {
            updateRouters([]);
        }
    }, [subTab, tab, updateRouters, adminItems, location]);

    const handleComponent = useCallback(() => {
        const findRoute = adminRoutes.find((route) => location.pathname.includes(route.route));

        if (findRoute) {
            return findRoute.component;
        }

        return <IframePage src={location.pathname.slice(8)} />
    }, [location.pathname]);

    return (
        <>
            {handleComponent()}
        </>
    );
}
export default AdminPage;
