import { IGetCategoryListResponse } from "modules/catalog/domain/dtos/IGetCategoryListResponse";
import { ICatalogService } from "modules/catalog/domain/interface/ICatalogService"
import { api } from "../Api";
import AntiCorruptionCatalogService from "./anticorruption/AntiCorruptionCatalogService";
import { IGetCatalogListItemResponse, IGetCatalogResponse } from "modules/catalog/domain/dtos/IGetCatalogListResponse";
import { IGetProductsListItemResponse, IGetProductsListResponse } from "modules/catalog/domain/dtos/IGetProductsListResponse";
import { IGetCatalogProductResponse } from "modules/catalog/domain/dtos/IGetCatalogProductResponse";
import { IGetMainCategoriesAndInvoiceSplicLocals } from "modules/catalog/domain/dtos/IGetMainCategoriesAndInvoiceSplicLocals";
import { IGetProductCategory } from "modules/catalog/domain/dtos/IGetProductCategory";
import { IAddCatalogRequest, IEditCatalogRequest } from "modules/catalog/domain/dtos/ISaveCatalogRequest";
import { IAddProductCategoryRequest, IEditProductCategoryRequest } from "modules/catalog/domain/dtos/ISaveProductCategoryRequets";
import { ISaveCatalogProductRequest } from "modules/catalog/domain/dtos/IAddCatalogProductRequest";
import { IImportProductItemRequest } from "modules/catalog/domain/dtos/IImportProductRequest";
import { IGetStoreItemResponse } from "modules/catalog/domain/dtos/IGetStoreResponse";
import { IGetAccessUserItemResponse } from "modules/catalog/domain/dtos/IGetAccessUserResponse";
import { ILimitAccessProductRequest, ILimitAccessProductResponse } from "modules/catalog/domain/dtos/ILimitAccessProductRequest";
import { IHideProductCatalogRequest } from "modules/catalog/domain/dtos/IHideProductCatalogRequest";
import { IGetEventsResponse } from "modules/catalog/domain/dtos/IGetEventsResponse";
import { IOrderCategoriesBody } from "modules/catalog/domain/dtos/IOrderCategories";
import { IPutFavoriteRequest } from "modules/catalog/domain/dtos/IPutFavoriteRequest";
import { IOrderProductsBody } from "modules/catalog/domain/dtos/IOrderProducts";


const AntiCorruptionService = AntiCorruptionCatalogService(api);
const CatalogService = (): ICatalogService => {

    api.defaults.params = { disableError: false }

    const getProduct = async (localId: string, productId: string): Promise<IGetCatalogProductResponse> => {
        const response = await api.get<IGetCatalogProductResponse>(`/Catalog/Product/` + productId);
        // const response = await AntiCorruptionService.GetProduto(localId, productId);
        return response.data
    }

    const getCategoryList = async (localId: string, hideEmpty?: boolean, keyword?: string): Promise<IGetCategoryListResponse[]> => {
        // const response = await AntiCorruptionService.getListaDeCategoriasFiltros(localId, hideEmpty, keyword)
        const response = await api.get<{ data: { id: string, name: string }[] }>(`/Catalog/categories`, { params: { localId: localId, hideEmptyCategory: hideEmpty, keyword, page: 1, pageSize: 200 } });
        return response.data.data.map((item) => ({ description: item.name, id: item.id }));
    };
    const getCategories = async (localId: string, catalogId?: string, hideEmpty?: boolean, keyword?: string): Promise<IGetCategoryListResponse[]> => {
        // const response = await AntiCorruptionService.getListaDeCategorias(localId, hideEmpty, keyword)
        const response = await api.get<{ data: IGetCategoryListResponse[] }>(`/Catalog/categories`, { params: { localId: localId, hideEmptyCategory: hideEmpty, keyword, catalogId, page: 1, pageSize: 200 } });
        return response.data.data.map((item) => ({ ...item, description: item.name ?? "" }));
    };

    const getCatalogList = async (localId: string): Promise<IGetCatalogListItemResponse[]> => {
        const response = await AntiCorruptionService.getListasDeProdutos(localId)
        return response
    }

    const getCatalogById = async (localId: string, catalogId: string): Promise<IGetCatalogResponse> => {
        const response = await AntiCorruptionService.getListaDeProdutoPorId(localId, catalogId)
        return response
    }

    const getProductsByCategoryIdAndCatalogId = async (localId: string, catalogId: string, categoryId: string, keyword: string): Promise<IGetProductsListItemResponse[]> => {
        // const response = await AntiCorruptionService.getProdutosPorCategoria(localId, categoryId, keyword);
        // return response;
        const response = await api.get<IGetProductsListResponse>(`/Catalog/products`, {
            params: {
                localId: localId,
                listId: catalogId,
                categoryLocalId: categoryId,
                page: 1,
                pageSize: 200,
                keyword: keyword
            }
        })
        return response.data.data;
    }

    const getProductsByCategoryId = async (localId: string, categoryId: string,): Promise<IGetProductsListItemResponse[]> => {
        const response = await api.get<IGetProductsListResponse>(`/Catalog/products`, {
            params: {
                localId: localId,
                categoryLocalId: categoryId,
                page: 1,
                pageSize: 200,
            }
        });
        return response.data.data;
    }
    const getConfigProducts = async (localId: string): Promise<IGetProductsListItemResponse[]> => {
        const response = await api.get<IGetProductsListResponse>(`/Catalog/ConfigProducts`, {
            params: {
                localId: localId,
                page: 1,
                pageSize: 200,
            }
        })
        return response.data.data;
    }

    const getMainCategoriesAndInvoicSplitFiscal = async (localId: string): Promise<IGetMainCategoriesAndInvoiceSplicLocals> => {
        const response = await AntiCorruptionService.getCategoriasPaiEllocaisSplitFiscalPorLocal(localId)
        return response;
    }
    const getCategory = async (localId: string, categoryId: string): Promise<IGetProductCategory> => {
        const response = await AntiCorruptionService.GetCategoriaPorId(localId, categoryId);
        return response;
    }

    const getAllProducts = async (localId: string): Promise<IGetProductsListItemResponse[]> => {
        const response = await AntiCorruptionService.getTodosOsProdutos(localId);
        return response;
    }


    const addCatalog = async (localId: string, request: IAddCatalogRequest) => {
        await AntiCorruptionService.adicionarListaDeProduto(localId, request);
    }
    const editCatalog = async (localId: string, request: IEditCatalogRequest) => {
        await AntiCorruptionService.editarListaDeProduto(localId, request);
    }

    const addProductCategory = async (localId: string, request: IAddProductCategoryRequest) => {
        await AntiCorruptionService.addCategoriaDeProdutos(localId, request);

    }
    const editProductCategory = async (localId: string, request: IEditProductCategoryRequest) => {
        await AntiCorruptionService.EditarCategoriaDeProdutos(localId, request);

    }
    const enableProduct = async (localId: string, productId: string) => {
        await AntiCorruptionService.ativarProduto(productId);

    }
    const disableProduct = async (localId: string, productId: string) => {
        await AntiCorruptionService.desativarProduto(productId);
    }

    const deleteCatalog = async (localId: string, catalogid: string) => {
        await AntiCorruptionService.removerListaDeProduto(catalogid);
    }

    const copyCatalog = async (localId: string, catalogid: string) => {
        await AntiCorruptionService.duplicarListaDeProduto(catalogid);
    }

    const addProduct = async (localId: string, request: ISaveCatalogProductRequest) => {
        await api.post(`/Catalog/Product`, request);
    }
    const editProduct = async (localId: string, productId: string, request: ISaveCatalogProductRequest) => {
        await api.put(`/Catalog/Product/${productId}`, request);
    }

    const deleteCategory = async (localId: string, categoryId: string, targetCategory?: string) => {
        await AntiCorruptionService.removerCategoriaDeProdutos(categoryId, targetCategory);
    }

    const importProducts = async (localId: string, catalogIds: string[], products: IImportProductItemRequest[]) => {
        await AntiCorruptionService.importarProdutos(localId, catalogIds, products);
    }

    const getStores = async (localId: string): Promise<IGetStoreItemResponse[]> => {
        return await AntiCorruptionService.getStores(localId);
    }
    const getAccessUsers = async (localId: string): Promise<IGetAccessUserItemResponse[]> => {
        return await AntiCorruptionService.getAcessosUsuariosList(localId);
    }
    const limitProductAccess = async (request: ILimitAccessProductRequest): Promise<void> => {
        return await AntiCorruptionService.limitarAcessoProduto(request);
    }
    const getLimitProductAccess = async (productId: string): Promise<ILimitAccessProductResponse[]> => {
        return await AntiCorruptionService.obterLimitesDeAcesso(productId);
    }

    const removeProductfromCatalog = async (catalogId: string, productId: string) => {
        const request = { productId, catalogId }
        await api.delete(`/Catalog/RemoveProductFromCatalog`, { data: request });
    }

    const copyProduct = async (localId: string, productId: string) => {
        await api.post(`/Catalog/Local/${localId}/DuplicateProduct/${productId}`);
    }
    const deleteProduct = async (productId: string) => {
        await api.delete(`/Catalog/Product/${productId}`);
    }

    const putFavorite = async (productId: string, body: IPutFavoriteRequest) => {
        await api.put(`/Catalog/Product/${productId}/featured`, body);
    }

    const hideCatalogProduct = async (reuqest: IHideProductCatalogRequest) => {
        await api.put(`/Catalog/Product/visibility`, reuqest);
    }

    const getEvents = async (localId: string): Promise<IGetEventsResponse> => {
        const response = await AntiCorruptionService.getEvents(localId);
        return response;
    }

    const orderCategories = async (body: IOrderCategoriesBody) => {
        await api.put(`/Produto/OrdenaCategorias`, body);
    }

    const orderProducts = async (body: IOrderProductsBody) => {
        await api.put(`/Product/OrderProducts`, body);
    }

    return ({
        getCategoryList,
        getCategory,
        getCatalogList,
        getProductsByCategoryIdAndCatalogId,
        getProductsByCategoryId,
        getProduct,
        getMainCategoriesAndInvoicSplitFiscal,
        getAllProducts,
        getCatalogById,
        addCatalog,
        editCatalog,
        addProductCategory,
        editProductCategory,
        enableProduct,
        disableProduct,
        getCategories,
        deleteCatalog,
        copyCatalog,
        addProduct,
        editProduct,
        deleteCategory,
        importProducts,
        getStores,
        removeProductfromCatalog,
        deleteProduct,
        getAccessUsers,
        limitProductAccess,
        getLimitProductAccess,
        hideCatalogProduct,
        getEvents,
        copyProduct,
        getConfigProducts,
        orderCategories,
        putFavorite,
        orderProducts
    })
}
export default CatalogService
