import { z } from "zod";

export enum InvoiceEditOrView {
  EDIT = 'edit',
  VIEW = 'view',
}
export interface IInvoiceFormValue {
  createdAt?: string;
  partnerId: string;
  partnerName: string;//adicionar
  partnerDocument: string;//adicionar
  createdById: string;
  operationId: string;
  operationName?: string;//adicionar
  currency: 'BRL' | 'USD';
  number?: string;
  series?: string;
  accessKey?: string;
  issueDate: string;
  date: string;
  shipping?: number;
  increase?: number;
  insurance?: number;
  discountValue?: number;
  discountPercentage?: number;
  others?: number;
  stHighlighted?: number;
  supplies: InvoiceSupplyValues[];
  observation?: string;
  createdByName?: string
}

export interface InvoiceSupplyValues {
  id: string;
  supplyId: string;
  supplyName: string;
  supplyCode: number;


  storageLocationId: string;
  storageLocationName: string;

  measurementUnitId: string;
  measurementUnitSymbol: string;

  // inventoryQuantity: number;
  unitPrice: number;
  quantity: number;

  icms: number;
  confins: number;
  pis: number;
  st: number;
  ipi: number;
  discountPercentage: number;
  discountValue: number;
  minStock?: number
}

export const IInvoiceSupplySchema = z.object({
  supplyId: z.string({ message: 'Suprimento é obrigatório' }),
  supplyCode: z.number({ message: 'Código é obrigatório' }),
  supplyName: z.string({ message: 'Nome é obrigatório' }),
  storageLocationId: z.string({ message: 'ID do local de armazenamento é obrigatório' }),
  storageLocationName: z.string({ message: 'Nome do local de armazenamento é obrigatório' }),
  // inventoryQuantity: z.number({ message: 'Quantidade em estoque é obrigatória' }),
  unitPrice: z.number({ message: 'Custo unitário é obrigatório' }),
  quantity: z.number({ message: 'Quantidade é obrigatória' }).min(1, { message: 'Quantidade deve ser maior que 0' }),
  measurementUnitId: z.string({ message: 'ID da unidade de medida é obrigatório' }).min(1, { message: 'Unidade de medida é obrigatório' }),
  measurementUnitSymbol: z.string({ message: 'Símbolo da unidade de medida é obrigatório' }),
  icms: z.number({ message: 'ICMS é obrigatório' }),
  confins: z.number({ message: 'COFINS é obrigatório' }),
  pis: z.number({ message: 'PIS é obrigatório' }),
  st: z.number({ message: 'ST é obrigatório' }),
  ipi: z.number({ message: 'IPI é obrigatório' }),
  discountPercentage: z.number({ message: 'Porcentagem de desconto é obrigatória' }),
  discountValue: z.number({ message: 'Valor de desconto é obrigatório' })
});

export const IInvoiceSchema = z.object({
  partnerId: z.string().min(1, { message: 'O parceiro é obrigatório' }),
  createdById: z.string({ message: 'ID do criador é obrigatório' }),
  operationId: z.string().min(1,{ message: 'O tipo de operação é obrigatório' }),
  currency: z.union([z.literal('BRL'), z.literal('USD')], { message: 'Moeda deve ser BRL ou USD' }),
  number: z.string().optional(),
  series: z.string().min(1,{ message: 'A série é obrigatório' }),
  accessKey: z.string().min(1,{ message: 'A chave de acesso é obrigatório' }),
  issueDate: z.string().min(1,{ message: 'Data de emissão é obrigatória' }),
  date: z.string().min(1,{ message: 'Data é obrigatória' }),
  shipping: z.number().optional(),
  increase: z.number().optional(),
  insurance: z.number().optional(),
  discount: z.number().optional(),
  others: z.number().optional(),
  stHighlighted: z.number().optional(),
  supplies: z.array(IInvoiceSupplySchema).min(1, { message: 'Pelo menos um suprimento é obrigatório' }),
  observation: z.string().optional(),
});
