import {
  IPaymentLinkInfoErrors,
  IPaymentLinkInfoForm,
} from "modules/paymentLink/presentation/interfaces/IPaymentLinkForms";

export const validateInformationForm = (
  formData: IPaymentLinkInfoForm,
  setErrors: React.Dispatch<React.SetStateAction<IPaymentLinkInfoErrors>>
) => {
  let _errors: IPaymentLinkInfoErrors = {};
  let hasError = false;
  if (!formData?.name?.trim()) {
    _errors = { ..._errors, name: "Campo obrigatório" };
    hasError = true;
  }
  if (!formData.expiration) {
    _errors = { ..._errors, expiration: "Campo obrigatório" };
    hasError = true;
  }
  if (!formData.img) {
    _errors = { ..._errors, img: "Campo obrigatório" };
    hasError = true;
  }

  setErrors(_errors);
  return !hasError;
};
