import { useCallback, useEffect, useState } from 'react'
import styles from './ProductList.module.scss'
import { IProductItem } from './IProductItem'
import DnDList, { IItem } from '../../ui/DnDList'
import ProductListItem from './ProductListItem'
import ProductListItemSkeleton from './ProductListItemSkeleton'
import CatalogService from 'services/api/catalog/CatalogService'
import { useLocal } from 'modules/local/presentation/context/LocalContext'
import OrderProductsUseCase from 'modules/catalog/application/useCases/OrderProducts'

export interface IProductListProps {
    //propertys
    products: IProductItem[],
    isLoading?: boolean,
    checkedProducts?: IProductItem[],
    onSortList?: (list: IProductItem) => void
    onClickProduct?: (product: IProductItem) => void
    onSelectProduct?: (product: IProductItem) => void
    onClickEditProduct?: (product: IProductItem) => void
    onClickCopyProduct?: (product: IProductItem) => void
    onClickRemoveProduct?: (product: IProductItem) => void
    onClickDeleteProduct?: (product: IProductItem) => void
    onChangeEnabledProduct?: (product: IProductItem, value: boolean) => void
    onChangeFeaturedProduct?: (product: IProductItem, value: boolean) => void
    onCheckProduct?: (product: IProductItem, value: boolean) => void
    onClickProductAccess?: (product: IProductItem) => void
    onChangeHide?: (product: IProductItem, value: boolean) => void
    currentCatalogId?: string
    isDragInDropDisabled?: boolean
}
const ProductList = ({
    products,
    checkedProducts,
    onClickProduct,
    onClickEditProduct,
    onChangeEnabledProduct,
    onClickRemoveProduct,
    onClickDeleteProduct,
    onChangeFeaturedProduct,
    onCheckProduct,
    onClickCopyProduct,
    isLoading,
    onClickProductAccess,
    onChangeHide,
    currentCatalogId,
    isDragInDropDisabled,
}: IProductListProps) => {
    const [sortedList, setSortedList] = useState(products);
    const catalogService = CatalogService();
    const { currentLocal } = useLocal()

    const onChangeOrder = useCallback(
        async (list: IItem[]) => {
            if (currentLocal) {
                const body = {
                    localClientId: currentLocal.id,
                    products: list.map((item, index: number) => ({
                        productId: item.id,
                        order: index + 1
                    }))
                }
                await OrderProductsUseCase(catalogService, body);
            }
        },
        [catalogService, currentLocal],
    )

    const onCheckProductHandle = useCallback((product: IProductItem, value: boolean, index: number) => {
        onCheckProduct?.(product, value);
    }, [onCheckProduct])

    useEffect(() => {
        setSortedList(products)
    }, [products])

    const renderItem = useCallback((item: IProductItem, index: number, dragHandleProps) => {
        return <ProductListItem
            isDragInDropDisabled={isDragInDropDisabled ?? false}
            onCheckProduct={onCheckProduct ? (product, value) => onCheckProductHandle(product, value, index) : undefined}
            onClickProduct={onClickProduct}
            onClickCopyProduct={onClickCopyProduct}
            onClickEditProduct={onClickEditProduct}
            onClickRemoveProduct={onClickRemoveProduct}
            onClickDeleteProduct={onClickDeleteProduct}
            onChangeEnabledProduct={onChangeEnabledProduct}
            onChangeFeaturedProduct={onChangeFeaturedProduct}
            productItem={item}
            onClickProductAccess={onClickProductAccess}
            onChangeHide={onChangeHide}
            currentCatalogId={currentCatalogId}
            dragHandleProps={dragHandleProps}
        />
    }, [isDragInDropDisabled, 
        onCheckProduct, 
        onClickProduct, 
        onClickCopyProduct, 
        onClickEditProduct, 
        onClickRemoveProduct, 
        onClickDeleteProduct, 
        onChangeEnabledProduct, 
        onChangeFeaturedProduct, 
        onClickProductAccess, 
        onChangeHide, 
        currentCatalogId, 
        onCheckProductHandle])

    return (
        <div id={styles.ProductList} >
            {
                isLoading ?
                    <ProductListItemSkeleton />
                    :
                    <DnDList
                        isDragInDropDisabled
                        list={sortedList}
                        renderItem={renderItem}
                        onChangeOrder={onChangeOrder}
                    />
            }
        </div>
    )
}
export default ProductList