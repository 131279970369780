
import { Icon } from '@material-ui/core'
import styles from './TurnoverTableItem.module.scss';

interface ITurnoverTableItemProps {
}

const TurnoverTableItem = () => {

    return (
        <div id={styles.TurnoverTableItem}>       
            <div className={styles.header}>
                <span>Média de giro: <span>6545</span></span>
                <button onClick={() =>{}}>Ordenar mesas <Icon>swap_vert</Icon></button>
                <button onClick={() => {}}>Ordenar giro <Icon>swap_vert</Icon></button>
            </div>
            <div className={styles.cardsContainer} >
                {                
                        <>
                            {                                
                                    <div className={styles.card} >
                                        <div className={styles.header}>
                                            1000
                                        </div>
                                        <div className={styles.footer}>
                                            <span>Giro</span>
                                            <span>100</span>
                                        </div>
                                    </div>
                                
                            }                           
                        </>                    
                }
            </div>
        </div>
    )
}

export { TurnoverTableItem }