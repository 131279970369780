import { FC } from "react";
import CreateCampaignComponent from "../components/crmFilterForm/components/Campaign/CampaignComponent/CampaignComponent";
import CrmFilterForm from "../components/crmFilterForm/CrmFilterForm";
import CrmList from "../components/crmList/CrmList";
import UseCrmPage from "./UseCrmPage";
import { useLocal } from "modules/local/presentation/context/LocalContext";
import SaasCrmFilterForm from "../components/crmFilterForm/SaasCrmFilterForm";

const CrmPage: FC = () => {
  const {
    customers,
    eventsByCategory,
    ticketCategories,
    onClickSelectAllHandle,
    onChangeFilterHandle,
    onChangePaginationHandle,
    postSaveFilter,
    savedFilters,
    isLoadingCrmCustomer,
    deleteFilter,
    params,
    getCustomerEvents,
    isLoadingCrmSavedFilter,
    createCampaignWithCustomerAndFilters,
    onClickCreateCampaignWithEvent,
    onClickCreateCampaignByCampaign,
    eventsList,
    campaignsList,
    fidelityList
  } = UseCrmPage();
  const { currentLocal } = useLocal();

  return (
    <div style={{ maxWidth: "1300px", margin: "auto", width: "100%" }}>
      <CreateCampaignComponent
        events={eventsList?.data ?? []}
        campaigns={campaignsList?.data ?? []}
        onClickCreateCampaignWithSelectedCostumers={
          createCampaignWithCustomerAndFilters
        }
        onClickCreateCampaignByEvent={onClickCreateCampaignWithEvent}
        onClickCreateCampaignByCampaign={onClickCreateCampaignByCampaign}
      />
      {
        currentLocal?.systemIdentifier !== 1 
        ? <CrmFilterForm
          isLoading={isLoadingCrmSavedFilter}
          onClickDeleteFilter={deleteFilter}
          onSubmit={onChangeFilterHandle}
          events={eventsList ? eventsList.data : []}
          eventCategories={eventsByCategory ? eventsByCategory.data : []}
          ticketsCategories={ticketCategories ? ticketCategories.data : []}
          fidelityPlanIds={fidelityList.sort((a, b) => (a.pointsRequired || 0) < (b.pointsRequired || 0) ? 1 : -1) ?? []}
          fidelityList={fidelityList || []}
          savedFilters={savedFilters ? savedFilters : []}
          onSaveFilter={postSaveFilter}
        />
        : <SaasCrmFilterForm
          isLoading={isLoadingCrmSavedFilter}
          onClickDeleteFilter={deleteFilter}
          onSubmit={onChangeFilterHandle}
          events={eventsList ? eventsList.data : []}
          eventCategories={eventsByCategory ? eventsByCategory.data : []}
          ticketsCategories={ticketCategories ? ticketCategories.data : []}
          fidelityPlanIds={fidelityList.sort((a, b) => (a.pointsRequired || 0) < (b.pointsRequired || 0) ? 1 : -1) ?? []}
          fidelityList={fidelityList || []}
          savedFilters={savedFilters ? savedFilters : []}
          onSaveFilter={postSaveFilter}
        />
      }
      <CrmList
        isLoading={isLoadingCrmCustomer}
        onClickSelectAll={onClickSelectAllHandle}
        list={customers}
        fidelityList={fidelityList || []}
        pagination={params?.pagination}
        onChangePagination={onChangePaginationHandle}
        getCustomerEvents={getCustomerEvents}
      />
    </div>
  );
};
export default CrmPage;
