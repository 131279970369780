import { useLocal } from "modules/local/presentation/context/LocalContext";
import { useCallback, useState } from "react";
import GeneralDashboardApi from "services/api/generalDashboard/GeneralDashboardApi";
import { IPosPaid } from "../interfaces/IPosPaid";
import GetPosPaidUseCase from "modules/generalDashboard/application/useCases/GetPosPaidUseCase";



const UsePosPaid = () => {

  const [isLoading, setIsLoading] = useState<boolean>();
  const [posPaidData, setPosPaidData] = useState<IPosPaid>()
  const { currentLocal } = useLocal();

  const getPosPaid= useCallback(
    async () => {
      try {
        const serviceGeneral = GeneralDashboardApi();
        setIsLoading(true);
        if (currentLocal) {
          const response = await GetPosPaidUseCase(serviceGeneral,
            currentLocal.id,          
          );

          setPosPaidData(response);

          return response
        }
      } finally {
        setIsLoading(false);
      }
    },
    [currentLocal]
  );


  return {
    posPaidData,
    isLoading,
    getPosPaid,
    setPosPaidData,
  };
};

export default UsePosPaid;
