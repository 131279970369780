import { IGetPreRechargeResponse } from "modules/generalDashboard/domain/dto/IGetPreRechargeResponse";
import { IGeneralDashboardApiService } from "modules/generalDashboard/domain/interfaces/IGeneralDashboardApiService";



const GetPreRechargeUseCase = async (service: IGeneralDashboardApiService, localId: string): Promise<IGetPreRechargeResponse> => {
    const response = await service.getPreRecharge(localId);

    return response;
}

export default GetPreRechargeUseCase;