import { FC } from 'react'
import TabView from '../../components/tab/TabView'
import DevicePaymentForm from '../../components/deviceProfile/profileForms/devicePaymentForm/DevicePaymentForm'
import OperationMethodForm from '../../components/deviceProfile/profileForms/deviceSystemForm/DeviceSystemForm'
import InvoiceForm from '../../components/deviceProfile/profileForms/deviceInvoiceForm/InvoiceForm'
import styles from './DeviceProfileForm.module.scss'
import OtherParamsForm from '../../components/deviceProfile/otherParamsForm/OtherParamsForm'
import DeviceOperatorsForm from '../../components/deviceProfile/profileForms/operatorsForm/OperatorsForm'
import ProfileForm from '../../components/deviceProfile/profileForms/profileForm/ProfileForm'
import UseDeviceProfileForm from './UseDeviceProfileForm'
import { FormDeviceProfileStep } from '../../../domain/interface/FormDeviceProfileSteps'
import DeviceCatalogForm from '../../components/deviceProfile/profileForms/deviceCatalogForm/DeviceCatalogForm'
import { Close } from '@material-ui/icons'
import './DeviceProfileForm.css';
import DeviceToProfileForm from '../../components/deviceProfile/profileForms/deviceProfileForm/DeviceToProfileForm'
import { Icon, IconButton } from '@material-ui/core'
import ConfirmAlert from '../../components/deviceProfile/profileForms/confirmAlert/ConfirmAlert'
import OperationParamsForm from '../../components/deviceProfile/profileForms/operationParamsForm/OperationParamsForm'
import GeneralParamsForm from '../../components/deviceProfile/profileForms/generalParamsForm/GeneralParamsForm'
import UsabilityForm from '../../components/deviceProfile/profileForms/usabilityForm/UsabilityForm'
import UserInterfaceForm from '../../components/deviceProfile/profileForms/userInterfaceForm/UserInterfaceForm'
import PrinterParamsForm from '../../components/deviceProfile/profileForms/printerParamsForm/PrinterParamsForm'
import { IFilterProfileDevice } from '../../components/deviceProfile/filterProfile/IFilterDeviceProfile'
import ProductsForm from '../../components/deviceProfile/profileForms/productsForm/ProductsForm'
import PagSeguroParamsForm from '../../components/deviceProfile/profileForms/pagSeguroParamsForm/PagSeguroParamsForm'
import UseProfilePage from '../../pages/profile/UseProfilePage'
import SucessButton from '../../components/sucessButton/SucessButton'
import FooterButtons from '../../components/footerButtons/FooterButtons'
import TerminalForm from '../../components/deviceProfile/profileForms/terminalForm/TerminalForm'
import ConfirmChangeNfce from '../../components/deviceProfile/profileForms/confirmChangeNfce/ConfirmChangeNfce'
import { IDeviceItemValue } from '../../components/deviceProfile/profileForms/deviceProfileForm/interfaces/IDeviceItem'
import { SystemIdentifierEnum } from 'modules/gestaoVendas/domain/enum/GetSegmentosEnum'
export interface IDeviceProfileFormProps {
    onChangeFilterHandle: (filter: IFilterProfileDevice) => void;
    profileNameList: string[];
    systemIdentifier?: SystemIdentifierEnum;
    path?: string;
}

const DeviceProfileForm: FC<IDeviceProfileFormProps> = ({ onChangeFilterHandle, profileNameList, systemIdentifier, path = "/private/pdv/perfil" }) => {
    const { perfilVinculo } = UseProfilePage()

    const {
        operations,
        paymentMethods,
        customPaymentMethods,
        operators,
        profileId,
        formStep,
        deviceProfileDefault,
        catalogs,
        sucessSaveFlag,
        deviceList,
        modified,
        confirmModalVisible,
        onLeaveWithoutSave,
        setConfirmModalVisible,
        setModified,
        onChangeFormStep,
        onSubmitProfile,
        onSubmitSystemConfig,
        onSubmitPayment,
        onSubmitOperator,
        onSubmitCatalog,
        onSubmitGeneralParams,
        onSubmitOperationParams,
        onSubmitUsability,
        onSubmitUserInterface,
        onSubmitPrinter,
        onSubmitPagSeguro,
        onSubmitInvoice,
        closeStepForm,
        onSaveSubmit,
        onSubmitDeviceToProfile,
        productList,
        onSubmitDeviceProductsParams,
        getMoreDevices,
        onSubmitTerminal,
        setConfirmChangeNfceVisible,
        confirmChangeNfceVisible,
        onSubmitConfirmedDeviceToProfile,
        getPrinterSectors
    } = UseDeviceProfileForm(onChangeFilterHandle, perfilVinculo, path);

    const getBackButton = (step: FormDeviceProfileStep) => {
        return <IconButton className={styles.backButton} onClick={() => onChangeFormStep?.(step)}><Icon>chevron_left</Icon></IconButton>;
    }

    const getFormTitle = () => {
        switch (formStep) {
            case FormDeviceProfileStep.INVOICE:
                return (<> {getBackButton(FormDeviceProfileStep.OTHER)} <h2>Configuração <span>Fiscal</span></h2></>);
            case FormDeviceProfileStep.PARAMS:
                return (<> {getBackButton(FormDeviceProfileStep.OTHER)} <h2>Configuração de <span>Parâmetros Gerais</span></h2></>);
            case FormDeviceProfileStep.OPERATOR:
                return (<> {getBackButton(FormDeviceProfileStep.OTHER)} <h2>Vincular  <span>Operadores</span></h2></>);
            case FormDeviceProfileStep.USABILITY:
                return (<> {getBackButton(FormDeviceProfileStep.OTHER)} <h2>Usabilidade</h2></>);
            case FormDeviceProfileStep.USER_INTERFACE:
                return (<> {getBackButton(FormDeviceProfileStep.OTHER)} <h2>Interface</h2></>);
            case FormDeviceProfileStep.PRINTER:
                return (<> {getBackButton(FormDeviceProfileStep.OTHER)} <h2>Impressão</h2></>);
            case FormDeviceProfileStep.PAG_SEGURO:
                return (<> {getBackButton(FormDeviceProfileStep.OTHER)} <h2>Configuração de <span>Acesso ao PagSeguro</span></h2></>);
            case FormDeviceProfileStep.CUSTOMER_IDENTIFICATION:
                return (<> {getBackButton(FormDeviceProfileStep.OTHER)} <h2>Identificação do cliente</h2></>);
            case FormDeviceProfileStep.OPERATION_PARAMS:
                return (<> {getBackButton(FormDeviceProfileStep.OTHER)} <h2>Operação</h2></>);
            case FormDeviceProfileStep.PRODUCTS:
                return (<> {getBackButton(FormDeviceProfileStep.OTHER)} <h2>Cardápios</h2></>);
            case FormDeviceProfileStep.DEVICE:
                return <h2>Vincular  <span>Equipamentos</span></h2>;
            case FormDeviceProfileStep.TERMINAL:
                return (<> {getBackButton(FormDeviceProfileStep.OTHER)} <h2>Terminal de <span>vendas</span></h2></>);
            default:
                return <h2>{profileId ? 'Alterar' : 'Adicionar'} <span>Perfil de Venda</span></h2>;
        }
    }

    return (
        <div id={styles.DeviceProfileForm} >
            <div className={styles.header} >
                <div className={styles.containerTitle}>
                    {getFormTitle()}
                    <Close onClick={closeStepForm} />
                </div>
                {
                    (
                        formStep === FormDeviceProfileStep.PROFILE ||
                        formStep === FormDeviceProfileStep.SYSTEM ||
                        formStep === FormDeviceProfileStep.PAYMENT ||
                        formStep === FormDeviceProfileStep.OTHER ||
                        formStep === FormDeviceProfileStep.AMBIENT ||
                        formStep === FormDeviceProfileStep.PRODUCTS ||
                        formStep === FormDeviceProfileStep.DEVICE ||
                        formStep === FormDeviceProfileStep.CONCLUDE
                    ) &&
                    <TabView
                        index={formStep}
                        scrollButton="on"
                        tabs={perfilVinculo === 1 ?
                            [
                                { label: 'Perfil', value: FormDeviceProfileStep.PROFILE },
                                { label: 'Operação', value: FormDeviceProfileStep.SYSTEM, disabled: !profileId },
                                { label: 'Pagamento', value: FormDeviceProfileStep.PAYMENT, disabled: !profileId },
                                { label: 'Equipamentos', value: FormDeviceProfileStep.DEVICE, disabled: !profileId },
                                { label: 'Cardápios ', value: FormDeviceProfileStep.PRODUCTS, disabled: !profileId },
                                { label: 'Configurações Adicionais ', value: FormDeviceProfileStep.OTHER, disabled: !profileId },
                                { label: 'Concluir ', value: FormDeviceProfileStep.CONCLUDE, disabled: !profileId },
                            ] : [
                                { label: 'Perfil', value: FormDeviceProfileStep.PROFILE },
                                { label: 'Operação', value: FormDeviceProfileStep.SYSTEM, disabled: !profileId },
                                { label: 'Pagamento', value: FormDeviceProfileStep.PAYMENT, disabled: !profileId },
                                { label: 'Cardápios ', value: FormDeviceProfileStep.PRODUCTS, disabled: !profileId },
                                { label: 'Configurações Adicionais ', value: FormDeviceProfileStep.OTHER, disabled: !profileId },
                                { label: 'Concluir ', value: FormDeviceProfileStep.CONCLUDE, disabled: !profileId },
                            ]
                        }
                        onChangeTab={(tab, value) => onChangeFormStep?.(tab.value)}
                    />}
            </div>

            {
                formStep === FormDeviceProfileStep.PRODUCTS &&
                <div className={styles.container}>
                    <ProductsForm
                        defaultValue={deviceProfileDefault?.products}
                        onClickBack={() => perfilVinculo === 1 ? onChangeFormStep?.(FormDeviceProfileStep.DEVICE) : onChangeFormStep?.(FormDeviceProfileStep.PAYMENT)}
                        onSubmit={onSubmitDeviceProductsParams}
                        onModified={setModified}
                        productList={productList}
                    />
                </div>
            }

            {
                formStep === FormDeviceProfileStep.PROFILE &&
                <div className={styles.container}>
                    <ProfileForm
                        defaultValue={deviceProfileDefault}
                        onSubmit={onSubmitProfile}
                        modified={modified}
                        onModified={setModified}
                        profileNameList={profileNameList}
                        onCancel={closeStepForm}
                    />
                </div>
            }

            {
                formStep === FormDeviceProfileStep.SYSTEM &&
                <div className={styles.container}>
                    <OperationMethodForm
                        defaultValue={deviceProfileDefault?.operation.methods}
                        operations={operations}
                        onSubmit={onSubmitSystemConfig}
                        onClickBack={() => onChangeFormStep?.(FormDeviceProfileStep.PROFILE)}
                        onModified={setModified}
                    />
                </div>
            }

            {
                formStep === FormDeviceProfileStep.PAYMENT &&
                <div className={styles.container}>
                    <DevicePaymentForm
                        defaultValue={deviceProfileDefault?.payment}
                        methods={paymentMethods}
                        customMethods={customPaymentMethods}
                        onSubmit={onSubmitPayment}
                        onClickBack={() => onChangeFormStep?.(FormDeviceProfileStep.SYSTEM)}
                        onModified={setModified}
                    />
                </div>
            }

            {
                formStep === FormDeviceProfileStep.OTHER &&
                <div className={styles.container}>
                    <OtherParamsForm
                        showSuccess={sucessSaveFlag}
                        onClick={(value) => onChangeFormStep?.(value)}
                        onClickBack={() => onChangeFormStep?.(FormDeviceProfileStep.PRODUCTS)}
                        onClickSave={onSaveSubmit}
                        onClickFinsh={onChangeFormStep}
                        modified={modified}
                        onModified={setModified}
                        formStep={formStep}
                        systemIdentifier={systemIdentifier}
                    />
                </div>
            }

            {
                formStep === FormDeviceProfileStep.CONCLUDE &&
                <div className={styles.container}>
                    <div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                        <SucessButton label={"Tudo certo, seu perfil de vendas já está ativo!"} />
                        <FooterButtons labelNext='Concluir' onClickNext={closeStepForm} />
                    </div>
                </div>
            }

            {
                formStep === FormDeviceProfileStep.DEVICE &&
                <div className={styles.container}>
                    <DeviceToProfileForm
                        deviceList={deviceList}
                        currentProfileId={profileId}
                        onClickBack={() => onChangeFormStep?.(FormDeviceProfileStep.PAYMENT)}
                        onSubmit={(values: IDeviceItemValue[]) => onSubmitDeviceToProfile(values)}
                        modified={modified}
                        onModified={setModified}
                        getMoreDevices={getMoreDevices}
                    />
                </div>
            }

            {
                formStep === FormDeviceProfileStep.INVOICE &&
                <div className={`${styles.container} ${styles.mtCustom}`}>
                    <InvoiceForm
                        defaultValues={deviceProfileDefault?.invoice}//TODO: rever nome da propriedade
                        onSubmit={onSubmitInvoice}
                        onClickBack={() => onChangeFormStep?.(FormDeviceProfileStep.OTHER)}
                        modified={modified}
                        onModified={setModified}
                    />
                </div>
            }

            {
                formStep === FormDeviceProfileStep.OPERATOR &&
                <div className={`${styles.container} ${styles.mtCustom}`}>
                    <DeviceOperatorsForm
                        operators={operators}
                        defaultValues={deviceProfileDefault?.operators}
                        onSubmit={onSubmitOperator}
                        onClickBack={() => onChangeFormStep?.(FormDeviceProfileStep.OTHER)}
                        modified={modified}
                        onModified={setModified}
                    />
                </div>
            }

            {
                formStep === FormDeviceProfileStep.OPERATION_PARAMS &&
                <div className={`${styles.container} ${styles.mtCustom}`}>
                    <OperationParamsForm
                        onSubmit={onSubmitOperationParams}
                        defaultValue={deviceProfileDefault?.operation}
                        onClickBack={() => onChangeFormStep?.(FormDeviceProfileStep.OTHER)}
                        onModified={setModified}
                    />
                </div>
            }

            {
                formStep === FormDeviceProfileStep.USABILITY &&
                <div className={`${styles.container} ${styles.mtCustom}`}>
                    <UsabilityForm
                        onSubmit={onSubmitUsability}
                        defaultValue={deviceProfileDefault?.usability}
                        onClickBack={() => onChangeFormStep?.(FormDeviceProfileStep.OTHER)}
                        onModified={setModified}
                    />
                </div>
            }

            {
                formStep === FormDeviceProfileStep.USER_INTERFACE &&
                <div className={`${styles.container} ${styles.mtCustom}`}>
                    <UserInterfaceForm
                        onSubmit={onSubmitUserInterface}
                        defaultValue={deviceProfileDefault?.userInterface}
                        onClickBack={() => onChangeFormStep?.(FormDeviceProfileStep.OTHER)}
                        onModified={setModified}
                    />
                </div>
            }

            {
                formStep === FormDeviceProfileStep.PRINTER &&
                <div className={`${styles.container} ${styles.mtCustom}`}>
                    <PrinterParamsForm
                        onSubmit={onSubmitPrinter}
                        getPrinterSectors={getPrinterSectors}
                        defaultValue={deviceProfileDefault?.printer}
                        onClickBack={() => onChangeFormStep?.(FormDeviceProfileStep.OTHER)}
                        onModified={setModified}
                    />
                </div>
            }

            {
                formStep === FormDeviceProfileStep.PAG_SEGURO &&
                <div className={`${styles.container} ${styles.mtCustom}`}>
                    <PagSeguroParamsForm
                        onSubmit={onSubmitPagSeguro}
                        defaultValue={deviceProfileDefault?.pagSeguroConfiguration}
                        onClickBack={() => onChangeFormStep?.(FormDeviceProfileStep.OTHER)}
                        onModified={setModified}
                    />
                </div>
            }

            {
                formStep === FormDeviceProfileStep.TERMINAL &&
                <div className={`${styles.container} ${styles.mtCustom}`}>
                    <TerminalForm
                        onSubmit={onSubmitTerminal}
                        defaultValue={deviceProfileDefault?.terminal}
                        onClickBack={() => onChangeFormStep?.(FormDeviceProfileStep.OTHER)}
                        onModified={setModified}
                    />
                </div>
            }

            {
                formStep === FormDeviceProfileStep.PARAMS &&
                <div className={`${styles.container} ${styles.mtCustom}`}>
                    <GeneralParamsForm
                        onSubmit={onSubmitGeneralParams}
                        defaultValue={deviceProfileDefault}
                        onClickBack={() => onChangeFormStep?.(FormDeviceProfileStep.OTHER)}
                        onModified={setModified}
                    />
                </div>
            }

            {
                formStep === FormDeviceProfileStep.MENU &&
                <div className={styles.container}>
                    <DeviceCatalogForm
                        catalogs={catalogs}
                        defaultValue={deviceProfileDefault?.catalogs}
                        onSubmit={onSubmitCatalog}
                        onClickBack={() => onChangeFormStep?.(FormDeviceProfileStep.OTHER)}
                        modified={modified}
                        onModified={setModified}
                    />
                </div>
            }

            <ConfirmAlert
                onClose={() => setConfirmModalVisible(false)}
                onConfirm={onLeaveWithoutSave}
                open={confirmModalVisible}
            />

            <ConfirmChangeNfce
                onClose={() => setConfirmChangeNfceVisible(false)}
                onConfirm={onSubmitConfirmedDeviceToProfile}
                open={confirmChangeNfceVisible}
            />
        </div >
    )
}
export default DeviceProfileForm