import { z } from "zod";

export interface IConversion {
  measurementUnitId: string;
  measurementUnitSymbol: string;
  quantity: number;
  factor: number;
}

export interface IComposition {
  code: string;
  supplyStockId: string;
  supplyName: string;
  quantity: number;
  measurementUnitSymbol: string;
  cost: number;
  storageLocationId?: string;
  updateStock: boolean;
  storageLocationName?: string;
  measurementUnitId?: string;
  compositionSupplyId: string;
}

export interface IXmlEquivalence {
  supplierXmlCode: string;
  supplierMeepCode: string;
  supplierName: string;
  xmlUnit: string;
  meepUnit: string;
}

export interface ISupplyFormValues {
  code: number;
  name: string;
  supplyType: SupplyTypeEnum | null;
  supplyGroup?: {
    id: string;
    name: string;
    code: string;
  } | null;
  product?: string;
  description: string;
  measurementUnitId: string | null;
  grossWeight: number;
  netWeight: number;
  minimumStock: number;
  conversions: IConversion[];
  compositions: IComposition[];
  xmlEquivalences: IXmlEquivalence[];
  meepProductId?: string;
  supplyGroupId?: string;
  supplyGroupName?: string;
  storageLocationId?: string;
}

export enum SupplyTypeEnum {
  PRODUCT = 1,
  NEW_SUPPLY = 2,
}

export enum SupplyConversionFactorEnum {
  MULTIPLY = 1,
  DIVIDE = 2,
}

export const DEFAULT_VALUE = {
  supplyType: null,
  conversions: [],
  compositions: [],
  xmlEquivalences: [],
  name: "",
  description: "",
  measurementUnitId: "",
  grossWeight: 0,
  netWeight: 0,
  minimumStock: 0,
};

export const ISupplySchema = z
  .object({
    name: z.string().min(1, { message: "Nome é obrigatório" }),
    supplyType: z.nativeEnum(SupplyTypeEnum),
    supplyGroup: z.object(
      {
        id: z.string(),
        name: z.string(),
      },
      { message: "Grupo é obrigatório" }
    ),
    product: z.string().nullable().optional(),
    description: z.string().optional(),
    measurementUnitId: z.string().min(1, { message: "Unidade é obrigatória" }),
    storageLocationId: z.string().nullable().optional(),
    // storageLocationId: z.string({ message: 'Local de estoque é obrigatório' }),
    // grossWeight: z.number({ message: 'Peso bruto é obrigatório' }),
    // netWeight: z.number({ message: 'Peso líquido é obrigatório' }),
    minimumStock: z.number().optional(),
    conversions: z
      .array(
        z.object({
          measurementUnitId: z
            .string({ message: "Unidade é obrigatória" })
            .min(1, { message: "Unidade é obrigatória" }),
          quantity: z
            .number({ message: "Quantidade é obrigatória" })
            .min(1, { message: "Quantidade é obrigatória" }),
          factor: z.nativeEnum(SupplyConversionFactorEnum, {
            message: "Fator de conversão é obrigatório",
          }),
        })
      )
      .optional(),
    compositions: z.array(
      z.object({
        supplyName: z
          .string({ message: "O insumo é obrigatório" })
          .min(1, { message: "O insumo é obrigatório" }),
        quantity: z
          .number({ message: "Quantidade é obrigatória" })
          .min(1, { message: "Quantidade é obrigatória" }),
        compositionSupplyId: z.string(),
        measurementUnitId: z.string(),
        updateStock: z.boolean(),
        storageLocationId: z.string({
          message: "Local de estoque é obrigatório",
        }),
      })
    ),
    // xmlEquivalences: z.array(z.object({
    //   supplierXmlCode: z.string(),
    //   supplierMeepCode: z.string(),
    //   supplierName: z.string(),
    //   xmlUnit: z.string(),
    //   meepUnit: z.string()
    // }))
  })
  .superRefine((data, ctx) => {
    if (data.supplyType === SupplyTypeEnum.PRODUCT) {
      if (data.product === "" || !data.product) {
        return ctx.addIssue({
          code: "custom",
          path: ["product"],
          message: "Produto é obrigatório",
        });
      }
      if (!data.storageLocationId || data.storageLocationId === "") {
        ctx.addIssue({
          code: "custom",
          path: ["storageLocationId"],
          message: "Local de estoque é obrigatório",
        });
      }
    }
    data.conversions?.map((conversion, index) => {
      if (conversion.measurementUnitId === data.measurementUnitId) {
        return ctx.addIssue({
          code: "custom",
          path: ["conversions", index, "measurementUnitId"],
          message:
            "Unidade de conversão não pode ser a mesma que a unidade de medida",
        });
      }
      return data;
    });
  });
