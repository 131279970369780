import { useLocal } from "modules/local/presentation/context/LocalContext";
import { useCallback, useState } from "react";
import DashboardTicketApi from "services/api/dashboardTicket/DashboardTicketApi";
import { IRankingCustomers } from "../interfaces/IRankingCustomers";
import { IFilterValue } from "../interfaces/IFilter";
import GetRankingCustomersUseCase from "modules/dashboardTicket/application/useCases/GetRankingCustomersUseCase";

export interface IParamsUseRankingCustomers {
  localId?: string,
  sessionId?: string,
  period?: number,
}

const UseRankingCustomers = (filter: IFilterValue | undefined) => {

  const [isLoading, setIsLoading] = useState<boolean>();
  const [rankingCustomers, setRankingCustomers] = useState<IRankingCustomers[]>()
  const { currentLocal } = useLocal();

  const getRankingCustomers = useCallback(
    async (params?: IParamsUseRankingCustomers) => {
      try {
        const servicePermutation = DashboardTicketApi();
        setIsLoading(true);
        if (currentLocal) {
          const response = await GetRankingCustomersUseCase(servicePermutation,
            currentLocal.id,
            {
              ...filter,
              period: filter?.period ?? 4,
            },
          );

          setRankingCustomers(response);

          return response
        }
      } finally {
        setIsLoading(false);
      }
    },
    [currentLocal, filter]
  );


  return {
    rankingCustomers,
    isLoading,
    getRankingCustomers,
    setRankingCustomers,
  };
};

export default UseRankingCustomers;
