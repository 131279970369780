import {
  Backdrop,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Drawer,
  IconButton,
} from "@material-ui/core";
import { Button, CircularProgress, Dialog, Icon } from "@mui/material";
import { formatDistanceToNow } from "date-fns";
import ptBR from "date-fns/esm/locale/pt-BR/index.js";
import { setItem } from "localforage";
import React, { FC, useCallback, useMemo, useState } from "react";
import { IKDSItem, IKDSItemStep, KDSStatusStep } from "./IKDSItem";
import styles from "./KDSItem.module.scss";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import {
  MoreVertOutlined,
  PrintOutlined,
  Moped,
  AddCircleOutline,
  Close,
  WhatsApp,
} from "@mui/icons-material";
import KDSSideSheetOrder from "../kDSSideSheetOrder/KDSSideSheetOrder";
import KDSTimer from "../KDSTimer/KDSTimer";
import { RightDrawer } from "components/layout/presentation/rightDrawer/RightDrawer";
import AlertButton from "modules/config/deviceConfig/presentation/components/alertButton/AlertButton";
import { IIssueNFCe } from "../../interfaces/IIssueNFE";
import { INFCeResponse } from "modules/kds/domain/dto/nfe/NFCeResponse";
import { moneyMaskNumber } from "services/utils/Money";
import Whatsapp from "res/assets/images/whatsapp.png";
import Utils from "services/utils/Utils";
import { useAuth } from "modules/auth/presentation/context/AuthContext";

export enum KDSStepAction {
  Accept = `acceptOrder`,
  Refuse = `refuseOrder`,
  Start = `startOrder`,
  Transport = `deliverOrder`,
  Refund = `returnedOrder`,
  Conclude = `receivedOrder`,
}
export interface IKDSItemProps {
  kdsItem: IKDSItem;
  onClickAction?: (
    action: KDSStepAction,
    item: IKDSItem
  ) => Promise<IKDSItemStep>;
  onClickPrint?: (item: IKDSItem) => Promise<void>;
  postIssueNfe: (item: IIssueNFCe) => Promise<INFCeResponse[] | undefined>;
  issueNFCe: INFCeResponse[];
  summaryView: boolean;
  setSummaryView: React.Dispatch<React.SetStateAction<boolean>>;
}

const KDSItem: FC<IKDSItemProps> = ({
  kdsItem,
  onClickAction,
  onClickPrint,
  postIssueNfe,
  issueNFCe,
  summaryView,
}) => {
  const [item, setItem] = useState<IKDSItem>(kdsItem);

  const [isLoading, setIsLoading] = useState(false);

  const [openAlertReceivedOrder, setOpenAlertReceivedOrder] = useState(false);

  const [openDrawer, setOpenDrawer] = useState(false);

  const { hasAccessRole } = useAuth();

  const [refuseModal, setRefuseModal] = useState(false);

  const [giveBackModal, setGiveBackModal] = useState(false);

  const onClickActionHandle = useCallback(
    async (action: KDSStepAction, item: IKDSItem) => {
      if (onClickAction) {
        try {
          setIsLoading(true);
          const response: IKDSItemStep = await onClickAction(action, item);
          setItem((prev) => ({ ...prev, currentStep: response }));
        } finally {
          setRefuseModal(false);
          setGiveBackModal(false);
          setIsLoading(false);
        }
      }
    },
    [onClickAction]
  );

  const closeAlertReceivedHandle = useCallback(() => {
    setOpenAlertReceivedOrder(false);
  }, []);

  const openAlertReceivedHandle = useCallback(() => {
    setOpenAlertReceivedOrder(true);
  }, []);

  const confirmAlertReceivedHandle = useCallback(() => {
    onClickActionHandle?.(KDSStepAction.Conclude, item);
    setOpenAlertReceivedOrder(false);
  }, [item, onClickActionHandle]);

  const closeRefuseModal = useCallback(() => {
    setRefuseModal(false);
    postIssueNfe({ pedidoId: "", documento: "" });
  }, [postIssueNfe]);

  const openRefuseModal = useCallback(() => {
    setRefuseModal(true);
  }, []);

  const closeGiveBackModal = useCallback(() => {
    setGiveBackModal(false);
  }, []);

  const openGiveBackModal = useCallback(() => {
    setGiveBackModal(true);
  }, []);

  const openDrawerOrder = useCallback(() => {
    setOpenDrawer(true);
  }, []);

  const closeDrawerOrder = useCallback(() => {
    setOpenDrawer(false);
  }, []);

  const onClickPrintHandle = useCallback(async () => {
    try {
      setIsLoading(true);
      await onClickPrint?.(kdsItem);
    } finally {
      setIsLoading(false);
    }
  }, [kdsItem, onClickPrint]);

  const colorsStatus = (nameStep: IKDSItemStep) => {
    switch (nameStep.name) {
      case KDSStatusStep.PENDING:
        return `#ED0029`;
      case KDSStatusStep.ACCEPTED:
        return `#6C2EE0`;
      case KDSStatusStep.FINISHED:
        return `#399C54`;
      case KDSStatusStep.PREPARING:
        return `#FF5C78`;
      case KDSStatusStep.TRANSPORT:
        return `#F44336`;
      case KDSStatusStep.DELIVERED:
        return `#399C54`;
      case KDSStatusStep.REFUSED:
        return `#BDBDBD`;
      default:
        return `#333333`;
    }
  };

  const buttons = useMemo(() => {
    if (item.currentStep.name)
      switch (item.currentStep.name) {
        case KDSStatusStep.PENDING:
          return (
            <div className={styles.actions}>
              <Button
                variant={"text"}
                className={styles.recuse}
                disabled={isLoading}
                onClick={openRefuseModal}
              >
                Recusar
              </Button>
              <Button
                variant={"text"}
                className={styles.accept}
                disabled={isLoading}
                onClick={() =>
                  onClickActionHandle?.(KDSStepAction.Accept, item)
                }
              >
                Aceitar pedido
              </Button>
            </div>
          );
        case KDSStatusStep.ACCEPTED:
          return (
            <div className={styles.actions}>
              <Button
                variant={"text"}
                className={styles.accept}
                disabled={isLoading}
                onClick={() => onClickActionHandle?.(KDSStepAction.Start, item)}
              >
                Iniciar
              </Button>
            </div>
          );
        case KDSStatusStep.PREPARING:
          return (
            <div className={styles.actions}>
              <Button
                variant={"text"}
                className={styles.accept}
                disabled={isLoading}
                onClick={() =>
                  onClickActionHandle?.(KDSStepAction.Transport, item)
                }
              >
                Despachar pedido <Moped style={{ marginLeft: 10 }} />
              </Button>
            </div>
          );
        case KDSStatusStep.TRANSPORT:
          return (
            <div className={styles.actions}>
              <Button
                variant={"text"}
                className={styles.recuse}
                disabled={isLoading}
                onClick={openGiveBackModal}
              >
                Devolver
              </Button>
              <Button
                variant={"text"}
                className={styles.accept}
                disabled={isLoading}
                onClick={openAlertReceivedHandle}
              >
                Finalizar
              </Button>
            </div>
          );
        default:
          return null;
      }
  }, [
    isLoading,
    item,
    onClickActionHandle,
    openAlertReceivedHandle,
    openGiveBackModal,
    openRefuseModal,
  ]);

  const distanceFromNow = useMemo(
    () => formatDistanceToNow(new Date(item.createdAt), { locale: ptBR }),
    [item]
  );

  const handleOpenWhatsapp = (phone: string) => {
    window.open(`https://api.whatsapp.com/send?phone=55${phone}`);
  };

  const itemContent = useMemo(
    () => (
      <>
        <div>
          {isLoading && (
            <div className={styles.loading}>
              <CircularProgress />
            </div>
          )}
        </div>
        {item.panelCode && <div className={styles.senha}><span>Senha: <b>{item.panelCode.toString().padStart(4, '0')}</b></span></div>}
        <div
          className={styles.header}
          style={{ backgroundColor: colorsStatus(item?.currentStep) }}
        >
          <div className={styles.headerTop}>
            <span className={styles.code}> {item.code}</span>

            <div className={styles.more}>
              {item.orderOrigin === 2 ? (
                <img src="/assets/img/ifood.png" alt="Logo ifood" />
              ) : (
                item.table && (
                  <>
                    <span className={styles.table}>
                      Mesa: <span>{item.table}</span>
                    </span>
                    <span>{item.customer?.name}</span>
                  </>
                )
              )}
              {!openDrawer ? (
                <IconButton
                  size="small"
                  style={{ color: "#fff" }}
                  onClick={() => openDrawerOrder()}
                >
                  <MoreVertOutlined />
                </IconButton>
              ) : (
                <IconButton
                  size="small"
                  style={{ color: "#fff" }}
                  onClick={() => closeDrawerOrder()}
                >
                  <Icon>close</Icon>
                </IconButton>
              )}
            </div>
          </div>
          <div className={styles.summaryView}>
            {summaryView && (
              <>
                <div className={styles.topSummary}>
                  {item.customer?.phoneNumber && (
                    <img
                      src={Whatsapp}
                      alt="Ícone whatsapp"
                      className={styles.linkItem}
                      onClick={() =>
                        handleOpenWhatsapp(item.customer?.phoneNumber ?? "")
                      }
                    />
                  )}
                  <span>{item.customer?.name && item.customer?.name}</span>
                </div>
                {item.deviceName && <span>Equipamento: {item.deviceName}</span>}
              </>
            )}
            <div className={styles.timerContainer}>
              {summaryView && (
                <span>({Utils.toDateAndTime(item.createdAt)})</span>
              )}
              <div className={styles.headerFooter}>
                {item.currentStep.name !== "Entregue" &&
                  item.currentStep.name !== "Recusado" &&
                  item.currentStep.name !== "Devolvido" ? (
                  <KDSTimer startDate={new Date(item.createdAt)} />
                ) : !summaryView ? (
                  <div style={{ fontSize: 12, opacity: 0.8 }}>
                    {new Date(item.createdAt).toLocaleString()}
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>

          <div className={styles.headerMain}>
            <span style={{ color: colorsStatus(item?.currentStep) }}>
              {" "}
              {item.currentStep.name}
            </span>
          </div>
        </div>
        <div className={styles.content}>
          {item.observation && (
            <div className={styles.observation}>
              <span>{item.observation}</span>
            </div>
          )}

          <div className={styles.order}>
            <div className={styles.list}>
              {item.order.items.map((orderItem, index) => {
                let showAdicionaisTag = true;
                if (
                  orderItem.compositions &&
                  orderItem.compositions.length === 1 &&
                  orderItem.compositions[0].description === "Observação"
                ) {
                  showAdicionaisTag = false;
                }
                return (
                  <div key={index} className={styles.orderItem}>
                    <span className={styles.categoryItem}>
                      {orderItem.nameCategory}
                    </span>
                    <div className={styles.top}>
                      <span>
                        {orderItem.quantity}x {orderItem.name}
                      </span>
                      {hasAccessRole("KDSVisualizarValores") && (
                        <span className={styles.value}>
                          {moneyMaskNumber(orderItem.value)}
                        </span>
                      )}
                    </div>
                    {orderItem.compositions
                      ?.filter((item) => !item.options.length)
                      .map(
                        (compositionItem, index) =>
                          !!compositionItem.description && (
                            <div className={styles.compositionItem} key={index}>
                              {index === 0 && (
                                <p className={styles.textObservation}>
                                  Observação:
                                </p>
                              )}
                              <span className={styles.observationComposition}>
                                {compositionItem.description}
                              </span>
                            </div>
                          )
                      )}
                    {!!orderItem.compositions?.length && (
                      <div className={styles.compositions}>
                        {orderItem.compositions.map(
                          (compositionItem, index) => (
                            <>
                              <div className={styles.compositionItem}>
                                {index === 0 && showAdicionaisTag && (
                                  <p className={styles.additional}>
                                    Adicionais:
                                  </p>
                                )}
                                <span className={styles.observationComposition}>
                                  {compositionItem.description}
                                </span>
                                {compositionItem.options.length > 0 && (
                                  <div
                                    className={styles.compositionItem}
                                    key={index}
                                  >
                                    <span>
                                      {compositionItem.options.map(
                                        (option, index) => (
                                          <ul>
                                            <li key={index}> - {option}</li>
                                          </ul>
                                        )
                                      )}
                                    </span>
                                  </div>
                                )}
                              </div>
                            </>
                          )
                        )}
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        {hasAccessRole("KDSVisualizarValores") && (
          <div className={styles.total}>
            <label>Valor</label>
            <span>{moneyMaskNumber(item.amount)}</span>
          </div>
        )}
        {!!buttons ? (
          <div className={styles.footer}>{buttons}</div>
        ) : (
          <div className={styles.footer}></div>
        )}
      </>
    ),
    [
      buttons,
      closeDrawerOrder,
      hasAccessRole,
      isLoading,
      item.amount,
      item.code,
      item.createdAt,
      item.currentStep,
      item.customer?.name,
      item.customer?.phoneNumber,
      item.deviceName,
      item.observation,
      item.order.items,
      item.orderOrigin,
      item.table,
      openDrawer,
      openDrawerOrder,
      summaryView,
    ]
  );

  return (
    <>
      <div id={styles.KDSItem}>
        {itemContent}
        <Dialog
          open={openAlertReceivedOrder}
          onClose={closeAlertReceivedHandle}
        >
          <DialogTitle>Confirmar entrega do pedido</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Ao confirmar essa ação não pode ser desfeita
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={closeAlertReceivedHandle}>Cancelar</Button>
            <Button onClick={confirmAlertReceivedHandle}>Confirmar</Button>
          </DialogActions>
        </Dialog>
      </div>
      <Drawer
        open={openDrawer}
        anchor="right"
        onClose={() => setOpenDrawer(false)}
      >
        <div id={styles.drawer}>
          <KDSSideSheetOrder
            colorsStatus={colorsStatus}
            kdsItem={item}
            postIssueNfe={postIssueNfe}
            onClickActionHandle={onClickActionHandle}
            onClickPrintHandle={onClickPrintHandle}
            openDrawer={openDrawer}
            setOpenDrawer={setOpenDrawer}
            openAlertReceivedHandle={openAlertReceivedHandle}
            isLoading={isLoading}
            openRefuseModal={openRefuseModal}
            openGiveBackModal={openGiveBackModal}
            issueNFCe={issueNFCe}
          />
        </div>
      </Drawer>

      <Drawer anchor="right" open={!!giveBackModal}>
        <div id={styles.drawer}>
          <div className={styles.containerModal}>
            <div className={styles.containerHeader}>
              <div className={styles.title}>
                <h2>
                  Devolver <span>pedido</span>
                </h2>
                <Close onClick={closeGiveBackModal} />
              </div>

              <AlertButton
                type="warnning"
                label={`Deseja realmente devolver o pedido ${item.code}?`}
              />
            </div>
            <div className={styles.containerButtons}>
              <Button
                fullWidth={false}
                onClick={closeGiveBackModal}
                variant="outlined"
                className={styles.cancel}
              >
                Cancelar
              </Button>
              <Button
                fullWidth={false}
                onClick={() =>
                  onClickActionHandle?.(KDSStepAction.Refund, item)
                }
                variant="contained"
                className={styles.action}
              >
                Devolver pedido
              </Button>
            </div>
          </div>
        </div>
      </Drawer>

      <Drawer anchor="right" open={!!refuseModal}>
        <div id={styles.drawer}>
          <div className={styles.containerModal}>
            <div className={styles.containerHeader}>
              <div className={styles.title}>
                <h2>
                  Recusar <span>pedido</span>
                </h2>
                <Close onClick={closeRefuseModal} />
              </div>

              <AlertButton
                type="warnning"
                label={`Deseja realmente recusar o pedido ${item.code}?`}
              />
            </div>
            <div className={styles.containerButtons}>
              <Button
                fullWidth={false}
                onClick={closeRefuseModal}
                variant="outlined"
                className={styles.cancel}
              >
                Cancelar
              </Button>
              <Button
                fullWidth={false}
                onClick={() =>
                  onClickActionHandle?.(KDSStepAction.Refuse, item)
                }
                variant="contained"
                className={styles.action}
              >
                Recusar pedido
              </Button>
            </div>
          </div>
        </div>
      </Drawer>
    </>
  );
};
export default KDSItem;
