import { ISort } from "interfaces/ISort";
import { IGetRankingProductsResponse } from "modules/dashboardTicket/domain/dto/IGetRankingProductsResponse";
import { IDashboardTicketApiService } from "modules/dashboardTicket/domain/interfaces/IMyAccountApiService";
import { IFilterValue } from "modules/dashboardTicket/presentation/interfaces/IFilter";
import { IRankingProducts } from "modules/dashboardTicket/presentation/interfaces/IRankingProducts";


const GetRankingProductsUseCase = async (service: IDashboardTicketApiService, localId: string, filter?: IFilterValue, sort?: ISort): Promise<IRankingProducts[]> => {

    const response: IGetRankingProductsResponse[] = await service.getRankingProducts(localId, filter, sort);


    const parse: IRankingProducts[] = response.map(item => {


        return {
            categoryId: item.CategoriaId,
            categoryName: item.CategoriaNome,
            colorPortal: item.CorPortal,
            description: item.Descricao,
            imageUrl: item.ImagemUrl,
            localName: item.LocalNome,
            productId: item.ProdutoId,
            productName: item.ProdutoNome,
            quantity: item.Quantidade,
            totalValue: item.ValorTotal,
            unitaryValue: item.ValorUnitario
        };
    });

    return parse;

}

export default GetRankingProductsUseCase;