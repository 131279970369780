import { FC } from 'react'
import styles from './CodePage.module.scss'
import { UseCodePage } from './UseCodePage'
import ReactCodeInput from 'react-code-input'
import Timer from '../../components/timer/Timer'
import { UseBackPage } from '../../hooks/UseBackPage'
import SaasPublicLayout from '../SaasPublicLayout'
import Button from 'components/ui/Button/Button'
import UseDimension from 'components/dimension/UseDimension'
import { CircularProgress } from '@mui/material'
import UseProspect from '../../hooks/UseProspect'
import { ProspectType } from 'modules/saas/domain/interfaces/ProspectTypeEnum'

export interface IRegisterPageProps {
    //propertys
}

const CodePage: FC<IRegisterPageProps> = () => {
    const {
        code,
        isLoading,
        progressBarColor,
        backgroundColorTop,
        backgroundColorBottom,
        setCode,
        handleSubmit,
        handleNewCode,
    } = UseCodePage();
    const { onBack } = UseBackPage();
    const { dimensions } = UseDimension();
    const { type } = UseProspect();
    
    return (
      <SaasPublicLayout
        step={type === ProspectType.CieloEvent || type === ProspectType.CieloEstablishment ? 3 : 2}
        totalSteps={type === ProspectType.CieloEvent || type === ProspectType.CieloEstablishment ? 6 : 5}
        progressBarColor={progressBarColor}
        backgroundColorTop={backgroundColorTop}
        backgroundColorBottom={backgroundColorBottom}
      >
        <div className={styles.container}>
          <div className={styles.header}>
            <h1>Agora, vamos confirmar seu email</h1>
            <span>Leva só 1 minuto</span>
          </div>

          <div className={styles.validateContent}>
            <p>Digite abaixo o código enviado para o seu e-mail:</p>
            <div className={styles.row}>
              <ReactCodeInput
                inputMode="numeric"
                name="code"
                type="number"
                fields={6}
                value={code}
                onChange={(value) => setCode(value)}
                inputStyle={{
                  fontFamily: "'Roboto', sans-serif",
                  fontSize: "18px",
                  fontWeight: "bold",
                  borderRadius: "8px",
                  border: "1px solid #A2A2A2",
                  margin: "4px",
                  padding: "10px",
                  width: dimensions.width > 600 ? "50px" : dimensions.width > 460 ? "40px" : "30px",
                  height: dimensions.width > 600 ? "50px" : dimensions.width > 460 ? "40px" : "30px",
                  boxSizing: "border-box",
                  textAlign: "center"
                }}
              />
            </div>

            <Timer initialTime={120} handleNewCode={handleNewCode} style={{ marginTop: "20px" }} btnColor={backgroundColorTop} />

            <div className={styles.footerButtons}>
              <Button variant="text" color="secondary" onClick={() => onBack("register")} style={{ color: backgroundColorTop }}>
                Voltar
              </Button>
              <Button
                color="secondary"
                style={{ backgroundColor: backgroundColorTop }}
                onClick={handleSubmit}
                disabled={code.length < 6}
              >
                {isLoading ? <CircularProgress size={16} color="inherit" /> : "Começar"}
              </Button>
            </div>
          </div>
        </div>
      </SaasPublicLayout>
    );
}
export default CodePage