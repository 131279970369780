import { useCallback, useEffect, useState } from "react";
import { ILocalSegments } from "../../interfaces/localSegments/ILocalSegments";
import { SaasApi } from "services/api/saas/SaasApi";
import { GetLocalSegmentsUseCase } from "modules/saas/application/useCases/GetLocalSegmentsUseCase";
import { ISimpleCheckoutRequest } from "modules/saas/domain/dtos/simpleCheckout/ISimpleCheckoutRequest";
import SimpleCheckoutUseCase from "modules/saas/application/useCases/SimpleCheckoutUseCase";
import { ValidateSimpleSaasUseCase } from "modules/saas/application/useCases/ValidateSimpleSaasUseCase";
import { useUi } from "contexts/userInterface/UserInterfaceContext";
import { useHistory } from "react-router-dom";
import { useQuery } from "hooks/UseQuery";
import { CheckEmailUseCase } from "modules/saas/application/useCases/CheckEmailUseCase";
import { getParamsObject, getParamsString } from "../../utils/params";
import { ILeads } from "../../interfaces/leads/ILeads";
import { SaveLeadsUseCase } from "modules/saas/application/useCases/SaveLeadsUseCase";
import { ILeadUser } from "../../interfaces/leads/ILeadUser";
import CryptoJS from "crypto-js";
import { cryptPassIv, cryptPassKey } from "Enviroment";

export enum StepEnum {
    form = 1,
    validate = 2
}

const service = SaasApi();

export const UseSimpleRegister = () => {
    const { toast, showLoading, hideLoading } = useUi();
    const { push } = useHistory();
    const query = useQuery();
    
    const [segments, setSegments] = useState<ILocalSegments[]>([]);
    const [step, setStep] = useState(StepEnum.form);
    const [isLoading, setIsLoading] = useState(false);
    const [leads, setLeads] = useState<ILeads>();
    const [user, setUser] = useState<ILeadUser>();
    const [encryptedPass, setEncryptedPass] = useState("");
    
    const handleAccessAccount = useCallback(() => {
        push('/public/saas/simple-login?' + getParamsString(query));
    }, [push, query]);

    const handleAccessPortal = useCallback(() => {
        push('/login?' + getParamsString(query));
    }, [push, query]);

    useEffect(() => {
        if (!leads)
            setLeads(getParamsObject(query));
    }, [leads, query]);

    useEffect(() => {
        (async () => {
            const response = await GetLocalSegmentsUseCase(service);
            setSegments(response);
        })();
    }, []);

    useEffect(() => {
        (async () => {
            if (!user && !!leads && leads.email) {
                try {
                    const response = await SaveLeadsUseCase(service, leads);
                    setUser(response);
                } finally {}
            }
        })();
    }, [handleAccessAccount, handleAccessPortal, leads, user]);

    useEffect(() => {
        const email = query.get("email");
        if (email) {
            (async () => {
                try {
                    showLoading();
                    const exist = await CheckEmailUseCase(service, email);
                    if (exist.isOwner) {
                        return handleAccessPortal();
                    }
                    if (exist.exists) {
                        handleAccessAccount();
                    }
                } finally {
                    hideLoading();
                }
            })();
        }        
    }, [handleAccessAccount, handleAccessPortal, hideLoading, query, showLoading]);

    const getSHA256 = (key: string) => {
        if (!key) {
            return null;
        }
    
        const hash = CryptoJS.SHA256(key);
        return hash.toString(CryptoJS.enc.Hex);
    }
    
    const getSHA128 = (iv: string) => {
        if (!iv) {
            return null;
        }
    
        const hash = CryptoJS.SHA256(iv);
        return hash.toString(CryptoJS.enc.Hex).slice(0, 32);
    }

    const encrypt = useCallback((pass: string) => {
        try {
          const keyHex = getSHA256(cryptPassKey ?? "");
          const ivHex = getSHA128(cryptPassIv ?? "");
      
          if (keyHex && ivHex) {
            const encrypted = CryptoJS.AES.encrypt(
              pass,
              CryptoJS.enc.Hex.parse(keyHex),
              { iv: CryptoJS.enc.Hex.parse(ivHex), mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 }
            );
      
            return encodeURIComponent(encrypted.toString());
          }
        } catch (error) {
          console.error("Erro ao criptografar:", error);
          return null;
        }
    }, []);

    const handleRegister = useCallback(async (values: ISimpleCheckoutRequest) => {
        if (isLoading) return;
        try {
            setIsLoading(true);
            await SimpleCheckoutUseCase(service, {...values, email: values.email.trim() });

            const encryptPass = encrypt(values.password);
            setEncryptedPass(encryptPass ?? "");

            setStep(StepEnum.validate);
        } catch (error: any) {
            if (error.response.data.message.includes("possui um estabelecimento criado")) {
                return handleAccessPortal();
            }
            toast(error.response.data.message, 'error');
        } finally {
            setIsLoading(false);
        }
    }, [encrypt, handleAccessPortal, isLoading, toast]);
    
    const handleValidate = useCallback(async (email: string, code: string) => {
        if (isLoading) return;
        try {
            setIsLoading(true);            
            const res = await ValidateSimpleSaasUseCase(service, { email, code });

            if (res.valid) {
                push(`/public/saas/simple-success?email=${email}`, { crypt: encryptedPass });
            } else {
                toast('A chave fornecida está inválida ou expirou.', 'error');
            }
        } catch {
            toast('Ocorreu um erro ao validar o código. Tente novamente.', 'error');
        } finally {
            setIsLoading(false);
        }
    }, [encryptedPass, isLoading, push, toast]);

    return {
        segments,
        step,
        isLoading,
        setStep,
        handleRegister,
        handleValidate
    }
}