import GetDigitalAccounts from "modules/contaDigital/application/useCases/DigitalAccountsUseCase";
import { DigitalAccountResponse } from "modules/contaDigital/models/dtos/DigitalAccountResponse";
import { useLocal } from "modules/local/presentation/context/LocalContext";
import { useCallback, useEffect, useState } from "react";
import DigitalAccountApi from "services/api/digitalAccount/DigitalAccountApi";
import { SelectChangeEvent } from "@mui/material";
import GetDigitalAccountsBalance from "modules/contaDigital/application/useCases/DigitalAccountsBalanceUseCase";
import { DigitalAccountBalanceResponse } from "modules/contaDigital/models/dtos/DigitalAccountBalanceResponse";
import PostWithdrawal from "modules/contaDigital/application/useCases/PostWithdrawalUseCase";
import PostConfirmationSms from "modules/contaDigital/application/useCases/PostConfirmationSmsUseCase";
import PostPayAccount from "modules/contaDigital/application/useCases/PostPayAccountUseCase";
import GetBankDetail from "modules/contaDigital/application/useCases/BankDetailUseCase";
import { BankDetailDepositResponse } from "modules/contaDigital/models/dtos/BankDetailDepositResponse";
import { useUi } from "contexts/userInterface/UserInterfaceContext";
import DeleteDadoBancario from "modules/contaDigital/application/useCases/DeleteDadoBancarioUseCase";
import GetExtract from "modules/contaDigital/application/useCases/ExtractUseCase";
import { subDays, startOfDay, endOfDay } from "date-fns";
import { useParams } from "react-router-dom";
import { ExtractResponse } from "modules/contaDigital/models/dtos/ExtractResponse";
import GetTotalLockUseCase from "modules/contaDigital/application/useCases/GetTotalLockUseCase";
import { TotalLockResponse } from "modules/contaDigital/models/dtos/TotalLockResponse";

export const UseContaDigitalPage = () => {
  const [contasDigitais, setContasDigitais] = useState<
    DigitalAccountResponse[] | undefined
  >();
  const [account, setAccount] = useState<DigitalAccountResponse | undefined>();
  const [balance, setBalance] = useState<
    DigitalAccountBalanceResponse | undefined
  >();
  const [bankDetail, setBankDetail] = useState<
    BankDetailDepositResponse[] | undefined
  >();
  const [listExtract, setListExtract] = useState<ExtractResponse | undefined>();
  const [loadingExtract, setLoadingExtract] = useState(false);
  const [loadingBalance, setLoadingBalance] = useState(false);
  const [totalLock, setTotalLock] = useState<TotalLockResponse | undefined>()

  const accountService = DigitalAccountApi();
  const { currentLocal } = useLocal();
  const { toast } = useUi();

  const { account: accountId } = useParams<{ account: string }>();

  const handleChange = (event: SelectChangeEvent) => {
    const selectedAccount =
      contasDigitais &&
      contasDigitais.find((conta) => conta.id === event.target.value);

    if (selectedAccount) {
      setAccount(selectedAccount);
    }
  };

  const handleDate = (period: number, periodStart: Date, periodEnd: Date) => {
    let endDate = endOfDay(new Date()).toISOString();
    let startDate;

    switch (period) {
      case 7:
        startDate = startOfDay(subDays(new Date(), 7)).toISOString();
        break;
      case 15:
        startDate = startOfDay(subDays(new Date(), 15)).toISOString();
        break;
      case 30:
        startDate = startOfDay(subDays(new Date(), 30)).toISOString();
        break;
      default:
        startDate = startOfDay(periodStart).toISOString();
        endDate = endOfDay(periodEnd).toISOString();
        break;
    }
    getExtract(startDate, endDate);
  };

  const getDigitalAccounts = useCallback(async () => {
    if (currentLocal) {
      const response = await GetDigitalAccounts(
        accountService,
        currentLocal.id
      );
      setContasDigitais(response);
      setAccount(response[0]);
    }
  }, [accountService, currentLocal]);

  const getExtract = useCallback(
    async (dateStart, dateEnd) => {
      setLoadingExtract(true);
      if (currentLocal) {
        await GetExtract(
          accountService,
          accountId,
          dateStart,
          dateEnd,
          currentLocal.id,
          0,
          20
        )
          .then((response) => {
            setListExtract(response);
            setLoadingExtract(false);
          })
          .catch((error) => {
            if (error?.response?.data?.message) {
              toast(error.response?.data.message, "error");
            }
          });
      }
    },
    [accountId, accountService, currentLocal, toast]
  );

  const getBankDetail = useCallback(async () => {
    if (currentLocal) {
      const response = await GetBankDetail(accountService, currentLocal.id);
      setBankDetail(response);
    }
  }, [accountService, currentLocal]);

  const getDigitalAccountBalance = useCallback(
    async (accountId) => {
      setLoadingBalance(true)
      if (currentLocal) {
        await GetDigitalAccountsBalance(
          accountService,
          currentLocal.id,
          accountId
        ).then((response) => {
          setBalance(response);
          setLoadingBalance(false);
        })
          .catch((error) => {
            if (error?.response?.data?.message && currentLocal.systemIdentifier !== 1) {
              toast(error.response?.data.message, "error");
            }
          });
      }
    },
    [currentLocal, accountService, toast]
  );

  const postWithdrawal = useCallback(
    async (ValorSolicitado, success = () => { }, TedTerceiro?, values?) => {
      const body = {
        DadoBancarioOrigemId: account && account.id,
        LocalClienteId: currentLocal && currentLocal.id,
        SaldoRestante: TedTerceiro
          ? values?.SaldoRestante
          : balance &&
          balance?.balanceResult.balanceAvailable -
          balance?.balanceResult.tedFee -
          ValorSolicitado,
        TaxaTED: TedTerceiro ? values?.TaxaTED : balance?.balanceResult.tedFee,
        ValorSolicitado: ValorSolicitado,
        desconto: 0,
        document: account && account.documento,
        multa: 0,
      };
      const bodyTedTerceiro = {
        ...body,
        codigoConfirmacao: values?.codigo,
        TEDParaTerceiro: {
          codigoBanco: values?.banco.value,
          agencia: values?.agencia,
          conta: values?.contaCorrente,
          agenciaNumero: values?.agencia,
          agenciaDigito: values?.digitoAg,
          contaNumero: values?.contaCorrente,
          contaDigito: values?.digitoConta,
          documento: values?.documento,
          nomeTitular: values?.nome,
        },
      };
      await PostWithdrawal(accountService, TedTerceiro ? bodyTedTerceiro : body)
        .then(() => {
          success();
        })
        .catch((error) => {
          if (error?.response?.data?.message) {
            toast(error.response?.data.message, "error");
          }
        });
    },
    [account, currentLocal, balance, accountService, toast]
  );

  const sendConfirmationSms = useCallback(
    (success = () => { }) => {
      if (currentLocal && account) {
        PostConfirmationSms(
          accountService,
          currentLocal && currentLocal.id,
          account.id
        )
          .then(() => {
            success();
          })
          .catch((error) => {
            if (error?.response?.data?.message) {
              toast(error.response?.data.message, "error");
            }
          });
      }
    },
    [currentLocal, account, accountService, toast]
  );

  const deleteDadoBancario = useCallback(
    (id, success = () => { }) => {
      DeleteDadoBancario(accountService, id)
        .then(() => {
          success();
        })
        .catch((error) => {
          if (error?.response?.data?.message) {
            toast(error.response?.data.message, "error");
          }
        });
    },
    [accountService, toast]
  );

  const postPayAccount = useCallback(
    async (values) => {
      const body = {
        LocalClienteId: currentLocal?.id,
        DadoBancarioOrigemId: account?.id,
        CodigoBarras: values.CodigoBarras,
        Valor: Number(values.valor),
        TaxaBoleto: values.custoSaque,
        SaldoRestante: values.SaldoRestante,
        codigoConfirmacao: values.codigo,
        documento: values.documento,
        multa: Number(values.multa),
        desconto: Number(values.desconto),
      };
      await PostPayAccount(accountService, body);
    },
    [currentLocal, account, accountService]
  );

  const getTotalLock = useCallback(async () => {
    if (currentLocal) {
      const response = await GetTotalLockUseCase(
        accountService,
        currentLocal.id
      );
      setTotalLock(response);
    }
  }, [accountService, currentLocal]);

  useEffect(() => {
    getDigitalAccounts();
    getBankDetail();
    getTotalLock()
    getExtract(
      startOfDay(subDays(new Date(), 7)).toISOString(),
      endOfDay(new Date()).toISOString()
    );
  }, [currentLocal]);

  return {
    contasDigitais,
    account,
    handleChange,
    balance,
    postWithdrawal,
    getDigitalAccountBalance,
    sendConfirmationSms,
    postPayAccount,
    bankDetail,
    deleteDadoBancario,
    listExtract,
    handleDate,
    loadingExtract,
    loadingBalance,
    totalLock
  };
};
