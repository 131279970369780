import { IGetServiceRateResponse } from "modules/dashboardTicket/domain/dto/IGetServiceRateResponse";
import { IDashboardTicketApiService } from "modules/dashboardTicket/domain/interfaces/IMyAccountApiService";
import { IFilterValue } from "modules/dashboardTicket/presentation/interfaces/IFilter";
import { IServiceRate } from "modules/dashboardTicket/presentation/interfaces/IServiceRate";


const GetServiceRateUseCase = async (service: IDashboardTicketApiService, localId: string, filter?: IFilterValue): Promise<IServiceRate> => {

    const response: IGetServiceRateResponse = await service.getServiceRate(localId, filter);

    const parse: IServiceRate =  {
        
         
            value: response.taxaServico,
        
    };

    return parse;

}

export default GetServiceRateUseCase;