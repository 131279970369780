import React, { FC, useCallback, useEffect, useState } from 'react'
import styles from './CatalogPage.module.scss'
import CatalogListTab, { ICatalogListItem } from './componentes/catalogListTab/CatalogListTab'
import GetCatalogListsUseCase from '../application/useCases/GetCatalogListsUseCase'
import { useLocal } from 'modules/local/presentation/context/LocalContext'
import CatalogService from 'services/api/catalog/CatalogService'
import CatalogFragment from './fragments/catalogFragment/CatalogFragment'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import CatalogFormFragment from './fragments/catalogFormFragment/CatalogListFragment'
import { Drawer } from '@material-ui/core'
import { useBreadcumbs } from 'components/breadcumbs/BreadcumbsContext'
import { ProductContextProvider } from 'modules/products/presentation/context/ProductPageContext'
import UseQueryParams from './hooks/UseQueryParams'
export interface ICatalogPageProps {
    //propertys
}

const catalogService = CatalogService();
const CatalogPage: FC<ICatalogPageProps> = () => {
    const { currentLocal } = useLocal();
    const [list, setList] = useState<ICatalogListItem[]>([])
    const { push } = useHistory();
    const { updateRouters } = useBreadcumbs();
    const [openCatalogForm, setOpenCatalogForm] = useState(false);
    const { catalogId } = useParams<{ catalogId: string }>()
    const { search } = useLocation();
    const params = React.useMemo(() => new URLSearchParams(search), [search]);

    const getList = useCallback(
        async () => {
            if (currentLocal) {
                const listResponse = await GetCatalogListsUseCase(catalogService, currentLocal.id);
                setList(listResponse)
            }
        },
        [currentLocal]
    );

    useEffect(() => {
        updateRouters([
            {
                title: "Cardápio",
            },
        ]);

        return () => {
            updateRouters([]);
        };
    }, [updateRouters]);


    const onChageCatalog = useCallback((listItem?: ICatalogListItem) => {
        push(`/private/catalog/${listItem?.id ?? ""}`)
    }, [push])

    useEffect(() => {
        getList();
    }, [currentLocal, getList])

    const onClickAdd = useCallback(() => {
        push(`/private/catalog/${catalogId}?action=addCatalog`)
    }, [catalogId, push])

    const onClose = useCallback(() => {
        push(`/private/catalog/${catalogId}`)
    }, [catalogId, push]);

    useEffect(() => {
        const action = params.get("action")
        if (action === "addCatalog" || action === "editCatalog") {
            setOpenCatalogForm(true);
        } else {
            setOpenCatalogForm(false);
        }
    }, [params]);

    return (
        <ProductContextProvider>
            <div id={styles.CatalogPage} >
                <div className={styles.container} >
                    <CatalogListTab
                        list={list}
                        onChangeList={onChageCatalog}
                        onClickAddList={onClickAdd}
                    />

                    <CatalogFragment catalogs={list} getCatalogs={getList} />
                    <Drawer anchor='right' open={openCatalogForm} onClose={onClose}>
                        <CatalogFormFragment reloadCatalogList={getList} onClose={onClose} />
                    </Drawer>
                </div>
            </div>
        </ProductContextProvider>
    )
}
export default CatalogPage