import { useLocal } from "modules/local/presentation/context/LocalContext";
import { useCallback, useState } from "react";
import { IMyAccount } from "../interfaces/IMyAccount";
import DashboardTicketApi from "services/api/dashboardTicket/DashboardTicketApi";
import GetMyAccountUseCase from "modules/dashboardTicket/application/useCases/GetMyAccountUseCase";

const UseMyAccount = () => {

  const [isLoading, setIsLoading] = useState<boolean>();
  const [myAccount, setMyAccount] = useState<IMyAccount>()
  const { currentLocal } = useLocal();

  const getMyAccount= useCallback(
    async () => {
      try {
        const servicePermutation = DashboardTicketApi();
        setIsLoading(true);
        if (currentLocal) {
          const response = await GetMyAccountUseCase(servicePermutation,
            currentLocal.id,          
          );

          setMyAccount(response);

          return response
        }
      } finally {
        setIsLoading(false);
      }
    },
    [currentLocal]
  );


  return {
    myAccount,
    isLoading,
    getMyAccount,
    setMyAccount,
  };
};

export default UseMyAccount;
