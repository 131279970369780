import { z } from "zod";

export const addressSchema = z.object({
  zipCode: z.string()
    .min(8, "Campo obrigatório!"),
  street: z.string()
    .min(1, "Campo obrigatório!"),
  number: z.string()
    .min(1, "Campo obrigatório!"),
  complement: z.string().optional(),
  neighborhood: z.string(),
  city: z.string()
    .min(1, "Campo obrigatório!"),
  state: z.string()
  .min(1, "Campo obrigatório!"),
  country: z.string(),
});

export type IAddressSchema = z.infer<typeof addressSchema>;
