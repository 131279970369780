import { useLocal } from "modules/local/presentation/context/LocalContext";
import { useCallback, useState } from "react";
import { IFilterValue } from "../interfaces/IFilter";
import { ISalesCategories } from "../interfaces/ISalesCategories";
import GeneralDashboardApi from "services/api/generalDashboard/GeneralDashboardApi";
import GetSalesCategoriesUseCase from "modules/generalDashboard/application/useCases/GetSalesCategoriesUseCase";

export interface IParamsUseSalesCategories {
  localId?: string,
  sessionId?: string,
  period?: number,
}

const UseSalesCategories = (filter: IFilterValue | undefined) => {

  const [isLoading, setIsLoading] = useState<boolean>();
  const [salesCategories, setSalesCategories] = useState<ISalesCategories[]>()
  const { currentLocal } = useLocal();

  const getSalesCategories = useCallback(
    async (params?: IParamsUseSalesCategories) => {
      try {
        const serviceGeneral = GeneralDashboardApi();
        setIsLoading(true);
        if (currentLocal) {
          const response = await GetSalesCategoriesUseCase(serviceGeneral,
            currentLocal.id,
            {
              ...filter,
            }
          );

          setSalesCategories(response);

          return response
        }
      } finally {
        setIsLoading(false);
      }
    },
    [currentLocal, filter]
  );


  return {
    salesCategories,
    isLoading,
    getSalesCategories,
    setSalesCategories,
  };
};

export default UseSalesCategories;
