import FormItemContainer from 'components/ui/inputContainer/FormItemContainer';
import Input from 'components/ui/input/Input'
import React, { FormEvent, useCallback, useEffect, useState } from 'react';
import FooterButtons from '../../../footerButtons/FooterButtons'
import { IUserInterfaceValues } from './IUserInterfaceValues';
import styles from './UserInterfaceForm.module.scss'

export interface IUserInterfaceFormProps {
    defaultValue?: IUserInterfaceValues;
    onModified: (modified: boolean) => void;
    onSubmit: (value: IUserInterfaceValues) => void
    onClickBack: (value: IUserInterfaceValues) => void
}

const UserInterfaceForm: React.FC<IUserInterfaceFormProps> = ({ defaultValue, onModified, onSubmit, onClickBack }) => {
    const [values, setValues] = useState<IUserInterfaceValues>(defaultValue ?? {} as IUserInterfaceValues);

    useEffect(() => {
        if (defaultValue) {
            setValues(defaultValue);
        }
    }, [defaultValue]);

    const onChangeHandle = (property: string, value: any) => {
        const properties = property.split('.');
        const fullObj = values[properties[0] as keyof IUserInterfaceValues] as object;

        if (properties.length === 1) {
            setValues((prev) => ({ ...prev, [properties[0]]: value }));
        } else {
            setValues((prev) => ({ ...prev, [properties[0]]: { ...fullObj, [properties[1]]: value } }));
        }

        onModified(true);
    }

    const submitHandle = useCallback((ev: FormEvent<HTMLFormElement>) => {
        ev.preventDefault();
        onSubmit(values);
    }, [onSubmit, values]);

    return (
        <form id={styles.UserInterfaceForm} onSubmit={submitHandle}>
            <span>
            Insira uma imagem do plano de fundo e personalize sua mensagem de pagamento.
            </span>
            <div className={styles.container}>
                <FormItemContainer className={styles.formItemContainer} label={"URL da imagem"}>
                    <Input 
                        placeholder='https:/image/www.97916818443/unknown.png'
                        variant={"outlined"} 
                        value={values?.wallpaperUrl || ''} 
                        onChange={ev => onChangeHandle("wallpaperUrl", ev.target.value)} 
                    />
                </FormItemContainer>
                
                <FormItemContainer className={styles.formItemContainer} label={"Mensagem após o pagamento"}>
                    <Input 
                        placeholder='Pagamento realizado com sucesso!'
                        variant={"outlined"} 
                        value={values?.labels?.paymentSuccess || ''} 
                        onChange={ev => onChangeHandle("labels.paymentSuccess", ev.target.value)} 
                    />
                </FormItemContainer>

                {/* <FormItemContainer className={styles.formItemContainer} label={"Mensagem após a ativação do cartão"}>
                    <Input 
                        placeholder='Cartão ativado com sucesso!'
                        variant={"outlined"} 
                        value={values?.labels?.activationCashless || ''} 
                        onChange={ev => onChangeHandle("labels.activationCashless", ev.target.value)} 
                    />
                </FormItemContainer> */}
            </div>
            <>
                <FooterButtons onClickBack={() => onClickBack(values)} labelNext={"Salvar"} nextType='submit'></FooterButtons>
            </>
        </form>
    )
}
export default UserInterfaceForm