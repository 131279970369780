
import { ICatalogService } from "modules/catalog/domain/interface/ICatalogService"
import { ICatalogProductCategoryFormValue, ISelectCategory, ISelectLocal } from "modules/catalog/presentation/componentes/_catalogProductCategoryForm/CategoryForm"

const GetCategoryUseCase = async (service: ICatalogService, localId: string, categorId: string, mainCategories: ISelectCategory[], invoiceSplitLocal: ISelectLocal[]): Promise<ICatalogProductCategoryFormValue> => {
    const response = await service.getCategory(localId, categorId);

    const value = {
        description: response.description,
        mainCategory: mainCategories.find(item => item.id === response.mainCategoryId),
        invoiceSplitLocal: invoiceSplitLocal.find(item => item.id === response.invoiceSplitLocalId),
        showInDashboard: response.showInDashboard,
        showInPOS: response.showInPOS,
        index: response.index,
        id: response.id,
    }
    return (value)
}
export default GetCategoryUseCase