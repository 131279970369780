import { EnumGender } from "modules/crm/presentation/intefaces/GenderEnum";

export interface ICrmFormOperatorValue<T = string> {
  filterType?: FilterOperatorType;
  startValue?: T;
  endValue?: T;
}
export interface IDropdown {
  name: string;
  id: string;
}

export interface ICrmFilterValue {
  keyword?: string;
  hasPhoneNumber?: boolean;
  hasEmail?: boolean;
  events?: IDropdown[];
  eventCategories?: IDropdown[];
  fidelityLevel?: IDropdown;
  ticketCategories?: IDropdown[];
  gender?: EnumGender;
  frequency?: ICrmFormOperatorValue<number>;
  age?: {
    filterType?: number;
    startAge?: number;
    endAge?: number;
  };
  averageTicketConsuption?: ICrmFormOperatorValue<number>;
  totalConsuptionValue?: ICrmFormOperatorValue<number>;
  totalPurchasedTicketsValue?: ICrmFormOperatorValue<number>;
  averageTicketEventTicket?: ICrmFormOperatorValue<number>;
  eventPeriod?: ICrmFormOperatorValue<Date>;
  birthday?: ICrmFormOperatorValue<Date>;
  surveyHealth?: ICrmFormOperatorValue<number>;
  hasMeepApp?: boolean;
  points?: ICrmFormOperatorValue<number>;
  coins?: ICrmFormOperatorValue<number>;
}

export enum FilterOperatorType {
  LESS_THAN = 1,
  GREATER_THAN = 2,
  BETWEEN = 3,
}
