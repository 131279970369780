import { Button, Icon, IconButton, TextField } from "@material-ui/core";
import { AddCircle } from "@material-ui/icons";
import { Skeleton } from "@material-ui/lab";
import { EventStatusEnum } from "modules/events/domain/models/IEvents";
import React, { FC, FormEvent, useCallback, useEffect, useMemo, useState } from "react";
import styles from "./FilterEventList.module.scss";
import { UseGestaoVendas } from "modules/gestaoVendas/presentation/hooks/UseGestaoVendas";
import { useAuth } from "modules/auth/presentation/context/AuthContext";

export interface IFilterDateEventsList {
  year?: number;
  month?: number;
  status?: EventStatusEnum;
  keyword?: string;
}

export interface IFilterSearchEventsList {
  search: string;
}

export interface EventPriodResumeValue {
  events: number; //Eventos
  customers: number; //Clientes do mês
  consumer: number; //Faturamento do mês
  averageTicketConsume: number; //Ticket médio de consumo
  averageTicketIngress: number; //Ticket médio de ingresso
}

export interface IFilterEventListProps {
  //propertys
  onChangeFilter: (values: IFilterDateEventsList) => void;
  onSubmitSearchFilter: (values: IFilterDateEventsList) => void;
  goToAddEvents: () => void;
  title: string;
  values?: IFilterDateEventsList;
  resume?: EventPriodResumeValue;
  eventsResumeLoading: boolean;
}

const FilterEventList: FC<IFilterEventListProps> = ({
  values,
  onChangeFilter,
  resume,
  goToAddEvents,
  eventsResumeLoading,
}) => {
  const { hasAccessRole } = useAuth();
  const [searchInputValue, setSearchInputValue] = useState<string>("");
  const { getSegmentos, responseConfigLocal } = UseGestaoVendas(true);

  useEffect(() => {
    getSegmentos();
  }, [getSegmentos]);

  const onClickMonth = useCallback(
    (month: number) => {
      onChangeFilter({ ...values, month, status: EventStatusEnum.ALL });
    },
    [onChangeFilter, values]
  );

  const onClickStatus = useCallback(
    (status: EventStatusEnum) => {
      onChangeFilter({ ...values, status });
    },
    [onChangeFilter, values]
  );

  const onDownYear = useCallback(() => {
    onChangeFilter({
      ...values,
      year: (values?.year ?? new Date().getFullYear()) - 1,
      status: EventStatusEnum.ALL,
    });
  }, [onChangeFilter, values]);

  const onUpYear = useCallback(() => {
    onChangeFilter({
      ...values,
      year: (values?.year ?? new Date().getFullYear()) + 1,
      status: EventStatusEnum.ALL,
    });
  }, [onChangeFilter, values]);

  const onClickCurrentDate = useCallback(() => {
    onChangeFilter({
      ...values,
      month: new Date().getMonth() + 1,
      year: new Date().getFullYear(),
      status: EventStatusEnum.ALL,
    });
  }, [onChangeFilter, values]);

  const submitHandle = useCallback(
    (ev: FormEvent<HTMLFormElement>) => {
      ev.preventDefault();
      onChangeFilter({ keyword: searchInputValue });
    },
    [onChangeFilter, searchInputValue]
  );

  const currentDate: IFilterDateEventsList = useMemo(
    () => ({
      month: new Date().getMonth() + 1,
      year: new Date().getFullYear(),
    }),
    []
  );
  const isDiferentDate = useMemo(
    () =>
      values?.month !== currentDate.month || values?.year !== currentDate.year,
    [currentDate.month, currentDate.year, values?.month, values?.year]
  );

  return (
    <div id={styles.FilterEventList}>
      <div className={styles.container}>
        <div className={styles.header}>
          <div className={styles.actionButton}>
            {responseConfigLocal &&
              responseConfigLocal?.trabalhaComEventos && (
                <Button
                  fullWidth={false}
                  onClick={goToAddEvents}
                  variant="contained"
                  className={styles.newEventButton}
                >
                  Novo evento
                  <AddCircle />
                </Button>
              )}
          </div>
          <form className={styles.input} onSubmit={submitHandle}>
            <div className={styles.containerSearch}>
              <TextField
                fullWidth
                size="small"
                placeholder="Buscar"
                value={searchInputValue}
                onChange={(ev) => setSearchInputValue(ev.currentTarget.value)}
              />
              <IconButton
                title="Buscar estabelecimento"
                className={styles.button}
                type={"submit"}
              >
                <Icon>search</Icon>
              </IconButton>
            </div>
          </form>
        </div>

        <div className={styles.yearWrapper}>
          <div className={styles.year}>
            <IconButton onClick={onDownYear} title="Ano anterior">
              <Icon>arrow_left</Icon>
            </IconButton>
            <span>{values?.year}</span>
            <IconButton
              onClick={onUpYear}
              title="Próximo ano" /*disabled={now.getFullYear() === values.year} */
            >
              <Icon>arrow_right</Icon>
            </IconButton>
          </div>
          <div className={styles.actionButton}>
            {isDiferentDate && (
              <Button
                variant="text"
                color="secondary"
                style={{ textDecoration: "underline" }}
                fullWidth={false}
                onClick={onClickCurrentDate}
              >
                Voltar ao mês atual
              </Button>
            )}
          </div>
        </div>
        <div className={styles.month}>
          <Button
            className={[
              styles.buttom,
              values?.month === 1 ? styles.active : "",
            ].join(" ")}
            variant={values?.month === 1 ? "contained" : "outlined"}
            onClick={() => onClickMonth(1)}
          >
            Jan
          </Button>
          <Button
            className={[
              styles.buttom,
              values?.month === 2 ? styles.active : "",
            ].join(" ")}
            variant={values?.month === 2 ? "contained" : "outlined"}
            onClick={() => onClickMonth(2)}
          >
            Fev
          </Button>
          <Button
            className={[
              styles.buttom,
              values?.month === 3 ? styles.active : "",
            ].join(" ")}
            variant={values?.month === 3 ? "contained" : "outlined"}
            onClick={() => onClickMonth(3)}
          >
            Mar
          </Button>
          <Button
            className={[
              styles.buttom,
              values?.month === 4 ? styles.active : "",
            ].join(" ")}
            variant={values?.month === 4 ? "contained" : "outlined"}
            onClick={() => onClickMonth(4)}
          >
            Abr
          </Button>
          <Button
            className={[
              styles.buttom,
              values?.month === 5 ? styles.active : "",
            ].join(" ")}
            variant={values?.month === 5 ? "contained" : "outlined"}
            onClick={() => onClickMonth(5)}
          >
            Mai
          </Button>
          <Button
            className={[
              styles.buttom,
              values?.month === 6 ? styles.active : "",
            ].join(" ")}
            variant={values?.month === 6 ? "contained" : "outlined"}
            onClick={() => onClickMonth(6)}
          >
            Jun
          </Button>
          <Button
            className={[
              styles.buttom,
              values?.month === 7 ? styles.active : "",
            ].join(" ")}
            variant={values?.month === 7 ? "contained" : "outlined"}
            onClick={() => onClickMonth(7)}
          >
            Jul
          </Button>
          <Button
            className={[
              styles.buttom,
              values?.month === 8 ? styles.active : "",
            ].join(" ")}
            variant={values?.month === 8 ? "contained" : "outlined"}
            onClick={() => onClickMonth(8)}
          >
            Ago
          </Button>
          <Button
            className={[
              styles.buttom,
              values?.month === 9 ? styles.active : "",
            ].join(" ")}
            variant={values?.month === 9 ? "contained" : "outlined"}
            onClick={() => onClickMonth(9)}
          >
            Set
          </Button>
          <Button
            className={[
              styles.buttom,
              values?.month === 10 ? styles.active : "",
            ].join(" ")}
            variant={values?.month === 10 ? "contained" : "outlined"}
            onClick={() => onClickMonth(10)}
          >
            Out
          </Button>
          <Button
            className={[
              styles.buttom,
              values?.month === 11 ? styles.active : "",
            ].join(" ")}
            variant={values?.month === 11 ? "contained" : "outlined"}
            onClick={() => onClickMonth(11)}
          >
            Nov
          </Button>
          <Button
            className={[
              styles.buttom,
              values?.month === 12 ? styles.active : "",
            ].join(" ")}
            variant={values?.month === 12 ? "contained" : "outlined"}
            onClick={() => onClickMonth(12)}
          >
            Dez
          </Button>
        </div>
        {eventsResumeLoading ? (
          <Skeleton height={250} />
        ) : (
          resume && (
            <div className={styles.resume}>
              <div className={styles.item}>
                <span>{resume?.events}</span>
                <label>Eventos </label>
              </div>
              <div className={styles.item}>
                <span>{resume?.customers}</span>
                <label>Clientes do mês</label>
              </div>
              {hasAccessRole("DashboardPosPago") && (
                <div className={styles.item}>
                  <span>{formatCurrency(resume?.consumer)}</span>
                  <label>Faturamento do mês</label>
                </div>
              )}
              <div className={styles.item}>
                <span>{formatCurrency(resume?.averageTicketConsume)}</span>
                <label>Ticket médio de consumo</label>
              </div>
              <div className={styles.item}>
                <span>{formatCurrency(resume?.averageTicketIngress)}</span>
                <label>Ticket médio de ingresso</label>
              </div>
            </div>
          )
        )}
        <div className={styles.eventStatus}>
          <Button
            className={[
              styles.buttom,
              values?.status === EventStatusEnum.ALL ? styles.active : "",
            ].join(" ")}
            variant="outlined"
            onClick={() => onClickStatus(EventStatusEnum.ALL)}
          >
            Todos
          </Button>
          <Button
            className={[
              styles.buttom,
              values?.status === EventStatusEnum.SCHEDULED ? styles.active : "",
            ].join(" ")}
            variant="outlined"
            onClick={() => onClickStatus(EventStatusEnum.SCHEDULED)}
          >
            Programado
          </Button>
          <Button
            className={[
              styles.buttom,
              values?.status === EventStatusEnum.HAPPENING ? styles.active : "",
            ].join(" ")}
            variant="outlined"
            onClick={() => onClickStatus(EventStatusEnum.HAPPENING)}
          >
            Acontecendo
          </Button>
          <Button
            className={[
              styles.buttom,
              values?.status === EventStatusEnum.CLOSED ? styles.active : "",
            ].join(" ")}
            variant="outlined"
            onClick={() => onClickStatus(EventStatusEnum.CLOSED)}
          >
            Encerrado
          </Button>
        </div>
      </div>
    </div>
  );
};
export default FilterEventList;

const formatCurrency = (value: number) =>
  new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
  }).format(value);
