import { useUi } from "contexts/userInterface/UserInterfaceContext";
import AddGuestsToListUseCase from "modules/events/application/useCases/guests/AddGuestsToListUseCase";
import GetGuestsOnListUseCase from "modules/events/application/useCases/guests/GetGuestsOnListUseCase";
import { IGuestRequest } from "modules/events/domain/dto/IEventsRequest";
import {
  IAddGuestForm,
  IGuestAlreadyInList,
  IGuestFailedInList,
  IPeopleList,
} from "modules/events/domain/models/IEvents";
import { useLocal } from "modules/local/presentation/context/LocalContext";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { EventsGuestsApi } from "services/api/events/guests/EventsGuestsApi";

interface IUseAddGuest {
  guestList: IPeopleList | undefined;
  handleAddGuest: (list: IAddGuestForm) => Promise<void>;
  showListAlertModal: boolean;
  setShowListAlertModal: (show: boolean) => void;
  showMigrateModal: boolean;
  toggleMigrateModal: () => void;
  guestsToMigrate: IGuestAlreadyInList[] | undefined;
  listGuestFailed: IGuestFailedInList[] | undefined;
  setListGuestFailed: any;
  listCpf: string[];
  setListCpf: any;
  hasRoleTransferCpf?: boolean
}

export const UseAddGuestPage = (): IUseAddGuest => {
  const guestService = EventsGuestsApi();
  const { goBack } = useHistory();
  const { toast, hideLoading, showLoading } = useUi();
  const [guestList, setGuestList] = useState<IPeopleList>();
  const { listId } = useParams<{ listId: string }>();
  const [showListAlertModal, setShowListAlertModal] = useState(false);
  const [showMigrateModal, setShowMigrateModal] = useState(false);
  const [guestsToMigrate, setGuestsToMigrate] = useState<IGuestAlreadyInList[]>();
  const [listGuestFailed, setListGuestFailed] = useState<IGuestFailedInList[]>();
  const [listCpf, setListCpf] = useState<string[]>([]);
  const { currentLocal } = useLocal();

  const [hasRoleTransferCpf, setHasRoleTransferCpf] = useState<boolean>();

  const toggleMigrateModal = () => {
    setShowMigrateModal(!showMigrateModal);
  };

  useEffect(() => {
    const getListDetails = async () => {
      if (!currentLocal) return;

      try {
        const result = await GetGuestsOnListUseCase(guestService, listId, currentLocal.id);
        setGuestList(result);
      } catch (error) {
        console.error("[ERROR]", error);
      }
    };

    getListDetails();
  }, [currentLocal, guestService, listId]);

  const handleAddGuest = async (payload: IAddGuestForm) => {
    showLoading();
    try {
      const guestList: IGuestRequest = {
        peopleListId: listId,
        cpfs: payload.cpfList,
        IgnoreAlert: payload.IgnoreAlert,
      };
      if (!currentLocal) return;

      const guestInOtherList = await AddGuestsToListUseCase(guestService, guestList, currentLocal.id);
      if (guestInOtherList.guestsListedToMigration.length > 0) {
        toggleMigrateModal();
        setGuestsToMigrate(guestInOtherList.guestsListedToMigration);
        setHasRoleTransferCpf(guestInOtherList.hasRoleTransferCpf);
      } else {
        goBack();
        toast("Convidados adicionados com sucesso!", "success");
      }
    } catch (error: any) {
      try {
        const items = JSON.parse(error?.response?.data?.message);
        if (Array.isArray(items)) {
          setListGuestFailed(items);
          setShowListAlertModal(true);
        }
      } catch (e) {
        toast(error?.response?.data?.message || "Não foi possível adicionar o convidado na lista!", "error");
      }
    } finally {
      hideLoading();
    }
  };

  return {
    handleAddGuest,
    guestList,
    showMigrateModal,
    toggleMigrateModal,
    guestsToMigrate,
    listGuestFailed,
    setListGuestFailed,
    listCpf,
    setListCpf,
    showListAlertModal,
    setShowListAlertModal,
    hasRoleTransferCpf
  };
};
