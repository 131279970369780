import { useLocal } from "modules/local/presentation/context/LocalContext";
import { useCallback, useState } from "react";
import DashboardTicketApi from "services/api/dashboardTicket/DashboardTicketApi";
import { ISalesDevices } from "../interfaces/ISalesDevices";
import GetSalesDevicesUseCase from "modules/dashboardTicket/application/useCases/GetSalesDevicesUseCase";
import { IFilterValue } from "../interfaces/IFilter";

export interface IParamsUseSalesDevices {
  localId?: string;
  sessionId?: string;
  period?: number;
}

const UseSalesDevices = (filter: IFilterValue | undefined) => {
  const [isLoading, setIsLoading] = useState<boolean>();
  const [salesDevices, setSalesDevices] = useState<ISalesDevices[]>();
  const { currentLocal } = useLocal();

  const getSalesDevices = useCallback(
    async (params?: IParamsUseSalesDevices) => {
      try {
        const servicePermutation = DashboardTicketApi();
        setIsLoading(true);
        if (currentLocal) {
          const response = await GetSalesDevicesUseCase(
            servicePermutation,
            currentLocal.id,
            {
              ...filter,
              period: filter?.period ?? 4,
            }
          );

          setSalesDevices(response);

          return response;
        }
      } finally {
        setIsLoading(false);
      }
    },
    [currentLocal, filter]
  );

  return {
    salesDevices,
    isLoading,
    getSalesDevices,
    setSalesDevices,
  };
};

export default UseSalesDevices;
