import { ChangeEvent, FC, useCallback, useRef, useState } from "react";
import styles from "./InformationForm.module.scss";
import { TextField } from "@material-ui/core";
import { Publish, EditOutlined } from "@mui/icons-material";
import {
  IPaymentLinkInfoErrors,
  IPaymentLinkInfoForm,
} from "modules/paymentLink/presentation/interfaces/IPaymentLinkForms";
import { validate } from "uuid";

export interface IInformationForm {
  handleChangeInfoForm: (name: string, value: string) => void;
  errors: IPaymentLinkInfoErrors;
  infoFormValues?: IPaymentLinkInfoForm;
}

const InformationForm: FC<IInformationForm> = ({
  errors,
  handleChangeInfoForm,
  infoFormValues,
}) => {
  const refInput = useRef<HTMLInputElement>(null);

  const [values, setValues] = useState<IPaymentLinkInfoForm>(
    infoFormValues ?? { name: "", expiration: "" }
  );
  const [showEditImage, setShowEditImage] = useState(false);

  const handleChangeName = useCallback(
    (ev: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const name = ev.target.value;
      handleChangeInfoForm("name", name);
      setValues((prev) => ({ ...prev, name: name }));
    },
    [handleChangeInfoForm]
  );

  const handleChangeDescription = useCallback(
    (ev: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const description = ev.target.value;
      handleChangeInfoForm("description", description);
      setValues((prev) => ({ ...prev, description: description }));
    },
    [handleChangeInfoForm]
  );

  const handleChangeDate = useCallback(
    (ev: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const expiration = ev.target.value;
      handleChangeInfoForm("expiration", expiration);
      setValues((prev) => ({ ...prev, expiration: expiration }));
    },
    [handleChangeInfoForm]
  );

  const fileToBase64 = useCallback((file: File) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onload = function (event) {
        resolve(event.target?.result);
      };

      reader.readAsDataURL(file);
    });
  }, []);

  const handleChangeImg = useCallback(
    async (ev: ChangeEvent<HTMLInputElement>) => {
      const files = ev.currentTarget.files;
      // eslint-disable-next-line prefer-const
      let images: string[] = [];
      if (files?.length) {
        for (let i = 0; i < files.length; i++) {
          const _file = files?.item(i);

          const base64 = _file && (await fileToBase64(_file));
          images.push(String(base64));
        }
      }
      handleChangeInfoForm("img", images[0]);
      setValues((prev) => ({ ...prev, img: images[0] }));
    },
    [fileToBase64, handleChangeInfoForm]
  );

  const onClickImage = useCallback(() => {
    refInput.current?.click();
  }, []);

  return (
    <div id={styles.InformationForm}>
      <div className={styles.item}>
        <label>
          Nome do link<span> *</span>
        </label>
        <TextField
          fullWidth
          inputProps={{ maxLength: 250 }}
          size="small"
          placeholder="Link de pagamento"
          variant="outlined"
          name="name"
          value={values?.name}
          error={!!errors?.name}
          helperText={errors?.name}
          onChange={handleChangeName}
        />
      </div>
      <div className={styles.date}>
        <label>
          Data de expiração<span> *</span>
        </label>
        <TextField
          InputProps={{
            inputProps: {
              style: {
                color: values?.expiration
                  ? "black"
                  : "var(--Neutral-Gray-700, #A2A2A2)",
              },
            },
          }}
          size="small"
          type="date"
          variant="outlined"
          name={"expiration"}
          value={values?.expiration}
          error={!!errors?.expiration}
          helperText={errors?.expiration}
          onChange={handleChangeDate}
        />
      </div>
      <div className={styles.item}>
        <label>Descrição</label>
        <TextField
          fullWidth
          inputProps={{ maxLength: 250 }}
          size="small"
          placeholder="Insira aqui seu texto..."
          variant="outlined"
          multiline
          value={values?.description}
          minRows={4}
          name="description"
          onChange={handleChangeDescription}
        />
      </div>
      <div className={styles.imageLabel}>
        <label>
          Imagem <span> *</span>
        </label>
      </div>
      <div className={styles.contentImg}>
        <input
          name="img"
          ref={refInput}
          id="input-file"
          accept=".jpg, .jpeg, .png"
          type="file"
          style={{ display: "none" }}
          onChange={handleChangeImg}
        />
        {values?.img ? (
          <div
            className={styles.boxImg}
            onClick={onClickImage}
            onMouseOver={() => setShowEditImage(true)}
            onMouseLeave={() => setShowEditImage(false)}
          >
            <img
              src={
                validate(values.img)
                  ? `${process.env.REACT_APP_URL_IMAGEM}mepay-link/${values.img}.png`
                  : values.img
              }
              alt="upload_img"
              style={{ filter: showEditImage ? "brightness(0.5)" : undefined }}
            />
            {showEditImage && (
              <div className={styles.editButton}>
                <EditOutlined />
                Editar
              </div>
            )}
          </div>
        ) : (
          <div>
            <div
              className={styles.uploadImg}
              onClick={onClickImage}
              style={{ border: errors?.img ? "1px solid #f44336" : undefined }}
            >
              <Publish fontSize="large" />
              <button className={styles.publishButton}>Enviar</button>
            </div>
            {errors?.img && <div className={styles.imgError}>{errors.img}</div>}
          </div>
        )}
        <ul>
          <li>
            Envie uma imagem nos formatos, PNG ou JPG com o tamanho mínimo de
            300x300px e máximo 1080x1080px.
          </li>
          <li>A imagem será usada na página do seu evento.</li>
        </ul>
      </div>
    </div>
  );
};

export default InformationForm;
