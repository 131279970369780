import { useCallback, useEffect } from "react"
import { SystemIdentifierEnum } from "modules/gestaoVendas/domain/enum/GetSegmentosEnum";


declare global {
  interface Window {
    fcWidget: any;
  }
}

const UseChatBase = (systemIdentifier: SystemIdentifierEnum, userName?: string, userId?: string, useEmail?: string) => {

  const updateUserData = useCallback((userName: string, userId: string, useEmail: string) => {
    window.fcWidget.setExternalId(userId);
    window.fcWidget.user.setFirstName(userName);
    window.fcWidget.user.setEmail(useEmail);
  }, [])
  useEffect(() => {
    if (systemIdentifier === SystemIdentifierEnum.General) return;
    const script = document.createElement('script');
    script.src = "//fw-cdn.com/11731639/4361003.js";
    script.setAttribute("chat", "true");
    document.head.appendChild(script);

  }, [systemIdentifier, updateUserData, useEmail, userId, userName])


  useEffect(() => {
    if (systemIdentifier === SystemIdentifierEnum.General) return;
    if (userName === undefined || userName === "" || userId === undefined || useEmail === undefined) return;
    const timer = setInterval(async () => {
      if (window.fcWidget?.user) {
        try {
          await window.fcWidget.user.setFirstName(userName);
          await window.fcWidget.user.setEmail(useEmail);
          await window.fcWidget.setExternalId(userId);
        }
        catch (e) {
          console.log(e)
        }
        clearTimeout(timer)
      }
    }, 1000);

  }, [useEmail, userId, userName, systemIdentifier]);


}
export default UseChatBase


// <button id="remove-chat-button" onclick="removeChat()" style="
// all: unset;
// position: absolute;
// bottom: 56px;
// right: 17px;
// z-index: 2147483646;
// cursor: pointer;
// font-family: 'Roboto', sans-serif;
// font-weight: 700;
// color: #5F5F5F;
// background-color: white;
// width: 18px;
// height: 18px;
// display: flex;
// align-items: center;
// justify-content: center;
// border-radius: 50%;
// display: none;
// "
// >
// <span style="position: relative;top: -2px;left: -1px;">x</span>
// </button>
