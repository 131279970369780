import { FC, useEffect } from 'react'
import styles from './AddressPage.module.scss'
import { TextField } from '@material-ui/core'
import { UseAddressPage } from './UseAddressPage'
import FoodSaasLoading from '../../components/foodSaasLoading/FoodSaasLoading'
import { UseBackPage } from '../../hooks/UseBackPage'
import SaasPublicLayout from '../SaasPublicLayout'
import InputMasked from 'components/inputMasked/InputMasked'
import UseProspect from '../../hooks/UseProspect'
import { ProspectType } from 'modules/saas/domain/interfaces/ProspectTypeEnum'
import { Controller, useForm } from 'react-hook-form'
import { IAddressSchema, addressSchema } from 'modules/saas/domain/schema/addressSchema'
import { zodResolver } from '@hookform/resolvers/zod'
import Button from 'components/ui/Button/Button'
import { CircularProgress } from '@mui/material'

export interface IAddressPageProps {
    //propertys
}

const AddressPage: FC<IAddressPageProps> = () => {
    const {
        isLoading,
        defaultValues,
        progressBarColor,
        backgroundColorTop,
        backgroundColorBottom,
        handleSearchByCEP,
        handleSubmit: submitForm,
    } = UseAddressPage();
    const { onBack } = UseBackPage();
    const { type } = UseProspect();

    const {
        register,
        handleSubmit,
        formState: { errors },
        setError,
        clearErrors,
        getValues,
        control,
        reset,
        setValue
    } = useForm<IAddressSchema>({
        resolver: zodResolver(addressSchema),
    });

    useEffect(() => {
        setValue("zipCode", defaultValues?.zipCode ?? "");
        setValue("street", defaultValues?.street ?? "");
        setValue("number", defaultValues?.number ?? "");
        setValue("city", defaultValues?.city ?? "");
        setValue("state", defaultValues?.state ?? "");
        setValue("neighborhood", defaultValues?.neighborhood ?? "");
        setValue("country", defaultValues?.country ?? "");
    }, [defaultValues, setValue]);

    return (
        <SaasPublicLayout
            step={type === ProspectType.CieloEvent || type === ProspectType.CieloEstablishment ? 6 : 5}
            totalSteps={type === ProspectType.CieloEvent || type === ProspectType.CieloEstablishment ? 6 : 5}
            progressBarColor={progressBarColor}
            backgroundColorTop={backgroundColorTop}
            backgroundColorBottom={backgroundColorBottom}
        >
            <div className={styles.container}>
                <div className={styles.header}>
                    <h1>Onde seu negócio está localizado?</h1>
                    <span>Esse é o último passo!</span>
                </div>

                <form
                    onSubmit={handleSubmit(submitForm)}
                >
                    <div className={styles.column}>
                        <label htmlFor="zipCode" className={styles.required}>CEP</label>
                        <Controller
                            name="zipCode"
                            control={control}
                            render={({ field }) => (
                                <InputMasked
                                    name="cep"
                                    variant="outlined"
                                    fullWidth
                                    mask="99999-999"
                                    placeholder="Digite seu CEP"
                                    value={field.value}
                                    onChange={(e: any) => field.onChange(e.target.value.replace(/\D/g, ""))}
                                    onBlur={(ev) => handleSearchByCEP(ev.target.value, clearErrors, reset, getValues, setError)}
                                    helperText={errors.zipCode?.message}
                                    error={!!errors.zipCode}
                                    disabled={isLoading}
                                />
                            )}
                        />
                    </div>

                    <div className={styles.row}>
                        <div className={styles.column}>
                            <label htmlFor="street" className={styles.required}>Rua</label>
                            <TextField
                                variant="outlined"
                                fullWidth
                                size="small"
                                placeholder="Nome da rua"
                                {...register("street")}
                                helperText={errors.street?.message}
                                error={!!errors.street}
                                disabled={isLoading}
                            />
                        </div>

                        <div className={styles.column}>
                            <label htmlFor="number" className={styles.required}>Número</label>
                            <TextField
                                variant="outlined"
                                fullWidth
                                size="small"
                                placeholder="Número"
                                {...register("number")}
                                helperText={errors.number?.message}
                                error={!!errors.number}
                                type="tel"
                                disabled={isLoading}
                            />
                        </div>
                    </div>

                    <div className={styles.row}>
                        <div className={styles.column}>
                            <label htmlFor="state" className={styles.required}>Estado</label>
                            <TextField
                                variant="outlined"
                                fullWidth
                                size="small"
                                placeholder="UF"
                                {...register("state")}
                                helperText={errors.state?.message}
                                error={!!errors.state}
                                type="tel"
                                disabled={isLoading}
                            />
                        </div>                            
                        <div className={styles.column}>
                            <label htmlFor="city" className={styles.required}>Cidade</label>
                            <TextField
                                variant="outlined"
                                fullWidth
                                size="small"
                                placeholder="Cidade"
                                {...register("city")}
                                helperText={errors.city?.message}
                                error={!!errors.city}
                                disabled={isLoading}
                            />
                        </div>
                    </div>

                    <div className={styles.column}>
                        <label htmlFor="complement">Complemento</label>
                        <TextField
                            variant="outlined"
                            fullWidth
                            size="small"
                            placeholder="Digite o complemento"
                            {...register("complement")}
                            helperText={errors.complement?.message}
                            error={!!errors.complement}
                            disabled={isLoading}
                        />
                    </div>

                    <div className={styles.footerButtons}>
                        <Button variant="text" color="secondary" onClick={() => onBack("local")} style={{ color: backgroundColorTop }}>
                            Voltar
                        </Button>
                        <Button
                            color="secondary"
                            style={{ backgroundColor: backgroundColorTop }}
                            type="submit"
                        >
                            {isLoading ? <CircularProgress size={16} color="inherit" /> : "Continuar"}
                        </Button>
                    </div>
                </form>
            </div>

            {isLoading && <FoodSaasLoading />}
        </SaasPublicLayout>
    )
}
export default AddressPage;