import { useBreadcumbs } from "components/breadcumbs/BreadcumbsContext";
import CouponsReportPage from "modules/config/discountCoupons/presentation/pages/report/CouponsReportPage";
import { IframePage } from "modules/oldPortal/presentation/components/iframe/IframePage";
import TicketsReportPage from "modules/ticketsReport/presentation/TicketsReportPage";
import React, { FC, memo, useCallback, useEffect } from "react";
import { useParams } from "react-router-dom";
import TabPage from "../dashboard/tabPage/TabPage";
import { OutputReport } from "./components";
import TransactionReportPage from "modules/transactionReport/presentation/TransactionReportPage";
import SaleForDevicePage from "modules/saleForDevice/presentation/SaleForDevicePage";
import CashCancellationPage from "modules/cashCancellation/presentation/CashCancellationPage";
import SaleForProductPage from "modules/saleForProduct/presentation/SaleForProductPage";
import CashClosingPage from "modules/cashClosing/presentation/CashClosingPage";
export interface IReportPageProps {
  //propertys
}

interface IRelatorioMenu {
  label: string;
  tab: string;
  url?: string;
  papel?: string;
  component?: React.ReactNode;
  subItems?: {
    label: string;
    tab: string;
    url: string;
    papel: string;
    component?: React.ReactNode;
  }[];
}

const ReportPage: FC<IReportPageProps> = () => {
  const { tab, subTab } = useParams<{ tab: string; subTab: string }>();
  const { updateRouters } = useBreadcumbs();

  useEffect(() => {
    const currentTab = relatoriosTabs.find((item) => tab === item.tab);
    const currentSubTab = currentTab ? currentTab.subItems?.find((item) => subTab === item.tab) : false;
    const routers = currentSubTab ?
      [
        {
          label: "Relatorios",
          title: "Relatórios",
        },
        {
          label: "Relatorios",
          title: currentTab?.label,
        },
        {
          label: "Relatorios",
          title: currentSubTab?.label,
        }
      ]
      : [
        {
          label: "Relatorios",
          title: "Relatórios",
        },
        {
          label: "Relatorios",
          title: currentTab?.label,
        }
      ];

    updateRouters(routers);
    return () => {
      updateRouters([]);
    };
  }, [subTab, tab, updateRouters]);

  const subTabComponent = useCallback(
    (tabItem: IRelatorioMenu) =>
      !!tabItem.subItems?.length ? (
        <TabPage
          routerPrefix={"/private/relatorios/" + tabItem.tab}
          subTabEnabled
          hideTabs
          tabsPages={tabItem.subItems?.map((subTabItem) => ({
            label: subTabItem.label,
            tab: subTabItem.tab,
            roles: subTabItem.papel,
            component: subTabItem.component ?? <IframePage src={subTabItem.url} />,
          }))}
        />
      ) : (
        tabItem.component ?? <IframePage src={tabItem.url!} />
      ),
    []
  );

  return (
    <TabPage
      routerPrefix={"/private/relatorios"}
      hideTabs
      tabsPages={relatoriosTabs.map((tabItem) => ({
        label: tabItem.label,
        tab: tabItem.tab,
        roles: tabItem.papel,
        component: subTabComponent(tabItem),
      }))}
    />
  );
};
export default memo(ReportPage);

const relatoriosTabs: IRelatorioMenu[] = [
  {
    label: "Gerencial",
    tab: `gerencial`,
    url: `/relatorios/gerencial`,
    papel: "RelatorioGerencial",
  },
  {
    label: "Recarga detalhada",
    tab: `recargaDetalhada`,
    url: `/cashless/relatorios/recargaDetalhada`,
  },
  {
    label: "Solicitações de reembolso",
    tab: `solicitacoesReembolso`,
    url: `/cashless/relatorios/solicitacoesReembolso`,
  },
  {
    label: "Saldo cartões",
    tab: `saldoCartoes`,
    url: `/cashless/relatorios/saldoCartoes`,
  },
  {
    label: "Saldo negativo",
    tab: `saldoNegativo`,
    url: `/cashless/relatorios/saldoNegativo`,
  },
  {
    label: "Caixa",
    tab: "caixa",
    subItems: [
      {
        label: "Fechamento",
        tab: `fechamento`,
        url: `/relatorios/caixa/fechamento`,
        papel: "RelatorioFechamentoCaixa",
        component: <CashClosingPage />
      },
      {
        label: "Sangria e suprimento",
        tab: `sangriaSuprimento`,
        url: `/relatorios/caixa/sangriaSuprimento`,
        papel: "RelatorioSangriasSuprimentos",
      },
      {
        label: "Comissão",
        tab: `comissao`,
        url: `/relatorios/caixa/comissao`,
        papel: "RelatorioComissao",
      },
      {
        label: "Cancelamento",
        tab: `cancelamento`,
        url: `/relatorios/caixa/cancelamento`,
        papel: "RelatorioCancelamentos",
        component: <CashCancellationPage />
      },
    ],
  },
  {
    label: "Venda",
    tab: "venda",
    subItems: [
      {
        label: "Por dia",
        tab: `porDia`,
        url: `/relatorios/venda/porDia`,
        papel: "RelatorioVendaPorDia",
      },
      {
        label: "Por produto",
        tab: `porProduto`,
        url: `/relatorios/venda/porProduto`,
        papel: "RelatorioVendaProdutos",
        component: <SaleForProductPage />,
      },
      {
        label: "Por equipamento",
        tab: `porEquipamento`,
        url: `/relatorios/venda/porEquipamento`,
        papel: "RelatorioVendaEquipamentos",
        component: <SaleForDevicePage />,
      },
      {
        label: "Por operador",
        tab: `porOperador`,
        url: `/relatorios/venda/porOperador`,
        papel: "RelatorioVendaOperadores",
      },
      {
        label: "Por tipo de pagamento",
        tab: `porTipoPagamento`,
        url: `/relatorios/venda/porTipoPagamento`,
        papel: "RelatorioPorTipoPagamento",
      },
      {
        label: "Por tipo de venda",
        tab: `porTipoVenda`,
        url: `/relatorios/venda/porTipoVenda`,
        papel: "RelatorioFluxoDeVendas",
      },
      {
        label: "Por bandeira",
        tab: `porBandeira`,
        url: `/relatorios/venda/porBandeira`,
        papel: "RelatorioVendaBandeiras",
      },
      {
        label: "Em produção",
        tab: `emProducao`,
        url: `/relatorios/venda/emProducao`,
        papel: "RelatorioVendaProducao",
      },
      {
        label: "Detalhada",
        tab: `detalhada`,
        url: `/relatorios/venda/detalhada`,
        papel: "RelatorioVendasDetalhadas",
      },
      {
        label: "Por produto e tipo pagamento",
        tab: `porProdutoETipoPagamento`,
        url: `/relatorios/venda/porProdutoETipoPagamento`,
        papel: "RelatorioProdutoTipoPagamento",
      },
    ],
  },
  {
    label: "Ticket",
    tab: "ticket",
    subItems: [
      {
        label: "Validados",
        tab: `validados`,
        url: `/relatorios/ticket/validados`,
        papel: "RelatorioFichasValidadas",
      },
      {
        label: "Reimpressão",
        tab: `reimpressao`,
        url: `/relatorios/ticket/reimpressao`,
        papel: "RelatorioReimpressao",
      },
      {
        label: "Produção",
        tab: `producao`,
        url: `/relatorios/ticket/producao`,
        papel: "RelatorioSaidaProducao",
      },
    ],
  },
  {
    label: "Plano",
    tab: "plano",
    subItems: [
      {
        label: "Fluxo",
        tab: `fluxo`,
        url: `/relatorios/plano/fluxo`,
        papel: "RelatorioVendaPlanos",
      },
      {
        label: "Assinatura",
        tab: `assinatura`,
        url: `/relatorios/plano/assinatura`,
        papel: "RelatorioAssinaturasPorPlanos",
      },
    ],
  },
  {
    label: "Financeiro",
    tab: "financeiro",
    subItems: [
      {
        label: "Recebíveis",
        tab: `recebiveis`,
        url: `/relatorios/financeiro/recebiveis`,
        papel: "RelatorioRecebiveis",
      },
      {
        label: "Split",
        tab: `split`,
        url: `/relatorios/financeiro/split`,
        papel: "RelatorioSplit",
      },
      {
        label: "Split agrupado",
        tab: `split-agrupado`,
        url: `/relatorios/financeiro/splitAgrupado`,
        papel: "RelatorioSplit",
      },
      {
        label: "Saque",
        tab: `saque`,
        url: `/relatorios/financeiro/saque`,
        papel: "RelatorioSaques",
      },
      {
        label: "Transações",
        tab: `transacoes`,
        url: `/relatorios/financeiro/transacoes`,
        papel: "RelatorioTransacoes",
        component: <TransactionReportPage />
      },
    ],
  },
  {
    label: "Validação de ficha",
    tab: `tickets`,
    url: `/relatorios/tickets`,
    component: <TicketsReportPage />,
    papel: "RelatorioAuditoriaValidacaoTickets",
  },
  {
    label: "Cupons de desconto",
    tab: `reportCoupons`,
    url: `/relatorios/coupons`,
    component: <CouponsReportPage />,
    papel: "CupomDesconto",
  },
  {
    label: "Saída",
    tab: `saida`,
    url: `/relatorios/saida`,
    component: <OutputReport />,
  },
];
