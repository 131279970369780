import { FC } from 'react'
import styles from './ComplementCreateActionForm.module.scss'
import { AssignmentOutlined, Fastfood } from '@material-ui/icons'
import ComplementForm, { IComplementGroupFormValue } from '../complementForm/ComplementForm';

export interface IComplementCreateActionFormProps {
    //propertys
    onClickNewGroup: () => void;
    onClickExistGroup: () => void;
    openNewDrawer: boolean;
    onCloseHandle: () => void;
    onSubmitComplementForm: (complementValues: IComplementGroupFormValue) => void;
}
const ComplementCreateActionForm: FC<IComplementCreateActionFormProps> = ({ onClickNewGroup, onClickExistGroup, openNewDrawer, onCloseHandle, onSubmitComplementForm }) => {
    return (
        <>
            {!openNewDrawer && (
                <div id={styles.ComplementCreateActionForm} >
                    <div className={styles.container} >
                        <span>Selecione como você quer adicionar complementos ao seu produto:</span>
                        <button onClick={onClickNewGroup}>
                            <Fastfood />
                            <span>
                                <b>Criar um novo <span style={{ color: "rgba(149, 92, 255, 1)"}}>grupo</span></b><br/>
                                Crie um grupo do zero para esse produto
                            </span>
                        </button>
                        <button onClick={onClickExistGroup}>
                            <AssignmentOutlined />
                            <span>
                                <b>Usar produto <span style={{ color: "rgba(149, 92, 255, 1)"}}>existente</span></b><br/>
                                Use um grupo criado em outro produto
                            </span>
                        </button>
                    </div>
                </div>
            )}
            {openNewDrawer && (
                <ComplementForm
                // disabledEditCategoryAndProduct
                onClose={onCloseHandle}
                onSubmit={onSubmitComplementForm}
            />
            )}
        </>
    )
}
export default ComplementCreateActionForm