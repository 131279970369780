import React, { FC, ReactNode } from 'react';
import styles from './ui.module.scss';

//subtitle
interface SubTitleProps {
    step: number;
    children: ReactNode;
}
export const SubTitle: FC<SubTitleProps> = (props) => {
    return <div className={styles.Subtitle}><span>{props.step}. </span>{props.children}</div>;
};
