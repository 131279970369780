import { RequestType } from "models/api/IApi";
import ISalesDashboardService from "modules/salesDashboard/domain/interfaces/ISalesDashboardService";
import { IRequestParams } from "modules/salesDashboard/domain/dtos/RequestParams";
import { IGetTurnoverTableResponse } from "modules/salesDashboard/domain/dtos/turnoverTable/IGetTurnoverTableResponse";
import { api } from "../Api";
import { SalesDashboardMock, SalesDashboardMockTypes } from "./mock/mock";
import { GetRankingProductsResponse } from "modules/salesDashboard/domain/dtos/rankingProducts/GetRankingProductsResponse";
import { getCategoryRankingMock, getDeviceSalesMock, getOperatorRakingMock, getProductsRankingMock, getSalesMock, getStoreRankingMock } from "./mock/mocks";
import { IRankingOperatorsResponse } from "modules/salesDashboard/domain/dtos/rankingOperators/IRankingOperatorsResponse";
import { IGetLocalResumeResponse } from "modules/salesDashboard/domain/dtos/localResume/IGetLocalResumeResponse";
import { CategoryProductsRankingResponse, CategoryRankingResponse } from "modules/salesDashboard/domain/dtos/rankingCategory/CategoryRankingResponse";
import { IGetSessionsListResponse } from "modules/salesDashboard/domain/dtos/getSessions/IGetSessionsListResponse";
import { getBuscarSessoes } from "./antiCorruption/BuscarSessoes";
import { IRankingStoresResponse } from "modules/salesDashboard/domain/dtos/rankingStores/GetRankingStoresResponse";
import { GetRankingDeviceSalesResponse } from "modules/salesDashboard/domain/dtos/deviceSales/GetDeviceSalesResponse";
import { IGetRankingClientsResponse } from "modules/salesDashboard/domain/dtos/rankingClients/IGetRankingClientsResponse";
import { IGradientTableResponse } from "modules/salesDashboard/domain/dtos/gradientTable/IGradientTable.type";
import { GetSalesBarChartResponse } from "modules/salesDashboard/domain/dtos/saleBarChart/GetSalesBarChartResponse";
import { IGetTopParentCategoriesResponse } from "modules/salesDashboard/domain/dtos/topParentCategories/IGetTopParentCategoriesResponse";
import { IGetTopSubCategoriesResponse } from "modules/salesDashboard/domain/dtos/topSubCategories/IGetTopSubCategoriesResponse";
import { format, parse } from "date-fns";
import { IProdutosSemSplitResponse } from "modules/salesDashboard/domain/dtos/IProdutosSemSplitResponse";

const mock = SalesDashboardMock();
const useMock = false;

const SalesDashboardService = (): ISalesDashboardService => {
    /*
    Método utilizado apenas para montagem da query PADRÃO.
    Não alterar para casos específicos
    */
    const makeQuery = (request: IRequestParams) => {
        const unifyArray = (prop: string, array: string[]) => {
            return array.map((item) => `&${prop}=${item}`).join("");
        }

        const formatDate = (date?: string) => {
            if (!date) return '';
            return format(parse(date, 'yyyy-MM-dd', new Date()), 'MM/dd/yyyy');
        }

        let query = "?" + unifyArray('selectedPlaces', request.selectedPlaces);
        query += request.selectedCashiers?.length ? `${unifyArray('selectedCashiers', request.selectedCashiers)}` : '';
        query += request.startDate ? `&startDate=${formatDate(request.startDate)}&endDate=${formatDate(request.endDate)}` : '';
        query += request.searchPeriod ? `&searchPeriod=${request.searchPeriod}` : '';
        query += request.groupBy ? `&groupBy=${request.groupBy}` : '';
        query += request.sort?.type ? `&sort.type=${request.sort?.type}` : '';
        query += request.sort?.orientation ? `&sort.orientation=${request.sort?.orientation}` : '';
        query += `&page=${request.page || 0}`;
        query += `&pageSize=${request.pageSize || 20}`;

        return query;
    }

    /*
    Método base de request.
    Todos os requests que forem seguir o mesmo padrão devem OBRIGATORIAMENTE utilizar esse método
    */
    async function executeRequest<T>(requestType: RequestType, request: IRequestParams, uri: string, params?: any): Promise<T> {
        const query = makeQuery(request);

        const result = await api[requestType]<T>(
            `dashboard/v3/sales/${uri}${RequestType.GET ? query : ''}`,
            { params: { disableError: true, ...params } }
        );

        return result.data;
    }

    // Caso queira fazer um tratamento padrão de erros, fazer aqui
    const catchError = (err: any) => {
        throw new Error(err);
    }

    const getTurnoverTable = async (request: IRequestParams): Promise<IGetTurnoverTableResponse> => {
        let response = {} as IGetTurnoverTableResponse;

        try {
            response = useMock ?
                await mock.makeMock<IGetTurnoverTableResponse>(() => mock[SalesDashboardMockTypes.GET_GIRO_MESA](request)) :
                await executeRequest<IGetTurnoverTableResponse>(RequestType.GET, request, 'v2/TablesTurn');
        } catch (err: any) {
            catchError(err);
        }

        return response;
    };

    const getLocalResume = async (request: IRequestParams): Promise<IGetLocalResumeResponse> => {
        let response = {} as IGetLocalResumeResponse;

        try {
            response = useMock ?
                await mock.makeMock<IGetLocalResumeResponse>(() => mock[SalesDashboardMockTypes.GET_LOCAL_RESUME](request)) :
                await executeRequest<IGetLocalResumeResponse>(RequestType.GET, request, 'v2/resume');
        } catch (err: any) {
            catchError(err);
        }

        return response;
    };

    //Top Produtos
    const getProductRanking = async (request: IRequestParams, productGroupId?: string): Promise<GetRankingProductsResponse> => {
        let response = {} as GetRankingProductsResponse;

        try {
            response = useMock ?
                await getProductsRankingMock(request) :
                await executeRequest<GetRankingProductsResponse>(RequestType.GET, request, 'v2/TopProducts', productGroupId ? { productGroupId } : {});
        } catch (err: any) {
            catchError(err);
        }

        return response;
    }

    // Top operadores
    const getOperatorRanking = async (request: IRequestParams): Promise<IRankingOperatorsResponse> => {
        let response = {} as IRankingOperatorsResponse;

        try {
            response = useMock ?
                await getOperatorRakingMock(request) :
                await executeRequest<IRankingOperatorsResponse>(RequestType.GET, request, 'v2/TopOperators');
        } catch (err: any) {
            catchError(err);
        }

        return response;
    }

    const getSalesBarChart = async (request: IRequestParams): Promise<GetSalesBarChartResponse> => {
        if (request.selectedPlaces.find(item => item === "a863671b-bfd8-e987-7866-54f32c71be2a")) {
            const response = getSalesMock(request)
            return response;

        } else {
            const response = await executeRequest<GetSalesBarChartResponse>(RequestType.GET, request, 'BillingChart')
            return response;
        }
    }

    const getRankingCategory = async (request: IRequestParams): Promise<CategoryRankingResponse> => {
        let response = {} as CategoryRankingResponse;

        try {
            response = useMock ?
                await getCategoryRankingMock() :
                await executeRequest<CategoryRankingResponse>(RequestType.GET, request, 'v2/TopParentCategories');
        } catch (err: any) {
            catchError(err);
        }

        return response;
    }


    const getTopParentCategories = async (request: IRequestParams, parentCategoryId?: string): Promise<IGetTopParentCategoriesResponse> => {
        let response = {} as IGetTopParentCategoriesResponse;

        try {
            response = await executeRequest<IGetTopParentCategoriesResponse>(RequestType.GET, request, 'v2/TopParentCategories', parentCategoryId ? { parentCategoryId } : {});
        } catch (err: any) {
            catchError(err);
        }

        return response;
    }

    const getTopSubCategories = async (request: IRequestParams, subCategoryId?: string): Promise<IGetTopSubCategoriesResponse> => {
        let response = {} as IGetTopSubCategoriesResponse;

        try {
            response = await executeRequest<IGetTopSubCategoriesResponse>(RequestType.GET, request, 'v2/TopSubCategories', subCategoryId ? { subCategoryId } : {});
        } catch (err: any) {
            catchError(err);
        }

        return response;
    }




    const getProductsRankingCategory = async (categoryId: string, request: IRequestParams): Promise<CategoryProductsRankingResponse> => {
        return await getCategoryRankingMock();
    }

    const getStoresRanking = async (request: IRequestParams): Promise<IRankingStoresResponse> => {
        return await getStoreRankingMock()
    }

    const getStoreRankingCategory = async (storeId: string, request: IRequestParams): Promise<CategoryRankingResponse> => {
        return await getCategoryRankingMock();
    }

    const getRankingClients = async (request: IRequestParams): Promise<IGetRankingClientsResponse> => {
        let response = {} as IGetRankingClientsResponse;

        try {
            response = useMock ?
                await mock.makeMock<IGetRankingClientsResponse>(() => mock[SalesDashboardMockTypes.GET_RANKING_CLIENTS](request)) :
                await executeRequest<IGetRankingClientsResponse>(RequestType.GET, request, 'v2/topCustomers');
        } catch (err: any) {
            catchError(err);
        }

        return response;
    };

    const getPaymentFormEquipment = async (request: IRequestParams): Promise<IGradientTableResponse> => {
        let response = {} as IGradientTableResponse;

        try {
            response = useMock ?
                await mock.makeMock<IGradientTableResponse>(() => mock[SalesDashboardMockTypes.GET_GRADIENT_TABLE](request)) :
                await executeRequest<IGradientTableResponse>(RequestType.GET, request, 'v2/GradiantTable');
        } catch (err: any) {
            catchError(err);
        }

        return response;
    };

    const getSessions = async (localId: string): Promise<IGetSessionsListResponse> => {
        return await getBuscarSessoes(api, localId);
    };

    const getDeviceSales = async (request: IRequestParams): Promise<GetRankingDeviceSalesResponse> => {
        let response = {} as GetRankingDeviceSalesResponse;

        try {
            response = useMock ?
                await getDeviceSalesMock() :
                await executeRequest<GetRankingDeviceSalesResponse>(RequestType.GET, request, 'v2/TopDevices');
        } catch (err: any) {
            catchError(err);
        }

        return response;
    }

    const getProdutosSemSplit = async (localId: string): Promise<IProdutosSemSplitResponse> => {
        const response = await api.get("Produto/BuscarProdutosSemSplit/" + localId)   
        return response.data
    }

    return ({
        getTurnoverTable,
        getLocalResume,
        getProductRanking,
        getOperatorRanking,
        getSalesBarChart,
        getRankingCategory,
        getProductsRankingCategory,
        getSessions,
        getStoresRanking,
        getStoreRankingCategory,
        getDeviceSales,
        getRankingClients,
        getPaymentFormEquipment,
        getTopParentCategories,
        getTopSubCategories,
        getProdutosSemSplit
    })
}
export default SalesDashboardService
