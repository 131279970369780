import { IGetSalesCategoriesResponse } from "modules/dashboardTicket/domain/dto/IGetSalesCategoriesResponse";
import { IDashboardTicketApiService } from "modules/dashboardTicket/domain/interfaces/IMyAccountApiService";
import { ISalesCategories } from "modules/dashboardTicket/presentation/components/rankingCategories/interfaces/IRankingCategories";
import { IFilterValue } from "modules/dashboardTicket/presentation/interfaces/IFilter";


const GetSalesCategoriesUseCase = async (service: IDashboardTicketApiService, localId: string,  filter?: IFilterValue): Promise<ISalesCategories[]> => {

    const response: IGetSalesCategoriesResponse[] = await service.getSalesCategories(localId, filter);

    const parse: ISalesCategories[] = response.map(item => {
        return {
            category: item.categoria,
            value: item.valor,
        };
    });

    return parse;

}

export default GetSalesCategoriesUseCase;