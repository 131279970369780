import { IGetRankingProductsResponse } from "modules/generalDashboard/domain/dto/IGetRankingProductsResponse";
import { IGeneralDashboardApiService } from "modules/generalDashboard/domain/interfaces/IGeneralDashboardApiService";
import { IFilterValue } from "modules/generalDashboard/presentation/interfaces/IFilter";

const GetRankingProductsUseCase = async (service: IGeneralDashboardApiService, localId: string, filter?: IFilterValue): Promise<IGetRankingProductsResponse[]> => {

    const response = await service.getRankingProducts(localId, filter);

    return response;

}

export default GetRankingProductsUseCase;