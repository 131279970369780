import { useLocal } from "modules/local/presentation/context/LocalContext";
import { useCallback, useState } from "react";
import DashboardTicketApi from "services/api/dashboardTicket/DashboardTicketApi";
import { IFilterValue } from "../interfaces/IFilter";
import { IServiceRate } from "../interfaces/IServiceRate";
import GetServiceRateUseCase from "modules/dashboardTicket/application/useCases/GetServiceRateUseCase";

export interface IParamsUseServiceRate {
  localId?: string,
  sessionId?: string,
  period?: number,
}

const UseServiceRate = (filter: IFilterValue | undefined) => {

  const [isLoading, setIsLoading] = useState<boolean>();
  const [serviceRate, setServiceRate] = useState<IServiceRate>()
  const { currentLocal } = useLocal();

  const getServiceRate = useCallback(
    async (params?: IParamsUseServiceRate) => {
      try {
        const servicePermutation = DashboardTicketApi();
        setIsLoading(true);
        if (currentLocal) {
          const response = await GetServiceRateUseCase(servicePermutation,
            currentLocal.id,
            {
              ...filter,
              period: filter?.period ?? 4,
            },
          );

          setServiceRate(response);

          return response
        }
      } finally {
        setIsLoading(false);
      }
    },
    [currentLocal, filter]
  );


  return {
    serviceRate,
    isLoading,
    getServiceRate,
    setServiceRate,
  };
};

export default UseServiceRate;
