import { EnumGender, EnumProductType } from "modules/catalog/domain/enums/CatalogProductEnums";
import { IComplementGroupFormValue } from "../complement/complementForm/ComplementForm";
import { IObservationProductFormValue } from "../observationForm/ObservationForm";
import { PrinterTypeEnum } from "modules/config/smartPrinterConfig/presentation/components/printerSectorConfig/PrinterSectorConfig";




export interface ICategorySelect {
    id: string;
    description: string;
    index?: number;
}
export interface ICatalogSelect {
    id: string;
    description: string;
}


export interface IStoreSelect {
    id: string;
    name: string;
}

export interface IEventSelect{
    id: string;
    name: string
}
export interface IProductSelect {
    id: string;
    name: string;
    value: number;
    categoryId?: string;
    categoryName?: string;
}

export interface IPrinterSelect {
    id: string;
    name: string;
    type?: PrinterTypeEnum;
    // IP: string;
}


export interface IProductFormValue {
    catalogs?: ICatalogSelect[] | null,
    //category
    category?: ICategorySelect | null; // Categoria 
    //categoryId?: ICategorySelect | null; // Categoria 

    enabled?: boolean;
    //detail
    name: string; // Nome
    productInformation: string; // InformacaoProduto
    type: EnumProductType;
    value: number; // Valor (optional)
    image?: string;
    imageUrl?: string;

    //indetification
    internalCode?: string; // CodigoInterno
    integrationCode?: string; // CodigoIntegracao (optional)mber; // Tipo (optional)
    barcode?: string; // codigoBarra (optional)

    //advance
    defaultPrinter?: IPrinterSelect | null
    genderRestriction?: EnumGender; // RestricaoPorSexo (optional)
    expireTime?: number | null; // ExpireTime (optional)
    maxQuantityPerUser?: number | null; // QuantidadeMaximaPorUsuario (optional)

    tare?: number; // Tara (optional)

    dontShowInKDS?: boolean; // DontShowInKDS
    adultHood: boolean; // AdultHood
    serviceFeeExemption?: boolean; // IsencaoTaxaServico (optional)
    featured?: boolean;
    blockPrint?: boolean;
    stockControl?: boolean;
    //invoice
    cest?: string; // Cest (optional)
    cfop?: string; // Cfop (optional)
    ncm?: string; // Ncm (optional)

    //Additionals And Composition


    complements: IComplementGroupFormValue[]; // Composicao
    observations: IObservationProductFormValue[]; // Observacoes

    store?: IStoreSelect | null;
    storeId?: string
    event?: IEventSelect | null;

    productComposition?: boolean; // ProdutoComposicao (optional) morreu
    freeAdditionalQuantity?: number; // QuantidadeAdicionaisGratuito (optional) morreu
    productGeneralObservation?: boolean
    reservedQuantity?: number;

    sectorPrinterIds?: string[];
}








export const ProductFormValueDefaut: IProductFormValue = {
    category: null, // Categoria 
    catalogs: null, // catalog 

    //detail
    name: "", // Nome
    productInformation: "", // InformacaoProduto
    type: EnumProductType.PRODUTO,
    value: 0, // Valor (optional)

    //indetification
    internalCode: "", // CodigoInterno
    integrationCode: "", // CodigoIntegracao (optional)mber, // Tipo (optional)
    barcode: "", // codigoBarra (optional)

    //advance
    defaultPrinter: null,
    genderRestriction: EnumGender.SEM_RESTRICOES, // RestricaoPorSexo (optional)
    expireTime: null, // ExpireTime (optional)
    maxQuantityPerUser: null, // QuantidadeMaximaPorUsuario (optional)

    tare: 0, // Tara (optional)

    dontShowInKDS: false, // DontShowInKDS
    adultHood: false, // AdultHood
    serviceFeeExemption: false, // IsencaoTaxaServico (optional)
    featured: false,
    //invoice
    cest: "", // Cest (optional)
    cfop: "", // Cfop (optional)
    ncm: "", // Ncm (optional)

    //Additionals And Composition
    productComposition: false, // ProdutoComposicao (optional)
    freeAdditionalQuantity: 0, // QuantidadeAdicionaisGratuito (optional)
    complements: [], // Composicao
    observations: [], // Observacoes
    productGeneralObservation: false,
}