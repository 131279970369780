import React, { useEffect, useState } from 'react'
import { IFilterValue } from '../interfaces/IFilter';

export const UseGeneralDashboardPage = () => {
  const [filterType, setFilterType] = useState("cashier");
  const [filterSearch, setFilterSearch] = useState();


  const [filter, setFilter] = useState<IFilterValue>();



  const onChangeFilterHandle = (value: IFilterValue) => {
    const { cashierIds, ...rest } = value;
    setFilter({
      cashierIds,     
      ...rest,
    });
  };

  return (
    {
      onChangeFilterHandle,
      filter,
      setFilterSearch,
      filterSearch,
      setFilterType,
      filterType
    }
  )
}
