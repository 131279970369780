import styles from "./TransactionReportPage.module.scss";
import { UseTransactionReportPage } from "./hooks/UseTransactionReportPage";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import TransactionsHeader from "./components/transactionsHeader/TransactionsHeader";
import { IGetTransactionsParams } from "../domain/dto/IGetTransactionsParams";
import TransactionItem from "./components/transactionItem.tsx/TransactionItem";
import TransactionsFilter from "./components/transactionsFilter/TransactionsFilter";
import { subDays } from "date-fns";
import { MenuItem, Pagination, Skeleton, TextField } from "@mui/material";
import UseDimension from "components/dimension/UseDimension";
import TransactionItemMobile from "./components/transactionItemMobile/TransactionItemMobile";
import Sidesheet from "components/sidesheet/Sidesheet";
import DetailsSidesheet from "./components/detailsSidesheet/DetailsSidesheet";
import { useReactToPrint } from "react-to-print";
import TransactionItemAccoridon from "./components/transactionItemAccoridon.tsx/TransactionItemAccoridon";
import { useAuth } from "modules/auth/presentation/context/AuthContext";

const TransactionReportPage = () => {
    const [filterParams, setFilterParams] = useState<IGetTransactionsParams>({
        StartDate: subDays(new Date(), 30).toISOString(),
        EndDate: new Date().toISOString(),
        OrderBy: "DataCriacao",
    } as IGetTransactionsParams);
    const [pageSize, setPageSize] = useState(10);
    const [openDetailsSidesheet, setOpenDetailsSidesheet] = useState("");

    const componentRef = useRef<HTMLDivElement | null>(null);

    const {
        isLoading,
        getTransactionsList,
        transactionsList: transactionsListResponse,
        totalPages,
        nextPage,
        currentPage,
        paymentTypes,
    } = UseTransactionReportPage();
    const { dimensions } = UseDimension();
    const { hasAccessRole } = useAuth();

    useEffect(() => {
        getTransactionsList(filterParams, currentPage, pageSize);
    }, [filterParams, currentPage, pageSize, getTransactionsList]);

    const transactionsList = useMemo(() => transactionsListResponse ?? [], [transactionsListResponse]);

    const handleFindPayment = useCallback((type: number) => {
        return paymentTypes?.find((payment) => payment.Value === type)?.Name ?? "-";
    }, [paymentTypes]);

    const hasDetailAccess = useMemo(
        () => hasAccessRole("RelatorioTransacoes") ?? false,
        [hasAccessRole]
    );

    const handleExport = useReactToPrint({
        content: () => componentRef.current,
    });

    const skeletons = Array.from({ length: 10 }, (_, index) => (
        <Skeleton key={index} variant="text" sx={{ fontSize: '40px', marginBottom: "10px" }} />
    ));

    const pagination = useCallback(() => {
        if (totalPages) {
            return (
                <div className={styles.conteinerPagination}>
                    <div className={styles.boxMultiplePage}>
                        <span>Exibir</span>
                        <TextField
                            variant="outlined"
                            size="small"
                            select
                            value={pageSize}
                            defaultValue={pageSize}
                            onChange={(ev) => setPageSize(Number(ev.target.value))}
                        >
                            <MenuItem className={styles.textPage} value={10}>10</MenuItem>
                            <MenuItem className={styles.textPage} value={20}>20</MenuItem>
                            <MenuItem className={styles.textPage} value={50}>50</MenuItem>
                        </TextField>
                    </div>
                    {totalPages > 0 && (
                        <Pagination
                            variant="outlined"
                            className={styles.paginationNumber}
                            shape="rounded"
                            sx={{
                                ".MuiPaginationItem-root": {
                                borderColor: "#BDBDBD",
                                color: "#001537",
                                },
                                ".Mui-selected": {
                                fontWeight: "bold",
                                borderColor: "#001537",
                                },
                            }}
                            count={totalPages}
                            page={currentPage + 1}
                            onChange={(_, value) => nextPage(filterParams, value - 1, pageSize)}
                        />
                    )}
                </div>
            )
        }
    }, [currentPage, filterParams, nextPage, pageSize, totalPages]);

    return (
        <>
            <div id={styles.TransactionReportPage}>
                <TransactionsFilter
                    filterParams={filterParams}
                    setFilterParams={setFilterParams}
                />

                {isLoading 
                ? (<div style={{ marginTop: "1rem" }}>
                    {skeletons}
                    {pagination()}
                    </div>)
                : transactionsList && transactionsList.length > 0
                    ? (
                        <div style={{ marginTop: "12px" }}>
                            {dimensions.width >= 850 && <TransactionsHeader params={filterParams} setParams={setFilterParams} />}
                            {transactionsList.map((transaction) => (
                                <>
                                    {dimensions.width > 1600 && (
                                        <TransactionItem
                                            key={transaction.Id}
                                            item={transaction}
                                            handleFindPayment={handleFindPayment}
                                            setOpenDetailsSidesheet={setOpenDetailsSidesheet}
                                            hasDetailAccess={hasDetailAccess}
                                        />
                                    )}
                                    {dimensions.width >= 850 && dimensions.width <= 1600 && (
                                        <TransactionItemAccoridon
                                            key={transaction.Id}
                                            item={transaction}
                                            handleFindPayment={handleFindPayment}
                                            setOpenDetailsSidesheet={setOpenDetailsSidesheet}
                                            hasDetailAccess={hasDetailAccess}
                                        />
                                    )}
                                    {dimensions.width < 850 && (
                                        <TransactionItemMobile
                                            key={transaction.Id}
                                            item={transaction}
                                            handleFindPayment={handleFindPayment}
                                            setOpenDetailsSidesheet={setOpenDetailsSidesheet}
                                            hasDetailAccess={hasDetailAccess}
                                        />
                                    )}
                                </>
                            ))}
                            {pagination()}
                        </div>
                    )
                    : (
                        <div className={styles.emptyList}>
                            <img src="/assets/img/empty-box.png" alt="" />
                            <span><b>Nenhuma transação</b> encontrada!</span>
                        </div>
                    )
                }
            </div>

            {!!openDetailsSidesheet && (
                <Sidesheet
                    open={openDetailsSidesheet}
                    onClose={() => setOpenDetailsSidesheet("")}
                    title={<h2>Detalhes da <b>transação</b></h2>}
                    content={
                        <div ref={componentRef}>
                            <DetailsSidesheet transactionId={openDetailsSidesheet} />
                        </div>
                    }
                    currentStep={1}
                    totalSteps={1}
                    cancelButton
                    continueButton="Fechar"
                    handleContinueButton={() => setOpenDetailsSidesheet("")}
                    customizedCancelButton="Gerar comprovante"
                    handleCustomizedCancelButton={handleExport}
                />
            )}
        </>
    )
}

export default TransactionReportPage;