import InvoiceService from "modules/meepErp/services/api/Invoice/InvoiceService";
import { IInvoiceFilterValue } from "modules/meepErp/presentation/components/invoices/invoiceList/invoiceFilter/IInvoiceFilter";
import { IInvoiceListRequest } from "../../dtos/invoice/IInvoiceDtos";

export const getInvoiceListUseCase = async ( filter: IInvoiceFilterValue) => {
    const service = InvoiceService();
    const page = filter.page ?? 1;
    const pageSize = filter.pageSize ?? 10;
    const sortBy = filter.sortBy?.length ? filter.sortBy : undefined;
    const request: IInvoiceListRequest = {
        page: page,
        pageSize: pageSize,
        orderBy: sortBy,
        ascending: filter.ascending,
        operationTypeId: filter.operationTypeId,
        number: filter.number,
        quantity: filter.quantity,
        total: filter.total,
        issueDateStart: filter.issueDateStart,
        issueDateEnd: filter.issueDateEnd,
        dateStart: filter.dateStart,
        dateEnd: filter.dateEnd
    };
    return service.getInvoiceList( request);
};
export const onDeleteInvoiceUseCase = async (id: string) => {
    const service = InvoiceService();
    return await service.deleteInvoice(id);
};

export const getQuantityStockUseCase = async (storageLocationId: string, supplyId : string) => {
    const service = InvoiceService();
    return await service.getQuantityStock(storageLocationId, supplyId);
};

