import { useMemo, useState } from "react";
import styles from "components/sidesheet/Sidesheet.module.scss";
import { Autocomplete, MenuItem, Select, TextField } from "@mui/material";
import { IEditCollaboratorRequest } from "modules/colaboradores/domain/dto/IEditCollaboratorRequest";
import { IEditCollaboratorFormErrors } from "../validade/EditCollaboratorValidation ";
import { IGetAllRolesResponse } from "modules/cargos/models/dtos/IGetAllRolesResponse";

export interface IEditCollaboratorProps {
  values: IEditCollaboratorRequest;
  setValues: React.Dispatch<React.SetStateAction<IEditCollaboratorRequest>>;
  errors: IEditCollaboratorFormErrors;
  setErrors: React.Dispatch<React.SetStateAction<IEditCollaboratorFormErrors>>;
  allRolesList: IGetAllRolesResponse[];
}

const EditCollaborator = ({ values, setValues, errors, setErrors, allRolesList }: IEditCollaboratorProps) => {
  const rolesList = useMemo(() => allRolesList ?? [], [allRolesList]);

  return (
    <>
      <div>
        <label htmlFor="role" className={styles.required}>
          Cargo
        </label>
        <Autocomplete
          disablePortal
          options={rolesList}
          getOptionLabel={option => option.Name}
          noOptionsText="Nenhuma opção encontrada"
          multiple
          renderInput={(params) => (
            <TextField
                {...params}
                placeholder="Filtrar pelo cargo"
                InputProps={{
                    style: { padding: '0 20px 0 10px' },
                    ...params.InputProps,
                }}
                error={!!errors.ProfileIds}
                helperText={errors.ProfileIds}
            />
          )}
          onChange={(_ev, autocompleteValues) => {
            setErrors({ ...errors, ProfileIds: "" });
            const rolesIds = autocompleteValues.map((it) => it.Id);
            setValues({...values, ProfileIds: rolesIds });
          }}
        />
      </div>
    </>
  );
};

export default EditCollaborator;
