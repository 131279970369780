import { Icon, IconButton } from "@material-ui/core";
import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import styles from "./Header.module.scss";
import UseDimension from "components/dimension/UseDimension";
import {
  Help,
  KeyboardArrowRight,
  Settings,
  Search,
  Notifications,
  History,
  KeyboardArrowLeft,
} from "@mui/icons-material";
import Button from "components/ui/Button/Button";
import { useLocal } from "modules/local/presentation/context/LocalContext";
import { useHistory, useLocation } from "react-router-dom";
import UserButton from "../userButton/UserButton";
import { IPlans } from "modules/plans/presentation/interfaces/IPlans";
import { ISubscription } from "modules/plans/presentation/interfaces/ISubscription";
import { usePlans } from "modules/plans/presentation/hooks/PlansContext";
import { IGetNotificationsByTypeResponse } from "components/layout/domain/dto/IGetNotificationsByTypeResponse";
import NotificationFragment from "modules/notification/presentation/fragment/NotificationFragment";
import { SystemIdentifierEnum } from "modules/gestaoVendas/domain/enum/GetSegmentosEnum";

export interface IheaderProps {
  //propertys
  onClickMenu?: () => void;
  disabledMenu?: boolean;
  onClickHelp: () => void;
  home: boolean | undefined;
  onClickMyAccount: () => void;
  onClickSettings: () => void;
  onClickSearch: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onClickNotifications: () => void;
  notification?: IGetNotificationsByTypeResponse[];
  hasBannerNotification?: boolean;
  isSaas?: boolean;
}
const Header: FC<IheaderProps> = ({
  onClickMenu,
  disabledMenu,
  onClickHelp,
  home,
  onClickMyAccount,
  onClickSettings,
  onClickSearch,
  onClickNotifications,
  isSaas,
  notification,
  hasBannerNotification,
}) => {
  const { isMobile } = UseDimension();
  const { currentLocal } = useLocal();
  const { push } = useHistory();
  const { getSubscription, getPlans, subscription } = usePlans();
  const [isPremium, setIsPremium] = useState(false);
  const [loading, setLoading] = useState(true);
  const location = useLocation();

  useEffect(() => {
    (async () => {
      try {
        const promises: [Promise<IPlans[] | undefined>, Promise<ISubscription | undefined>] = [getPlans(), getSubscription()];
        const [plans, subscription] = await Promise.all(promises);
        let premiumPlan: IPlans | undefined = undefined;

        if (plans?.length) {
          premiumPlan = plans.find(x => x.name === 'Plano Premium');
        }
        if (subscription) {
          setIsPremium(!!premiumPlan && premiumPlan.id === subscription?.planId);
        }

        setLoading(false);
      } catch {
        setLoading(false);
      }
    })();
  }, [getPlans, getSubscription]);

  const unreaddNotifications = useMemo(() => {
    const total = notification?.filter((item) => !item.MensagemLida);
    return total ? total.length : null;
  }, [notification]);
  
  const handleCurrentPlan = useCallback(() => {
    if (isSaas) {
      return subscription?.planName;
    }
    return !isPremium ? 'Básico' : 'Premium';
  }, [isPremium, isSaas, subscription?.planName]);

  const handleLogo = useMemo(() => {
    if (currentLocal?.systemIdentifier !== 1 || location.pathname === "/private") {
      return <img src={"/assets/img/LogoMeepBeta.svg"} alt=""/>;
    }
    return <img src={"/assets/img/LogoMee.svg"} alt=""/>;
  }, [currentLocal?.systemIdentifier, location.pathname]);
  
  return (
    <div id={styles.Header}>
      <div
        className={styles.container}
        style={{ marginBottom: hasBannerNotification ? 0 : "" }}
      >
        <div className={!isMobile ? styles.start : styles.startMobile}>
          {!disabledMenu && onClickMenu && (
            <IconButton onClick={onClickMenu} className={styles.buttonIcon}>
              <Icon className={styles.menuIcon}>menu</Icon>
            </IconButton>
          )}
          <div className={!isMobile ? styles.logo : styles.logoMobile}>
            {handleLogo}
          </div>
          {!home && !isMobile && (
            <>
              <div className={styles.border} />
              <Button
                fullWidth={false}
                variant="outlined"
                onClick={() => push("/private")}
              >
                {currentLocal?.name} <KeyboardArrowRight />
              </Button>
            </>
          )}
        </div>
        <div className={styles.center}></div>

        <div className={styles.end}>
          {!isMobile &&
            !loading &&
            location.pathname !== '/private/' &&
            location.pathname !== 'private/' &&
            location.pathname !== '/private' && (
              <div className={styles.plano}>
                <p>
                  <span>Plano Atual:</span> {handleCurrentPlan()}
                </p>
                <a href="/private/plans">
                  {isPremium || (isSaas && subscription?.planName === "Avançado")
                    ? 'Ver plano'
                    : 'Fazer upgrade'
                  }
                  <img src="/assets/icon/mdi_crown.svg" alt="" />
                </a>
              </div>
            )}
          <button className={styles.item} onClick={onClickSearch}>
            <Search />
          </button>
          {!isMobile && (
            <>
              {/* <div className={styles.item} onClick={onClickNotifications}>
                {unreaddNotifications ? (
                  <div className={styles.bullet}>{unreaddNotifications}</div>
                ) : null}
                <Notifications />
              </div> */}
              <NotificationFragment />
              <div className={styles.item} onClick={onClickHelp}>
                <Help />
              </div>
              {!disabledMenu && currentLocal?.systemIdentifier !== SystemIdentifierEnum.Saas && (
                <div className={styles.item} onClick={onClickSettings}>
                  <Settings />  
                </div>
              )}
              {!isSaas && (
                <div
                  className={styles.item}
                  onClick={() =>
                    process.env.REACT_APP_OLD_PORTAL_URL &&
                    (window.location.href = process.env.REACT_APP_OLD_PORTAL_URL)
                  }
                >
                  <History />
                  <span>
                    Versão <br />
                    clássica
                  </span>
                </div>
              )}
            </>
          )}
          <div className={styles.item} onClick={onClickMyAccount}>
            <UserButton />
            {isMobile && <KeyboardArrowLeft className={styles.arrow} />}
          </div>
        </div>
      </div>
    </div>
  );
};
export default Header;
