
import { FC, useEffect, useRef } from 'react'
import styles from './RankingProducts.module.scss'
import './RankingProducts.css'
import RankingList from '../rakingList/RankingList'
import { IRankingProducts } from '../../interfaces/IRankingProducts'
import { IParamsUseRankingProducts } from '../../hooks/UseRankingProducts'
import { moneyMaskNumber } from 'services/utils/Money'


export interface IRankingProductsProps {
    //propertys
    getRankingProducts: (params?: IParamsUseRankingProducts) => Promise<IRankingProducts[] | undefined>
    rankingProducts: IRankingProducts[] | undefined
    isMobile?: boolean
    isLoading: boolean | undefined

}
const RankingProducts: FC<IRankingProductsProps> = ({ rankingProducts, isMobile, getRankingProducts, isLoading  }) => {

    useEffect(() => {
        getRankingProducts();
    }, [getRankingProducts])   

    const containerRef = useRef<HTMLDivElement>(null);   

    return (
        (<div ref={containerRef} id={styles.RankingProducts}>

            <div className={styles.container}>
                {
                    <RankingList
                        // keyProp="id"
                        list={rankingProducts}
                        isLoading={isLoading}

                        colunms={{
                            description: { title: "Nome" },
                            categoryName: { title: "Categoria", hide: isMobile },
                            unitaryValue: { title: "Preço médio", hide: isMobile, formatValue: (value) => moneyMaskNumber(value) },
                            quantity: { title: isMobile ? "Qtd" : "Quantidade" },
                            total: { title: "Total" }
                        }}

                    />
                }
            </div>
        </div>)
    )
}
export default RankingProducts