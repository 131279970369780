import { IGetKdsStepsTotalRequest } from "modules/kds/domain/dto/getKdsSteps/GetKdsStepsTotalRequest"
import { IKDSService } from "modules/kds/domain/interfaces/IKDSService"
import { IKDSFilter } from "modules/kds/presentation/interfaces/IKDSFilter"

const GetStepTotalUseCase = async (service: IKDSService, filter: IKDSFilter, localId: string, stepId: string): Promise<{ total: number }> => {
    const request: IGetKdsStepsTotalRequest = {
        localId: localId,
        stepId: stepId,
        startDate: filter.startDate ? new Date(filter.startDate).toISOString() : '',
        endDate: filter.endDate ? new Date(filter.endDate).toISOString() : '',
    }


    const response = await service.getStepsTotals({ ...request, stepId });
    return (response)
}
export default GetStepTotalUseCase  