import React, { FormEvent, useCallback, useEffect, useMemo, useState } from 'react';
import FooterButtons from '../../../footerButtons/FooterButtons'
import { IPrinterParamsValues } from './IPrinterParamsValues';
import styles from './PrinterParamsForm.module.scss'
import { FormControlLabel, Radio, Switch, TextField } from '@material-ui/core';
import { Autocomplete, AutocompleteRenderInputParams } from '@material-ui/lab';

interface IPrinterSector {
    id: string;
    name: string;
}

export interface IPrinterParamsFormProps {
    defaultValue?: IPrinterParamsValues;

    getPrinterSectors: () => Promise<IPrinterSector[]>;
    onModified: (modified: boolean) => void;
    onSubmit: (value: IPrinterParamsValues) => void
    onClickBack: (value: IPrinterParamsValues) => void
}

const PrinterParamsForm: React.FC<IPrinterParamsFormProps> = ({ defaultValue, onModified, onSubmit, onClickBack, getPrinterSectors }) => {
    const [values, setValues] = useState<IPrinterParamsValues>(defaultValue ?? {} as IPrinterParamsValues);
    const [sectors, setSectors] = useState<IPrinterSector[]>([]);

    useEffect(() => {
        getPrinterSectors().then((response) => {
            setSectors(response);
        });
    }, [getPrinterSectors]);

    useEffect(() => {
        if (defaultValue) {
            setValues(defaultValue);
        }
    }, [defaultValue]);

    const onChangeHandle = (property: string, value: any) => {
        setValues((prev) => ({ ...prev, [property]: value }));

        onModified(true);
    }

    const submitHandle = useCallback((ev: FormEvent<HTMLFormElement>) => {
        ev.preventDefault();
        onSubmit(values);
    }, [onSubmit, values]);

    const sectorValue = useMemo(() => sectors.find((sector) => {
        console.log(sector.id, values.receiptPrinterSectorId);
        return sector.id === values.receiptPrinterSectorId
    }) ?? null, [sectors, values.receiptPrinterSectorId]);

    return (
        <form id={styles.PrinterParamsForm} onSubmit={submitHandle}>
            <span>
                Habilite quais parâmetros de impressão deseja utilizar em seu perfil de venda:
            </span>
            <div className={styles.container}>
                <FormControlLabel
                    checked={values.isPrintLogoEnabled}
                    onChange={(_, checked) => onChangeHandle("isPrintLogoEnabled", checked)}
                    control={<Switch color='secondary' />} label={"Habilitar impressão da logomarca"}
                />
                <FormControlLabel
                    checked={values.isPrintQrCodeEnabled}
                    onChange={(_, checked) => onChangeHandle("isPrintQrCodeEnabled", checked)}
                    control={<Switch color='secondary' />} label={"Habilitar impressão do QR code"}
                />
                <FormControlLabel
                    checked={values.isPrintProductCategoryEnabled}
                    onChange={(_, checked) => onChangeHandle("isPrintProductCategoryEnabled", checked)}
                    control={<Switch color='secondary' />} label={"Habilitar impressão da categoria do produto"}
                />
                <FormControlLabel
                    checked={values.isControlEnabled}
                    onChange={(_, checked) => onChangeHandle("isControlEnabled", checked)}
                    control={<Switch color='secondary' />} label={"Habilitar controle de impressão"}
                />
                <label>Impressora de recibo:</label>
                <Autocomplete
                    options={sectors}
                    size='small'
                    getOptionLabel={(option) => option.name}
                    value={sectorValue}
                    onChange={(_, value) => onChangeHandle("receiptPrinterSectorId", value?.id ?? "")}
                    renderInput={params => <TextField variant='outlined' {...params}
                    // label="Impressora de recibo"
                    />}
                />
            </div>
            <>
                <FooterButtons onClickBack={() => onClickBack(values)} labelNext={"Salvar"} nextType='submit'></FooterButtons>
            </>
        </form>
    )
}
export default PrinterParamsForm