import React, { FC, useCallback, useEffect, useState } from 'react'
import styles from './TabMenu.module.scss'
import { useMenuContext } from '../context/MenuContext'
import { IGetMenuResponse } from 'components/layout/domain/dto/IGetMenuResponse'
import { Link, useLocation, useParams } from 'react-router-dom'
export interface ItabMenuProps {
    //propertys
}
const TabMenu: FC<ItabMenuProps> = () => {
    const { getCurrentGrandchildrenMenu, menuPosition } = useMenuContext()
    const [menuItems, setMenuItems] = useState<IGetMenuResponse[]>();
    const [selectedTab, setSelectedTab] = useState<IGetMenuResponse>();
    const { pathname } = useLocation()
    const { listId, guestListId } = useParams<{ listId: string, guestListId: string }>();

    useEffect(() => {
        const _currentMenu = getCurrentGrandchildrenMenu();
        if (_currentMenu) {
            setMenuItems(_currentMenu.submenus)
        }
    }, [getCurrentGrandchildrenMenu]);


    const onClickRoute = useCallback(
        (route: string) => {
            if (menuPosition === 1 && pathname.includes("private/event/")) {
                if (listId) {
                    return `${route.trim()}/${listId}`;
                }
                if (guestListId) {
                    return `${route.trim()}/${guestListId}`;
                }
                const eventId = localStorage.getItem("@eventId");

                if (route === "/private/event/dashboard/Customer" && eventId) {
                    return `${route.trim()}/${eventId}`;
                }

                return `${route.trim()}/${eventId}`;
            }
            return(route.trim());
        },
        [menuPosition, pathname, listId, guestListId]
    );


    useEffect(() => {
        const _selectedMenu = menuItems?.find(item => item.route && pathname === item.route);
        setSelectedTab(_selectedMenu);
    }, [menuItems, pathname])

    return (
        <div id={styles.TabMenu} >
            {menuItems?.map(item => <Link to={onClickRoute(item.route ?? '#')}><div className={selectedTab?.id === item.id ? styles.selected : styles.item}>{item.title}
            </div></Link>)}
        </div>
    )
}
export default TabMenu