import Item from "modules/customers/presentation/components/list/Item/Item"
import { IGetLocalResumeRequest, IGetLocalResponse, IGetLocalResumeEventResponse, IGetLocalResumeConfigurationResponse } from "modules/local/domain/dtos/IGetLocalResumeResponse"
import { IGetLocalsListRequest, IGetLocalsListResponse } from "modules/local/domain/dtos/IGetLocalsListResponse"
import { IlocalService } from "modules/local/domain/interfaces/IlocalService"
import { api } from "../Api"

const LocalService = (): IlocalService => {
    const getLocalResumeList = async (request: IGetLocalResumeRequest): Promise<IGetLocalResumeEventResponse> => {
        const response = await api.get<IGetLocalResumeEventResponse>("/SuperApp/Event/Local/Resume?" + request.localId.map(item => `localId=${item}`).join('&'))
        return response.data
        // return getLocalStorageLocals(request)
    }
    const getLocalsList = async (request: IGetLocalsListRequest): Promise<IGetLocalsListResponse> => {
        const response = await api.get<IGetLocalsListResponse>("/dashboard/v2/sales/PlacesSelector", { params: { ...request, page: (request.page ?? 1) - 1, keyword: request.search } })
        return response.data
        // return getLocalStorageLocals(request)
    }

    const getLocalConfiguration = async (localId: string): Promise<IGetLocalResumeConfigurationResponse> => {
        const response = await api.get(`/Proprietario/BuscarConfiguracoesDoLocal/${localId}`);
        const config = response.data.configuracaoSistemasGestao.filter((x: { tipoSistema: { descricao: string, id: number } }) => x.tipoSistema.id === 2);
        const result: IGetLocalResumeConfigurationResponse = {
            cashlessPrepaid: (config.length > 0),
            gerarNFCE: response.data.gerarNFCE,
            podeExportarProdutos: response.data.podeExportarProdutos
        };

        return result;

    };
    return ({ getLocalResumeList, getLocalsList, getLocalConfiguration })
}
export default LocalService
