import { AxiosInstance } from "axios"
import { IGetCatalogListItemResponse, IGetCatalogListProductItemResponse, IGetCatalogResponse } from "modules/catalog/domain/dtos/IGetCatalogListResponse";
import { IGetCatalogProductResponse } from "modules/catalog/domain/dtos/IGetCatalogProductResponse";
import { IGetCategoryListResponse } from "modules/catalog/domain/dtos/IGetCategoryListResponse"
import { IGetProductsListItemResponse } from "modules/catalog/domain/dtos/IGetProductsListResponse";
import { IGetCategoriaResponse } from "./produto/Categoria";
import { ProdutoItem, ProdutoSelectItem } from "./produto/ProdutoLista";
import { ProdutoModel } from "./produto/produtoModel";
import ProdutoToProduct from "./ProdutoToProduct";
import { IGetMainCategoriesAndInvoiceSplicLocals } from "modules/catalog/domain/dtos/IGetMainCategoriesAndInvoiceSplicLocals";
import { IGetProductCategory } from "modules/catalog/domain/dtos/IGetProductCategory";
import { IListaDeProdutos } from "./produto/IListaDeProdutos";
import { IAddCatalogRequest, IEditCatalogRequest } from "modules/catalog/domain/dtos/ISaveCatalogRequest";
import { AddListaProdutoItem, IAddListaDeProdutoRequest } from "./produto/IAddListaDeProdutoRequest";
import { IEditListaDeProdutos, IEditListaProdutoItem } from "./produto/IEditPRodutoListaRequest";
import { IEditarCategoriaDeProduto, ISalvarCategoriaDeProduto } from "./produto/ISalvarCategoriaDeProduto";
import { IAddProductCategoryRequest, IEditProductCategoryRequest } from "modules/catalog/domain/dtos/ISaveProductCategoryRequets";
import { IImportProductItemRequest } from "modules/catalog/domain/dtos/IImportProductRequest";
import { LojaItem } from "./produto/IObterLojas";
import { IGetStoreItemResponse } from "modules/catalog/domain/dtos/IGetStoreResponse";
import { IGetAccessUserItemResponse } from "modules/catalog/domain/dtos/IGetAccessUserResponse";
import { IGetAcessosUsuarios, ILimiteAcessosRequest } from "./produto/IGetAcessosUsuarios";
import { ILimitAccessProductRequest, ILimitAccessProductResponse } from "modules/catalog/domain/dtos/ILimitAccessProductRequest";
import { IEventSelect } from "modules/catalog/presentation/componentes/productForm/IProducFormValuet";
import { IGetEventsItensResponse, IGetEventsResponse } from "modules/catalog/domain/dtos/IGetEventsResponse";

const AntiCorruptionCatalogService = (api: AxiosInstance) => {

    const GetProduto = async (localId: string, produtoId: string,): Promise<IGetCatalogProductResponse> => {
        const categoriasPromise = api.get<IGetCategoriaResponse>(`/Produto/BuscarCategoriasPorLocalId/${localId}`);
        const produtoPromise = api.get<ProdutoModel>(`/Produto/BuscarProdutoDoLocal/${produtoId}`);
        const [categorias, produto] = await Promise.all([categoriasPromise, produtoPromise])
        const product = ProdutoToProduct(localId, produto.data, categorias.data.categorias)
        return product;
    }

    const getCategoriasPaiEllocaisSplitFiscalPorLocal = async (localId: string): Promise<IGetMainCategoriesAndInvoiceSplicLocals> => {
        const response = await api.get<IGetCategoriaResponse>(`/Produto/BuscarCategoriasPorLocalId/${localId}`);
        return {
            mainCategories: response.data.categoriasPai.map(item => ({ id: item.id, description: item.descricao })),
            invoiceSplitLocals: response.data.locaisSplitFiscal.map(item => ({ id: item.id, name: item.nome }))
        }
    }

    const GetCategoriaPorId = async (localId: string, categoriaId: string): Promise<IGetProductCategory> => {
        const response = await api.get<IGetCategoriaResponse>(`/Produto/BuscarCategoriasPorLocalId/${localId}?usarOrdemCategoria=true`);
        const categoria = [...response.data.categorias, ...response.data.categoriasPai].find(item => item.id === categoriaId)
        if (categoria) {
            return {
                id: categoria.id,
                description: categoria?.descricao,
                mainCategoryId: categoria?.paiId,
                invoiceSplitLocalId: categoria?.localSplitFiscal?.id,
                showInDashboard: categoria?.mostrarDashboard,
                showInPOS: categoria?.mostrarPos,
                index: categoria?.ordenacao,
                categoryName: categoria?.categoria ?? "",
            }
        } else {
            throw (new Error(`{ message: "Categoria não encontrada" }`))
        }
    }

    const getProdutos = async (localId: string, categoryId?: string, keyword?: string): Promise<IGetProductsListItemResponse[]> => {
        var response = await api.get<{ Registros: ProdutoItem[] }>(`/Produto/BuscarProdutosDoLocal?categoriasIds=${categoryId}&keyword=${keyword ?? ""}&localId=${localId}&tipoProdutoId=0&page=0&pageSize=200`);

        return response.data.Registros.map<IGetProductsListItemResponse>(item => ({
            id: item.id,
            name: item.nome,
            value: item.valor,
            imageUrl: `${process.env.REACT_APP_URL_IMAGEM}${localId}/${item.id}.jpg?v=4`,
            description: item.informacaoProduto ?? "",
            isFeatured: item.destaque,
            enabled: item.ativado,
            type: item.tipo,
            localId,
            category: {
                id: item.idCategoria,
                name: item.nomeCategoria
            },
        }));
    }

    const getListaDeCategoriasFiltros = async (localId: string, hideEmpty?: boolean, keyword?: string): Promise<IGetCategoryListResponse[]> => {
        var response = await api.get<{ nome: string, id: string }[]>(`/CategoriaProduto/Filtro?localId=${localId}&hideEmpty=${hideEmpty}&keyword=${keyword}`);
        // var response = await api.get<{ nome: string, id: string }[]>(`/CategoriaProduto/${localId}`);
        return response.data.map<IGetCategoryListResponse>(item => ({ id: item.id, description: item.nome }))
    }

    const getListaDeCategorias = async (localId: string, hideEmpty?: boolean, keyword?: string): Promise<IGetProductCategory[]> => {
        var response = await api.get<IGetCategoriaResponse>(`/Produto/BuscarCategoriasPorLocalId/${localId}?hideEmpty=${hideEmpty}&keyword=${keyword}`);
        return response.data.categorias.map<IGetProductCategory>(item => ({
            id: item.id,
            description: item.descricao,
            mainCategoryId: item.paiId,
            invoiceSplitLocalId: item.localSplitFiscal?.id,
            showInDashboard: item.mostrarDashboard,
            showInPOS: item.mostrarPos,
            index: item.ordenacao,
        }))
    }

    const getListasDeProdutos = async (localId: string): Promise<IGetCatalogListItemResponse[]> => {
        var response = await api.get<{ descricao: string, listaProdutoId: string, ehListaPadrao: boolean }[]>("/Proprietario/BuscarListasProdutosDoLocal/" + localId);
        return response.data.map(item => ({ id: item.listaProdutoId, description: item.descricao, isDefault: item.ehListaPadrao }))
    }

    const getTodosOsProdutos = async (localId: string): Promise<IGetProductsListItemResponse[]> => {
        var response = await api.get<ProdutoSelectItem[]>("Produto/BuscarTodosProdutosDoLocal/" + localId);

        return response.data.map<IGetProductsListItemResponse>(item => ({
            id: item.id,
            name: item.nome,
            value: item.valor,
            imageUrl: `${process.env.REACT_APP_URL_IMAGEM}${localId}/${item.id}.jpg?v=4`,
            description: "",
            isFeatured: item.destaque,
            enabled: item.ativado,
            type: item.tipo,
            localId,
            category: {
                id: item.categoriaLocalClienteId,
                name: item.categoriaLocalCliente
            }
        }));
    }

    const getListaDeProdutoPorId = async (localId: string, listaId: string): Promise<IGetCatalogResponse> => {
        var response = await api.get<IListaDeProdutos>("/Produto/BuscarListaProdutoPorId/" + listaId);
        var lista = response.data;

        const responseCatalog: IGetCatalogResponse = {
            id: lista.listaProdutoId,
            description: lista.descricao,
            products: lista.produtosDaLista.map<IGetCatalogListProductItemResponse>(item => ({
                id: item.produtoId,
                name: item.descricao,
                categoryId: item.categoriaLocalCliente?.id,
                value: item.valor,
                discount: item.desconto,
                index: item.ordem
            })),
            showInApp: lista.exibirAppMeepFood,
            showInWebApp: lista.exibirSiteMeepFood
        }
        return responseCatalog;
    }


    const adicionarListaDeProduto = async (localId: string, request: IAddCatalogRequest) => {
        const AddListaProdutoItem: IAddListaDeProdutoRequest = {
            localId: localId,
            descricao: request.description,
            produtos: request.products.map<AddListaProdutoItem>(item => ({
                produtoId: item.id,
                categoria: item.category.name,
                descricao: item.name,
                valor: item.value,
                desconto: item.discount
            }))

        }
        var response = await api.post("/Proprietario/AdicionarListaProduto", AddListaProdutoItem);
    }

    const editarListaDeProduto = async (localId: string, request: IEditCatalogRequest) => {
        const EditListaProdutoItem: IEditListaDeProdutos = {
            localId: localId,
            listaProdutoId: request.id,
            descricao: request.description,
            exibirAppMeepFood: request.showInApp,
            exibirSiteMeepFood: request.showInWebApp,
            produtosDaLista: request.products.map<IEditListaProdutoItem>(item => ({
                produtoId: item.id,
                descricao: item.name,
                valor: item.value,
                desconto: item.discount
            }))

        }
        var response = await api.post("/Proprietario/EditarListaProduto", EditListaProdutoItem);
    }

    const addCategoriaDeProdutos = async (localId: string, request: IAddProductCategoryRequest) => {
        const EditListaProdutoItem: ISalvarCategoriaDeProduto = {
            mostrarDashboard: request.showInDashboard,
            mostrarPos: request.showInPOS,
            descricao: request.description,
            localSplitFiscalId: request.invoiceSplitLocalId,
            localId: localId,
            paiId: request.mainCategoryId,
            ordenacao: request.index,
            locais: request.locais,
            shared: request.shared,
            fotoCategoria: request.fotoCategoria
        }
        var response = await api.post("/Produto/SalvarCategoria", EditListaProdutoItem);
    }

    const EditarCategoriaDeProdutos = async (localId: string, request: IEditProductCategoryRequest) => {
        const EditListaProdutoItem: IEditarCategoriaDeProduto = {
            id: request.id,
            categoriaId: request.id,
            mostrarDashboard: request.showInDashboard,
            nomeCompleto: request.description,
            mostrarPos: request.showInPOS,
            descricao: request.description,
            localId: localId,
            localSplitFiscalId: request.invoiceSplitLocalId,
            ordenacao: request.index,
            paiId: request.mainCategoryId,
            fotoCategoria: request.fotoCategoria
        }
        var response = await api.post("/Produto/SalvarCategoria", EditListaProdutoItem);
    }


    const desativarProduto = async (productId: string) => {
        await api.post(`/Proprietario/DesativarProdutos/${productId}`);
    }

    const ativarProduto = async (productId: string) => {
        await api.post(`/Proprietario/AtivarProdutos/${productId}`);
    }
    const duplicarListaDeProduto = async (catalogId: string) => {
        await api.post(`/Proprietario/DuplicarListaProduto/`, { id: catalogId });
    }

    const removerListaDeProduto = async (catalogId: string) => {
        await api.post(`/Proprietario/RemoverListaProduto/${catalogId}`);
    }

    const removerCategoriaDeProdutos = async (categoryId: string, targetCategoryId?: string) => {
        await api.delete(`/Produto/DeletarCategoria/${categoryId}/${targetCategoryId}`)
    }

    const importarProdutos = async (localId: string, catalogIds: string[], products: IImportProductItemRequest[]) => {
        const request = products.map(item => (`${item.categoryName};${item.productName};${item.value};${item.NCM};${item.CEST};${item.CFOP};${item.barCode};${item.internalCode};${item.productId};${item.externalCode};${item.productType}`))
        return await api.post(`/importarproduto`, {
            localClienteId: localId,
            catalogIds,
            conteudo: request.join('\n'),
        });
    }

    const getStores = async (localId: string): Promise<IGetStoreItemResponse[]> => {
        const response = await api.get<LojaItem[]>(`/store?localClienteId=${localId}`);
        return response.data.map<IGetStoreItemResponse>(item => ({
            id: item.Id,
            name: item.Name,
        }))
    }

    const getAcessosUsuariosList = async (localId: string): Promise<IGetAccessUserItemResponse[]> => {

        const response = await api.get<IGetAcessosUsuarios[]>(`/Politica/Acessos?localId=${localId}`);

        return response.data.map(item => {
            return {
                id: item.Id,
                name: item.Nome,
                document: item.CPF,
                email: item.Email,
            }

        })
    }



    const limitarAcessoProduto = async (request: ILimitAccessProductRequest) => {
        const response = await api.post<ILimiteAcessosRequest>(`/Produto/LimitarAcessoProdutoUsuario`, {
            Id: request.productId,
            Usuarios: request.userIds
        });
    }

    const obterLimitesDeAcesso = async (productId: string) => {
        const response = await api.get<ILimitAccessProductResponse[]>(`/Produto/ObterLimitesDeAcesso/${productId}`);
        return response.data;
    }

    //https://portal-api.meep.cloud/api/SuperApp/Event/UpcomingEvents?localId=05b6edf4-abac-e3cc-9c1e-c88cf3a0afcb
    //https://portal-api.meep.cloud/api/SuperApp/Event/OngoingEvents?localId=05b6edf4-abac-e3cc-9c1e-c88cf3a0afcb
    const getEvents = async (localId: string): Promise<IGetEventsResponse> => {

        const UpcomingEventsPromise = api.get<IGetEventsResponse>(`SuperApp/Event/UpcomingEvents/`, { params: { localId } });
        const OngoingEventsPromise = api.get<IGetEventsItensResponse[]>(`/SuperApp/Event/OngoingEvents/`, { params: { localId } });

        const [upcomingEvents, ongoingEvents] = await Promise.all([UpcomingEventsPromise, OngoingEventsPromise])

        const items = [...upcomingEvents.data.items, ...ongoingEvents.data]
        return { items }

    }

    return ({
        getListaDeCategorias,
        getProdutosPorCategoria: getProdutos,
        getListasDeProdutos,
        GetProduto,
        getCategoriasPaiEllocaisSplitFiscalPorLocal,
        GetCategoriaPorId,
        getListaDeProdutoPorId,
        getTodosOsProdutos,
        adicionarListaDeProduto,
        editarListaDeProduto,
        addCategoriaDeProdutos,
        EditarCategoriaDeProdutos,
        desativarProduto,
        ativarProduto,
        getListaDeCategoriasFiltros,
        duplicarListaDeProduto,
        removerListaDeProduto,
        removerCategoriaDeProdutos,
        importarProdutos,
        getStores,
        getAcessosUsuariosList,
        limitarAcessoProduto,
        obterLimitesDeAcesso,
        getEvents
    })
}
export default AntiCorruptionCatalogService
