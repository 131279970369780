import { Icon } from '@material-ui/core'
import { Schedule } from '@mui/icons-material'
import { differenceInDays, differenceInHours, differenceInSeconds, format } from 'date-fns'
import { differenceInMinutes } from 'date-fns/esm'
import formatDistanceToNow from 'date-fns/formatDistanceToNow'
import { ptBR } from 'date-fns/locale'
import React, { FC, useEffect, useState } from 'react'
import styles from './KDSTimer.module.scss'
export interface IKDSTimerProps {
    //propertys
    startDate: Date;
    timeToPrepareInMinutes?: number
}

const KDSTimer: FC<IKDSTimerProps> = ({ startDate }) => {
    const [time, setTime] = useState<string>();
    const [isTimer, setIsTimer] = useState(false);
    const [statusColor, setStatusColor] = useState("")
    useEffect(() => {
        const timer = setInterval(() => {

            // if (differenceInDays(new Date(), startDate) > 1) {
            //     setTime(formatDistanceToNow(startDate, { locale: ptBR }))
            // } else
            if (differenceInHours(new Date(), startDate) >= 1 && differenceInDays(new Date(), startDate) < 1) {
                const intervalTime = differenceInHours(new Date(), startDate)
                setTime(formatDistanceToNow(startDate, { locale: ptBR }))
                setStatusColor("#ddd")
                setIsTimer(true)
            }
            else if (differenceInHours(new Date(), startDate) < 1) {
                setStatusColor("#399C54")
                if (differenceInMinutes(new Date(), startDate) >= 10 && differenceInMinutes(new Date(), startDate) < 20) {
                    setStatusColor("#DE9C00")
                } else if (differenceInMinutes(new Date(), startDate) >= 20) {
                    setStatusColor("#F44336")
                }
                const intervalTime = differenceInSeconds(new Date(), startDate)
                setTime(format(new Date(0, 0, 0, 0, 0, intervalTime, 0), "mm:ss"))
                setIsTimer(true)
            }
            else {
                setIsTimer(false)

            }
        }, 1000)

        return () => {
            clearInterval(timer)
        }
    }, [startDate])

    return (
        <>
            {
              isTimer &&
                <div id={styles.KDSTimer} style={{ background: "#FFFFFF", borderColor: statusColor, color: statusColor }} >
                    <Schedule />
                    <span>{time}</span>
                </div>


            }
        </>
    )
}
export default KDSTimer