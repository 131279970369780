import {
  AuthConsumer,
  useAuth,
} from "modules/auth/presentation/context/AuthContext";
import { Button, Drawer, Icon, IconButton, Popover } from "@material-ui/core";
import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import Header from "./header/_Header";
import styles from "./Layout.module.scss";
import Breadcumbs from "components/breadcumbs/Breadcumbs";
import LeftSideMenu from "components/sideMenu/LeftSideMenu";
import { HelpItem } from "./help/HelpItem";
import { MyAccount } from "./myAccount/MyAccount";
import { MyAccountMobile } from "./myAccountMobile/MyAccountMobile";
import UseLayout from "./UseLayout";
import UseDimension from "components/dimension/UseDimension";
import Menu from "./menu/Menu";
import { Settings } from "./settings/Settings";
import { SearchItem } from "./search/SearchItem";
import { Notifications } from "./notifications/Notifications";
import NotificationBanner from "./notificationBanner/NotificationBanner";
import { Star, StarBorder } from "@mui/icons-material";
import { useMenuContext } from "./menu/context/MenuContext";
import { UseMenuList } from "./menu/UseMenuList";
import { useLocal } from "modules/local/presentation/context/LocalContext";
import Wizard from "components/wizard/presentation/Wizard";
import { UseWizard } from "components/wizard/presentation/UseWizard";
import { IGetWizardResponse } from "components/wizard/domain/dto/IGetWizardResponse";
import { useLocation, useRouteMatch } from "react-router-dom";
import { useBreadcumbs } from "components/breadcumbs/BreadcumbsContext";
import { OnboardingCard } from "modules/onboarding/presentation/components/onboardingCard/OnboardingCard";
import { IGetMenuResponse } from "../domain/dto/IGetMenuResponse";
import TabMenu from "./menu/tabMenu/TabMenu";
import WizardBanner from "components/wizard/presentation/wizardBanner/WizardBanner";
import { SaasAlert } from "modules/saas/presentation/components/saasAlert/SaasAlert";
import { usePlans } from "modules/plans/presentation/hooks/PlansContext";
import SaasBlockPage from "modules/saas/presentation/components/saasBlockPage/SaasBlockPage";
import UseMenuTutorials from "./UseMenuTutorials";
import { SystemIdentifierEnum } from "modules/gestaoVendas/domain/enum/GetSegmentosEnum";
import { PlanStatus } from "modules/saas/domain/interfaces/PlanStatus";
import TutorialNotification from "modules/tutoriais/presentation/components/tutorialNotification/TutorialNotification";
import { ITutoriaisRecords } from "modules/tutoriais/domain/dto/IGetTutoriaisResponse";

export interface ILayoutProps {
  disabledMenu?: boolean;
  disableBreadcrumb?: boolean;
  disablePageRequests?: boolean;
  bgColor?: string;
  limitedWidth?: boolean;
}

const Layout: FC<ILayoutProps> = ({
  children,
  disabledMenu = false,
  disableBreadcrumb = false,
  disablePageRequests = false,
  limitedWidth = false,
  bgColor = "#fff"
}) => {
  const [openMenu, setOpenMenu] = useState<boolean>(true);
  const { isMobile } = UseDimension();
  const [isSideMenuHelpOpen, setIsSideMenuHelpOpen] = useState(false);
  const [isSideMenuMyAccountOpen, setIsSideMenuMyAccountOpen] = useState(false);
  const [isSideMenuSettingsOpen, setIsSideMenuSettingsOpen] = useState(false);
  const [isSideMenuSearchOpen, setIsSideMenuSearchOpen] =
    useState<HTMLButtonElement | null>(null);
  const [isSideMenuNotificationsOpen, setIsSideMenuNotificationsOpen] =
    useState(false);

  const location = useLocation();
  const { currentLocal } = useLocal();
  const { menuId, menuPosition, menuListContext, setMenuId } = useMenuContext();
  const { path } = useRouteMatch();
  const { routers } = useBreadcumbs();
  const {
    isMenuFavorite,
    postMenuFavorite,
    deleteMenuFavorite,
    postMenuActivity,
    getIsMenuFavorite,
  } = UseMenuList();
  const { setMenuPosition } = useMenuContext();
  const { logout } = useAuth();

  const [openWizard, setOpenWizard] = useState<boolean>(true);
  const [openWizardBanner, setOpenWizardBanner] = useState<boolean>(true);
  const [pageTutorial, setPageTutorial] = useState<ITutoriaisRecords>();

  const { getWizard, wizard } = UseWizard();

  const [currentMenu, setCurrentMenu] = useState({} as IGetMenuResponse);
  const [hasCalledGetWizard, setHasCalledGetWizard] = useState(false);


  const { showWelcomeTutorial, resetStepsHandler } = UseMenuTutorials();


  const wizardContent = useMemo(
    () => wizard ?? ({} as IGetWizardResponse),
    [wizard]
  );

  const {
    showBanner,
    bannerNotifications,
    notRequiredNotifications: getNotRequiredNotifications,
    getNotificationsByType,
    saveReadNotification,
    getTutoriaisList
  } = UseLayout();
  const { subscription } = usePlans();

  useEffect(() => {
    getNotificationsByType();
  }, [getNotificationsByType]);

  const handleSaveNotification = useCallback(async (id: string) => {
    const res = await saveReadNotification(id);
    if (res === "ok") {
      getNotificationsByType("2");
    }
  }, [getNotificationsByType, saveReadNotification]);

  const hasTutorial = useCallback(async (currentMenu: IGetMenuResponse) => {
    if (location.pathname === "/private") {
      return setPageTutorial({} as ITutoriaisRecords);
    }
    const res: ITutoriaisRecords[] = await getTutoriaisList(0, currentMenu.id);
    return setPageTutorial(res.find((tutorial) => tutorial.menuTitle === currentMenu.title));
  }, [getTutoriaisList, location.pathname]);

  const notRequiredNotifications = useMemo(() => getNotRequiredNotifications ?? [], [getNotRequiredNotifications]);

  const notification = useMemo(
    () =>
      bannerNotifications && bannerNotifications.length > 0
        ? bannerNotifications.filter(
          (notification) => !notification.MensagemLida
        )
        : [],
    [bannerNotifications]
  );

  const hasBannerNotification = useMemo(
    () =>
      bannerNotifications &&
      bannerNotifications.length > 0 &&
      showBanner !== "false" &&
      notification.length > 0,
    [bannerNotifications, showBanner, notification]
  );

  const isSaasBlockedPage = useMemo(
    () => {
      if (!currentLocal) return false;
      if (!currentLocal?.systemIdentifier) return false;
      if (!subscription?.status?.id) return false;

      return currentLocal?.systemIdentifier === SystemIdentifierEnum.Saas &&
        subscription?.status?.id !== PlanStatus.ACTIVE &&
        subscription?.status?.id !== PlanStatus.PENDING &&
        !(
          location.pathname.includes("saas/payment") ||
          location.pathname.includes("saas/mudar-forma-pagamento") ||
          location.pathname.includes("/private/plans") ||
          location.pathname === "/private"
        )
    }
    , [currentLocal, location.pathname, subscription?.status?.id]
  );

  const isSaas = useMemo(
    () =>
      currentLocal?.systemIdentifier === SystemIdentifierEnum.Saas && location.pathname !== "/private",
    [currentLocal?.systemIdentifier, location.pathname]
  );

  const headerRef = useRef<HTMLDivElement>(null);

  const headerHeight = headerRef.current?.clientHeight;

  const openPopover = Boolean(isSideMenuSearchOpen);
  const id = openPopover ? "simple-popover" : undefined;

  useEffect(() => {
    const storedMenuOpen = localStorage.getItem("@menuOpened");
    if (storedMenuOpen !== null) {
      setOpenMenu(JSON.parse(storedMenuOpen));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("@menuOpened", String(openMenu));
  }, [openMenu]);

  const onClickOpenMenu = useCallback(() => {
    setOpenMenu(!openMenu);
  }, [openMenu]);

  useEffect(() => {
    if (isMobile) {
      setOpenMenu(false);
    }
  }, [isMobile]);

  const hideMenu = window.location !== window.parent.location;

  const handleMenuToggle = () => {
    setIsSideMenuHelpOpen(!isSideMenuHelpOpen);
  };

  const onClickHelp = () => {
    setIsSideMenuMyAccountOpen(false);
    setIsSideMenuSettingsOpen(false);
    setIsSideMenuNotificationsOpen(false);
    setIsSideMenuHelpOpen(!isSideMenuHelpOpen);
  };

  const onClickMyAccount = () => {
    setIsSideMenuMyAccountOpen(!isSideMenuMyAccountOpen);
    setIsSideMenuSettingsOpen(false);
    setIsSideMenuNotificationsOpen(false);
    setIsSideMenuHelpOpen(false);
  };

  const onClickSettings = () => {
    setMenuPosition(0);
    setIsSideMenuMyAccountOpen(false);
    setIsSideMenuSettingsOpen(!isSideMenuSettingsOpen);
    setIsSideMenuNotificationsOpen(false);
    setIsSideMenuHelpOpen(false);
  };

  const onClickNotifications = () => {
    setIsSideMenuMyAccountOpen(false);
    setIsSideMenuSettingsOpen(false);
    setIsSideMenuNotificationsOpen(!isSideMenuNotificationsOpen);
    setIsSideMenuHelpOpen(false);
  };

  const onClickSearch = (event: HTMLButtonElement) => {
    setIsSideMenuSearchOpen(event);
    setIsSideMenuMyAccountOpen(false);
    setIsSideMenuSettingsOpen(false);
    setIsSideMenuNotificationsOpen(false);
    setIsSideMenuHelpOpen(false);
  };

  const handleContentClassName = useMemo(() => {
    if (isMobile || disabledMenu) {
      return styles.contentLayoutFull;
    } else {
      if (openMenu) {
        return styles.contentLayout;
      }
      return styles.contentLayoutMenuClose;
    }
  }, [isMobile, disabledMenu, openMenu]);

  const handleOnClickFavorite = useCallback(
    (id: string) => {
      if (currentLocal) {
        if (isMenuFavorite?.isFavorited) {
          return deleteMenuFavorite({
            menuId: id,
            ownerId: currentLocal?.id,
          });
        }
        postMenuFavorite({
          menuId: id,
          ownerId: currentLocal?.id,
        });
      }
    },
    [currentLocal, isMenuFavorite, deleteMenuFavorite, postMenuFavorite]
  );

  const hasSubItem = useCallback(
    (item: IGetMenuResponse) => {
      const subMenuItem = item.submenus.find((it) => {
        return it.route && location.pathname.includes(it.route.trim());
      });
      if (subMenuItem) {
        return subMenuItem;
      }
      return false;
    },
    [location.pathname]
  );

  useEffect(() => {
    let findMenu = {} as IGetMenuResponse;
    menuListContext.every((item) => {
      if (item.submenus.length > 0) {
        const findSubMenuItem = item.submenus.map((it) => {
          const found = hasSubItem(it);
          if (found) {
            return found;
          }
          return false;
        });
        const filteredItem = findSubMenuItem.filter(
          (item) => item !== false
        )[0];
        if (filteredItem) {
          findMenu = filteredItem;
          setMenuId(filteredItem.id);
          return false;
        }
        const currentRoute = item.submenus.find((subItem) => {
          return (
            subItem.route && location.pathname.includes(subItem.route.trim())
          );
        });
        if (currentRoute) {
          findMenu = currentRoute;
          setMenuId(currentRoute.id);
          return false;
        }
      }
      if (
        item.route &&
        (item.route === location.pathname ||
          location.pathname.includes(item.route.trim()))
      ) {
        findMenu = item;
        setMenuId(item.id);
        return false;
      }
      if (
        routers &&
        routers?.length > 0 &&
        routers[routers.length - 1]?.title === item?.title
      ) {
        findMenu = item;
        setMenuId(item.id);
        return false;
      }
      setMenuId("");
      return true;
    });

    setCurrentMenu(findMenu);
    hasTutorial(findMenu);
  }, [menuListContext, routers, location, setMenuId, hasSubItem, hasTutorial]);

  useEffect(() => {
    if (currentLocal && currentMenu.route && location.pathname.includes(currentMenu.route) && !disablePageRequests && !hasCalledGetWizard) {
      const params = {
        menuId: currentMenu.id,
        ownerId: currentLocal?.id,
      };
      postMenuActivity(params);
      getIsMenuFavorite(params);
      getWizard(currentMenu.id);
      setHasCalledGetWizard(true);
    }
  }, [currentMenu, menuId, currentLocal, disablePageRequests, hasCalledGetWizard, location, getIsMenuFavorite, getWizard, postMenuActivity]);

  useEffect(() => {
    if (path.includes("private/event/")) {
      setMenuPosition(1);
    } else {
      setMenuPosition(2);
    }
  }, [path, setMenuPosition]);


  return (
    <div id={styles.ContainerMenu}>
      {
        process.env.NODE_ENV === 'development' && 
        currentLocal?.systemIdentifier === SystemIdentifierEnum.Saas && 
        <Button style={{ position: "fixed", left: 0, bottom: 0, zIndex: 1000 }} onClick={resetStepsHandler} >Reiniciar tutorial</Button>
      }
      <div id={styles.Layout}>
        <div className={styles.container}>
          {!hideMenu && (
            <div ref={headerRef}>
              <header
                className={`${styles.header} ${disableBreadcrumb ? styles.noBreadcrumb : ""
                  }`}
              >
                {isSaas && <SaasAlert />}
                <Header
                  disabledMenu={disabledMenu}
                  onClickMenu={isMobile ? () => setOpenMenu(true) : undefined}
                  onClickHelp={() => onClickHelp()}
                  home={disabledMenu}
                  onClickMyAccount={() => onClickMyAccount()}
                  onClickSettings={() => onClickSettings()}
                  onClickSearch={(event) => onClickSearch(event.currentTarget)}
                  onClickNotifications={() => onClickNotifications()}
                  notification={notRequiredNotifications}
                  hasBannerNotification={hasBannerNotification}
                  isSaas={isSaas}
                />
                {hasBannerNotification && notification.length > 0 && (
                  <NotificationBanner notification={notification[0]} />
                )}

                <div className={styles.headerEndContent}>
                  {!isMobile && !disabledMenu && !disableBreadcrumb && (
                    <Breadcumbs />
                  )}
                  {menuPosition !== 1 && !disabledMenu && (
                    <div
                      className={styles.menuFavorite}
                      onClick={() => handleOnClickFavorite(menuId)}
                      style={isMobile ? {
                        position: "absolute",
                        right: "1.5rem",
                        top: `calc(${headerHeight}px + 10px)`,
                        marginRight: 0
                      } : {}}
                    >
                      {isMenuFavorite?.isFavorited ? (
                        <Star
                          style={{
                            color: "rgba(255, 179, 0, 1)",
                          }}
                        />
                      ) : (
                        <StarBorder />
                      )}
                    </div>
                  )}
                </div>
              </header>
            </div>
          )}
          <main className={styles.main}>
            {!isMobile && !disabledMenu && !hideMenu && (
              <div
                className={
                  openMenu
                    ? styles.menuContainerOpen
                    : styles.menuContainerClose
                }
                style={{ height: `calc(100vh - ${headerHeight}px)` }}
              >
                <div className={styles.menu}>
                  <Menu
                    opened={openMenu}
                    onLoadFirstItem={showWelcomeTutorial}
                    setOpenMenu={!isMobile ? setOpenMenu : undefined}
                  />
                  <div
                    className={styles.buttonOpenMenu}
                    style={{
                      transform: openMenu ? "rotate(180deg)" : "rotate(0deg)",
                      position: "fixed",
                      top: `calc(${headerHeight}px + 50px)`,
                      left: openMenu ? "246px" : "42px"
                    }}
                    onClick={onClickOpenMenu}
                  >
                    <img
                      src="/assets/icon/side-menu-open-close.svg"
                      alt="menu"
                    />
                  </div>
                </div>
              </div>
            )}

            <div className={styles.col} style={{ flex: 1 }}>
              <AuthConsumer>
                {({ isAuth }) =>
                  isAuth !== undefined ? (
                    <div className={handleContentClassName} style={{ height: `calc(100vh - ${headerHeight}px)`, flex: 1, background: bgColor }}>
                      {isSaasBlockedPage
                        ? <SaasBlockPage />
                        : (
                          <>
                            <TabMenu />
                            {openWizardBanner &&
                              wizardContent.id &&
                              wizardContent.fullTutorialUrl &&
                              !disabledMenu &&
                              !isSaas && (
                                <WizardBanner
                                  onClose={() => setOpenWizardBanner(false)}
                                  link={wizardContent.fullTutorialUrl}
                                />
                              )
                            }
                            {pageTutorial?.id ? <TutorialNotification tutorial={pageTutorial} onClose={() => setPageTutorial(undefined)} /> : null}

                            <div className={limitedWidth ? styles.contentLayoutLimited : styles.contentLayoutStandard}>
                              {children}
                            </div>
                          </>
                        )
                      }
                    </div>
                  ) : (
                    <div>Verificando Autenticação</div>
                  )
                }
              </AuthConsumer>
            </div>
          </main>
        </div>
        {isMobile && !disabledMenu && (
          <Drawer anchor={"left"} open={openMenu} onClose={onClickOpenMenu}>
            <div className={styles.drawer}>
              {!hideMenu && (
                <div className={styles.buttonOpenMenu}>
                  <IconButton onClick={onClickOpenMenu}>
                    {openMenu ? <Icon>menu_open</Icon> : <Icon>menu</Icon>}
                  </IconButton>
                </div>
              )}
              <div className={styles.menu}>
                {!hideMenu && (
                  <Menu opened={openMenu} onOpenItem={onClickOpenMenu} />
                )}
              </div>
            </div>
          </Drawer>
        )}
      </div>
      <div id={styles.LeftSide}>
        {isMobile ? (
          <>
            <Drawer
              className={styles.drawer}
              anchor="right"
              open={!!isSideMenuMyAccountOpen}
              onClose={() => setIsSideMenuMyAccountOpen(false)}
            >
              <MyAccountMobile
                logout={logout}
                onClose={() => setIsSideMenuMyAccountOpen(false)}
                home={disabledMenu}
                onClickHelp={() => setIsSideMenuHelpOpen(true)}
                onClickSettings={() => setIsSideMenuSettingsOpen(true)}
                onClickNotifications={() =>
                  setIsSideMenuNotificationsOpen(true)
                }
                notification={notRequiredNotifications}
              />
            </Drawer>
            <Drawer
              anchor="right"
              open={!!isSideMenuHelpOpen}
              onClose={() => setIsSideMenuHelpOpen(false)}
            >
              <HelpItem
                isMobile={isMobile}
                onClose={() => setIsSideMenuHelpOpen(false)}
              />
            </Drawer>
            <Drawer
              anchor="right"
              open={!!isSideMenuSettingsOpen}
              onClose={() => setIsSideMenuSettingsOpen(false)}
            >
              <Settings
                isMobile={isMobile}
                onClose={() => setIsSideMenuSettingsOpen(false)}
              />
            </Drawer>
            <Drawer
              anchor="right"
              open={!!isSideMenuSearchOpen}
              onClose={() => setIsSideMenuSearchOpen(null)}
            >
              <SearchItem
                isMobile={isMobile}
                onClose={() => setIsSideMenuSearchOpen(null)}
                handleOnClickFavorite={handleOnClickFavorite}
              />
            </Drawer>
            <Drawer
              anchor="right"
              open={!!isSideMenuNotificationsOpen}
              onClose={() => setIsSideMenuNotificationsOpen(false)}
            >
              <Notifications
                isMobile={isMobile}
                onClose={() => setIsSideMenuNotificationsOpen(false)}
                getNotificationsList={notRequiredNotifications}
                handleSaveNotification={handleSaveNotification}
              />
            </Drawer>
          </>
        ) : (
          <>
            <div
              className={
                isSideMenuHelpOpen
                  ? styles.leftSideMenuContainerOpen
                  : styles.leftSideMenuContainerClose
              }
            >
              <LeftSideMenu onClose={handleMenuToggle} title={"Ajuda"}>
                <HelpItem />
              </LeftSideMenu>
            </div>

            <div
              className={
                isSideMenuMyAccountOpen
                  ? styles.leftSideMenuContainerOpen
                  : styles.leftSideMenuContainerClose
              }
            >
              <LeftSideMenu
                onClose={() => setIsSideMenuMyAccountOpen(false)}
                title={"Minha"}
                titleBold={" conta"}
              >
                <MyAccount logout={logout} />
              </LeftSideMenu>
            </div>

            <div
              className={
                isSideMenuSettingsOpen
                  ? styles.leftSideMenuContainerOpen
                  : styles.leftSideMenuContainerClose
              }
            >
              <LeftSideMenu
                onClose={() => setIsSideMenuSettingsOpen(false)}
                title={"Configurações"}
              >
                <Settings onClose={() => setIsSideMenuSettingsOpen(false)} />
              </LeftSideMenu>
            </div>

            <div
              className={
                isSideMenuNotificationsOpen
                  ? styles.leftSideMenuContainerOpen
                  : styles.leftSideMenuContainerClose
              }
            >
              <LeftSideMenu
                onClose={() => setIsSideMenuNotificationsOpen(false)}
                title={"Todas"}
                titleBold={" notificações"}
              >
                <Notifications
                  getNotificationsList={notRequiredNotifications}
                  handleSaveNotification={handleSaveNotification}
                />
              </LeftSideMenu>
            </div>

            <Popover
              id={id}
              open={openPopover}
              anchorEl={isSideMenuSearchOpen}
              onClose={() => setIsSideMenuSearchOpen(null)}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              PaperProps={{
                style: {
                  marginTop: "20px",
                },
              }}
            >
              <SearchItem handleOnClickFavorite={handleOnClickFavorite} />
            </Popover>
          </>
        )}
      </div>
      {openWizard && wizardContent.id && !wizardContent.isReaded && !disabledMenu && (
        <Wizard
          onClose={() => setOpenWizard(false)}
          wizardContent={wizardContent}
        />
      )}
      {
        !location.pathname.includes("onboarding") &&
        location.pathname !== "/private" &&
        location.pathname !== "private/" &&
        location.pathname !== "/private/" &&
        !isSaas && <OnboardingCard />
      }
    </div>
  );
};
export default Layout;
