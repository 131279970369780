import Sidesheet from "components/sidesheet/Sidesheet";
import SidesheetFeedback from "components/sidesheet/sidesheetFeedback/SidesheetFeedback";
import TabView from "components/tab/TabView";
import {
  LaunchOutlined,
  LinkOutlined,
} from "@mui/icons-material";
import {
  ForwardRefRenderFunction,
  forwardRef,
  useCallback,
  useImperativeHandle,
} from "react";
import InformationForm from "../forms/information/InformationForm";
import ValuesForm from "../forms/values/ValuesForm";
import { UsePaymentLinkSideSheet } from "./UsePaymentLinkSideSheet";
import styles from './PaymentLinkSideSheet.module.scss';
import { IGetPaymentLinkListParams } from "../../interfaces/IGetPaymentLinkListParams";

export enum TabViewSteps {
  INFO = 0,
  VALUES = 1,
  SUCCESS = 2,
}

export interface IPaymentLinkSideSheet {
  getPaymentLinkList: (params?: IGetPaymentLinkListParams) => Promise<void>
}

export interface PaymentLinkSideSheetHandles {
  openSideSheet: (paymentLinkId?: string) => void;
}

const PaymentLinkSideSheet: ForwardRefRenderFunction<
  PaymentLinkSideSheetHandles,
  IPaymentLinkSideSheet
> = ({ getPaymentLinkList }, ref) => {
  const {
    openSideSheet,
    step,
    handleChangeTab,
    infoFormValues,
    handleChangeInfoForm,
    infoFormError,
    valuesFormsErrors,
    handleChangeValuesForm,
    valuesFormsValues,
    isOpen,
    isLoading,
    paymentLinkEditId,
    handleContinue,
    handleBackButton,
    handleReturn,
    getProductsList,
    copyLinkToClipboard,
    openLinkNewTab,
    link
  } = UsePaymentLinkSideSheet(getPaymentLinkList);

  useImperativeHandle(ref, () => ({
    openSideSheet,
  }));

  const tabView = useCallback(() => {
    if (step === TabViewSteps.SUCCESS) {
      return <></>;
    }
    const tabs = [
      {
        label: "Informações gerais",
        value: TabViewSteps.INFO,
      },
      {
        label: "Pagamento",
        value: TabViewSteps.VALUES,
      },
    ];

    return (
      <TabView
        disabled
        index={step}
        tabs={tabs}
        onChangeTab={(_, tab) => handleChangeTab(tab)}
      />
    );
  }, [handleChangeTab, step]);

  const continueOrSaveButton = useCallback(() => {
    if (step === TabViewSteps.INFO) {
      return "Continuar";
    } else {
      return "Salvar";
    }
  }, [step]);

  const handleContent = useCallback(() => {
    if (step === TabViewSteps.INFO) {
      return (
        <InformationForm
          infoFormValues={infoFormValues}
          handleChangeInfoForm={handleChangeInfoForm}
          errors={infoFormError}
        />
      );
    }
    if (step === TabViewSteps.VALUES) {
      return (
        <ValuesForm
          errors={valuesFormsErrors}
          handleChangeValues={handleChangeValuesForm}
          valuesFormValues={valuesFormsValues}
          getProductsList={getProductsList}
        />
      );
    }
  }, [
    handleChangeInfoForm,
    handleChangeValuesForm,
    infoFormError,
    infoFormValues,
    step,
    valuesFormsErrors,
    valuesFormsValues,
    getProductsList
  ]);
 

  return (
    <Sidesheet
      open={isOpen}
      onClose={handleReturn}
      isLoading={isLoading}
      title={
        <h2>
          {paymentLinkEditId ? "Editar" : "Novo"} <b>link de pagamento</b>
        </h2>
      }
      tabView={tabView()}
      content={handleContent()}
      currentStep={step}
      totalSteps={2}
      continueButton={continueOrSaveButton()}
      handleContinueButton={handleContinue}
      customizedCancelButton={handleBackButton}
      cancelButton={true}
      notTotalHeight
      handleCustomizedCancelButton={handleReturn}
      feedback={
        <div id={styles.PaymentLinkSideSheet}>
          <SidesheetFeedback text={`Link de pagamento ${paymentLinkEditId ? 'editado' : 'criado'} com sucesso!`} success />
          <div>
            <div className={styles.link}>{link}</div>
            <div className={styles.icon}>
              <LaunchOutlined titleAccess="Nova aba" onClick={openLinkNewTab} />
              <LinkOutlined titleAccess="Link" onClick={copyLinkToClipboard} />
            </div>
          </div>
        </div>
      }
    />
  );
};

export default forwardRef(PaymentLinkSideSheet);
