import React, { FC, useCallback, useMemo, } from 'react'
import styles from './InvoiceList.module.scss'
import List, { ColumnConfigs, ISort } from 'modules/meepErp/presentation/components/ui/list/List';
import { IInvoiceFilterValue } from './invoiceFilter/IInvoiceFilter';
import InvoiceFilter from './invoiceFilter/InvoiceFilter';
import { IInvoiceListResponse, IInvoiceSupplyItem } from 'modules/meepErp/application/dtos/invoice/IInvoiceDtos';
import { useAuth } from 'modules/auth/presentation/context/AuthContext';
import { PermissionsErp } from 'modules/meepErp/models/PermissionsErp';
import { format, parseISO } from 'date-fns';
import { formatCurrency } from 'modules/meepErp/presentation/utils/Format';
import { Icon, Tooltip } from '@mui/material';
import { OperationsTypes } from 'modules/meepErp/models/operations/_OperationsType';
import { IGetStockOperationListResponse } from 'modules/meepErp/application/dtos/stockOperations/IGetStockOperationListResponse';


export interface IInvoiceListProps {
    filter: IInvoiceFilterValue
    isLoading: boolean;
    invoice?: IInvoiceListResponse
    onChangeFilter: (filter: IInvoiceFilterValue) => void
    onDelete: (id: string) => Promise<void> | void
    onEdit: (id: string) => Promise<void>
    onView: (id: string) => Promise<void>
    operations?: IGetStockOperationListResponse 
}
const InvoiceList: FC<IInvoiceListProps> = ({
    filter,
    isLoading,
    invoice,
    onChangeFilter,
    onDelete,
    onEdit,
    operations,
    onView
}) => {
    const { hasAccessRole } = useAuth()

    const submitFilter = useCallback((values: IInvoiceFilterValue) => {
        onChangeFilter(values);
    }, [onChangeFilter])

    const onChangePage = useCallback((page: number, size: number) => {
        onChangeFilter({ page: page, pageSize: size })
    }, [onChangeFilter])

    const onClickSort = useCallback((sortBy: ISort) => {
        onChangeFilter({ sortBy: sortBy.property, ascending: sortBy.ascending })
    }, [onChangeFilter])

    const columnsConfig: ColumnConfigs<IInvoiceSupplyItem> = {
        issueDate: { 
            title: "Data de emissão", sortable: true,
            renderRow: (value: string) => format(parseISO(value), 'dd/MM/yyyy')
        },
        date: { 
            title: "Data de entrada/saída", sortable: true, 
            renderRow: (value: string) => format(parseISO(value), 'dd/MM/yyyy')
        },
        number: { title: "N° da nota", sortable: true },
        operationName: { title: "Tipo de operação", sortable: true },
        quantity: { title: "Quantidade", sortable: true },    
        total: { 
            title: "Valor da nota", sortable: true, 
            renderRow: (value: {amount: number, currency: string}) => formatCurrency(value.amount)
        },
    }
    
    const actionButtons = [       
        {
            showLoading: true,
            onClick: (value : IInvoiceSupplyItem) => onEdit(value.id),
            iconRender: (item : IInvoiceSupplyItem) => item.operationName !== OperationsTypes.SALE_NOTE && <Tooltip title="Editar"><Icon className={styles.icon}>edit</Icon></Tooltip>
        },
        {
            showLoading: true,
            onClick: (value : IInvoiceSupplyItem) => onDelete(value.id),
            iconRender: (item : IInvoiceSupplyItem) => item.operationName !== OperationsTypes.SALE_NOTE && <Tooltip title="Excluir"><Icon className={styles.icon}>delete</Icon></Tooltip>
        },
        {
            tooltip: "Visualizar",    
            icon: "keyboard_arrow_right",
            showLoading: true,
            onClick: (value : IInvoiceSupplyItem) => onView(value.id)
        },
    ]



    const pagination = useMemo(() => {
        return {
            page: invoice?.page ?? 1,
            pageSize: 10,
            count: invoice?.totalPages ?? 0
        }
    }, [invoice?.page, invoice?.totalPages])

    return (
        <div id={styles.InvoiceList} >
            <InvoiceFilter
                onSubmit={submitFilter}
                defaultValue={filter}
                operations={operations}
            />
            {
                <List
                    loading={isLoading}
                    minWidth={700}
                    list={invoice?.items}
                    columnsConfigs={columnsConfig}
                    actionButtons={hasAccessRole(PermissionsErp.ERP_ESTOQUE_CENTRAL_LANCAMNETO_CADASTRAR) ? actionButtons : undefined}
                    pagination={pagination}
                    onChangePage={onChangePage}
                    onClickSort={onClickSort}
                />
            }
        </div>
    )
}
export default InvoiceList