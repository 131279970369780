import GetAllMovementsUseCase from "modules/generalDashboard/application/useCases/GetAllMovementsUseCase";
import { IAllMovements } from "modules/generalDashboard/presentation/interfaces/IAllMovements";
import { useLocal } from "modules/local/presentation/context/LocalContext";
import { useCallback, useState } from "react";
import GeneralDashboardApi from "services/api/generalDashboard/GeneralDashboardApi";

export interface IParamsUseAllMovements {
  localId?: string,
  sessionId?: string,
  period?: number,
}

const UseAllMovements = () => {

  const [isLoading, setIsLoading] = useState<boolean>();
  const [allMovements, setAllMovements] = useState<IAllMovements>()
  const { currentLocal } = useLocal();

  const getAllMovements= useCallback(
    async (params?: IParamsUseAllMovements) => {
      try {
        const serviceGeneral = GeneralDashboardApi();
        setIsLoading(true);
        if (currentLocal) {
          const response = await GetAllMovementsUseCase(serviceGeneral,
            currentLocal.id,          
          );

          setAllMovements(response);

          return response
        }
      } finally {
        setIsLoading(false);
      }
    },
    [currentLocal]
  );


  return {
    allMovements,
    isLoading,
    getAllMovements,
    setAllMovements,
  };
};

export default UseAllMovements;
