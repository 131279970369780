import styles from './SaasPaymentPage.module.scss';
import { PaymentMethod } from 'modules/saas/presentation/components/paymentMethod/PaymentMethod';
import { PaymentForm } from 'modules/saas/presentation/components/paymentForm/PaymentForm';
import { KeyboardArrowLeftOutlined } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import { UseSaasPaymentPage } from './hooks/UseSaasPaymentPage';
import SaasPlanList from 'components/saasPlansOverlay/SaasPlanList';

const SaasPaymentPage = () => {
    const history = useHistory();
    const {
        selectedType,
        setSelectedType,
        selectedPlan,
        setSelectedPlan,
        plans,
        wPos,
        setWPos,
        form, 
        setForm,
        formLoading,
        handleSubmit
    } = UseSaasPaymentPage();

    const navigate = () => {
        return history.push("/private/plans");
    }

    return (
        <div id={styles.SaasPaymentPage}>
            <div className={styles.backContainer} onClick={navigate}>
                <KeyboardArrowLeftOutlined />
                <span>Voltar</span>
            </div>
            <div className={styles.container}>
                <header>
                    <h1>Contrate a Mee</h1>
                    <p>Garanta uma operação simplificada para o seu negócio</p>
                </header>

                <SaasPlanList
                    isCieloCustomer={false}
                    showAnnualOption
                    plans={plans}
                    selectedMode={selectedType}
                    selectedPlan={selectedPlan}
                    selectModeFn={setSelectedType}
                    selectPlanFn={setSelectedPlan}
                />

                <PaymentForm
                    selectedType={selectedType}
                    selectedPlan={selectedPlan}
                    plans={plans}
                    form={form}
                    wPos={wPos}
                    setForm={setForm}
                    formLoading={formLoading}
                    onSubmit={handleSubmit}
                />
            </div>
        </div>
    )
}

export default SaasPaymentPage;