import { useLocal } from "modules/local/presentation/context/LocalContext";
import { useCallback, useState } from "react";
import GeneralDashboardApi from "services/api/generalDashboard/GeneralDashboardApi";
import { IAverageTicket } from "../interfaces/IAverageTicket";
import GetAverageTicketUseCase from "modules/generalDashboard/application/useCases/GetAverageTicketUseCase";



const UseAverageTicket = () => {

  const [isLoading, setIsLoading] = useState<boolean>();
  const [averageTicketData, setAverageTicketData] = useState<IAverageTicket>()
  const { currentLocal } = useLocal();

  const getAverageTicket = useCallback(
    async () => {
      try {
        const serviceGeneral = GeneralDashboardApi();
        setIsLoading(true);
        if (currentLocal) {
          const response = await GetAverageTicketUseCase(serviceGeneral,
            currentLocal.id,          
          );

          setAverageTicketData(response);

          return response
        }
      } finally {
        setIsLoading(false);
      }
    },
    [currentLocal]
  );


  return {
    averageTicketData,
    isLoading,
    getAverageTicket,
    setAverageTicketData,
  };
};

export default UseAverageTicket;
