

export enum FormDeviceProfileStep {
    PROFILE = 0,
    SYSTEM = 1,
    PAYMENT = 2,
    DEVICE = 3,
    PRODUCTS = 4,
    OTHER = 5,
    INVOICE = 6,
    OPERATOR = 7,
    PRINTER = 8,
    MENU = 9,
    KIOSK = 10,
    PARAMS = 11,
    OPERATION_PARAMS = 12,
    FINISH = 13,
    CUSTOMER_IDENTIFICATION = 14,
    USABILITY = 15,
    USER_INTERFACE = 16,
    AMBIENT = 17,
    PAG_SEGURO = 18,
    CONCLUDE= 19,
    TERMINAL = 20,
}
