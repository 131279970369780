import { Icon, Tooltip } from "@material-ui/core";
import { FC, Fragment, ReactEventHandler, RefObject, useCallback, useEffect, useMemo, useRef, useState } from "react";
import styles from "./MenuItem.module.scss";
import { IGetMenuResponse } from "components/layout/domain/dto/IGetMenuResponse";
import { useMenuContext } from "../context/MenuContext";

interface IMenuItemProps {
  menuItem: IGetMenuResponse;
  onClickRoute: (route: string, title?: string) => void;
  opened?: boolean;
  onOpen?: () => void;
  setOpenMenu?: React.Dispatch<React.SetStateAction<boolean>>;
  onLoad?: (divRef:RefObject<HTMLDivElement>) => void;
}

export interface RouteParams {
  id: string;
  tab: string;
}

const MenuItem: FC<IMenuItemProps> = ({
  menuItem,
  onClickRoute,
  opened,
  onOpen,
  setOpenMenu,
  onLoad
}) => {
  const [openSubMenu, setOpenSubMenu] = useState(false);
  const [openSubItemMenu, setOpenSubItemMenu] = useState(false);
  const [openSubMenuItemMenu, setOpenSubMenuItemMenu] = useState(0);

  const { menuId, menuListContext } = useMenuContext();
  const currentPath = window.location.pathname;
  const [subItemSelected, setSubItemSelected] = useState<string | undefined>("");
  const [subMenuItemSelected, setSubMenuItemSelected] = useState<string | undefined>("");

  const firsItemRef = useRef<HTMLDivElement>(null);

  const onClickItem = useCallback(() => {
    if (menuItem.submenus?.length) {
      setOpenSubMenu(!openSubMenu);
      if (setOpenMenu) return setOpenMenu(true);
    } else {
      menuItem.route && onClickRoute(menuItem.route);
    }
  }, [menuItem, openSubMenu, onClickRoute, setOpenMenu]);

  const onClickSubItem = useCallback(
    (subItem: IGetMenuResponse, menuItemTitle: string) => {
      if (subItem.submenus.length && subItem.submenus[0].route) {
        subItem.submenus && onClickRoute(subItem.submenus[0].route);
        if (onOpen) {
          onOpen();
        }
      } else {
        subItem.route && onClickRoute(subItem.route, menuItemTitle);
        if (onOpen) {
          onOpen();
        }
      }
    },
    [onClickRoute, onOpen]
  );

  useEffect(() => {
    if (menuItem.submenus.length > 0) {
      const subItemWithSubMenu = menuItem.submenus.find((it) => {
        if (it.submenus.length > 0) {
          const findSubMenuItem = it.submenus.find((subIt) => subIt.id === menuId);
          if (findSubMenuItem) {
            setSubMenuItemSelected(findSubMenuItem.id);
            return it;
          }
          return false;
        }
        return false;
      });
      if (subItemWithSubMenu) {
        setSubItemSelected(subItemWithSubMenu.id);
        setOpenSubMenuItemMenu(subItemWithSubMenu.submenus.length);
        setOpenSubMenu(true);
      } else {
        setSubItemSelected("");
        setOpenSubMenuItemMenu(0);
        const subItem = menuItem.submenus.find((it) => it.id === menuId);    
        setSubItemSelected(subItem?.id);
        if (menuItem.title === 'Gestão de estoque' && currentPath.includes('meeperp')){
          setOpenSubMenu(true)
        }else {
          setOpenSubMenu(!!subItem);
        }

      }
    }
    if (!opened) {
      setOpenSubMenu(false);
    }
  }, [opened, menuItem.submenus, menuListContext, menuId]);

  const menuSelected = useMemo(
    () => {
      if (menuItem.submenus.length) {
        return !!menuItem.submenus.find((it) => it.id === subItemSelected);
      }
      return !!(menuItem.id === menuId);
    },
    [menuItem, subItemSelected, menuId]
  );

  useEffect(() => {
    
    if (onLoad && firsItemRef.current) {
      onLoad(firsItemRef);
    }
    return () => {
      
    }
  }, [onLoad])
  

  return (
    <div id={styles.menuItem} ref={firsItemRef}>
      <div onClick={onClickItem} className={styles.item}>
        <Tooltip title={menuItem.title} placement="left">
          <div
            className={`${styles.icon} ${menuSelected ? styles.menuSeleted : ""
              }`}
          >
            {menuItem.icon && <Icon fontSize="medium">{menuItem.icon}</Icon>}
          </div>
        </Tooltip>
        <div
          className={`${!menuSelected ? styles.text : styles.textBold} ${styles.menuLabel
            }`}
        >
          {menuItem.type === 2
            ? (
              <>
                {menuItem.title}
                <div className={styles.crow} style={{ margin: "0" }}>
                  <img src="/assets/icon/mdi_crown_yellow.svg" alt="" />
                </div>
              </>
            ) : menuItem.title
          }
        </div>
        {!!menuItem.submenus?.length && (
          <div
            className={styles.arrow}
            style={{
              transform: openSubMenu ? "rotate(-180deg)" : "rotate(0deg)",
            }}
          >
            <Icon fontSize="small">keyboard_arrow_down</Icon>
          </div>
        )}
      </div>
      {!!menuItem.submenus?.length && openSubMenu && (
        <div
          className={styles.subItens}
        >
          {menuItem.submenus.map((subMenu) => (
            <Fragment key={subMenu.route}>
              <div
                onClick={() => onClickSubItem(subMenu, menuItem.title)}
                className={styles.subItem}
              >
                <div
                  className={
                    subMenu.id !== subItemSelected ? styles.text : styles.textBold
                  }
                >
                  {subMenu.type === 2
                    ? (
                      <>
                        {subMenu.title}
                        <div className={styles.crow} style={{ margin: "0" }}>
                          <img src="/assets/icon/mdi_crown_yellow.svg" alt="" />
                        </div>
                      </>
                    ) : subMenu.title
                  }
                </div>
              </div>

            </Fragment>
          ))}
        </div>
      )}
    </div>
  );
};

export default MenuItem;