import { ISession } from "./ISession";

export interface IFilterValue {
  cashierIds?: ISession[];
  startDate?: string;
  endDate?: string;
  period?: enumPeriodType;
}


export enum enumPeriodType {
  last24Hours = 1,
  last7Days = 2,
  PerMonth = 3,
  PerCashier = 4,
  lastHour = 5,
}
