import { IEditProductCategoryRequest } from "modules/catalog/domain/dtos/ISaveProductCategoryRequets"
import { ICatalogService } from "modules/catalog/domain/interface/ICatalogService"
import { ICatalogProductCategoryFormValue } from "modules/catalog/presentation/componentes/_catalogProductCategoryForm/CategoryForm"

const EditProductCategoryUseCase = async (service: ICatalogService, localId: string, categoryId: string, values: ICatalogProductCategoryFormValue) => {
    const request: IEditProductCategoryRequest = {
        id: categoryId,
        description: values.description,
        mainCategoryId: values.mainCategory?.id,
        invoiceSplitLocalId: values.invoiceSplitLocal?.id,
        showInDashboard: !!values.showInDashboard,
        showInPOS: !!values.showInPOS,
        index: values.index,
        fotoCategoria: values.fotoCategoria,
    }
    await service.editProductCategory(localId, request);
}
export default EditProductCategoryUseCase