import { useAuth } from "modules/auth/presentation/context/AuthContext";
import { useLocal } from "modules/local/presentation/context/LocalContext";
import { CheckProductListUseCase } from "modules/onboarding/application/useCases/CheckProductListUseCase";
import { CheckSalesManagementUseCase } from "modules/onboarding/application/useCases/CheckSalesManagementUseCase";
import { CheckTeamUseCase } from "modules/onboarding/application/useCases/CheckTeamUseCase";
import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { OnboardingApi } from "services/api/onboarding/OnboardingApi";

const service = OnboardingApi();

export const UseOnboarding = () => {
    const [isMenuCompleted, setIsMenuCompleted] = useState(false);
    const [isTeamCompleted, setIsTeamCompleted] = useState(false);
    const [isSalesCompleted, setIsSalesCompleted] = useState(false);
    const [loading, setLoading] = useState(true);
    const [wasLoaded, setWasLoaded] = useState(false);

    const history = useHistory();
    const { currentLocal } = useLocal();
    const { hasAccessRole } = useAuth();

    const getData = useCallback(async (currentLocalId?: string) => {
        if (currentLocalId || currentLocal) {
          try {
              const promises = [
                  CheckProductListUseCase(service, currentLocalId || currentLocal!.id),
                  CheckTeamUseCase(service, currentLocalId || currentLocal!.id),
                  CheckSalesManagementUseCase(service, currentLocal!.id),
              ];

              const response = await Promise.all(promises);
              const isMenuCompleted = response[0].isCompleted && !(hasAccessRole("VisualizarCardapio") || hasAccessRole("VisualizarMenuCardapio"));
              const isTeamCompleted = response[1].isCompleted && !(hasAccessRole("VisualizarCargos") || hasAccessRole("VisualizarMenuCargos"));
              const isSalesCompleted = response[2].isCompleted && !(hasAccessRole("MenuGestaoVenda") || hasAccessRole("VisualizarMenuGestaoDeVendas"));

              setIsMenuCompleted(isMenuCompleted);
              setIsTeamCompleted(isTeamCompleted);
              setIsSalesCompleted(isSalesCompleted);
              setWasLoaded(true);

              return {
                isMenuCompleted: isMenuCompleted,
                isTeamCompleted: isTeamCompleted,
                isSalesCompleted: isSalesCompleted,
              }
          } finally {
              setLoading(false);
          }
      }
    }, [currentLocal, hasAccessRole]);

    useEffect(() => {
      if (!wasLoaded) getData();
    }, [currentLocal, getData, history, wasLoaded]);

    const handleStart = () => {
      if (!isTeamCompleted) {
        history.push('/private/equipe/cargos');
      } else if (!isMenuCompleted) {
        history.push('/private/catalog');
      } else if (!isSalesCompleted) {
        history.push('/private/gestaoVendas');
      } else {
        history.push('/private/dashboard/fichas');
      }
    }

    const handleDoItLater = () => {
      history.push('/');
    }

    return {
        isMenuCompleted,
        isTeamCompleted,
        isSalesCompleted,
        loading,
        history,
        getData,
        handleStart,
        handleDoItLater
    }
}
