import { AxiosInstance } from "axios";
import { ChangeKDSStepRequest } from "modules/kds/domain/dto/changeKDSStep/ChangeStepRequest";
import { ChangeKDSStepResponse } from "modules/kds/domain/dto/changeKDSStep/ChangeStepResponse";
import { IGetKdsGroupedListResponse } from "modules/kds/domain/dto/getKdsList/GetKdsGroupedListResponse";
import { IGetKdsListRequest } from "modules/kds/domain/dto/getKdsList/GetKdsListRequest";
import { IGetKdsListResponse, IKDSItemResponse } from "modules/kds/domain/dto/getKdsList/GetKdsListResponse";
import { IGetKdsStepsRequest } from "modules/kds/domain/dto/getKdsSteps/GetKdsStepsRequest";
import { IGetKdsStepsResponse } from "modules/kds/domain/dto/getKdsSteps/GetKdsStepsResponse";
import { IGetKdsStepsTotalRequest } from "modules/kds/domain/dto/getKdsSteps/GetKdsStepsTotalRequest";
import { GetKdsStepsTotalsResponse } from "modules/kds/domain/dto/getKdsSteps/GetKdsStepsTotalsResponse";
import { IGetKdsStoresRequest } from "modules/kds/domain/dto/getKdsStores/GetKdsStoresRequest";
import { IGetKdsStoresResponse } from "modules/kds/domain/dto/getKdsStores/GetKdsStoresResponse";
import { IGetKdsTableRequest } from "modules/kds/domain/dto/_getTables/GetKdsTableRequest";
import { IGetKdsTableResponse } from "modules/kds/domain/dto/_getTables/GetKdsTableResponse";
import { FiltroKds } from "./FiltroKds";
import { MesaLocalResult } from "./MesaLocal";
import { PedidoKDSAgrupadoPorMesaModel, PedidoKDSModel } from "./PedidoModel";
import { WorkflowStateFilter, WorkflowStateModel } from "./WorkflowInstanceModel";
import { IDeviceSelect } from "modules/kds/domain/dto/getDevice/IGetDeviceListResponse";
import { IDeviceItemOld } from "./IDeviceItemOld";

const AntiCorruptionKDSService = (api: AxiosInstance) => {

    const getKDSListFromOldApi = async (request: IGetKdsListRequest): Promise<IGetKdsListResponse> => {
        const requestObject: FiltroKds = {
            keyword: request.input ?? "",
            localClienteId: request.localId,
            dataInicio: request.startDate,
            dataFim: request.endDate,
            etapas: request.stepIds,
            storeId: request.storesId,
            shouldOpenStore: false,
            kdsDesktop: false,
            equipamentoId: request.deviceId,
            mesaIds: request.tableIds,
            page: request.page,
            pageSize: request.pageSize,
            "sort.orientation": request.orientation,
            "sort.shouldOrderByTable": request.sortByTable,
            agruparMesa: request.groupedByTable,
            maxCodigoPainelSenha: request.maxCodigoPainelSenha,
            minCodigoPainelSenha: request.minCodigoPainelSenha

        }

        const result = await api.get<PedidoKDSModel[]>("kds", { params: { ...requestObject } });

        const responseItems: IKDSItemResponse[] = result.data.map<IKDSItemResponse>(item => ({
            id: item.Id,
            customer: {
                name: item.Cliente?.Nome,
                document: item.Cliente?.CPF,
                address: item.Cliente?.Endereco,
                phoneNumber: item.Cliente?.Telefone,
                email: item.Cliente?.Email,
            },
            deviceName: item.NomeEquipamento,
            code: item.Codigo,
            createdAt: item.DataCriacao,
            acceptedAt: item.DataPedidoAceito,
            isPresentialPayment: item.EhPagamentoPresencial,
            observation: item.ObservacaoPedido,
            table: item.Mesa,
            orderOrigin: item.OrigemPedido,
            paymentType: {
                id: item.TipoPagamento.Id,
                description: item.TipoPagamento.Descricao
            },
            change: item.Troco,
            amount: item.ValorTotal,
            remotePrinter: {
                address: item.EnderecoServidorLocal,
            },
            order: {
                items: item.Itens.map((_pedidoItem) => ({
                    id: _pedidoItem.Id,
                    name: _pedidoItem.NomeProduto,
                    value: _pedidoItem.Valor,
                    quantity: _pedidoItem.Quantidade,
                    printerName: _pedidoItem.NomeImpressora,
                    nameCategory: _pedidoItem.NomeCategoria,
                    compositions: _pedidoItem.Observacoes?.map(compositonItem => ({
                        description: compositonItem.Descricao,
                        options: compositonItem.Opcoes
                    }))
                }))
            },
            currentStep: {
                id: item.WorkflowInstance.CurrenteState.Id,
                name: item.WorkflowInstance.CurrenteState.Name,
                color: item.WorkflowInstance.CurrenteState.BorderColor,
            },
            panelCode: item.CodigoPainelSenha
        }))

        const response: IGetKdsListResponse = {
            items: responseItems,
            count: result.headers["x-total-count"],
        }

        return response
    }

    const getKDSListGroupedByTableFromOldApi = async (request: IGetKdsListRequest): Promise<IGetKdsGroupedListResponse> => {
        const requestObject: FiltroKds = {
            keyword: request.input ?? "",
            localClienteId: request.localId,
            dataInicio: request.startDate,
            dataFim: request.endDate,
            etapas: request.stepIds,
            storeId: request.storesId,
            equipamentoId: request.deviceId,
            shouldOpenStore: false,
            kdsDesktop: false,
            mesaIds: request.tableIds,
            page: request.page,
            pageSize: request.pageSize,
            "sort.orientation": request.orientation,
            "sort.shouldOrderByTable": request.sortByTable,
            agruparMesa: request.groupedByTable,
            maxCodigoPainelSenha: request.maxCodigoPainelSenha,
            minCodigoPainelSenha: request.minCodigoPainelSenha
        }

        const result = await api.get<PedidoKDSAgrupadoPorMesaModel>("kds", { params: { ...requestObject } });

        const response: IGetKdsGroupedListResponse = {
            items: result.data?.Itens.map((item) => ({
                name: item.Mesa ?? "Mesa nao informada",
                kdsItems: item.Pedidos.map((item) => pedidoKdsToKDSItem(item))
            })),
            count: result.data.Total
        }

        return response
    }

    const pedidoKdsToKDSItem = (item: PedidoKDSModel): IKDSItemResponse => ({
        id: item.Id,
        customer: {
            name: item.Cliente?.Nome,
            document: item.Cliente?.CPF,
            address: item.Cliente?.Endereco,
            phoneNumber: item.Cliente?.Telefone,
            email: item.Cliente?.Email,
        },
        code: item.Codigo,
        createdAt: item.DataCriacao,
        acceptedAt: item.DataPedidoAceito,
        isPresentialPayment: item.EhPagamentoPresencial,
        observation: item.ObservacaoPedido,
        table: item.Mesa,
        deviceName: item.NomeEquipamento,
        orderOrigin: item.OrigemPedido,
        paymentType: {
            id: item.TipoPagamento.Id,
            description: item.TipoPagamento.Descricao
        },
        change: item.Troco,
        amount: item.ValorTotal,
        remotePrinter: {
            address: item.EnderecoServidorLocal,
        },
        order: {
            items: item.Itens.map((_pedidoItem) => ({
                id: _pedidoItem.Id,
                name: _pedidoItem.NomeProduto,
                value: _pedidoItem.Valor,
                quantity: _pedidoItem.Quantidade,
                printerName: _pedidoItem.NomeImpressora,
                nameCategory: _pedidoItem.NomeCategoria,
                compositions: _pedidoItem.Observacoes?.map(compositonItem => ({
                    description: compositonItem.Descricao,
                    options: compositonItem.Opcoes
                }))
            }))
        },
        currentStep: {
            id: item.WorkflowInstance.CurrenteState.Id,
            name: item.WorkflowInstance.CurrenteState.Name,
            color: item.WorkflowInstance.CurrenteState.BorderColor,
        },
        panelCode: item.CodigoPainelSenha
    }
    )

    const getKdsStepsFromOldApi = async (request: IGetKdsStepsRequest): Promise<IGetKdsStepsResponse> => {

        const _request: WorkflowStateFilter = {
            Name: "DefaultKitchenProductionWorkflow",
            LocalClienteId: request.localId
        }
        const result = await api.get<WorkflowStateModel[]>('/workFlow/states', { params: _request });

        return {
            items: result.data.map(item => ({
                id: item.Id,
                name: item.Name,
                color: item.BorderColor,
                ordersQuantity: item.TotalItem
            }))
        }
    }

    const getKdsStepsTotalFromOldApi = async (request: IGetKdsStepsTotalRequest): Promise<GetKdsStepsTotalsResponse> => {

        const _request = {
            Name: "DefaultKitchenProductionWorkflow",
            LocalClienteId: request.localId,
            DataInicio: request.startDate,
            DataFim: request.endDate,
            StateId: request.stepId,
            LocalId: request.localId
        }
        const result = await api.get<{ Total: number }>('/kds/count', { params: _request });

        return { total: result.data.Total }

    }

    const getKdsStoresFromOldApi = async (request: IGetKdsStoresRequest): Promise<IGetKdsStoresResponse> => {
        const _request = {
            LocalClienteId: request.localId
        }
        const result = await api.get<{ Id: string, Name: string }[]>('/store/mystores', { params: _request });

        return {
            items: result.data.map(item => ({
                id: item.Id,
                name: item.Name,
            }))
        }
    }

    const deviceListFromOldApi = async (localId: string): Promise<IDeviceSelect> => {
        const result = await api.get<IDeviceItemOld[]>(`/Proprietario/BuscarEquipamentosQueJaTrabalharamNoLocal/` + localId);

        return {
          items: result.data.map(item => ({
            id: item.id,
            name: item.nome
          }))
        };
    }

    const getKdsTablesFromOldApi = async (request: IGetKdsTableRequest): Promise<IGetKdsTableResponse> => {
        const result = await api.get<MesaLocalResult>('/comandaseletronicasfluxo', {
            params: {
                localClienteId: request.localId
            }
        });

        return {
            items: result.data.models.map(item => ({
                id: item.id,
                number: item.numero,
                commandId: "",
            }))
        } as IGetKdsTableResponse
    }

    const changeStepFromOldApi = async (request: ChangeKDSStepRequest): Promise<ChangeKDSStepResponse> => {
        const result = await api.post<WorkflowStateModel>(`/kds/` + request.action, {
            Id: request.itemId,
            NewStateId: request.itemId,
        });

        return {
            id: result.data.Id,
            name: result.data.Name,
            color: result.data.BorderColor,
        };
    }

 

    return ({
        getKDSListFromOldApi,
        getKdsStepsFromOldApi,
        getKdsTablesFromOldApi,
        changeStepFromOldApi,
        getKdsStoresFromOldApi,
        getKdsStepsTotalFromOldApi,
        getKDSListGroupedByTableFromOldApi,
        deviceListFromOldApi
    })
}

export default AntiCorruptionKDSService
