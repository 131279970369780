export default class Utils {
  public static toDateAndTime(date: Date | string) {
    date = date instanceof Date ? date : new Date(date);
    return `${date.toLocaleDateString([], {
      year: "2-digit",
      month: "2-digit",
      day: "2-digit",
    })} - ${date.toLocaleTimeString([], {
      timeStyle: "short",
      hour12: false
    })}`;
  }
  public static toDate(date: Date | string) {
    date = date instanceof Date ? date : new Date(date);
    return `${date.toLocaleDateString([], {
      year: "2-digit",
      month: "2-digit",
      day: "2-digit",
    })}`;
  }
  public static toInputDateString(date: Date | string) {
    date = date instanceof Date ? date : new Date(date);
    return `${date
      .toLocaleDateString("pt-BR", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      })
      .split("/")
      .reverse()
      .join("-")}T${date.toLocaleTimeString([], {
        timeStyle: "short",
        hour12: false
      })}`;
  }

  public static toInputDateStringNoTime(date: Date | string) {
    date = date instanceof Date ? date : new Date(date);
    return `${date
      .toLocaleDateString("pt-BR", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      })
      .split("/")
      .reverse()
      .join("-")}`;
  }

  public static dateValidation(date: string | Date, type: "<" | ">" | "=", limitDate: string | Date) {
    date = date instanceof Date ? date : new Date(date);
    limitDate = limitDate instanceof Date ? limitDate : new Date(limitDate);
    if (type === "=") {
      if (date.toLocaleDateString() === limitDate.toLocaleDateString()) {
        if (date.getHours() === limitDate.getHours() && date.getMinutes() === limitDate.getMinutes()) {
          return true;
        }
      } else {
        return false;
      }
    }
    if (type === ">") {
      if (date > limitDate) {
        return true;
      } else if ((date === limitDate && date.getHours() > limitDate.getHours())) {
        return true
      } else if ((date === limitDate && date.getHours() === limitDate.getHours() && date.getMinutes() > limitDate.getMinutes())) {
        return true
      } else {
        return false;
      }
    }
    if (type === "<") {
      if (date < limitDate) {
        return true;
      } else if ((date === limitDate && date.getHours() < limitDate.getHours())) {
        return true
      } else if ((date === limitDate && date.getHours() === limitDate.getHours() && date.getMinutes() < limitDate.getMinutes())) {
        return true
      } else {
        return false;
      }
    }
  }
  public static discountsDateValidation(date: string | Date, previousDates: string[] | Date[]) {
    date = Utils.toInputDateString(new Date(date));
    if (previousDates.length === 0) {
      return true;
    }
    previousDates = previousDates.map(it => Utils.toInputDateString(new Date(it)));

    return previousDates.find(it => it === date) ? false : true;
  }
}
