import { IPaymentType, PaymentTypeEnum } from "modules/paymentLink/domain/dto/IPostPaymentLinkRequest";
import { IPutPaymentLinkRequest } from "modules/paymentLink/domain/dto/IPutPaymentLinkRequest";
import { IPaymentLinkService } from "modules/paymentLink/domain/interfaces/IPaymentLinkService";
import { IPaymentLinkInfoForm } from "modules/paymentLink/presentation/interfaces/IPaymentLinkForms";
import { IPaymentLinkValuesForm } from "modules/paymentLink/presentation/interfaces/IPaymentValuesFroms";
import { validate } from "uuid";

export const EditPaymentLinkUseCase = async (
  service: IPaymentLinkService,
  localId: any,
  paymentLinkId: string,
  info: IPaymentLinkInfoForm,
  values: IPaymentLinkValuesForm
) => {
  const result = await service.putPaymentLink(
    parsePostPaymentLinkPayload(localId, paymentLinkId, info, values)
  );
  return result;
};

const parsePostPaymentLinkPayload = (
  localId: string,
  paymentLinkId: string,
  info: IPaymentLinkInfoForm,
  values: IPaymentLinkValuesForm
): IPutPaymentLinkRequest => {
  let paymentTypes: IPaymentType[] = [];

  if (values.credit) {
    paymentTypes.push({paymentTypeId: PaymentTypeEnum.CREDIT})
  }

  if (values.ticket) {
    paymentTypes.push({paymentTypeId: PaymentTypeEnum.TICKET})
  }

  if (values.pix) {
    paymentTypes.push({paymentTypeId: PaymentTypeEnum.PIX})
  }

  return {
    id: paymentLinkId,
    localId,
    name: info.name,
    expirationDate: info.expiration,
    description: info.description,
    image: validate(info?.img ?? '') ? '' : info.img,
    productId: values.productId,
    price: Number(values.price.replace(/,/g, '.')),
    maximumInstallments: values.credit ? values.installments : undefined,
    interestPerInstallment: values.credit ? values.tax : undefined,
    paymentTypes,
  };
};
