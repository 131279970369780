import React from "react";
import styles from "./AverageTicketCards.module.scss";
import { CircularProgress } from "@material-ui/core";
import { moneyMaskNumber } from "services/utils/Money";
import { useUi } from "contexts/userInterface/UserInterfaceContext";
import { IAverageTicket } from "../../interfaces/IAverageTicket";
import { IGetAverageTicketResponse } from "modules/generalDashboard/domain/dto/IGetAverageTicketResponse";

interface AverageTicketCardsProps {
  isLoading: boolean | undefined;
  averageTicketData: IAverageTicket | undefined
  getAverageTicket: () => Promise<IGetAverageTicketResponse | undefined>
}

interface CardProps {
  title: string;
  value: number;
  isLoading: boolean | undefined;
}

const Card: React.FC<CardProps> = ({ title, value, isLoading }) => {
  if (value <= 0 && !isLoading) {
    return null;
  }

  return (
    <div>
      <p>{title}</p>
      {isLoading ? (
        <div className={styles.loading}>
          <CircularProgress size={16} color="inherit" />{" "}
        </div>
      ) : (
        <span>{moneyMaskNumber(value)}</span>
      )}
    </div>
  );
};

const AverageTicketCards: React.FC<AverageTicketCardsProps> = ({
  isLoading,
  averageTicketData,
  getAverageTicket
}) => {
  const { isMobile } = useUi();

  return (
    <div id={!isMobile ? styles.AverageTicketCards : styles.AverageTicketCardsMobile}>
      <Card title="Taxa de serviço" value={500} isLoading={isLoading} />
      <Card title="Desconto" value={500} isLoading={isLoading} />
      <Card title="Consumo cashless" value={500} isLoading={isLoading} />
      <Card title="Consumo pós-pago" value={500} isLoading={isLoading} />
      <Card title="Consumo ficha" value={500} isLoading={isLoading} />
    </div>
  );
};

export default AverageTicketCards;
