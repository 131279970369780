import React, { ChangeEvent, FC, FormEvent, useCallback, useEffect, useMemo, useState } from 'react'
import styles from './SelectProductPicker.module.scss'
import { IProductItem } from '../../categoryAndProducts/productList/IProductItem'
import ButtonsForm from '../../ui/ButtonsForm'
import { Icon, IconButton, TextField } from '@material-ui/core'
import { Select } from '@mui/material'
import ProductPickerItem from './ProductPickerItem'
import CatalogSkeleton from '../../complement/catalogSkeleton/CatalogSkeleton'
import ProductListItemSkeleton from '../../categoryAndProducts/productList/ProductListItemSkeleton'

// export interface IProductItemToSelect extends IProductItem {
export interface IProductItemToSelect {
    id: string,
    name: string,
    value: number
    disabled?: boolean;
    category?: {
        id?: string,
        name?: string
    },
}
export interface ISelectProductPickerProps {
    //propertys
    products: IProductItemToSelect[],
    isLoading: boolean,
    defautlValue?: IProductItemToSelect[],
    onSubmit: (products: IProductItemToSelect[]) => void,
    onClickProduct?: (product: IProductItemToSelect) => void,
    onClose: () => void
}
const SelectProductPicker: FC<ISelectProductPickerProps> = ({ defautlValue, products, onClose, onSubmit, onClickProduct, isLoading }) => {

    const [selectedProducts, setSelectedProducts] = useState<IProductItemToSelect[]>([]);
    const [filteredProducts, setFilteredProducts] = useState<IProductItemToSelect[]>(products);
    const [keyword, setKeyword] = useState<string>()

    useEffect(() => {
        if (products) {
            setFilteredProducts(products.map(item => ({ ...item, checked: defautlValue?.some(p => p.id === item.id) })));
        }
    }, [defautlValue, products])

    useEffect(() => {
        if (defautlValue) {
            setSelectedProducts(defautlValue);
        }
    }, [defautlValue])

    const onCheckProduct = useCallback((product: IProductItemToSelect, checked: boolean) => {
        if (checked) {
            setSelectedProducts(prev => [...prev, product])
        } else {
            setSelectedProducts(prev => prev.filter(p => p.id !== product.id))
        }
    }, [])

    const submitHandle = useCallback(() => {
        onSubmit(selectedProducts);
    }, [onSubmit, selectedProducts])


    const changeHandle = useCallback((ev: ChangeEvent<HTMLInputElement>) => {
        setKeyword(ev.target.value);
    }, []);


    const clearKeyWord = useCallback(() => {
        setKeyword('')
        setFilteredProducts(products.map(item => ({ ...item, checked: selectedProducts?.some(p => p.id === item.id) })));
    }, [products, selectedProducts])


    const submitSearch = useCallback(() => {
        if (!!keyword) {
            const newProducts = products.filter(p => p.name.toLowerCase().includes(keyword.toLowerCase()) || p.category?.name?.toLowerCase().includes(keyword.toLowerCase()))
            setFilteredProducts(newProducts.map(item => ({ ...item, checked: selectedProducts?.some(p => p.id === item.id) })));

        } else {
            clearKeyWord()
        }
    }, [clearKeyWord, keyword, products, selectedProducts])

    const submitSearchForm = useCallback((ev: FormEvent<HTMLFormElement>) => {
        ev.preventDefault();
        submitSearch()
    }, [submitSearch]);

    const search = useMemo(() => (<>
        {
            <form onSubmit={submitSearchForm}>
                {products.length > 0 && <TextField size='small' value={keyword} variant='outlined' onChange={changeHandle}
                    fullWidth
                    InputProps={{
                        endAdornment: <>
                            {!!keyword?.length && <IconButton size='small' onClick={clearKeyWord}><Icon fontSize='small'>close</Icon></IconButton>}
                            <IconButton size='small' type={`submit`} onClick={submitSearch}><Icon fontSize='small'>search</Icon></IconButton>
                        </>
                    }}
                ></TextField>}
            </form>
        }
    </>
    ), [submitSearchForm, products.length, keyword, changeHandle, clearKeyWord, submitSearch])

    // const productsList = useMemo(() => (<>

    //     <ProductList
    //         isLoading={isLoading}
    //         products={filteredProducts}
    //         onCheckProduct={onCheckProduct}
    //         checkedProducts={defautlValue}
    //     />
    // </>
    // ), [isLoading, filteredProducts, onCheckProduct, defautlValue])
    const productsList = useMemo(() => (<>
        <div>
            {filteredProducts.map(p => <ProductPickerItem
                disabled={p.disabled}
                productItem={p}
                onCheckProduct={onCheckProduct}
                selectedProducts={selectedProducts}
            />)}
        </div>
    </>
    ), [filteredProducts, onCheckProduct, selectedProducts])




    return (
        isLoading ? <div>
            <ProductListItemSkeleton />
            <ProductListItemSkeleton />
            <ProductListItemSkeleton />
        </div> :
            <>
                <div id={styles.SelectProductPicker} >
                    {/* {search} */}
                    {!!selectedProducts.length && <div>{selectedProducts.length}{selectedProducts.length > 1 ? " Produtos Selecionados" : " Produto Selecionado"}</div>}
                    {productsList}
                </div>
                <ButtonsForm stick submitLabel="Criar" onSubmit={submitHandle} onCancel={onClose} color='primary' width="100px"></ButtonsForm>
            </>
    )
}
export default SelectProductPicker