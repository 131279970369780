import { FC, useEffect, useMemo } from 'react'
import styles from './CreateOrUpdateInventoryPage.module.scss'
import InventoryForm from 'modules/meepErp/presentation/components/inventory/inventoryForm/InventoryForm'
import { IAutocompletePaginatedRequest } from 'modules/meepErp/presentation/components/autocomplete/AutocompleteInput'
import { IInventoryFormValue } from 'modules/meepErp/presentation/components/inventory/inventoryForm/InventoryFormValue'
import { useMutation, useQuery } from 'react-query'
import { ISupplyFilterValue } from 'modules/meepErp/presentation/components/supply/supplyList/supplyFilter/ISupplyFilter'
import { ISupplyGroupListRequest } from 'modules/meepErp/presentation/components/supply/suplyGroup/suplyGroupForm/selectSupplyParentGroup/SelectSupplyParentGroup'
import { getSuppliesListUseCase } from 'modules/meepErp/application/useCases/supply/SupplyUseCases'
import { getSupplyGroupListUseCase } from 'modules/meepErp/application/useCases/supply/supplyGroup/SupplyGroupUseCases'
import { getSupplyStorageLocalsUseCase } from 'modules/meepErp/application/useCases/supplyLocals/SupplyLocalsUseCase'
import { useLocal } from 'modules/local/presentation/context/LocalContext'

import { useHistory, useParams } from 'react-router-dom'
import UseQueryState from 'modules/meepErp/presentation/hook/UseQueryFilter'
import { IGetInventoryRequest } from 'modules/meepErp/application/dtos/invetory/InvetoryOperationDto'
import { AddMoreSupplyInventoryUseCase, CreateInventoryUseCase, DeleteInventorySupplyUseCase, FinishInventoryUseCase, GetInventoryUseCase, updateInventorySupplyCountedQuantity } from 'modules/meepErp/application/interfaces/inventory/InventoryUseCases'
import InventorySupplyList from 'modules/meepErp/presentation/components/inventory/inventorySupplyList/InventorySupplyList'
import { useUi } from 'contexts/userInterface/UserInterfaceContext'
import { ButtonContainer } from 'modules/meepErp/presentation/components/ui/form/FormContainers'
import { InventoryStatus } from 'modules/meepErp/models/inventory/InventoryStatus'
import Back from 'modules/meepErp/presentation/components/ui/back/Back'
import Button from 'components/ui/Button/Button'
import { useBreadcumbs } from 'components/breadcumbs/BreadcumbsContext'
export interface ICreateOrUpdateInventoryPageProps {
    //propertys
}
const CreateOrUpdateInventoryPage: FC<ICreateOrUpdateInventoryPageProps> = () => {

    const { replace } = useHistory()
    const { currentLocal } = useLocal();
    const { showLoading, hideLoading } = useUi()
    const { updateRouters } = useBreadcumbs();
    
    useEffect(() => {
        updateRouters([
            {
                title: "Gestão de estoque",
            },
            {
                title: "Inventário",
                url: '/private/meeperp/inventory'
            },
            {
                title: "Lançamento de inventário",
            },  
        ]);
        
        return () => {
            updateRouters([]);
        };
    }, [updateRouters]);

    const { id } = useParams<{ id: string }>()

    const { state: filter, updateState: setFilter } = UseQueryState<IGetInventoryRequest>();


    const onSuccessfulSubmit = (_id: string) => {
        replace(`/private/meeperp/inventory/update/${_id}`)
    }
    console.log(filter, 'filter 1')

    const getSupplyMutation = useMutation(async (request: ISupplyFilterValue) => {
        try {
            showLoading();
            if (!currentLocal)
                throw new Error("Local não encontrado");
            console.log("getSupplyMutation", request)
            const response = await getSuppliesListUseCase(currentLocal.id, { ...request });
            return response;
        } finally {
            hideLoading();
        }
    });

    const getSupplyGroupList = useMutation(async (request: ISupplyGroupListRequest) => {
        if (!currentLocal)
            throw new Error("Local não encontrado");
        return await getSupplyGroupListUseCase({ page: 1, pageSize: 20, ...request })
    });

    const getSuplyStorageLocals = useMutation(async (request: IAutocompletePaginatedRequest) => {
        if (!currentLocal)
            throw new Error("Local não encontrado");
        return await getSupplyStorageLocalsUseCase(request);
    });

    const onSubmit = useMutation(async (request: IInventoryFormValue) => {
        try {
            showLoading();
            if (!currentLocal)
                throw new Error("Local não encontrado");
            if (id) {
                return await AddMoreSupplyInventoryUseCase(currentLocal.id, id, request);
            } else {
                return await CreateInventoryUseCase(currentLocal.id, request);
            }
        } finally {
            hideLoading();
            invetory.refetch()
        }
    }, {
        onSuccess: (data) => {
            onSuccessfulSubmit(data.id)
        }
    });

    const invetory = useQuery(['inventory', id, filter], async () => {
        try {
            showLoading();
            return await GetInventoryUseCase(currentLocal?.id!, id, filter)
        } finally {
            hideLoading();
        }
    }, {
        enabled: !!id && !!currentLocal
    })

    const updateSupplyItem = useMutation(async (request: { id: string, countedQuantity: number }) => {
        try {
            showLoading();
            if (!currentLocal)
                throw new Error("Local não encontrado");
            return await updateInventorySupplyCountedQuantity(currentLocal.id, request.id, {
                countedQuantity: request.countedQuantity
            });
        } finally {
            hideLoading();
        }
    })

    const deleteSupplyItem = useMutation(async (id: string) => {
        if (!currentLocal)
            throw new Error("Local não encontrado");
        await DeleteInventorySupplyUseCase(currentLocal.id, id)
        invetory.refetch();
    })
    const FinishInventory = useMutation(async () => {
        try {
            showLoading();
            if (!currentLocal)
                throw new Error("Local não encontrado");
            return await FinishInventoryUseCase(currentLocal.id, id)
        } finally {
            hideLoading();
            replace('/private/meeperp/inventory')
        }
    })

    const disableFinish = useMemo(() => (invetory.data?.status && invetory.data?.status !== InventoryStatus.Creating) || invetory.isLoading, [invetory.data?.status, invetory.isLoading])
    return (
        <div id={styles.CreateOrUpdateInventoryPage} >
            <Back route='/private/meeperp/inventory' margin />
            <div className={styles.container} >
                <InventoryForm
                    getSupply={getSupplyMutation.mutateAsync}
                    getSupplyGroupList={getSupplyGroupList.mutateAsync}
                    getSuplyStorageLocals={getSuplyStorageLocals.mutateAsync}
                    onSubmit={onSubmit.mutateAsync}
                    disabled={disableFinish}
                    defaultValues={invetory.data ? {
                        id: invetory.data?.id ?? '',
                        description: invetory.data?.description ?? ''
                    } : undefined}
                />
            </div>
            {!!id &&
                <InventorySupplyList
                    inventorySupply={invetory.data}
                    onDelete={deleteSupplyItem.mutateAsync}
                    // onView={onView}
                    filter={filter}
                    disabled={disableFinish}
                    onChangeFilter={setFilter}
                    isLoading={invetory.isLoading}
                    getSupply={getSupplyMutation.mutateAsync}
                    getSupplyGroupList={getSupplyGroupList.mutateAsync}
                    getSuplyStorageLocals={getSuplyStorageLocals.mutateAsync}
                    updateSupplyItem={updateSupplyItem.mutateAsync}
                />}

            <ButtonContainer style={{
                marginTop: '20px'
            }}>
                <Button variant={'outlined'} onClick={() => replace('/private/meeperp/inventory')} fullWidth={false} color='secondary'>Cancelar</Button>
                {!disableFinish && !!id && <Button onClick={() => FinishInventory.mutate()} fullWidth={false} color='secondary'>Gerar inventário</Button>}
            </ButtonContainer>
        </div >
    )
}
export default CreateOrUpdateInventoryPage