import { useUi } from "contexts/userInterface/UserInterfaceContext";
import { GetPlansUseCase } from "modules/saas/application/useCases/GetPlansUseCase";
import { SubscriptionType } from "modules/saas/domain/interfaces/SubscriptionType";
import { IPlansItem } from "modules/saas/presentation/interfaces/plans/IPlans";
import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { SaasApi } from "services/api/saas/SaasApi";
import { useLocal } from "modules/local/presentation/context/LocalContext";
import { CheckoutSaasUseCase } from "modules/saasPayment/application/CheckoutSaasUseCase";
import { CheckoutSaasApi } from "services/api/saasPayment/CheckoutSaasApi";
import { IAddress } from "components/googleAutocomplete/interfaces/IAddress";
import { GetLocalAddressUseCase } from "modules/kds/application/useCases/GetLocalAddressUseCase";
import KDSService from "services/api/KDS/KDSService";
import { ICheckoutForm } from "modules/saas/presentation/interfaces/checkout/ICheckoutForm";
import { usePlans } from "modules/plans/presentation/hooks/PlansContext";

const saasService = SaasApi();
const service = CheckoutSaasApi();
const kdsService = KDSService();

interface Error {
    string: string;
}

export const UseSaasPaymentPage = () => {
    const [selectedType, setSelectedType] = useState<SubscriptionType>(SubscriptionType.ANNUAL);
    const [selectedPlan, setSelectedPlan] = useState<IPlansItem>();
    const [plans, setPlans] = useState<IPlansItem[]>([]);
    const [wPos, setWPos] = useState(false);
    const [form, setForm] = useState<ICheckoutForm>({ creditCardPayment: { billingAddress: {}, installment: 12 } } as ICheckoutForm);
    const [errors, setErrors] = useState({} as Error);
    const [formLoading, setFormLoading] = useState(false);
    const [localAddress, setLocalAddress] = useState<IAddress>();
    
    const history = useHistory();
    const { showLoading, hideLoading, toast } = useUi();
    const { currentLocal } = useLocal();
    const { getSubscription } = usePlans();

    useEffect(() => {
        getPlans(selectedType, true);
    }, []);

    useEffect(() => {
        (async () => {
            if (currentLocal) {
                try {
                    showLoading();
                    const response = await GetLocalAddressUseCase(kdsService, currentLocal?.id);
                    setLocalAddress(response);
                } finally {
                    hideLoading();
                }
              }
        })();
    }, [currentLocal, hideLoading, showLoading]);

    useEffect(() => {
        if (selectedType === SubscriptionType.MONTHLY) setWPos(false);

        getPlans(selectedType)

    }, [selectedType]);

    const getPlans = useCallback((selectedType: any, loadDefault = false) => {
        (async () => {
            try {
                showLoading();
                const params = `categoryId=1&subscriptionType=4&subscriptionType=${selectedType}`;
                const response = await GetPlansUseCase(saasService, params);
                setPlans(response);

                if (loadDefault && response.length !== 0) {
                    setSelectedPlan(response.find(plan => plan.index === 2));
                }
            } finally {
                hideLoading();
            }
        })();
    }, [hideLoading, showLoading]);

    const wait = (ms: number) => new Promise(func => setTimeout(func, ms));

    const handleSubmit = async (useSameAddress: boolean) => {
        if (currentLocal) {
            try {
                setFormLoading(true);
                showLoading();
                let formData = form;
    
                formData.planSelected = {
                    ...form.planSelected,
                    planId: selectedPlan!.id,
                }

                if (selectedType === SubscriptionType.MONTHLY) {
                    formData.creditCardPayment.installment = 1;
                }
    
                if (useSameAddress && localAddress) {
                    formData.creditCardPayment.billingAddress = { ...localAddress, zipCode: localAddress.CEP ?? "" }
                }
    
                if (wPos) {
                    formData.planSelected.featureIds = [selectedPlan?.features[0]?.id!];
                }
    
                await CheckoutSaasUseCase(service, {...formData, ownerId: currentLocal.id });

                await wait(3000);

                await getSubscription();

                toast("Pagamento realizado com sucesso!", "success");
                history.push("/private/plans");
            } finally {
                setFormLoading(false);
                hideLoading();
            }
        }
    }

    return {
        selectedType,
        setSelectedType,
        selectedPlan,
        setSelectedPlan,
        plans,
        wPos,
        setWPos,
        form, 
        setForm,
        errors, 
        setErrors,
        handleSubmit,
        formLoading,
    }
}