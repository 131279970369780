import {
  DeleteOutline,
  EditOutlined,
  LaunchOutlined,
  LinkOutlined,
} from "@mui/icons-material";
import Sidesheet from "components/sidesheet/Sidesheet";
import SidesheetFeedback from "components/sidesheet/sidesheetFeedback/SidesheetFeedback";
import { useUi } from "contexts/userInterface/UserInterfaceContext";
import DeletePaymentLinkUseCase from "modules/paymentLink/application/useCases/DeletePaymentLinkUseCase";
import DeleteValidation from "modules/permutation/presentation/pages/permutationAccountList/components/permutationAccountListContainer/deleteValidation/DeleteValidation";
import { FC, useCallback, useMemo, useState } from "react";
import PaymentLinkService from "services/api/paymentLink/PaymentLinkService";
import { moneyMaskNumber } from "services/utils/Money";
import { IGetPaymentLinkListParams } from "../../interfaces/IGetPaymentLinkListParams";
import { IPaymentLink } from "../../interfaces/IPaymentLink";
import {
  IPaymentLinkColumns,
  IPaymentLinkProperty,
} from "../../interfaces/IPaymentLinkColumns";
import styles from "./PaymentLinkItem.module.scss";

export interface IPaymentLinkItem {
  paymentLink: IPaymentLink;
  columns: IPaymentLinkColumns[];
  openSidesheet: (id?: string) => void;
  getPaymentLinkList: (params?: IGetPaymentLinkListParams) => Promise<void>;
}

const PaymentLinkItem: FC<IPaymentLinkItem> = ({
  paymentLink,
  columns,
  openSidesheet,
  getPaymentLinkList,
}) => {
  const { isMobile, toast } = useUi();

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [step, setStep] = useState(0);

  const handleDelete = useCallback(() => {
    setShowDeleteModal(true);
  }, []);

  const closeDeleteModal = useCallback(() => {
    setShowDeleteModal(false);
  }, []);

  const deletePaymentLink = useCallback(async () => {
    try {
      setIsLoading(true);
      const service = PaymentLinkService();
      await DeletePaymentLinkUseCase(service, paymentLink.id);
      getPaymentLinkList({
        pagination: {
          page: 0,
          pageSize: 20
        },
        sort: {
          orientation: 'asc',
          type: 'name'
        }
      });
      setStep(1);
    } finally {
      setIsLoading(false);
    }
  }, [paymentLink.id, getPaymentLinkList]);

  const openLinkNewTab = useCallback(() => {
    window.open(
      `${process.env.REACT_APP_MEPAY_URL}/paymentlink/${paymentLink.id}`,
      "_blank"
    );
  }, [paymentLink.id]);

  const copyLinkToClipboard = useCallback(() => {
    navigator.clipboard.writeText(
      `${process.env.REACT_APP_MEPAY_URL}/paymentlink/${paymentLink.id}`
    );
    toast("Link copiado para areá de transferencia", "success");
  }, [paymentLink.id, toast]);

  const formatValues = useCallback(
    (property: IPaymentLinkProperty): string => {
      if (paymentLink.hasOwnProperty("id")) {
        switch (property) {
          case "expiration":
            return paymentLink?.expiration
              ? new Date(paymentLink.expiration).toLocaleDateString()
              : "-";
          case "price":
            return paymentLink?.price
              ? moneyMaskNumber(Number(paymentLink?.price))
              : "-";

          default:
            return String(paymentLink?.[property]) ?? "-";
        }
      }
      return "-";
    },
    [paymentLink]
  );

  const WebItem = useMemo(
    () =>
      columns.map((colunm, index) => {
        return (
          !colunm.hide && (
            <div
              key={index}
              className={styles.row}
              style={colunm.style ?? { flex: 1 }}
            >
              <span title={formatValues(colunm.property)}>
                {formatValues(colunm.property)}
              </span>
            </div>
          )
        );
      }),
    [columns, formatValues]
  );

  const MobileItem = useMemo(
    () => (
      <>
        <div className={styles.top}>
          <span>{paymentLink.name}</span>
          <div className={styles.icon}>
            <LaunchOutlined titleAccess="Nova aba" onClick={openLinkNewTab} />
            <LinkOutlined titleAccess="Link" onClick={copyLinkToClipboard} />
            <EditOutlined
              titleAccess="Editar"
              onClick={() => openSidesheet(paymentLink.id)}
            />
            <DeleteOutline titleAccess="Excluir" onClick={handleDelete} />
          </div>
        </div>
        <div className={styles.mid}>
          <span>Valor:</span>
          <span>{moneyMaskNumber(Number(paymentLink.price))}</span>
        </div>
        <div className={styles.footer}>
          <span>Expira em:</span>
          <span>{new Date(paymentLink.expiration).toLocaleDateString()}</span>
        </div>
      </>
    ),
    [
      paymentLink.expiration,
      paymentLink.id,
      paymentLink.name,
      paymentLink.price,
      openSidesheet,
      openLinkNewTab,
      copyLinkToClipboard,
      handleDelete,
    ]
  );

  return (
    <div id={styles.PaymentLinkItem}>
      {isMobile ? (
        <div className={styles.mobileContainer}>{MobileItem}</div>
      ) : (
        <div className={styles.webContainer}>
          {WebItem}
          <div className={styles.icon}>
            <LaunchOutlined titleAccess="Nova aba" onClick={openLinkNewTab} />
            <LinkOutlined titleAccess="Link" onClick={copyLinkToClipboard} />
            <EditOutlined
              titleAccess="Editar"
              onClick={() => openSidesheet(paymentLink.id)}
            />
            <DeleteOutline titleAccess="Excluir" onClick={handleDelete} />
          </div>
        </div>
      )}

      <Sidesheet
        open={showDeleteModal}
        onClose={closeDeleteModal}
        isLoading={isLoading}
        currentStep={step}
        totalSteps={1}
        title={
          <h2>
            Excluir <b>link de pagamento</b>
          </h2>
        }
        content={
          <DeleteValidation
            text="Deseja realmente excluir o link de pagamento?"
            highlightText="Essa ação não poderá ser desfeita!"
          />
        }
        handleContinueButton={deletePaymentLink}
        notTotalHeight
        continueButton="Excluir"
        feedback={
          <SidesheetFeedback
            text={`Link de pagamento excluído com sucesso!`}
            success
          />
        }
      />
    </div>
  );
};

export default PaymentLinkItem;
