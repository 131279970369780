
import Radio from '@material-ui/core/Radio';
import { Icon, IconButton, TextField } from '@mui/material';
import { RadioGroup, FormControlLabel, Button } from '@material-ui/core';
import Autocomplete from '@mui/material/Autocomplete';
import React, { ChangeEvent, FC, FormEvent, useCallback, useEffect, useState } from 'react'
import { IKDSStep } from '../../interfaces/IKDSSteps';
import { IKDSTable } from '../../interfaces/IKDSTable';
import { IKDSStores } from '../../interfaces/_IKDSStores';
import SelectorSteps from '../selectorSteps/SelectorSteps';
import styles from './KDSFilter.module.scss'
import { IKDSFilter } from '../../interfaces/IKDSFilter';
import { IStepLoading } from '../../UseKDSPage';
import { ISelect } from 'interfaces/ISelect';
import Utils from 'services/utils/Utils';
import UseSelectedEventStartDate from '../../hooks/UseSelectedEventStartDate';
import UseDimension from 'components/dimension/UseDimension';
import Row from 'modules/catalog/presentation/componentes/ui/Row';
import { useLocal } from 'modules/local/presentation/context/LocalContext';

export interface IKDSFilterProps {
    tables?: IKDSTable[];
    steps?: IKDSStep[];
    stores?: IKDSStores[];
    onSubmit: (values: IKDSFilter) => void;
    defaultValues: IKDSFilter;
    enableRefresh: React.MutableRefObject<boolean>;
    getStepTotals: (stepId: string) => Promise<{ total: number; } | null | undefined>;
    setTotal: React.Dispatch<React.SetStateAction<number>>;
    total: number;
    summaryView: boolean;
    setSummaryView: React.Dispatch<React.SetStateAction<boolean>>;
    handleSummaryView: (event: React.ChangeEvent<HTMLInputElement>) => void;
    isLoading: IStepLoading[];
    isButtonDisabled: boolean;
    setFilter: React.Dispatch<React.SetStateAction<IKDSFilter>>;
    deviceList: ISelect[]
}

const defaultDevice = {
    id: '',
    name: 'Selecione o equipamento',
};
const defaulWebApp = {
    id: '00000000-0000-0000-0000-000000000000',
    name: 'WebApp/App',
};

const KDSFilter: FC<IKDSFilterProps> = ({
    tables,
    steps,
    stores,
    onSubmit,
    defaultValues,
    enableRefresh,
    getStepTotals,
    setTotal,
    total,
    summaryView,
    setSummaryView,
    handleSummaryView,
    isLoading,
    isButtonDisabled,
    setFilter,
    deviceList
}) => {
    const [values, setValues] = useState<IKDSFilter>(defaultValues);
    const [selectedDefaultDevice, setSelectedDefaultDevice] = useState(defaultDevice);

    const { selectEventStartDate } = UseSelectedEventStartDate();
    const { dimensions } = UseDimension();
    const { localConfiguration } = useLocal();

    const [showAdvancedFilter, setShowAdvancedFilter] = useState(false);

    useEffect(() => {
        if (selectEventStartDate) {            
            setValues({...defaultValues, startDate: selectEventStartDate});
        }
    }, [defaultValues, selectEventStartDate]);

    const submitHandle = useCallback((ev: FormEvent<HTMLFormElement>) => {
        ev.preventDefault();
        onSubmit(values)       
    }, [onSubmit, values])

    const changeHandle = useCallback((ev: ChangeEvent<HTMLInputElement>) => {
        setValues((prev) => ({ ...prev, [ev.target.name]: ev.target.value }));
    }, []);

    const changeHandleSenha = useCallback((ev: ChangeEvent<HTMLInputElement>) => {
        setValues((prev) => ({ ...prev, [ev.target.name]: Number(ev.target.value) }));
    }, []);

    const changeStepsHandle = useCallback((steps: IKDSStep[]) => {
        setFilter((prev) => ({ ...prev, steps: steps }));
    }, [setFilter]);

    const changeStoresHandle = useCallback((store?: IKDSStores | null) => {
        setValues((prev) => ({ ...prev, store: store ?? undefined }));
    }, []);

    const changeTableHandle = useCallback((tables: IKDSTable[] | null) => {
        setValues((prev) => ({ ...prev, tables: tables || undefined }));
    }, []);

    const changeVisualizationHandle = useCallback((groupedByTable?: boolean) => {
        setValues((prev) => ({ ...prev, groupedByTable }));
    }, []);

    const onClickShowAdevancedFilterHandle = useCallback(() => {
        setValues(prev => ({ ...prev, tables: undefined, store: undefined }))
        setShowAdvancedFilter(prev => !prev);
    }, []);

    const sort = defaultValues?.orientation === 'asc' ? 'desc' : 'asc';
    const onClickSortHandle = useCallback(() => {
        onSubmit({ ...(defaultValues ?? {}), orientation: sort });
    }, [defaultValues, onSubmit, sort]);

    const onClickSortTableHandle = useCallback(() => {
        onSubmit({ ...(defaultValues ?? {}), sortByTable: !defaultValues.sortByTable });
    }, [defaultValues, onSubmit]);

    const handleChangeDevice = useCallback((value: string | undefined | null) => {
        setValues((prev) => ({
            ...prev,
            deviceId: value ?? '',
        }));
    }, []);

    return (
        <div id={styles.KDSFilter} >
            <form onSubmit={submitHandle}>
                <div className={styles.containerFilter}>
                    <div className={styles.visualization}>
                        <span >Visualização</span>
                        <RadioGroup
                            row
                            onChange={(_, value) => changeVisualizationHandle(value === `true` ? true : false)}
                            value={values.groupedByTable ? "true" : "false"}
                            aria-labelledby="demo-radio-buttons-group-label"
                        >
                            <FormControlLabel value={`false`} control={<Radio />} label="Individual" />
                            <FormControlLabel value={`true`} control={<Radio />} label="Mesa" />
                        </RadioGroup>

                    </div>
                    <div className={styles.footer} >
                        <div className={styles.selector} >
                            <SelectorSteps
                                steps={steps}
                                total={total}
                                setTotal={setTotal}
                                values={values?.steps}
                                onChange={(values) => changeStepsHandle(values)}
                                getStepTotals={getStepTotals}
                                isLoading={isLoading}
                            />
                        </div>
                    </div>
                </div>
                <div className={styles.containerRefresh}>
                    <div className={styles.containerSearch} style={{ width: dimensions.width < 600 ? "100%" : ""}}>
                        <TextField
                            placeholder="Nome, CPF, Produto ou Nº do pedido"
                            fullWidth
                            className={styles.search}
                            size='small' name={'input'}
                            onChange={changeHandle}
                            value={values.input}
                        />
                        <IconButton title='Buscar estabelecimento' className={styles.button} type={'submit'}><Icon>search</Icon></IconButton>
                    </div>

                    <div className={styles.advancedFilterButton}>
                        {<Button className={styles.buttonFilterAdvanced} onClick={onClickShowAdevancedFilterHandle}><span> Filtros avançados</span>{!showAdvancedFilter ? <Icon>sort</Icon> : <Icon>close</Icon>}</Button>}
                    </div>
                </div>
                {
                    showAdvancedFilter && <div className={styles.advancedFilter}>
                        <div className={styles.containerTop}>
                            <div className={styles.item}>
                                <label>Inicio</label>
                                <TextField size='small' name={'startDate'} onChange={changeHandle} fullWidth value={Utils.toInputDateString(values.startDate + 'Z')} type="datetime-local" />
                            </div>
                            <div className={styles.item}>
                                <label>Fim</label>
                                <TextField size='small' name={'endDate'} onChange={changeHandle} fullWidth defaultValue={Utils.toInputDateString(values.endDate)} type="datetime-local" />
                            </div>
                            <div className={styles.item}>
                                <label>Origem do pedido</label>
                                <Autocomplete
                                    disableClearable
                                    fullWidth
                                    options={[defaultDevice, defaulWebApp, ...deviceList ?? []]}
                                    getOptionLabel={(option) => option.name}
                                    value={selectedDefaultDevice}
                                    onChange={(_, value: any) => {
                                        setSelectedDefaultDevice(value);
                                        handleChangeDevice(value.id);
                                    }}
                                    size="small"
                                    renderInput={(params) => (
                                        <TextField {...params} variant="outlined" />
                                    )}
                                    style={{ width: "100%" }}
                                />
                            </div>
                            {(localConfiguration?.deveHabilitarGerenciamentoSenhaPedido ?? false) && <div>
                                <label>Senha</label>
                                <Row>
                                <TextField  placeholder='Min' size='small' className={styles.inputSenha} type='number' name='minPanelCode' onChange={changeHandleSenha}/>
                                <TextField  placeholder='Max' size='small'className={styles.inputSenha} type='number' name='maxPanelCode' onChange={changeHandleSenha}/>
                                </Row>
                            </div>}
                            <div className={styles.item}>
                                <label>Mesa</label>
                                <Autocomplete
                                    fullWidth
                                    size='small'
                                    multiple
                                    limitTags={2}
                                    options={tables ?? []}
                                    getOptionLabel={option => option.number}
                                    value={values?.tables}
                                    onChange={(ev, value) => changeTableHandle(value)}
                                    placeholder='mesa'
                                    renderInput={params => <TextField {...params} />}
                                    style={{ width: "100%" }}
                                />
                            </div>

                            {!stores?.length && <div className={styles.containerButton} style={{ width: "100%" }}>
                                <Button
                                    className={styles.button}
                                    fullWidth
                                    style={{ height: 40, borderRadius: 8, width: dimensions.width < 600 ? "100%" : 110 }}
                                    variant='contained'
                                    color={"primary"}
                                    type='submit'
                                    // disabled={isLoading.some(item => item.isLoading) || isButtonDisabled}
                                >
                                    Buscar
                                </Button>
                            </div>
                            }
                        </div>                       
                        {!!stores?.length && <div className={styles.containerBottom}>

                            <div className={styles.item} style={{ flex: 3, minWidth: 300 }}>
                                <label>Lojas</label>
                                <Autocomplete
                                    fullWidth
                                    size='small'
                                    options={stores ?? []}
                                    getOptionLabel={option => option.name}
                                    onChange={(ev, values) => changeStoresHandle(values)}
                                    placeholder='Loja'
                                    value={values?.store}
                                    limitTags={3}
                                    renderInput={params => <TextField  {...params} />}
                                />
                            </div>

                            <div className={styles.containerButton} >
                                <Button
                                    // variant={}
                                    className={styles.button}
                                    fullWidth
                                    style={{ height: 40, borderRadius: 8, width: 110 }}
                                    variant='contained'
                                    color={"primary"}
                                    type='submit'
                                    // disabled={isLoading.some(item => item.isLoading) || isButtonDisabled}
                                >
                                    Buscar
                                </Button>
                            </div>
                        </div>
                        }
                    </div>
                }

                {
                    defaultValues.groupedByTable ?
                        <>
                            <Button className={styles.ordernation} onClick={onClickSortTableHandle}><Icon>swap_vert</Icon>{!values.sortByTable ? `Ordernar por mesa` : `Ordernar por tempo`}</Button>
                            {!!values.sortByTable && <Button className={styles.ordernation} onClick={onClickSortHandle}><Icon>swap_vert</Icon>{values.orientation === 'desc' ? `Crescente` : `Decrecente`}</Button>}
                        </>
                        :
                        <>
                            <Button className={styles.ordernation} onClick={onClickSortHandle}><Icon>swap_vert</Icon>{values.orientation === 'desc' ? `Últimos pedidos` : `Primeiros pedidos`}</Button>
                        </>
                }
            </form >
        </div >
    )
}
export default KDSFilter