import { useBreadcumbs } from "components/breadcumbs/BreadcumbsContext";
import Layout from "components/layout/presentation/Layout";
import { IframePage } from "modules/oldPortal/presentation/components/iframe/IframePage";
import React, { FC, useEffect } from "react";
import { useParams } from "react-router-dom";
import TabPage from "../dashboard/tabPage/TabPage";
import styles from "./Cartoes.module.scss";
import UseManageEventPage from "modules/events/presentation/pages/manageEvent/UseManageEventPage";

export interface ICartoesProps {
  //propertys
}
const Cartoes: FC<ICartoesProps> = () => {
  const { updateRouters } = useBreadcumbs();
  const { tab, eventId } = useParams<{ tab: string, eventId: string }>();
  const { event } = UseManageEventPage()

  useEffect(() => {
    if (eventId) {
      updateRouters([
        {
          title: "Evento/Caixa",
          url: '/private/eventos/list/eventos'
        },
        {
          title: event?.name,
        },
        {
          title: "Informações dos Clientes",
        },
        {
          title: "Comandas",
        },
      ]);
    } else {
      updateRouters([
        {
          title: "Informações dos Clientes",
        },
        {
          title: "Comandas",
        },
      ]);
    }

    return () => {
      updateRouters([]);
    };
  }, [tab, updateRouters, event, eventId]);

  return (
    <Layout>
      <TabPage
        intialTab="Cashless"
        routerPrefix={"/private/event/cartoes"}
        hideTabs
        tabsPages={[
          {
            label: "Cashless",
            tab: "Cashless",
            roles: "CashlessCartoesPortal",
            component: <IframePage src={"/cashless/cartoes"} />,
          },
          {
            label: "Contas",
            tab: "Contas",
            roles: "ContasPosPagoPortal",
            component: <IframePage src={"/posPago/contas"} />,
          },
        ]}
      />
    </Layout>
  );
};
export default Cartoes;
