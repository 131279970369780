import React, { FC, useCallback, useMemo } from "react";
import FlagIcon from '@mui/icons-material/Flag';
import DeleteForeverOutlinedIcon from "@material-ui/icons/DeleteForeverOutlined";
import { IconButton, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { MenuItem, Pagination } from "@mui/material";

import { Modal } from "components/ui/modal/Modal";
import { IEventPeople, IEventPeopleList, PreCheckinStatus } from "modules/events/presentation/pages/eventGuestsLists/interfaces/IEventGuestsListsPage";
import { UseAlphabeticalEventPeopleList } from "./UseAlphabeticalEventPeopleList";
import { IListObj } from "modules/events/presentation/utils/utils";
import { ErrorMessage } from "components/ui/errorMessage/ErrorMessage";
import { IEventGuestFilter, IParamsClientEventGuestList } from "../../UseEventPeopleListPage";
import Button from "components/ui/Button/Button";
import styles from "./AlphabeticalEventPeopleList.module.scss";
import checkIcon from "./icons/check_icon.svg";
import pendingIcon from "./icons/pending_icon.svg";
import waitingIcon from './icons/waiting_icon.svg';
import boughtATicketEnabled from './icons/boughtATicket-enabled.svg';
import boughtATicketDisabled from './icons/boughtATicket-disabled.svg';
import attentionIcon from './icons/attention_icon.svg';
import InputMasked from "components/inputMasked/InputMasked";

interface IAlphabeticalEventPeopleListProps {
  guestList: IEventPeopleList;
  eventName: string | undefined;
  closingdateTime?: Date;
  refreshList: () => Promise<void>;
  onChangePaginationHandle: (pagination: {
    page?: number | undefined;
    pageSize?: number | undefined;
  }) => void;
  params: IParamsClientEventGuestList;
  setParams: React.Dispatch<React.SetStateAction<IParamsClientEventGuestList>>;
  pagination: {
    page?: number | undefined;
    pageSize?: number | undefined;
  } | undefined;
  onChangeFilterHandle: (filter: IEventGuestFilter) => void;
}

const AlphabeticalEventPeopleList: FC<IAlphabeticalEventPeopleListProps> = ({
  guestList,
  eventName,
  refreshList,
  onChangePaginationHandle,
  params,
  pagination,
  onChangeFilterHandle
}) => {
  const {
    handleDeleteUser,
    filterByGuest,
    filterByList,
    filterByPromoter,
    userToDelete,
    showDeleteModal,
    toggleDeleteModal,
    deleteGuest,
    filterByCpf,
    handlePreCheckin,
    preCheckinUser,
    setPrecheckinUser,
    togglePreCheckinModal,
    showPrecheckinModal,
    submitHandle,
    eventGuestList,
    value,
    mountCategories,
    addCustomerInCategory,
    removeCustomerFromCategory,
  } = UseAlphabeticalEventPeopleList(guestList, refreshList, onChangeFilterHandle);

  const RenderPeopleCard = useCallback((obj: IEventPeople, key: number) => {
    return (
      <div key={key} className={styles.guest}>
        <div>
          <div className={styles.alertListName}>
            <h2>{obj.name}</h2>
            {obj.isOnAlert && (
              <div className={styles.alertList}>
                <div className={styles.alertListButton}><FlagIcon fontSize='small'>vertical_align_bottom</FlagIcon>Alerta</div>
              </div>
            )}
          </div>

          <div className={styles.categoriesContainer}>
            {
              mountCategories(obj).map(item => (
                <button onClick={() => item.isActive ? removeCustomerFromCategory(item.id, obj.id) : addCustomerInCategory(item.id, obj.id)} className={item.isActive ? styles.active : ''}>
                  <img src={`/assets/icon/customerCategory/${item.isActive ? 'white' : 'gray'}/${item.icon}.png`} alt="" />
                </button>
              ))
            }
          </div>

          <p>{`Lista: ${obj.listName}`}</p>
          <p>{`CPF: ${obj.cpf}`}</p>
          <p>{obj.promoterName === 'Integração ou POS' ? <span style={{color: 'var(--secondary'}}>Entrou pelo link</span> : `Promoter: ${obj.promoterName}`}</p>
        </div>
        {
          obj.boughtATicket ?
            <div className={styles.boughtATicket}>
              <img src={boughtATicketEnabled} alt="Icone ingresso comprado" />
              <span>
                <b>Ingresso retirado </b>
              </span>
            </div>
            :
            <div className={styles.boughtATicket}>
              <img src={boughtATicketDisabled} alt="Icone ingresso não comprado" />
              <span className={styles.disabled}><b>Ingresso não retirado </b></span>
            </div>
        }
        <div>
          <img
            src={obj.checkinAt ? checkIcon : obj.preCheckinStatus !== PreCheckinStatus.Waiting ? pendingIcon : waitingIcon}
            alt=""
          />
          {obj.checkinAt ? (
            <>
              <p>Check-in realizado</p>
              <p>
                {new Date(obj.checkinAt).toLocaleString([], {
                  dateStyle: "short",
                  timeStyle: "short",
                })}
              </p>
            </>
          ) : obj.preCheckinStatus !== PreCheckinStatus.Waiting ? (
            <div className={styles.pending}>
              <p>Check-in pendente</p>
              <Button variant="outlined" onClick={() => setPrecheckinUser(obj)}>Validar</Button>
            </div>
          ) : (
            <div>
              <p>Aguardando Checkin</p>
              {!obj.isGuestListCheckinEnabledWithTicketValidator && (
                <Button variant="outlined" onClick={() => setPrecheckinUser(obj)}>Validar</Button>
              )}
            </div>
          )}
        </div>
        <IconButton
          onClick={() =>
            handleDeleteUser(obj.id, obj.name)
          }
        >
          {" "}
          <DeleteForeverOutlinedIcon />{" "}
        </IconButton>
      </div>
    );
  }, [handleDeleteUser, setPrecheckinUser]);

  const onChangePageHandle = useCallback(
    (page: number, pageSize?: number) => {
      onChangePaginationHandle({ ...pagination, page: page - 1, pageSize });
    },
    [onChangePaginationHandle, pagination]
  );

  const countPage = useMemo(() => Math.ceil((guestList?.totalRecords ?? 1) / (pagination?.pageSize ?? 20)), [guestList?.totalRecords, pagination?.pageSize])

  const filteredGuest = useMemo(() => guestList.records.map((item, key) => RenderPeopleCard(item, key)), [RenderPeopleCard, guestList.records])

  return (
    <div id={styles.GuestContainer}>
      <form onSubmit={submitHandle}>
        <div className={styles.filterContainer}>
          <h1>{eventName}</h1>
          <div className={styles.contentInputs} >

            <InputMasked
              name={'cpf'}
              value={value?.cpf ?? ''}
              variant='outlined'
              mask="999.999.999-99"
              label={'CPF'}
              className={styles.input}
              onChange={(ev: any) => filterByCpf('cpf', ev.target.value)}
            />

            <TextField label="Nome" color={"secondary"} {...params} variant="outlined" size="small" onChange={(ev) => filterByGuest(ev.target.value)} />

            <Autocomplete
              options={eventGuestList ?? []}
              getOptionLabel={(option: IListObj) => option.name}
              onChange={filterByList}
              renderInput={(params) => (
                <TextField label="Lista" color={"secondary"} {...params} variant="outlined" size="small" />
              )}
            />

            <TextField label="Promoter" color={"secondary"} {...params} variant="outlined" size="small" onChange={(ev) => filterByPromoter(ev.target.value)} />

            <div className={styles.footer}>
              <Button variant={'contained'} className={styles.button} fullWidth style={{ height: 40, borderRadius: 8, width: 110 }} type='submit' >Buscar</Button>
            </div>
          </div>
        </div>
      </form>
      <div className={styles.counter}>
        <p>{`Check-in: ${(
          guestList?.totalCheckIn + '/' +
          guestList?.totalRecords
        )}`}</p>
      </div>

      <div>

        {
          !guestList?.records?.length
            ? <ErrorMessage type="warning" />
            : (
              <>
                {filteredGuest}
                <div className={styles.conteinerPagination}>
                  <div className={styles.boxMultiplePage}>
                    <span>Exibir</span>
                    <TextField variant='outlined' size='small' select onChange={(ev) => onChangePageHandle?.(1, Number(ev.target.value))}>
                      <MenuItem className={styles.textPage} value={10} >10</MenuItem>
                      <MenuItem className={styles.textPage} value={20} >20</MenuItem>
                      <MenuItem className={styles.textPage} value={50} >50</MenuItem>
                    </TextField>
                  </div>
                  <Pagination
                    variant="outlined"
                    style={{ alignSelf: 'flex-end' }}
                    className={styles.paginationNumber}
                    shape="rounded"
                    count={countPage}
                    sx={{
                      '.MuiPaginationItem-root': {
                        borderColor: '#BDBDBD',
                        color: '#001537',
                      },
                      '.Mui-selected': {
                        fontWeight: 'bold',
                        borderColor: '#001537',
                      },
                    }}
                    onChange={(_, page) => onChangePageHandle?.(page, pagination?.pageSize)}
                    page={((pagination?.page ? pagination?.page + 1 : 1))}
                  />
                </div>
              </>
            )
        }

      </div>
      {userToDelete && (
        <Modal
          title="Remover Convidado"
          open={showDeleteModal}
          toggleModal={toggleDeleteModal}
          onConfirm={() => deleteGuest(userToDelete.id)}
        >
          <p>{`Tem certeza que deseja remover o convidado ${userToDelete.name} da lista?`}</p>
        </Modal>
      )}

      {preCheckinUser && (
        <Modal
          title="Validação"
          open={showPrecheckinModal}
          toggleModal={togglePreCheckinModal}
          onConfirm={() => handlePreCheckin(preCheckinUser.id, preCheckinUser.isGuestListCheckinEnabledWithTicketValidator)}>
          <div className={styles.preCheckin}>
            <img
              src={attentionIcon}
              alt="attentionIcon"
            />
            <p>Você deseja realizar a validação do cliente?</p>
            <p>{preCheckinUser.name} - {preCheckinUser.cpf}</p>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default AlphabeticalEventPeopleList;
