import styles from "../Trial/TrialPage.module.scss";
import { useHistory, useLocation } from "react-router-dom";
import { useEffect } from "react";
import { useQuery } from "hooks/UseQuery";
import SaasSuccessLayout from "../SaasSuccessLayout";

export const SimpleSuccessPage = () => {
    const history = useHistory();
    const location = useLocation<{ crypt: string }>();
    let query = useQuery();

    const email = query.get("email");

    useEffect(() => {
        setTimeout(() => {
            history.push(`/login?email=${email}`, { crypt: location.state?.crypt });
        }, 25000);
    }, []);

    return (
        <SaasSuccessLayout background="linear-gradient(179.05deg, #6B41C9 0.82%, #865FDD 106.68%)" logo="/assets/img/logo-mee-white.svg">
            <div id={styles.SimpleSuccessPage}>
                <div className={styles.container}>
                    <div className={styles.row}>
                        <div className={styles.column}>
                            <span
                                style={{
                                    fontWeight: 900,
                                    color: "#FFF",
                                    marginBottom: "8px"
                                }}
                            >
                                Parabéns! 🎉
                            </span>
                            <span style={{ color: "#FFF" }}>
                                Você está experimentando a <b>revolução do seu negócio  gratuitamente!</b>
                            </span>
                            <p style={{ color: "#FFF" }}>
                                Aguarde, estamos redirecionado você para o Portal Mee 
                            </p>
                        </div>
                        <div className={styles.img}>
                            <img src="/assets/gif/intro-saas.gif" alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </SaasSuccessLayout>
    )
}