import { useLocal } from "modules/local/presentation/context/LocalContext";
import { CheckProductListUseCase } from "modules/onboarding/application/useCases/CheckProductListUseCase";
import { CheckSalesManagementUseCase } from "modules/onboarding/application/useCases/CheckSalesManagementUseCase";
import { CheckTeamUseCase } from "modules/onboarding/application/useCases/CheckTeamUseCase";
import { useEffect, useState } from "react";
import { OnboardingApi } from "services/api/onboarding/OnboardingApi";

const service = OnboardingApi();

export const UseOnboardingCard = () => {
    const [isMenuCompleted, setIsMenuCompleted] = useState(false);
    const [isTeamCompleted, setIsTeamCompleted] = useState(false);
    const [isSalesCompleted, setIsSalesCompleted] = useState(false);
    const [loading, setLoading] = useState(true);

    const { currentLocal } = useLocal();

    useEffect(() => {
        (async () => {
            if (currentLocal) {
                try {
                    const promises = [
                        CheckProductListUseCase(service, currentLocal.id),
                        CheckTeamUseCase(service, currentLocal.id),
                        CheckSalesManagementUseCase(service, currentLocal!.id),
                    ];

                    const response = await Promise.all(promises);

                    setIsMenuCompleted(response[0].isCompleted);
                    setIsTeamCompleted(response[1].isCompleted);
                    setIsSalesCompleted(response[2].isCompleted);
                } finally {
                    setLoading(false);
                }
            }
        })();
    }, [currentLocal]);

    return {
        isMenuCompleted,
        isTeamCompleted,
        isSalesCompleted,
        loading
    }
}
