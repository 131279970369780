import React, { FC, FormEvent, Fragment, useCallback, useEffect, useState } from 'react'
import styles from './ProductComplementListForm.module.scss'
import { IComplementProductFormValue } from '../interfaces/IComplementProductFormValue'
import { v4 } from 'uuid'
import ProductComplementItemForm, { IproductComplementError as IProductComplementError } from './productComplementFormItem/ProductComplementItemForm'
import { Separator } from '../../ui/Separator'
import ButtonsForm from '../../ui/ButtonsForm'
import { CompositionProductType } from 'modules/catalog/domain/enums/CatalogProductEnums'
import Button from 'components/ui/Button/Button'
import { AddCircle } from '@material-ui/icons'
import { useLocal } from 'modules/local/presentation/context/LocalContext'

const DEFAULTPRODUCT: IComplementProductFormValue = {
    id: v4(),
    name: "",
    // image?: string;
    value: 0,
    min: 0,
    max: 5,
    quantity: 1,
    type: CompositionProductType.VARIAVEL
}

export interface IProductComplementListFormProps {
    //propertys
    defaultValues?: IComplementProductFormValue[]
    onSubmit: (values: IComplementProductFormValue[]) => void
    onCancel: () => void
    disabledEditProduct?: boolean
}

const ProductComplementListForm: FC<IProductComplementListFormProps> = ({ defaultValues, onSubmit, onCancel, disabledEditProduct }) => {
    const [values, setValues] = useState<IComplementProductFormValue[]>(defaultValues ?? []);
    const [errors, setErrors] = useState<{ id: string, error: IProductComplementError }[]>(defaultValues?.map((item) => ({ id: item.id, error: {} })) ?? []);
    const { localConfiguration } = useLocal();

    useEffect(() => {
        if (defaultValues) {
            setValues(defaultValues);
        }
    }, [defaultValues])

    const onAddComplementProductHandle = useCallback(() => {
        setValues(prev => [...prev, {
            ...DEFAULTPRODUCT,
            id: v4(),
        }])
    }, []);

    const onChangeComplementProductHandle = useCallback((complementProduct: IComplementProductFormValue) => {
        setValues(prev => prev.map(item => item.id === complementProduct.id ? complementProduct : item))
    }, []);

    const onDeleteComplementProductHandle = useCallback((complementProduct: IComplementProductFormValue) => {
        setValues(prev => prev.filter(item => item.id !== complementProduct.id))
    }, []);

    const validate = useCallback(() => {
        const _errors = values.map<{ id: string, error: IProductComplementError }>((item, index, array) => {
            if (item.name.length === 0) {
                return { id: item.id, error: { name: "O campo nome é obrigatário" } }
            } else if (item.value === undefined || item.value === null) {
                return { id: item.id, error: { value: "O campo valor é obrigatário" } }
            } else if (!item.type) {
                return { id: item.id, error: { type: "O campo tipo é obrigatário" } }
            } else if (!item.ncm && (localConfiguration?.gerarNFCE ?? false)) {
                return { id: item.id, error: { ncm: "O campo NCM é obrigatário" } }
            } else if (!item.cfop && (localConfiguration?.gerarNFCE ?? false)) {
                return { id: item.id, error: { cfop: "O campo CFOP é obrigatário" } }
            }
            return { id: item.id, error: {} }
        })
        setErrors(_errors);
        if (_errors.some(item => Object.keys(item.error).length > 0)) {
            return false;
        }
        setErrors(values.map((item, index, array) => ({ id: item.id, error: {} })));
        return true;
    }, [localConfiguration?.gerarNFCE, values])

    const submitHandle = useCallback((ev: FormEvent<HTMLFormElement>) => {
        ev.preventDefault();
        if (validate()) {
            onSubmit?.(values)
        }
    }, [onSubmit, validate, values])

    return (
        <form id={styles.ProductComplementListForm} onSubmit={submitHandle} >
            <div className={styles.container} >
                {
                    values.map((item, index, array) =>
                        <Fragment key={index}>
                            <ProductComplementItemForm
                                disabledClose={array.length === 1}
                                key={index}
                                error={errors.find(itemError => itemError.id === item.id)?.error}
                                disabledEditProduct={disabledEditProduct}
                                complementProductItem={item}
                                onChange={onChangeComplementProductHandle}
                                onRemove={onDeleteComplementProductHandle}
                            />
                            <Separator />
                        </Fragment>
                    )
                }
                {!disabledEditProduct && <Button variant="text" onClick={onAddComplementProductHandle} startIcon={<AddCircle />} style={{ width: "150px" }}>Novo Produto</Button>}
            </div>
            <ButtonsForm onCancel={onCancel} color='primary' width="100px" />
        </form>
    )
}
export default ProductComplementListForm