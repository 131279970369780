import styles from './GeneralDashboardPage.module.scss'
import { MyAccount } from '../components/myAccount/MyAccount'
import UseMyAccount from '../hooks/UseMyAccount';
import { useHistory } from 'react-router-dom';
import { AllMovements } from '../components/allMovements/AllMovements';
import UseAllMovements from '../hooks/UseAllMovements';
import TransactionChart from '../components/transactionChart/TransactionChart';
import UsePosPaid from '../hooks/UsePosPaid';
import PosPaidPieChart from '../components/posPaidGeneral/PosPaidPieChart';
import Cashless from '../components/cashlessGeneral/Cashless';
import UseCashless from '../hooks/UseCashless';
import PreRecharge from '../components/preRecharge/PreRecharge';
import UsePreRecharge from '../hooks/UsePreRecharge';
import AverageTicket from '../components/averageTicket/AverageTicket';
import UseAverageTicket from '../hooks/UseAverageTicket';
import AvegareTicketCards from '../components/avegareTicketCard/AverageTicketCards';
import TurnoverTable from '../components/turnoverTable/TurnoverTable';
import GridDashboard from '../components/gridDashboard/GridDashboard';
import DashboardContainer from '../components/dashboardContainer/DashboardContainer';
import RankingOperators from '../components/rankingOperators/RankingOperators';
import RankingCustomers from '../components/rankingCustomers/RankingCustomers';
import SalesCategories from '../components/rankingCategories/RankingCategories';
import SalesDevice from '../components/salesDevices/SalesDevice';
import UseRankingProducts from '../hooks/UseRankingProducts';
import UseRankingOperators from '../hooks/UseRankingOperators';
import UseRankingCustomers from '../hooks/UseRankingCustomers';
import UseSalesCategories from '../hooks/UseSalesCategories';
import UseSalesDevices from '../hooks/UseSalesDevices';
import { UseGeneralDashboardPage } from './UseGeneralDashboardPage';
import RankingProducts from '../components/rakingProducts/RankingProducts';


const GeneralDashboardPage = () => {
  const { filter } = UseGeneralDashboardPage();

  const history = useHistory();
  const myAccount = UseMyAccount();
  const allMovements = UseAllMovements();
  const posPaidData = UsePosPaid();
  const cashlessData = UseCashless();
  const preRechargeData = UsePreRecharge();
  const avarageTicketData = UseAverageTicket();
  const rankingProducts = UseRankingProducts(filter);
  const rankingOperators = UseRankingOperators(filter);
  const rankingCustomers = UseRankingCustomers(filter);
  const salesCategories = UseSalesCategories(filter);
  const salesDevices = UseSalesDevices(filter);



  const goMyAccount = () => {
    history.push('/private/contaDigital')
  }
  
  return (
    <div id={styles.GeneralDashboardPage}>
      <MyAccount {...myAccount} goMyAccount={goMyAccount} />
      <AllMovements {...allMovements} />      
      <TransactionChart transactions={allMovements.allMovements?.financialMovements} />
      <PosPaidPieChart {...posPaidData}/>
      <Cashless {...cashlessData}/>   
      <PreRecharge {...preRechargeData} />   
      <AverageTicket {...avarageTicketData}/>
      <AvegareTicketCards {...avarageTicketData} />
      <TurnoverTable />
      <DashboardContainer
        minHeight={400}
        title={"Top Produtos"}
        span={6}
        hideIcons
      >
        <RankingProducts {...rankingProducts} />
      </DashboardContainer>

      <GridDashboard>
         <DashboardContainer
          minHeight={400}
          title={"Top categoria pai"}
          span={3}
          hideIcons
        >
          <SalesDevice  {...salesDevices} />
        </DashboardContainer>
   
        <DashboardContainer
          minHeight={400}
          title={"Top categoria"}
          span={3}
          hideIcons
        >
          <SalesCategories {...salesCategories} />
        </DashboardContainer>

        <DashboardContainer
          minHeight={400}
          title={"Top Operadores"}
          span={3}
          hideIcons
        >
          <RankingOperators {...rankingOperators} />
        </DashboardContainer>

        <DashboardContainer
          minHeight={400}
          title={"Top Clientes"}
          span={3}
          hideIcons
        >
          <RankingCustomers {...rankingCustomers} />
        </DashboardContainer>    
      </GridDashboard>
    </div>
  )
}

export default GeneralDashboardPage
