import { FC } from "react";
import styles from "./TurnoverTable.module.scss";
import { TurnoverTableItem } from "./components/turnoverTableItem/TurnoverTableItem";

export interface ITurnoverTableProps {}

const TurnoverTable: FC<ITurnoverTableProps> = () => {
  return (
    <div id={styles.TurnoverTable}>
        <div className={styles.topTitle}>
            <p>Giro de mesa</p>
        </div>
        <TurnoverTableItem />
      
    </div>
  );
};
export default TurnoverTable;
