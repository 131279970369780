import React, { FC, useEffect } from 'react'
import RankingList from '../rakingList/RankingList';
import { moneyMaskNumber } from 'services/utils/Money';
import { ISalesCategories } from './interfaces/IRankingCategories';
import { IParamsUseSalesCategories } from '../../hooks/UseSalesCategories';

export interface ISalesCategoriesProps {
   getSalesCategories: (params?: IParamsUseSalesCategories) => Promise<ISalesCategories[] | undefined>
   salesCategories: ISalesCategories[] | undefined
   isLoading: boolean | undefined
}

const SalesCategories: FC<ISalesCategoriesProps> = ({ getSalesCategories, isLoading, salesCategories }) => {

    useEffect(() => {
        getSalesCategories();
    }, [getSalesCategories])  



    return (
        <div>
            <RankingList
                // keyProp="id"
                list={salesCategories}
                isLoading={isLoading}
                colunms={{
                    category: { title: "Nome da categoria" },
                    value: { title: "Total", formatValue: (value) => moneyMaskNumber(value) },
                }}
            />
        </div>
    )
}
export default SalesCategories