import axios from 'axios';
import { ILocalPrinterNFCe } from 'modules/localPrinter/domain/dtos/ILocalPrinterNFCe';
import { INFCeLocalPrinterService } from 'modules/localPrinter/domain/interfaces/INFCeLocalPrinterService';
import AnticorruptionLocalPrinter from './anticorruption/AnticorruptionLocalPrinter';


const LocalPrinterNFCeApi = (): INFCeLocalPrinterService => {


  const printerNFCe = async (
    order: ILocalPrinterNFCe,
    tagOrNumber: string,
    deviceName: string,
    printerServeIp?: string,
  ) => {
    const api = axios.create();
    if (printerServeIp) {

      const sendToPrinterOld = AnticorruptionLocalPrinter(api, printerServeIp);

      try {
        return sendToPrinterOld.EnviarNFCeParaImpressora(order, tagOrNumber, '', deviceName);
      } catch (error) {
        new Error('Falha em imprimir NFC-e');
      }
    }
  };


  return { printerNFCe };
};
export default LocalPrinterNFCeApi;

