import { useLocal } from "modules/local/presentation/context/LocalContext";
import KDSService from "services/api/KDS/KDSService";
import { IAddress } from "components/googleAutocomplete/interfaces/IAddress";
import { useCallback, useEffect, useState } from "react";
import { GetLocalAddressUseCase } from "modules/kds/application/useCases/GetLocalAddressUseCase";
import { useUi } from "contexts/userInterface/UserInterfaceContext";
import UpdateUserUseCase from "modules/plans/application/UpdateUserUseCase";
import { IUpdateUserData } from "modules/plans/domain/dtos/IUpdateUserData";
import { IUpdateLocalData } from "modules/plans/domain/dtos/IUpdateLocalData";
import UpdateLocalUseCase from "modules/plans/application/UpdateLocalUseCase";
import SimpleSaasApi from "services/api/simpleSaas/SimpleSaasApi";
import { AuthRepository } from "services/repository/auth/AuthRepository";
import { useAuth } from "modules/auth/presentation/context/AuthContext";
import UpdateBaseLocalUseCase from "modules/plans/application/UpdateBaseLocalUseCase";
import UpdateBaseUserUseCase from "modules/plans/application/UpdateBaseUserUseCase";

interface IFormValues {
    name: string;
    document: string;
    phone: string;
    establishmentDocument: string;
    cep: string;
    street: string;
    number: string;
    complement?: string;
    neighborhood: string;
    city: string;
    state: string;
    country: string;
}

const service = SimpleSaasApi();
const kdsService = KDSService();

export const UseSimpleTrialModal = (onClose: () => void) => {
    const { currentLocal } = useLocal();
    const { saveAuth } = useAuth();
    const { showLoading, hideLoading, toast } = useUi();

    const [isLoading, setIsLoading] = useState(false);
    const [address, setAddress] = useState<IAddress>();

    useEffect(() => {
        (async () => {
            if (currentLocal) {
                try {
                    showLoading();
                    const response = await GetLocalAddressUseCase(kdsService, currentLocal?.id);
                    if (response.CEP !== "30160-011" || (response.CEP === "30160-011" && response.complement !== "5º andar")) {
                        setAddress(response);
                    } else {
                        setAddress({} as IAddress);
                    }
                } finally {
                    hideLoading();
                }
            }
        })();
    }, [currentLocal, hideLoading, showLoading]);

    const handleUpdateUser = useCallback(async (data: IUpdateUserData) => {
        if (currentLocal) {
            try {
                setIsLoading(true);
                await UpdateUserUseCase(service, { ...data, ownerId: currentLocal.id });
            } finally {
                setIsLoading(false);
            }
        }
    }, [currentLocal]);

    const handleUpdateLocal = useCallback(async (data: IUpdateLocalData) => {
        if (currentLocal) {
            try {
                setIsLoading(true);
                await UpdateLocalUseCase(service, { ...data, ownerId: currentLocal.id });
            } finally {
                setIsLoading(false);
            }
        }
    }, [currentLocal]);

    const updateAuthLocalStorage = useCallback((name: string, phoneNumber: string, document: string ) => {
        const authLocalStorage = AuthRepository();
        const auth = authLocalStorage.get();
        if (auth) {
            saveAuth({...auth, user: {...auth.user, name, phoneNumber, document }});
        }
    }, [saveAuth]);

    const handleUpdateData = useCallback(async () => {
        await UpdateBaseUserUseCase(service);
        if (currentLocal) {
            await UpdateBaseLocalUseCase(service, currentLocal.id);
        }
    }, [currentLocal]);

    const handleRegister = useCallback(async (values: IFormValues) => {
        try {
            setIsLoading(true);
            await handleUpdateUser({
                name: values.name,
                document: values.document,
                phoneNumber: values.phone,
            });
            await handleUpdateLocal({
                document: values.establishmentDocument,
                zipCode: values.cep,
                street: values.street,
                number: values.number,
                complement: values.complement,
                neighborhood: values.neighborhood,
                city: values.city,
                uf: values.state,
                country: values.country,
            });

            updateAuthLocalStorage(values.name, values.phone, values.document);
            toast("Dados atualizados com sucesso!", "success");
            onClose();
            handleUpdateData();
        } finally {
            setIsLoading(false);
        }
    }, [handleUpdateData, handleUpdateLocal, handleUpdateUser, onClose, toast, updateAuthLocalStorage]);

    return {
        isLoading,
        address,
        handleRegister,
    }
};