import React, { useEffect, useState } from 'react'
import styles from './AllMovements.module.scss'
import { AttachMoney, CreditCard, Money, MoreHoriz } from '@mui/icons-material'
import { moneyMaskNumber } from 'services/utils/Money'
import { CircularProgress } from '@mui/material'
import { IAllMovements } from '../../interfaces/IAllMovements'
import PixIcon from '../../../../../res/assets/icons/iconGeneralDash/pix-icon.png';
import VoucherIcon from '../../../../../res/assets/icons/iconGeneralDash/voucher-icon.png';
import MoneyIcon from '../../../../../res/assets/icons/iconGeneralDash/money.png';

interface IAllmovimentsProps {
    getAllMovements: () => Promise<IAllMovements | undefined>
    allMovements: IAllMovements | undefined
    isLoading: boolean | undefined
}

export const AllMovements: React.FC<IAllmovimentsProps> = ({ allMovements, isLoading, getAllMovements }) => {
    const [creditTotal, setCreditTotal] = useState<number>(0);
    const [debitTotal, setDebitTotal] = useState<number>(0);
    const [moneyTotal, setMoneyTotal] = useState<number>(0);
    const [pixTotal, setPixTotal] = useState<number>(0);
    const [voucherTotal, setVoucherTotal] = useState<number>(0);
    const [othersTotal, setOthersTotal] = useState<number>(0);

    useEffect(() => {
        getAllMovements();
    }, [getAllMovements])

    useEffect(() => {
        if (allMovements) {
            const credit = allMovements.credit || 0;
            const debit = allMovements.debit || 0;
            const money = allMovements.money || 0;
            const pix = allMovements.pix || 0;
            const voucher = allMovements.voucher || 0;
            const others = allMovements.other || 0;

            setCreditTotal(credit);
            setDebitTotal(debit);
            setMoneyTotal(money);
            setPixTotal(pix)
            setVoucherTotal(voucher)
            setOthersTotal(others);
        }
    }, [allMovements]);
    return (
        <div id={styles.AllMovements}>
            <div className={styles.top}>
                <p>Todas movimentações</p>
            </div>
            <div className={styles.mid}>
                <p>Total</p>
                {
                      isLoading ? 
                        <div className={styles.loading}><CircularProgress  size={16} color="inherit" /> </div>
                        :
                        <p>{moneyMaskNumber(allMovements?.total)}</p>
                }
            </div>
            <div className={styles.bottom}>
                <div>
                    <p>Crédito <CreditCard className={styles.credit} /> </p>
                    {
                        isLoading ? 
                        <div className={styles.loading}><CircularProgress  size={16} color="inherit" /> </div>
                        :
                        <p>{moneyMaskNumber(creditTotal)}</p>
                    }
                </div>
                <div className={styles.separator}></div>
                <div>
                    <p>Débito  <CreditCard className={styles.debit} /></p>
                    {
                        isLoading ? 
                        <div className={styles.loading}><CircularProgress  size={16} color="inherit" /> </div>
                        :
                        <p>{moneyMaskNumber(debitTotal)}</p>
                    }
                </div>
                <div className={styles.separator}></div>
                <div>
                    <p>Dinheiro <img src={MoneyIcon} alt="Pix_Icon" style={{width: '26px', height: '19px'}}/></p>
                    {
                        isLoading ? 
                        <div className={styles.loading}><CircularProgress  size={16} color="inherit" /> </div>
                        :
                        <p>{moneyMaskNumber(moneyTotal)}</p>
                    }
                </div>
                <div className={styles.separator}></div>
                <div>
                    <p>Pix <img src={PixIcon} alt="Pix_Icon" style={{width: '26px', height: '26px'}}/></p>
                    {
                        isLoading ? 
                        <div className={styles.loading}><CircularProgress  size={16} color="inherit" /> </div>
                        :
                        <p>{moneyMaskNumber(pixTotal)}</p>
                    }
                </div>
                <div className={styles.separator}></div>
                <div>
                    <p>Voucher <img src={VoucherIcon} alt="Voucher_Icon" style={{width: '26px', height: '26px'}} /></p>
                    {
                        isLoading ? 
                        <div className={styles.loading}><CircularProgress  size={16} color="inherit" /> </div>
                        :
                        <p>{moneyMaskNumber(voucherTotal)}</p>
                    }
                </div>
                <div className={styles.separator}></div>
                <div>
                    <p>Outros <MoreHoriz className={styles.others} /></p>
                    {
                        isLoading ? 
                        <div className={styles.loading}><CircularProgress  size={16} color="inherit" /> </div>
                        :
                        <p>{moneyMaskNumber(othersTotal)}</p>
                    }
                </div>
            </div>
        </div>
    )
}
