import { useLocal } from "modules/local/presentation/context/LocalContext";
import { useCallback, useState } from "react";
import { IFilterValue } from "../interfaces/IFilter";
import { IRankingProducts } from "../interfaces/IRankingProducts";
import GeneralDashboardApi from "services/api/generalDashboard/GeneralDashboardApi";
import GetRankingProductsUseCase from "modules/generalDashboard/application/useCases/GetRankingProductsUseCase";


export interface IParamsUseRankingProducts {
  localId?: string,
  sessionId?: string,
}

const UseRankingProducts = (filter: IFilterValue | undefined) => {

  const [isLoading, setIsLoading] = useState<boolean>();
  const [rankingProducts, setRankingProducts] = useState<IRankingProducts[]>()
  const { currentLocal } = useLocal();

  const getRankingProducts = useCallback(
    async (params?: IParamsUseRankingProducts) => {
      try {
        const serviceGeneral = GeneralDashboardApi();
        setIsLoading(true);
        if (currentLocal) {
          const response = await GetRankingProductsUseCase(serviceGeneral,
            currentLocal.id,
            {
              ...filter,
            },
          );

          setRankingProducts(response);

          return response
        }
      } finally {
        setIsLoading(false);
      }
    },
    [currentLocal, filter]
  );


  return {
    rankingProducts,
    isLoading,
    getRankingProducts,
    setRankingProducts,
  };
};

export default UseRankingProducts;
