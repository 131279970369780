import { IGetAllMovementsResponse } from "modules/generalDashboard/domain/dto/IGetAllMovementsResponse";
import { IGeneralDashboardApiService } from "modules/generalDashboard/domain/interfaces/IGeneralDashboardApiService";



const GetAllMovementsUseCase = async (service: IGeneralDashboardApiService, localId: string): Promise<IGetAllMovementsResponse> => {
    const response = await service.getAllMovements(localId);

    return response;
}

export default GetAllMovementsUseCase;