import { IKDSItem } from "modules/kds/presentation/components/KDSItem/IKDSItem";
import { INFCeItem } from "modules/kds/presentation/interfaces/IIssueNFE";
import { ILocalPrinterNFCe } from "../domain/dtos/ILocalPrinterNFCe";
import { INFCeLocalPrinterService } from "../domain/interfaces/INFCeLocalPrinterService";


const SendNFCeToLocalPrinterUseCase = async (
  localPrinterService: INFCeLocalPrinterService,
  item: string,
  order: IKDSItem
) => {

  const localPrinterNFCe: ILocalPrinterNFCe = {
    id: order.id,
    table: order.table??"",
    nfceItem: item,
    printerName: order.order.items.map(printer => printer.printerName)[0],
    orderItem: order.order.items.map(orderItem => ({
      printerName: orderItem.printerName,
      name: orderItem.name,
      productPrice: orderItem.value,
      quantity: orderItem.quantity,
      orderComposition: orderItem.compositions?.map((compositionItem) => ({
        description: compositionItem.description,
        compositionItems: compositionItem.options.map((option) => ({
          description: option,
        }))
      }))
    }))

  }
  await localPrinterService.printerNFCe(localPrinterNFCe, order.code, order.customer?.name?? "" + " " + order.table, order.remotePrinter.address);
};
export default SendNFCeToLocalPrinterUseCase;
