import { ICheckProductListResponse } from "modules/onboarding/domain/dto/ICheckProductListResponse"
import { IOnboardingService } from "modules/onboarding/domain/interfaces/IOnboardingService"
import { api } from "../Api"
import { ICheckTeamResponse } from "modules/onboarding/domain/dto/ICheckTeamResponse"
import { ICheckSalesManagementResponse } from "modules/onboarding/domain/dto/ICheckSalesManagementResponse"

export const OnboardingApi = (): IOnboardingService => {
    const checkProductList = async (ownerId: string): Promise<ICheckProductListResponse> => {
        const response = await api.get(`/local/${ownerId}/produtos/listas/verificar`);
        return response?.data?.Dado || false;
    }

    const checkTeam = async (ownerId: string): Promise<ICheckTeamResponse> => {
        const response = await api.get(`/acesso/colaborador/ExisteColaborador/${ownerId}`);
        return response?.data || false;
    }

    const checkSalesManagement = async (ownerId: string): Promise<ICheckSalesManagementResponse> => {
        const response = await api.get(`/Proprietario/VerficarConfiguracaoGestao/${ownerId}`);
        return {
          tipoOperacao: response.data.TipoOperacao,
          data: response.data.Data
        };
    }

    return {
        checkProductList,
        checkTeam,
        checkSalesManagement
    }
}
