import { useUi } from "contexts/userInterface/UserInterfaceContext";
import CreateCampaignWithCampaignId from "modules/crm/application/useCases/CreateCampaignWithCampaignId";
import CreateCampaignWithCustomerAndFiltersUseCase from "modules/crm/application/useCases/CreateCampaignWithCustomerAndFilters";
import { useLocal } from "modules/local/presentation/context/LocalContext";
import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import CrmServiceApi from "services/api/crm/CrmApi";
import {
  ICrmFilterValue,
  IDropdown,
} from "../components/crmFilterForm/interfaces/ICrmFilterValue";
import { useSelectCrmCustomer } from "../context/SelectCustomerContext";
import UseCampaignsList from "../hook/UseCampaigns";
import UseGetCustomersCrm, { ParamsClientCrm } from "../hook/UseCustomersCrm";
import UseCrmEventList from "../hook/UseCrmEventList";
import UseGetEventsByCategory from "../hook/UseGetEventsByCategory";
import UseGetTicketCategories from "../hook/UseGetTicketCategories";
import UseSavedFilter from "../hook/UseSavedFilter";
import { useQuery } from "hooks/UseQuery";
import { UseFidelityPage } from "modules/fidelity/presentation/UseFidelityPage";
const UseCrmPage = () => {
  const { customers, getData, getCustomerEvents, isLoadingCrmCustomer } =
    UseGetCustomersCrm();
  const { customerQuantity } = useSelectCrmCustomer();

  const { eventsList, getEventsList } = UseCrmEventList();
  const { campaignsList, getCampaignsList } = UseCampaignsList();
  const { eventsByCategory, getEventsByCategory } = UseGetEventsByCategory();
  const { ticketCategories, getTicketCategoriesData } =
    UseGetTicketCategories();
  const {
    postSaveFilter,
    savedFilters,
    isLoadingCrmSavedFilter,
    getSavesdFilters,
    deleteFilter,
  } = UseSavedFilter();
  const [params, setParams] = useState<ParamsClientCrm>();

  const {
    selectAll,
    unSelectAll,
    onChangeFilter,
    selectedCustomer,
    selectedException,
  } = useSelectCrmCustomer();

  const { fidelityList } = UseFidelityPage();


  const { currentLocal } = useLocal();
  const { showLoading, hideLoading } = useUi();
  const { push } = useHistory();

  const query = useQuery();

  useEffect(() => {
    const keyword = query.get("keyword");
    if (keyword) {
      setParams((prev) => ({ ...prev, filter: { keyword: keyword } }));
    }
  }, [query]);

  const onChangeFilterHandle = useCallback((filter: ICrmFilterValue) => {
    setParams((prev) => ({ ...prev, filter }));
  }, []);

  const onChangePaginationHandle = useCallback(
    (pagination: { page?: number; pageSize?: number }) => {
      setParams((prev) => ({ ...prev, pagination }));
    },
    []
  );

  const goToCampaignEditPage = useCallback(
    (campaignId: string) => {
      push("/private/crm/campaign/edit/" + campaignId);
    },
    [push]
  );

  const createCampaignWithCustomerAndFilters = useCallback(async () => {
    try {
      showLoading();
      if (currentLocal) {
        const service = CrmServiceApi();
        const response = await CreateCampaignWithCustomerAndFiltersUseCase(
          service,
          currentLocal.id,
          {
            filter: params?.filter,
            customers: selectedCustomer,
            exceptionCustomers: selectedException,
            totalAudience: customerQuantity,
          }
        );
        goToCampaignEditPage(response.id);
      }
    } finally {
      hideLoading();
    }
  }, [currentLocal, customerQuantity, goToCampaignEditPage, hideLoading, params?.filter, selectedCustomer, selectedException, showLoading]);

  const onClickCreateCampaignWithEvent = useCallback(
    async (events: IDropdown[]) => {
      try {
        showLoading();
        if (currentLocal) {
          const service = CrmServiceApi();
          const response = await CreateCampaignWithCustomerAndFiltersUseCase(
            service,
            currentLocal.id,
            {
              filter: { events },
              totalAudience: customerQuantity
            }
          );
          goToCampaignEditPage(response.id);
        }
      } finally {
        hideLoading();
      }
    },
    [currentLocal, customerQuantity, goToCampaignEditPage, hideLoading, showLoading]
  );

  const onClickCreateCampaignByCampaign = useCallback(
    async (campaign: IDropdown) => {
      try {
        showLoading();
        if (currentLocal) {
          const service = CrmServiceApi();
          const response = await CreateCampaignWithCampaignId(
            service,
            campaign.id
          );
          goToCampaignEditPage(response.id);
        }
      } finally {
        hideLoading();
      }
    },
    [currentLocal, goToCampaignEditPage, hideLoading, showLoading]
  );

  useEffect(() => {
    getData({ ...params });
  }, [getData, params]);

  useEffect(() => {
    if (customers) {
      onChangeFilter(params?.filter ?? {}, customers?.totalRegisters);
    }
  }, [customers, customers?.totalRegisters, onChangeFilter, params]);

  useEffect(() => {
    getEventsList();
  }, [getEventsList]);

  useEffect(() => {
    getCampaignsList();
  }, [getCampaignsList]);

  useEffect(() => {
    getEventsByCategory();
  }, [getEventsByCategory]);

  useEffect(() => {
    getTicketCategoriesData();
  }, [getTicketCategoriesData]);

  useEffect(() => {
    getSavesdFilters();
  }, [getSavesdFilters]);

  //TODO: Useeffect get fidelity list

  const onClickSelectAllHandle = useCallback(
    (value: boolean) => {
      if (value) {
        selectAll();
      } else {
        unSelectAll();
      }
    },
    [selectAll, unSelectAll]
  );

  return {
    customers,
    eventsList,
    eventsByCategory,
    ticketCategories,
    onClickSelectAllHandle,
    onChangeFilterHandle,
    onChangePaginationHandle,
    postSaveFilter,
    savedFilters,
    isLoadingCrmCustomer,
    getSavesdFilters,
    deleteFilter,
    params,
    getCustomerEvents,
    isLoadingCrmSavedFilter,
    createCampaignWithCustomerAndFilters,
    onClickCreateCampaignWithEvent,
    onClickCreateCampaignByCampaign,
    campaignsList,
    fidelityList
  };
};

export default UseCrmPage;
